import { MobiModule } from '@/modules/Module';
import FloatEffectView from './module/visitor.vue';

class _FloatEffect extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return FloatEffectView;
    }
}

export const FloatEffect = new _FloatEffect();
