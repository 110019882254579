import { fixUrl as FaiFixUrl, addUrlParams } from '@/shared/fai';
import { isDevEnv, REQUEST_BASE_URL, MANAGE_DOMAIN, MANAGE_SITE_BASE_URL } from '@/shared/env';
export function getUrlParamObj(searchStr) {
    var paramObj = new Object();
    if (searchStr.indexOf('?') != -1) {
        var str = searchStr.substr(1); //去除问号
        let strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
            var key = decodeURIComponent(strs[i].split('=')[0]);
            var value = strs[i].split('=')[1];
            if (value) {
                paramObj[key] = decodeURIComponent(value);
            } else {
                paramObj[key] = '';
            }
        }
    }
    return paramObj;
}

export function getUrlParam(url, name) {
    var paramStrings = url.substring(url.indexOf('?') + 1, url.length).split('&');
    var value;
    jm.each(paramStrings, function (index, str) {
        var tmpKey = decodeURIComponent(str.substring(0, str.indexOf('=')));
        if (tmpKey === name) {
            value = decodeURIComponent(str.substring(str.indexOf('=') + 1, str.length));
            return false;
        }
    });
    return value;
}

export const getSiteMultiLanguageUrl = function () {
    if (isDevEnv) {
        return MANAGE_SITE_BASE_URL;
    }
    const topUrl = window.location.href,
        locFlag = topUrl.split('/')[3],
        siteDomain = window._siteDomain;
    const notLocFlag = ['manage', '#'];
    if (!notLocFlag.includes(locFlag)) {
        return siteDomain + '/' + locFlag;
    }
    return siteDomain + '';
};

export const getMobiMultiLanguageUrl = function () {
    const topUrl = window.location.href,
        locFlag = topUrl.split('/')[3],
        mobiDomain = isDevEnv ? REQUEST_BASE_URL : `${location.origin}`;
    const notLocFlag = ['manage', '#'];
    if (!notLocFlag.includes(locFlag)) {
        return mobiDomain + '/' + locFlag;
    }
    return mobiDomain + '';
};

export const changeNavigatorUrl = function (url) {
    // 移除管理态域名
    {
        // eslint-disable-next-line @jz/no-use-http-prefix
        url = url.replace('http://' + Fai.top._userHostName, '');

        // eslint-disable-next-line @jz/no-use-http-prefix
        url = url.replace('https://' + Fai.top._userHostName, '');
    }

    // 移除安全模式
    {
        if (url.indexOf('_safeMode=false&') > -1) {
            url = url.replace('_safeMode=false&', '');
        } else if (url.indexOf('?_safeMode=false') > -1) {
            url = url.replace('?_safeMode=false', '');
        } else if (url.indexOf('&_safeMode=false') > -1) {
            url = url.replace('&_safeMode=false', '');
        }
    }

    return url;
};

export const encodeUrl = (url) => (typeof url === 'undefined' ? '' : encodeURIComponent(url));

export function fixUrl(url, hostUrl) {
    if (!url || !hostUrl) return;
    hostUrl = FaiFixUrl(hostUrl);
    url = FaiFixUrl(url);

    url = url.indexOf(Fai.top._visitorHostName) > -1 ? url.replace(Fai.top._visitorHostName, '') : url;

    let subStrUrl = url.substring(0, hostUrl.length);
    if (subStrUrl === hostUrl) {
        return subStrUrl == url ? '/' : url.substring(hostUrl.length, url.length);
    } else {
        return url;
    }
}

export function preprocessUrl(_ref2) {
    var path = _ref2.path,
        oldPath = _ref2.oldPath,
        _ref2$isUsePath = _ref2.isUsePath,
        isUsePath = _ref2$isUsePath === void 0 ? true : _ref2$isUsePath,
        _ref2$useNew = _ref2.useNew,
        useNew = _ref2$useNew === void 0 ? false : _ref2$useNew;
    var isOpen =
        typeof Fai !== 'undefined' && typeof Fai.top !== 'undefined' && typeof Fai.top._curLanCode !== 'undefined';
    var hasLanCode = isOpen && window.location.pathname.indexOf('/' + Fai.top._curLanCode + '/') !== -1;

    if (!useNew) {
        if (!isUsePath || !isOpen) {
            return oldPath;
        } else {
            if (hasLanCode) {
                return '/' + Fai.top._curLanCode + '/' + path.replace(/^\/+/, '');
            } else {
                return '/' + path.replace(/^\/+/, '');
            }
        }
    } else {
        if (hasLanCode) {
            return '/' + Fai.top._curLanCode + '/' + path.replace(/^\/+/, '');
        } else {
            return '/' + path.replace(/^\/+/, '');
        }
    }
}

export const getMobiDomainPath = function () {
    let mobiDomain = isDevEnv ? MANAGE_DOMAIN : Fai.top._mobiDomain;
    return mobiDomain;
};

const addBackQueryParam = function (baseUrl, hash) {
    return addUrlParams(baseUrl, '_back=' + encodeUrl(Fai.top.window.location.href)) + (hash ? '#' + hash : '');
};

export function createManagePageUrl(initPage = 'manageFrameJZAllSiteStat', hash) {
    if (Fai.top._isManageFrame) {
        return Fai.top.Site._createManagePageUrl(initPage);
    }

    let baseUrl = Fai.top.httpStr + Fai.top._jzAllSiteDomain;
    let params = ['_item=' + initPage, 'mobiColId=' + Fai.top._colId, 'siteId=' + Fai.top._siteId, '_mobiV2=true'];

    if (Fai.top._lcid !== Fai.top._siteLcid) {
        params.push('_lcid=' + Fai.top._lcid);
    }

    if (Fai.top._isLoginO) {
        params.push('__tmpaid=' + Fai.top._aid);
    }

    baseUrl = params.reduce(function (acc, param) {
        return (acc = Fai.addUrlParams(acc, param));
    }, baseUrl);

    return addBackQueryParam(baseUrl, hash);
}

export function storageReturnUrl(returnUrl) {
    if (!returnUrl.match(/login.jsp$/)) {
        localStorage.setItem('returnUrl', jm.encodeUrl(returnUrl));
    }
}

// 从jzm进来，跳转其他页面时需要把标志jzm进来的参数带上
export function fixJzmHomeIframeParam($link, href) {
    if (getUrlParam(window.location.href, '_fromJzmHome') != 'true') {
        return href;
    }
    if (typeof href != 'string') return href;

    if (
        href.indexOf('mailto') >= 0 ||
        href.indexOf('sms') >= 0 ||
        href == '#' ||
        href.indexOf('javascript:') >= 0 ||
        href.indexOf('tel') >= 0
    ) {
        return href;
    }

    var paramLink = href.indexOf('?') < 0 ? '?' : '&',
        newHref = href + paramLink + '_fromJzmHome=true';

    $link.attr('href', newHref);

    return newHref;
}

//mobi端previewWindow专用ajax跳转路径的赋值
export function genAjaxUrl(url) {
    var beg = '';
    if (document.location.pathname.indexOf('/manage/') >= 0) {
        beg = preprocessUrl({ path: '', oldPath: '../' }) + 'ajax/';
    } else {
        beg = preprocessUrl({ path: '', oldPath: '' }) + 'ajax/';
    }
    return beg + url;
}
