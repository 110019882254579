import { bindTapEvent } from '../event';
import { deepExtendClone } from '@jz/utils';
import { slideshowView as bizSharedSlideshowView } from '@jz/biz-shared';

import jzFdpLogIns from '../fdpForJz';

/*
    初始化幻灯片功能（图册目录，轮播多图，列表多图）
    data eg:
    selector: "#module67 .photoDiv"  // string 图片集节点

    photoJson: [
        {
            id: 0, // int 必须 selector上dom的data-picid，通过id查找当前点击进入的是第几张照片
            src: "", // string 图片路径
            w: 0, // int 图片原始宽度
            h: 0, // int 图片原始高度
            title: "", // string 图片名字
            description: "", // string 图片描述
            detail: false, // boolean 图片是否有详情页
            openLink: false, // boolean 图片是否跳转
        },
        {...},
        {...},
    ]

    options = {
        fromCurentInx: false,                                       // boolean 从当前点击的图片开始播放
        captionDetailClass: "",                                     // string 字幕区皮肤控制class
        addCaptionDetailHTMLFn: addCaptionDetailHTMLFn,             // function 字幕区详情HTML定制
        captionDetailCloseCallBackFn: captionDetailCloseCallBackFn, // function 关闭字幕区详情回调
    }

    selector的HTML要求：dom上有data-picid = 'photo.id' 否则
*/
export function initPictureSlidesV4(selector, photoJson, options, nolink = false) {
    if (photoJson.length < 1) {
        return;
    }
    //图文展示 详情 ue
    // 图册目录 详情
    // 文章详情 ue
    // 产品展示详情 ue
    // 产品展示详情 头图 等模块使用新幻灯片
    // 拼接幻灯片图片的数据
    var images = [];
    for (var i = 0; i < photoJson.length; i++) {
        if (photoJson[i].src && /image\/v2\/defaultIcon/.test(photoJson[i].src)) {
            continue;
        }
        images.push({
            src: photoJson[i].src,
            w: photoJson[i].width,
            h: photoJson[i].height,
            title: photoJson[i].title,
            description: typeof photoJson[i].desc == 'undefined' ? '' : photoJson[i].desc,
            detail: photoJson[i].detail,
            detailContent: photoJson[i].detailContent ? photoJson[i].detailContent : null,
        });
    }
    Fai.top.previewSrcList = images;
    const slideshowViewConfig = {};
    slideshowViewConfig.logEventConfig = {};
    slideshowViewConfig.logEventConfig['event'] = 'jz_hdpzj';
    slideshowViewConfig.logEventConfig['properties'] = {};
    slideshowViewConfig.logEventConfig['properties']['jz_version'] = Fai.top.jzVersion;
    // 有传入终端则根据终端设置
    if (options.contentTerminal) {
        slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = options.contentTerminal;
    } else {
        if (selector.includes('photoGroupPicImg')) {
            slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = 'mobi-图册目录-图册详情';
        }
        if (selector.includes('photoGroupResult')) {
            slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = 'mobi-图册分类-图册筛选结果';
        }
        if (selector.includes('proDetailImg')) {
            slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = 'mobi-产品展示-产品详情';
        }
        if (selector.includes('productDetailTabPanel')) {
            slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = 'mobi-产品展示-产品详情-ue';
        }
        if (selector.includes('J_richContentPanel')) {
            slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = 'mobi-图文展示-ue';
        }
        if (selector.includes('newsDetail')) {
            slideshowViewConfig.logEventConfig['properties']['jz_content_terminal'] = 'mobi-文章详情-ue';
        }
    }
    const id = selector.replace(/#|\.|[ ]*/g, '');
    bindTapEvent(selector, 'tap', function () {
        const $parent = jm(this).parent('.swipImgBox');
        if ($parent.find('.videoPlay').length && selector.includes('proDetailImg')) {
            return;
        }

        const idx = $parent.attr('data-index');
        const picId = jm(this).attr('data-picId');

        let initialIndex = 0;
        if (picId) {
            for (var i = 0; i < photoJson.length; i++) {
                if (photoJson[i].id === picId) {
                    initialIndex = i;
                    break;
                }
            }
        } else {
            initialIndex = parseInt(idx) || 0;
        }
        if (!nolink && photoJson[initialIndex].openLink) {
            // 图片跳转则不进入幻灯片
            return;
        }
        slideshowViewConfig.id = id;
        slideshowViewConfig.previewSrcList = images;
        slideshowViewConfig.zIndex = 9999;
        slideshowViewConfig.initialIndex = initialIndex;
        slideshowView(slideshowViewConfig);
    });
}

/**
 * @function name slideshowView
 * @description 幻灯片
 * @author mikey.chuhui
 * @date 2021-02-03
 * @param options {Object}
 * @example
 * Site.slideshowView({
 *                   id: 999,//幻灯片id Number
 *                   previewSrcList: [{src:"",title:"title", description:"description"}], // 幻灯片图片列表 Array
 *                   initialIndex: 0,// 打开幻灯片的默认图片 Number
 *                   logEventConfig: {// fdplog
 *                      jz_content_terminal: 'site',
                        jz_version: Fai.top.jzVersion,
 *                   },
 *               });
 */

export const slideshowView = (options) => {
    const _options = deepExtendClone(
        {
            id: Math.random() * 100,
            previewSrcList: [],
            zIndex: 9999,
            initialIndex: 0,
            onLog: jzFdpLogIns.logFdpRequest,
            manageMode: VITE_APP_MODE !== 'visitor',
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-产品展示-产品详情',
                    jz_version: Fai.top.jzVersion,
                },
            },
        },
        options
    );
    if (VITE_APP_MODE !== 'visitor') {
        _options.appendToContainerId = 'mobiReviewPage';
        _options.appendToBody = false;
        _options.isMobi = true;
    }
    bizSharedSlideshowView(_options);
};
