<template>
    <ModuleFrame :module-id="moduleId">
        <div class="cookie_policy_content" v-html="cookiePolicyContent"></div>
    </ModuleFrame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'CookiePolicy',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        cookiePolicyContent() {
            const regex = new RegExp('(?<! )&nbsp;(?! )', 'g');
            return this.module.cookiePolicyContent?.replace(regex, ' ') || '';
        },
    },
};
</script>

<style>
.cookie_policy_content {
    padding: 15px;
    overflow: hidden;
}
</style>
