import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { MODULE_ID } from '../prop-def';
export default {
    methods: {
        _validateHasLink(el) {
            let hasLink = false;
            if (el != null) {
                let $el = jm(el);
                while (!$el.hasClass('admReqContent')) {
                    $el = $el.parent();
                    let href = $el.attr('href');
                    if ($el[0].tagName === 'A' && href != '' && href != null && href != 'javascript:void(0)') {
                        hasLink = true;
                        break;
                    }
                }
            }
            return hasLink;
        },
        _dataHandle(selector) {
            let result = [];

            jm(selector).forEach((el, index) => {
                // 过滤掉video标签内嵌标签
                if (!jm(el).parent().is('video')) {
                    var openLink = this._validateHasLink(el);
                    var imageId = 'photoswipe_' + index;

                    var elSrc = jm(el).attr('src');
                    result.push({
                        src: elSrc,
                        width: jm(el).attr('vwidth') || jm(el).attr('picWidth') || el.naturalWidth,
                        height: jm(el).attr('vheight') || jm(el).attr('picHeight') || el.naturalHeight,
                        title: '',
                        description: '',
                        detail: false,
                        id: imageId,
                        openLink: openLink,
                    });

                    el.setAttribute('data-picId', imageId);
                }
            });
            return result;
        },
        initPictureSlides() {
            let images = [];
            let selector = null;

            {
                selector = `#module${MODULE_ID} .admReqContent img`;

                images = this._dataHandle(selector);
            }

            initPictureSlidesV4(selector, images, {
                fromCurentInx: true,
                contentTerminal: 'mobi-留言板-ue',
            });
        },
    },
};
