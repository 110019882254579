<template>
    <module-frame :module-id="moduleId">
        <component :is="component" v-if="canPrint" v-bind="propsData" />
    </module-frame>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_ID } from '../constants';
import AllGroupView from './components/all-group.vue';
import CatalogView from './components/catalog.vue';
import { getUrlParamObj } from '@/shared/url';
import ModuleFrame from '@/modules/frame/index.vue';

export default {
    name: 'ProductGroupDetailV1View',
    components: {
        AllGroupView,
        CatalogView,
        ModuleFrame,
    },
    data() {
        return {
            moduleId: MODULE_ID,
            fromMallNav: false,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleExtInfo() {
            return this.module?.extInfo ?? {};
        },
        propsData() {
            return {
                groupStyle: this.pgStyle,
                productGroupType: this.pgType,
                prUrl: this.prUrl,
                firstGroupList: this.firstGroupList,
                secondGroupMap: this.secondGroupMap,
                topLevel: this.topLevel,
                panelOptions: this.panelOptions,
                isMallTheme: this.isMallTheme,
                hasAuth: this.hasAuth,
            };
        },
        mallPgType() {
            return this.moduleExtInfo.mallPgType;
        },
        isMallTheme() {
            return this.moduleExtInfo.isMallTheme;
        },
        canPrint() {
            return this.pgType !== 1 && this.pgStyle !== 0;
        },
        pgStyle() {
            return this.moduleExtInfo.pgStyle;
        },
        pgType() {
            return this.productGroupType;
        },
        hasAuth() {
            return this.moduleExtInfo.hasAuth;
        },
        groupStyle() {
            return this.moduleExtInfo.groupStyle;
        },
        productGroupType() {
            return this.moduleExtInfo.productGroupType;
        },
        prUrl() {
            return this.moduleExtInfo.prUrl;
        },
        firstGroupList() {
            return this.moduleExtInfo.firstGroupList;
        },
        secondGroupMap() {
            return this.moduleExtInfo.secondGroupMap;
        },
        topLevel() {
            return this.moduleExtInfo.topLevel;
        },
        panelOptions() {
            return this.moduleExtInfo.panelOptions;
        },
        component() {
            if (this.pgType === 0) {
                return 'AllGroupView';
            } else if (this.pgType === 2) {
                return 'CatalogView';
            }
            return void 0;
        },
    },
    mounted() {
        this.inValidateFromMallNav();
    },
    methods: {
        inValidateFromMallNav() {
            let entry = '';
            if (VITE_APP_MODE !== 'visitor') {
                entry = this.$router.query?.entry ?? null;
            } else {
                const params = getUrlParamObj(window.location.href);
                entry = params.entry;
            }
            this.fromMallNav = entry !== null ? entry === 'mallNav' : false;
        },
    },
};
</script>
