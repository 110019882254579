export function initScaleImage(photoSwipe) {
    if (photoSwipe) {
        var allImageDom = photoSwipe.querySelectorAll('img'),
            viewWidth = photoSwipe.clientWidth,
            scaleImg = 0;
        if (allImageDom) {
            for (var item = 0; item < allImageDom.length; item++) {
                var vWidth = parseInt(
                        allImageDom[item].getAttribute('vwidth') ? allImageDom[item].getAttribute('vwidth') : 1
                    ),
                    vHeight = parseInt(
                        allImageDom[item].getAttribute('vheight') ? allImageDom[item].getAttribute('vheight') : 1
                    ),
                    scale = vHeight / vWidth;
                allImageDom[item].style.width = viewWidth + 'px';
                // allImageDom[item].style.height =  Math.floor(viewWidth * scale) + "px";
                allImageDom[item].style.height = '100%';
                if (scale > scaleImg) {
                    scaleImg = scale;
                }
            }
            if (viewWidth * scaleImg != 0) {
                photoSwipe.style.height = Math.floor(viewWidth * scaleImg) + 'px';
            }

            //产品详情页 依据图片高度
            if (photoSwipe.className == 'proImgSwipe') {
                var proDetailImg = photoSwipe.querySelector('.proDetailImg');
                if (proDetailImg) {
                    photoSwipe.style.height = proDetailImg.clientHeight + 'px';
                }
            }
        }
    }
}
