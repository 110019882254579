// @ts-check
import { MobiModule } from '@/modules/Module';
import NewNewsDetailVisitor from './visitor.vue';
import { MODULE_STYLE } from '@/def/module';

class NewNewsDetailModule extends MobiModule {
    constructor() {
        super(MODULE_STYLE.NEWS_NEW_DETAIL, { system: true });
    }

    /** @override */
    render() {
        return NewNewsDetailVisitor;
    }
}

export const NewNewsDetail = new NewNewsDetailModule();
