import { request } from '@/api/request';
export const MonitorDef = {};

/*
 * 『业务模块监控』: 支持统计业务次数，耗时，错误率
 */
MonitorDef.MonitorId = {
    // 模块通用操作
    // 添加模块
    ADD_MODULE: 7743,
    // 隐藏模块
    HIDE_MODULE: 7744,
    // 复制模块
    COPY_MODULE: 7745,
    // 刷新模块(旧的模块)
    REFRESH_MODULE: 7747,
    // 切换模块状态
    SWITCH_STATUS: 7873,
    // 特殊模块行为
    // 表单提交
    SUBMIT_FORM: 7748,
    // 标签模块按需加载
    TAB_CONTAINER_SWITCH: 7749,
    // 折叠标签模块按需加载
    FOLD_CONTAINER_SWUTCH: 7750,
    // 重要交互
    // 打开面板
    OPEN_PANEL: 7751,
    // 大保存
    DESIGN_SAVE: 7752,
    // 新增页面
    ADD_PAGE: 7753,
    // 打开极速建站
    OPEN_RAPID_SITE: 7754,
};

/*
 * 『普通业务监控』: 原有的业务监控，统计业务次数, 可配置告警以及实时视图
 */
MonitorDef.AlarmMonitorId = {
    // 模块通用操作
    // 添加模块
    ADD_MODULE: 7755,
    // 隐藏模块
    HIDE_MODULE: 7756,
    // 复制模块
    COPY_MODULE: 7757,
    // 刷新模块(旧的模块)
    REFRESH_MODULE: 7758,
    // 切换模块状态
    SWITCH_STATUS: 7875,
    // 特殊模块行为
    // 表单提交
    SUBMIT_FORM: 7759,
    // 标签模块按需加载
    TAB_CONTAINER_SWITCH: 7760,
    // 折叠标签模块按需加载
    FOLD_CONTAINER_SWUTCH: 7761,
    // 重要交互
    // 打开面板
    OPEN_PANEL: 7762,
    // 大保存
    DESIGN_SAVE: 7763,
    // 新增页面
    ADD_PAGE: 7764,
    // 打开极速建站
    OPEN_RAPID_SITE: 7766,
    // 关键函数
    // JZ_UTILS 报错监控
    JZ_UTILS: 7767,
};

const MonitorId = MonitorDef.MonitorId;
const AlarmMonitorId = MonitorDef.AlarmMonitorId;

/*
 * 自动告警监控
 * 在这里配置业务模块监控与普通业务监控（告警）的对应关系
 * 在业务监控接收到错误时自动进行告警监控的统计，可以省去手动进行告警监控的步骤
 */
MonitorDef._AutoAlarmMonitorMap = {
    [MonitorId.ADD_MODULE]: AlarmMonitorId.ADD_MODULE,
    [MonitorId.HIDE_MODULE]: AlarmMonitorId.HIDE_MODULE,
    [MonitorId.COPY_MODULE]: AlarmMonitorId.COPY_MODULE,
    [MonitorId.REFRESH_MODULE]: AlarmMonitorId.REFRESH_MODULE,
    [MonitorId.FOLD_CONTAINER_SWUTCH]: AlarmMonitorId.FOLD_CONTAINER_SWUTCH,
    [MonitorId.TAB_CONTAINER_SWITCH]: AlarmMonitorId.TAB_CONTAINER_SWITCH,
    [MonitorId.OPEN_PANEL]: AlarmMonitorId.OPEN_PANEL,
    [MonitorId.DESIGN_SAVE]: AlarmMonitorId.DESIGN_SAVE,
    [MonitorId.ADD_PAGE]: AlarmMonitorId.ADD_PAGE,
    [MonitorId.OPEN_RAPID_SITE]: AlarmMonitorId.OPEN_RAPID_SITE,
};

export class Monitor {
    // 业务模块监控：业务执行
    // 业务执行结束后需执行结束句柄哦
    // 推荐直接使用返回的句柄进行结束统计，无法拿到句柄可使用endMonitor方法
    static startMonitor(monitorId) {
        if (!monitorId) {
            console.error('请传入 monitorId');
            return () => {};
        }

        // 记录开始时间
        const startTime = new Date();
        Monitor._monitors[monitorId] = { startTime };
        // 返回一个执行结束的函数句柄
        return (isSuccess = true, err) => this.endMonitor(monitorId, isSuccess, err);
    }

    // 业务模块监控：业务执行结束
    // 直接用 startMonitor 返回的函数句柄进行结束统计更方便哦（适用于不易拿到结束句柄的场景）
    static endMonitor(monitorId, isSuccess = true, err) {
        // 获取监控数据
        const monitor = Monitor._monitors[monitorId];
        delete Monitor._monitors[monitorId];
        // 检测监控数据是否存在
        if (!monitor || !monitor.startTime) {
            // console.error(`请先开启 monitorId：${ monitorId } 的 Monitor`);
            return;
        }

        // 数据上报
        this.logMonitor(monitorId, !isSuccess, new Date() - monitor.startTime);

        if (err) {
            this.logErr(
                `mobiFrontendMonitorErr, monitorId: ${monitorId}; href: ${location.href}; errmsg:${
                    err.stack || err.toString()
                }`
            );
        }
    }

    // 业务模块监控（手动）
    // 支持统计业务次数，耗时，错误率
    static logMonitor(monitorId, isError, consumeTime) {
        this._sendData({
            monitorId: monitorId,
            cmd: 'wafNotCk_logMonitor',
            isError: isError,
            consumeTime: consumeTime,
            alarmMonitorId: this._getAutoAlarmMonitorId(monitorId),
        });
    }

    // 普通业务监控（手动告警监控）
    // 推荐通过配置 _AutoAlarmMonitorMap 进行自动告警监控
    // 原有的业务监控，统计业务次数，可配置告警以及实时视图
    static logAlarmMonitor(monitorId) {
        this._sendData({
            monitorId: monitorId,
            cmd: 'wafNotCk_logAlarmMonitor',
        });
    }

    // 发送 ajax 请求，把数据统计到后台
    static _sendData(data) {
        // 监控数据调试
        if (location.search.includes('monitor_log')) {
            console.log('监控上报', data);
        }

        const url = '/ajax/frontMonitor_h.jsp';
        if (typeof jQuery !== 'undefined') {
            // JQ = jQuery
        } else if (typeof jm !== 'undefined') {
            // JQ = jm
        } else {
            // 没有引入JQ或者jm，不进行log
            return;
        }

        request
            .post(url, {
                params: data,
            })
            .then((response) => {
                let { data } = response;
                let result = data;
                if (!result.success) {
                    console.error('监控数据上报错误', result);
                }
            })
            .catch((err) => {
                console.error('监控数据上报错误', err);
            });
    }

    //将错误信息上报到后台日志
    static logErr(str) {
        if (!str) return;

        request
            .get('/ajax/log_h.jsp', {
                params: {
                    cmd: 'wafNotCk_err',
                    err: encodeURIComponent(str),
                },
            })
            .catch((e) => {
                console.log('log_h error', e);
            });
    }

    // 获取自动告警监控时需要的告警id
    static _getAutoAlarmMonitorId(monitorId) {
        return MonitorDef._AutoAlarmMonitorMap[monitorId] || 0;
    }

    // 监控修饰器
    // 以后再扩展ES7 Decorator（目前大部分场景不适用ES7 Decorator，环境也要改）
    static monitorDecorator(func) {
        return (monitorId) =>
            function () {
                let result;
                const endMonitor = Monitor.startMonitor(monitorId);
                try {
                    result = func.apply(this, arguments);
                } catch (err) {
                    endMonitor(false, err);
                    throw err;
                }
                // 异步兼容（Promise、async function）
                if (result instanceof Promise) {
                    return result
                        .then((data) => {
                            endMonitor(true);
                            return data;
                        })
                        .catch((err) => {
                            endMonitor(false, err);
                            throw err;
                        });
                } else {
                    endMonitor(true);
                    return result;
                }
            };
    }
}

Monitor._monitors = {};
if (typeof window !== 'undefined') {
    window.Monitor = Monitor;
    window.MonitorDef = MonitorDef;
}

// 在 Function 原型上挂载 bindMonitor 方法方便调用
Function.prototype.bindMonitor = function (monitorId) {
    return Monitor.monitorDecorator(this)(monitorId);
};
