import { getModuleInfoById } from '@/api/module.js';
import { MODULE_STYLE, NewsDetailV3ModuleId } from '@/def/module';
import { isRecommendedNewsListModule } from '@/modules/type-mapping';

export const refreshModule = async (id, div, name, moduleInfo, args = {}) => {
    // 重新获取模块数据，旧版是通过修改dom方式
    if (moduleInfo == null) {
        let data = await getModuleInfoById(id, window._colId, window._extId, args);
        let vueModuleInfo = window.$store.state.currentPageModuleIdMap[id];
        let { style } = vueModuleInfo;
        if (style == MODULE_STYLE.SYS_PHOTOGROUP_RESULT) {
            // 图册筛选结果需要groupId，太难传了，先这样处理
            moduleInfo = data.moduleInfo;
            moduleInfo.extInfo = vueModuleInfo.extInfo; // 这里是图片数据，弹窗没改动过，可以直接使用
            // moduleInfo = Object.assign({}, vueModuleInfo, data.moduleInfo);
        } else {
            moduleInfo = Object.assign(vueModuleInfo, data.moduleInfo);
        }
    } else {
        let { allAttrV3 } = moduleInfo;
        if (allAttrV3 == null) {
            allAttrV3 = {
                patternV3: moduleInfo.patternV3,
                moduleName: moduleInfo.name,
                patternChanged: false,
                moduleNameChanged: false,
            };
            moduleInfo.allAttrV3 = allAttrV3;
        }
    }

    window.$store.commit('updateModule', moduleInfo);
    // getModuleInfoById(id, window._colId, window._extId).then((data) => {
    //     let { moduleInfo } = data;
    //     window.$store.commit('updateModule', moduleInfo);
    // });
    // 参考旧版mobi, preview/module/manage.js
    if (window._openThemeV3) {
        let moduleInfo = window.$store.state.currentPageModuleIds[id];
        if (moduleInfo != null) {
            let allAttrV3 = moduleInfo.allAttrV3;
            allAttrV3.moduleName = name;
        }
    }
};

export const refreshNewsListModule = function (moduleId) {
    if (isRecommendedNewsListModule(moduleId)) {
        const newsDetail = window.$store.getters.getModuleById(NewsDetailV3ModuleId);
        const newsId = newsDetail.renderOptions.newsInfo?.id ?? -1;
        if (newsId !== -1) {
            return refreshModule(moduleId, undefined, undefined, undefined, {
                id: newsId,
            });
        }
    } else {
        return refreshModule(moduleId);
    }
};
