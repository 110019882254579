import { deepExtendClone } from '@jz/utils';
import { InterSectionObserApi } from '@jz/biz-shared';
import { initMobiAnimation } from './methods';

export const registerAnimateObseverDirective = (Vue) => {
    Vue.directive('animate-obsever', (el, binding) => {
        let animationData = binding.value,
            needAnimated = false;
        const { style, id, animation } = deepExtendClone(animationData);
        if (animation.t > 0) needAnimated = true;
        const curEl = el;
        if (needAnimated) {
            setTimeout(() => {
                initMobiAnimation({ id, animation, style });
                !InterSectionObserApi.isObserveEl(curEl, 1) && InterSectionObserApi.initAnimationObserver(curEl);
            }, 100);
        }
    });
};
