import { sharePage, showCommSharePanel } from '@/features/global-mobi-function/methods/share';
import { scrollFadeIn } from '@/modules/comm';
import { getPreviewObject } from '@/shared/getPreviewObject';
import { initWebPage } from '@/shared/misc/init-webpage';
import { initScaleImage } from '@/shared/photo/scale';
import { preprocessUrl } from '@/shared/url';
import { destroyWebPage } from '@/shared/misc/init-webpage';
import { changeHref, getRouterHistory, goBack } from '@/features/router/universal';

export function initPhotoGroupSwipe(swipeId) {
    var swipe = getPreviewObject(swipeId),
        jmSwipe = jm(swipe);
    if (!swipe) return;

    initScaleImage(swipe);

    if (!swipe.querySelector('.bullets')) return;
    var bullets = swipe.querySelector('.bullets').getElementsByTagName('li'),
        swipehandle,
        maxIndex,
        picBtn;

    maxIndex = parseInt(jmSwipe.find('.J_maxNum').text());
    swipehandle = jm('#' + swipeId).swipehandle(swipe, {
        //auto: 3000,
        continuous: true,
        bulletsClick: true,
        callback(pos) {
            var liLen = bullets.length,
                index;
            while (liLen--) {
                bullets[liLen].className = ' ';
            }
            if (!bullets[pos]) {
                bullets[pos - bullets.length].className = 'on';
            } else {
                bullets[pos].className = 'on';
            }

            index = (pos + 1) % maxIndex;

            if (index == 0) {
                index = maxIndex;
            }
            jmSwipe.find('.J_curPhotoNumber').text(index);
        },
    });

    if (/(iPhone|iPad|iPod|Android)/i.test(navigator.userAgent)) {
        return;
    }
    picBtn = jmSwipe.find('.J_btn');

    jmSwipe
        .on('mouseover', function () {
            picBtn.show();
        })
        .on('mouseleave', function () {
            picBtn.hide();
        });

    jmSwipe.find('.J_preBtn').on('click', function () {
        swipehandle.prev();
    });

    jmSwipe.find('.J_nextBtn').on('click', function () {
        swipehandle.next();
    });
}
export class PhotoGroupDetailSharePanel {
    constructor(shareData) {
        this.shareData = shareData;
        this.triggerId = 'newsDetailShare';
        this.shareOption = {
            triggerId: this.triggerId,
            pageBg: true,
            pageHtml: sharePage(shareData),
            direction: 'bottom',
            callback() {
                showCommSharePanel('shareListShowSwipe', 'photo');
            },
            closeWebPage: '.shareListPanelBg',
        };
    }

    render() {
        this.destroy();
        initWebPage(this.shareOption);
    }

    destroy() {
        destroyWebPage(this.triggerId);
    }
}

// 主题3.0
export function photoGroupContentInitV3(moduleId) {
    var photoGroupDetail = jm('#photoGroupDetailPanel' + moduleId),
        photoGroupGoBack = photoGroupDetail.find('.icon-gClose');
    /*  go back */
    jm(photoGroupGoBack)
        .unbind('click')
        .bind('click', function () {
            if (window.history.length <= 1) {
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
            } else {
                if (VITE_APP_MODE !== 'visitor') {
                    const histories = getRouterHistory();
                    if (histories.length > 0) {
                        goBack();
                    } else {
                        changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                    }
                } else {
                    history.back();
                }
            }
            return false;
        });

    scrollFadeIn('photoGroupDetailHeader' + moduleId, 'photoGroupPicPanel' + moduleId, 1);
}

export function photoGroupContentInit(moduleId) {
    var photoGroupDetail = jm('#photoGroupDetailPanel' + moduleId),
        width = photoGroupDetail.find('.photoGroupDetail').width(),
        pic = photoGroupDetail.find('.photoGroupPic'),
        photoGroupGoBack = photoGroupDetail.find('.icon-gClose');

    jm('#photoGroupPicList' + moduleId).width(width * pic.length + 'px');
    pic.width(width + 'px');

    /*  share start */
    var shareOption = {
        triggerId: 'newsDetailShare',
        pageBg: true,
        pageHtml: sharePage(Fai.top._shareDataPhotoGroup),
        direction: 'bottom',
        callback() {
            showCommSharePanel('shareListShowSwipe', 'photo');
        },
        closeWebPage: '.shareListPanelBg',
    };
    initWebPage(shareOption);
    /*  share end */

    /*  go back */
    jm(photoGroupGoBack)
        .unbind('click')
        .bind('click', function () {
            if (window.history.length <= 1) {
                changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
            } else {
                if (VITE_APP_MODE !== 'visitor') {
                    const histories = getRouterHistory();
                    if (histories.length > 0) {
                        goBack();
                    } else {
                        changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                    }
                } else {
                    history.back();
                }
                // 为了防止返回无效
                // var gobackSetTimeout = setTimeout(function(){
                //     window.location.href = "index.jsp";
                //     gobackSetTimeout = null;
                // }, 500);
            }
            return false;
        });

    scrollFadeIn('photoGroupDetailHeader' + moduleId, 'photoGroupPicPanel' + moduleId, 1);
}
