<template>
    <div
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="mixNewsStyleLine newsLine picNum1 J_picNum"
        :class="lineClass"
        picnum="3"
    >
        <div :id="lineBodyId" class="lineBody">
            <div class="mixNewsStyleImgBox1">
                <a
                    hidefocus="true"
                    :href="href"
                    :target="target"
                    style="width: auto; height: auto"
                    :onclick="onclickStr"
                >
                    <div v-if="!pictureId" class="icon-noFigureM"></div>
                    <img v-else alt="文章附图" title="" :src="picturePath" />
                </a>
            </div>
            <div class="mixNewsStyleTitleContainer">
                <div :class="topFlagClass"></div>
                <a
                    hidefocus="true"
                    :href="href"
                    :target="target"
                    :class="[showLine ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2']"
                    :onclick="onclickStr"
                >
                    {{ title }}
                </a>
            </div>
            <a hidefocus="true" :href="href" :target="target" class="mixNewsStyleSummary" :onclick="onclickStr">
                {{ summary }}
            </a>
        </div>
    </div>
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';

export default {
    name: 'NewsMoreItemStyle1',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
};
</script>

<style></style>
