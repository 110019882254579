<template>
    <div class="firstGroup">
        <ul class="firstGroupList">
            <li
                v-for="firstGroup in firstGroupList"
                :key="firstGroup.id"
                class="firstGroupItem"
                :class="{ checkedItem: firstGroup.id === selectGroup }"
                :data-id="firstGroup.id"
            >
                <div class="f-bgColor J_navSelect" :class="{ 'fk-navSelect': firstGroup.id === selectGroup }"> </div>
                <p class="firstGroupName" :class="{ 'f-fontColor': firstGroup.id === selectGroup }"
                    >{{ firstGroup.name }}
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ProductGroupDetailVerticalNav',
    props: {
        firstGroupList: {
            type: Array,
            default: () => [],
        },
        selectGroup: {
            type: Number,
            default: -1,
        },
    },
};
</script>

<style></style>
