<template>
    <div id="preview" class="preview-area" :class="previewClass" :style="previewStyle">
        <loading v-if="manageMode" v-show="pageLoading" />
        <m-dialog v-if="simultaneousLogin && !$isServer && !manageMode">
            <template #content>
                <div>您的账号已在另一台设备登录，如需继续浏览请重新登录</div>
            </template>
        </m-dialog>
        <div id="g_body" :class="gBodyClasses">
            <visitor-top-tips />
            <div class="webLeft"></div>
            <div id="g_web" class="g_web" :class="gWebClasses" :style="gWebStyle" @scroll="onPageScroll">
                <div id="webLoading" class="loading" style="display: none">
                    <div id="splashscreen" class="splashscreen ui-loader">
                        <span class="ui-icon ui-icon-loading spin"></span>
                    </div>
                </div>
                <div id="webTopBox" class="webTopBox">
                    <div id="webTop" class="webTop">
                        <div id="J_navExpandAnimate" class="jz_navExpendAnimate"></div>
                        <!-- 导航区域 -->
                        <component :is="systemComponents.Nav" v-show="showNav" v-if="showNavInWebTopBox"></component>
                        <nav-button v-if="openThemeV3" v-show="showNav"></nav-button>
                    </div>
                </div>

                <!-- 顶部 -->
                <div v-if="!openThemeV3" id="webHeaderBox" class="webHeaderBox" :class="webHeaderBoxClass">
                    <span v-if="logo.hideFileUrl" :hideFileUrl="logo.hideFileUrl" style="display: hidden"></span>
                    <template v-if="designMode || (!isMallTheme && !designMode)">
                        <div
                            v-if="templateFrameId != 1004 && templateFrameId != 1023"
                            v-show="showNavV2Button"
                            id="navButton"
                            class="navButton"
                        >
                            <div class="navButtonPanel"></div>
                            <div class="menuNav">
                                <div class="menuNavTip icon-menuNavTip"></div>
                            </div>
                        </div>
                        <div id="headerWhiteBg" class="headerSiteMaskWhiteBg"></div>
                        <div id="headerBg" class="headerSiteMaskBg"></div>
                    </template>
                    <component :is="systemComponents.Header"></component>
                    <component :is="systemComponents.Nav" v-show="showNav" v-if="showNavV2InHeaderBox"></component>
                    <component :is="systemComponents.Locater" v-if="locaterV2InNavFixVisible"></component>
                </div>
                <component :is="systemComponents.Header" v-else></component>

                <!-- 横幅区域 -->
                <div id="webBannerBox" class="webBannerBox moveAnimation" :class="webBannerBoxClass">
                    <component :is="systemComponents.Locater" v-if="locaterV2InNavUnFixVisible"></component>
                    <component :is="systemComponents.Banner" v-if="bannerVisible"></component>
                    <component :is="systemComponents.Nav" v-show="showNav" v-if="showNavV2InBannerBox"></component>
                </div>

                <!-- 模块内容区域 -->
                <div
                    id="webContainerBox"
                    class="webContainerBox"
                    :class="webContainerBoxClass"
                    :style="webContainerStyle"
                >
                    <template v-if="manageMode">
                        <div
                            v-if="!pageLoading"
                            id="webModuleContainer"
                            v-sortable="sortable"
                            class="webModuleContainer"
                        >
                            <component
                                :is="componentsTable[module.style]"
                                v-for="module in renderModules"
                                :key="module.id"
                                :module-id="module.id"
                                :children="module.children"
                            />
                            <component :is="systemComponents.FloatEffect" v-if="isEditFloatZone"></component>
                            <div v-if="!renderModules.length" class="addNewModule">
                                <div class="addBtn">
                                    <div class="addBtn_Icon" @click="initModuleTab()"> </div>
                                </div>
                            </div>
                            <div id="bookingPanelTriggle"></div>
                        </div>
                    </template>
                    <div v-else id="webModuleContainer" class="webModuleContainer">
                        <component
                            :is="componentsTable[module.style]"
                            v-for="module in renderModules"
                            :key="module.id"
                            :module-id="module.id"
                            :children="module.children"
                        />
                        <component :is="systemComponents.FloatEffect" v-if="isEditFloatZone"></component>
                        <div id="bookingPanelTriggle"></div>
                    </div>
                </div>
                <!-- 底部区域 -->
                <component :is="systemComponents.WebFooter" v-if="openThemeV3"></component>
                <div id="webFooterBox" class="webFooterBox" :class="webFooterBoxClass">
                    <component :is="systemComponents.Nav" v-if="!openThemeV3 && showNavV2InFooterBox"></component>
                    <div id="webFooter" class="webFooter" :class="webFooterClass">
                        <component :is="systemComponents.WebFooter" v-if="!openThemeV3"></component>
                    </div>
                    <div id="fullScreenDivCotainer" class="fullScreenDivCotainer"></div>
                </div>

                <component :is="systemComponents.FooterNav"></component>
                <div class="u_area_box_wrap J_area_box_wrap">
                    <div
                        id="fixedAreaBox"
                        :class="'fixedAreaBox fixedAreaBox' + templateFrameId + '_' + templatePresetIndex"
                        :tplid="templateFrameId + '_' + templatePresetIndex"
                    >
                    </div>
                </div>
                <div id="mobiReviewPage"></div>

                <!-- 背景音乐 -->
                <component :is="systemComponents.BackgroundMusic"></component>

                <!-- 右下角按钮区域 -->
                <fixed-right-side-btns>
                    <component :is="systemComponents.FloatEffectIcon"></component>
                    <component :is="systemComponents.BackTop"></component>
                </fixed-right-side-btns>
                <component :is="systemComponents.FloatEffect" v-if="!isEditFloatZone"></component>
                <!-- 背景box -->
                <component :is="systemComponents.BackgroundBox"></component>
                <!-- 悬浮效果 -->
            </div>
            <div class="webRight"></div>
            <div id="hiddenModuleForms" class="hiddenModuleForms" style="display: none"></div>
        </div>
        <div v-if="isEditMode" class="edit_mode_wrap">
            <span :class="{ active: isActiveSaveBtn }" class="jz_button edit_mode_save" @click="save($event)"
                >保 存</span
            >
            <span :class="{ active: isActiveCancelBtn }" class="jz_button edit_mode_cancel" @click="cancel($event)"
                >取 消</span
            >
            <span :class="{ active: isActiveExitBtn }" class="jz_button edit_mode_close" @click="exit($event)"
                >退出弹窗设计</span
            >
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { TYPE as NAV_TYPE, LAYOUT as NAV_LAYOUT } from '@/shared/nav/types';
import FixedRightSideBtns from './templates/FixedRightSideBtns.vue';
import { debounce } from '@/shared/util';

import { isPopupZoneChildren, isPopupZoneModule, isFloatZoneModule, isBaseModule } from '@/modules/type-mapping';
import { floatZone } from '@/system/floatEffect/container/module/floatZone';
import { initGuideAd } from '@/features/advertisement/guide';
import {
    COL,
    isWebDetailPage,
    isProductDetailCol,
    isNewsDetailCol,
    isSpecialIndependSetMainNavPageV3,
    COL_DEF_FLAG,
} from '@/def/col';
import { viewAllowDetailModuleStyle, hasUeMap } from '@/def/module';
import { TEMPLATE_LAYOUT } from '@/def/template';
import loading from '@/components/loading/loading.vue';
import { checkThemeVer } from '@/shared/checkThemeVer';
import { jumpAnchorModule } from '@/features/jumpAnchorModule';
const manageMode = VITE_APP_MODE !== 'visitor';
import { logDog } from '@/shared/log';
import visitorTopTips from '@/components/top-tips/visitor.vue';
import { memberLogin1 } from '@/shared/member';
import { changeHref } from '@/features/router/universal';
import { clueReuqestVm, Clue } from '@jz/biz-shared';
import { checkBit } from '@/shared/fai';

import NavButton from '@/system/nav/module/v3/nav-button.vue';
import { initIntegral } from '@/features/integral';
import { isBaiduBrowser } from '@/shared/browser';

import MDialog from '@/components/dialog/m-dialog.vue';
import { checkMemberSameLogin } from '@/api/member';
import { getUrlParam } from '@/shared/url';
import { switchJump } from '@/shared/linkSetting';
import { createStyleSheet } from '@/shared/style';

export default {
    name: 'Preview',
    components: {
        FixedRightSideBtns,
        loading,
        visitorTopTips,
        NavButton,
        MDialog,
    },
    props: {
        componentsTable: {
            type: Object,
            default: () => ({}),
        },
        systemComponents: {
            type: Object,
            default: () => ({}),
        },
        sortable: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            simultaneousLogin: false,
            renderUeMapTimer: null,
        };
    },
    computed: {
        ...mapState(['components']),
        ...mapState('app', [
            'openThemeV3',
            'colId',
            'colInfo',
            'lcid',
            'isRegularHeadStyle',
            'templateLayoutId',
            'designMode',
            'templateFrameId',
            'templatePresetIndex',
            'templateDesignType',
            'silentLogin',
            'oem',
            'detailPageHasBottomFixed',
            'themeColor',
        ]),
        ...mapState('header', ['headerV2']),
        ...mapState('nav', ['skinPattern', 'fullPageNav', 'independentNav']),
        ...mapState('backgroundSetting', ['backgroundImg']),
        ...mapState('editMode', [
            'isEditMode',
            'isActiveSaveBtn',
            'isActiveCancelBtn',
            'isActiveExitBtn',
            'isPopupZone',
        ]),
        ...mapGetters(['currentPageModules']),
        ...mapGetters('app', ['isDependentCol']),
        pageState() {
            return VITE_APP_MODE !== 'visitor' ? this.$store.state.manage.pageState : {};
        },
        previewClass() {
            let classList = [];
            if (VITE_APP_MODE !== 'visitor') {
                classList.push('preview-area_manage');
            } else {
                classList.push('preview-area_visit');
            }
            classList.push(`g_locale${this.lcid}`);
            return classList;
        },
        pageLoading() {
            return VITE_APP_MODE !== 'visitor' ? this.$store.getters['manage/pageLoading'] : false;
        },
        locaterV2InNavUnFixVisible() {
            return this.templateLayoutId !== TEMPLATE_LAYOUT.NAV_FIX && this.openThemeV3 === false;
        },
        locaterV2InNavFixVisible() {
            return this.templateLayoutId === TEMPLATE_LAYOUT.NAV_FIX && this.openThemeV3 === false;
        },
        showNavV2InFooterBox() {
            return this.templateLayoutId === TEMPLATE_LAYOUT.NAV_BOTTOM;
        },
        showNavV2InHeaderBox() {
            return [
                TEMPLATE_LAYOUT.NAV_HEAD,
                TEMPLATE_LAYOUT.NAV_TOP,
                TEMPLATE_LAYOUT.NAV_TOP_HIDE,
                TEMPLATE_LAYOUT.NAV_LEFT_FIX,
                TEMPLATE_LAYOUT.NAV_AUTO,
            ].includes(this.templateLayoutId);
        },
        showNavInWebTopBox() {
            if (this.openThemeV3) {
                return true;
            } else if (
                this.templateLayoutId === TEMPLATE_LAYOUT.NAV_LEFT ||
                this.templateLayoutId === TEMPLATE_LAYOUT.NAV_FIX
            ) {
                return true;
            } else {
                return false;
            }
        },
        showNavV2InBannerBox() {
            return !this.openThemeV3 && this.templateLayoutId === TEMPLATE_LAYOUT.NAV_BANNER_BOTTOM;
        },
        showNavV2() {
            return !this.showNavV2InFooterBox && !this.showNavV2InHeaderBox;
        },
        showNavV2Button() {
            return (
                (!this.fullPageNav.hide && !this.independentNav.set) ||
                (this.independentNav.set && !this.independentNav.hide)
            );
        },
        webBannerBoxClass() {
            const classes = [];
            if (this.isDependentCol) {
                classes.push('webBannerBoxHide');
            }
            if (isWebDetailPage(this.colId, this.templateDesignType, this.openThemeV3)) {
                classes.push('webDetailPage');
            }
            return classes;
        },
        bannerVisible() {
            return [
                COL.SYS_INDEX,
                COL.SYS_MSG_BOARD,
                COL.CUS,
                COL.SYS_PHOTOGROUP_RESULT,
                COL.SYS_PRODUCT_RESULT,
                COL.SYS_NEWS_RESULT,
                COL.SYS_CUSTOM_SEARCH_RESULT,
            ].includes(this.$store.state.app.colInfo.type);
        },

        manageMode() {
            return manageMode;
        },
        showNav() {
            if (this.openThemeV3) {
                return this.allowPrintMainNav();
            } else {
                return this.showNavV2;
            }
        },
        renderModules() {
            const modules = this.currentPageModules.filter((module) => module != null && !isBaseModule(module.style));
            let expectInTabModule = modules.filter(
                (module) => !((module.inTab || module.inFold || module.inFloatZone) > 0)
            );

            // 弹窗容器模块不用直接渲染
            let renderModules = expectInTabModule
                .filter((module) => {
                    return !(isPopupZoneModule(module.style) || isPopupZoneChildren(module));
                })
                .filter((module) => !isFloatZoneModule(module.style));

            if (this.isViewAllowCol && !manageMode) {
                let allowInfo = this.getViewAllowInfo();
                if (!allowInfo.viewAllow) {
                    return [];
                }
            }

            // printErr 代表后台模块初始化报错，此时跳过这个模块渲染
            return renderModules.filter(({ printErr }) => !printErr);
        },
        webContainerBoxClass() {
            let classNames = [];
            if (this.isDependentCol) {
                classNames.push('webContainerBoxStatic');
            }
            if (this.colId === COL.SYS_PRODUCT_NEW_GROUP) {
                classNames.push('webContainerBoxProductNewGroupPage');
            }
            if (this.detailPageHasBottomFixed) {
                classNames.push('hasBottomFixed');
            }
            return classNames;
        },
        webFooterBoxClass() {
            let classNames = [];
            if (this.colId === COL.SYS_PRODUCT_NEW_GROUP) {
                classNames.push('webProductNewGroupPage ');
            }
            return classNames;
        },
        gWebClasses() {
            let themeIdClass = `jz_theme_${this.templateFrameId}`;

            let templatePresetIndexClass = `jz_themePresetIndex_${this.templatePresetIndex}`;
            let themeVer = checkThemeVer(this.templateFrameId, this.templateDesignType);

            let themeVerClass = !this.openThemeV3 ? `jz_${themeVer}` : '';

            let mallThemeClass = 'jz_otherTheme';

            let classNames = [this.navTypeClass, themeIdClass, templatePresetIndexClass, themeVerClass, mallThemeClass];

            if (this.isDependentCol) {
                classNames.push('g_web_overflow_x_auto');
            }
            if (this.colId === COL.SYS_PRODUCT_NEW_GROUP) {
                classNames.push('g_web_ProductNewGroupPage');
            }
            return classNames;
        },
        navTypeClass() {
            if (!this.showNav) {
                return '';
            }

            let type = this.skinPattern?.type ?? 0;
            let layout = this.skinPattern?.layout ?? 0;

            let className = '';
            if (type === NAV_TYPE.DRAWER) {
                className += ' jz_nav-drawer';
            } else if (type === NAV_TYPE.LABEL) {
                className += ' jz_nav-label';
            } else if (type === NAV_TYPE.SUSPENSION) {
                className += ' jz_nav-suspension';
            }

            switch (layout) {
                case NAV_LAYOUT.DRAWER_ALL_COVER: {
                    className += ' jz_nav-drawer-cover jz_nav-drawer-allCover';
                    break;
                }
                case NAV_LAYOUT.DRAWER_PART_COVER: {
                    className += ' jz_nav-drawer-cover jz_nav-drawer-halfCover';
                    break;
                }
                case NAV_LAYOUT.DRAWER_LEFT: {
                    className += ' jz_nav-drawer-left';
                    break;
                }
                case NAV_LAYOUT.DRAWER_RIGHT: {
                    className += ' jz_nav-drawer-right';
                    break;
                }
                case NAV_LAYOUT.SUSPENSION_ALL_COVER: {
                    className += ' jz_nav-suspension-allCover';
                    break;
                }
                case NAV_LAYOUT.LABEL_SLIDE: {
                    className += ' jz_nav-label-slide';
                    break;
                }
                case NAV_LAYOUT.LABEL_PANEL: {
                    className += ' jz_nav-label-panel';
                    break;
                }
                case NAV_LAYOUT.SUSPENSION_LEFT: {
                    className += ' jz_nav-suspension-left';
                    break;
                }
                case NAV_LAYOUT.SUSPENSION_BOX: {
                    className += ' jz_nav-suspension-box';
                    break;
                }
                case NAV_LAYOUT.DRAWER_PART_COVER_SLIDE: {
                    className += ' jz_nav-drawer-partCoverSlide';
                    break;
                }
            }
            return className;
        },
        gBodyClasses() {
            let classList = [];
            if (this.openThemeV3) {
                classList.push('jz_themeV3');
                classList.push(this.isRegularHeadStyle ? 'regularHeadStyle' : 'unRegularHeadStyle');
            } else {
                classList.push('jz_themeV2');
            }
            classList.push(`mobiCol${this.colId}`);
            return classList;
        },
        backgroundImgStyle() {
            let type = this.backgroundImg.type;
            let sysnum = this.backgroundImg.sysnum;
            let style = {};
            if ((type == 0 && sysnum != 0) || type == 1 || type == 2) {
                style['background'] = 'none';
            }
            return style;
        },
        webContainerStyle() {
            let style = {};
            if (this.colId !== COL.SYS_PRODUCT_NEW_GROUP) {
                style['overflow'] = 'hidden';
            }
            Object.assign(style, this.backgroundImgStyle);
            return style;
        },
        gWebStyle() {
            let style = {};
            Object.assign(style, this.backgroundImgStyle);
            return style;
        },
        isEditFloatZone() {
            return this.$store.state.floatEffect.isEdit;
        },
        webHeaderBoxClass() {
            const classes = [];
            if (this.isDependentCol) {
                if (this.colId !== COL.SYS_MEMBER_PROFILE) {
                    classes.push('webHeaderBoxHide');
                }
            }
            if (isWebDetailPage(this.colId, this.templateDesignType, this.openThemeV3)) {
                classes.push('webDetailPage');
            }

            if (this.headerV2.isHeadHide) {
                classes.push('webTitleClose');
            }

            return classes;
        },
        logo() {
            return this.headerV2.logo;
        },
        isMallTheme() {
            return this.headerV2.isMallTheme;
        },
        webFooterClass() {
            const classes = [];
            if (isWebDetailPage(this.colId, this.templateDesignType, this.openThemeV3)) {
                classes.push('webDetailPage');
            }
            return classes;
        },
        isViewAllowCol() {
            return isNewsDetailCol(this.colId) || isProductDetailCol(this.colId);
        },
        independentSetHead() {
            return checkBit(this.colInfo.flag, COL_DEF_FLAG.INDEPEND_SET_HEAD);
        },
        independentSetHeadHide() {
            return checkBit(this.colInfo.flag, COL_DEF_FLAG.INDEPEND_SET_HEAD_HIDE);
        },
        isHiddenHead() {
            return (
                (this.fullHeadHidden == 1 && !this.independentSetHead) ||
                (this.independentSetHead && this.independentSetHeadHide)
            );
        },
        previewStyle() {
            let style = {};
            if (this.openThemeV3 && this.colId == COL.SYS_MEMBER_PROFILE) {
                style['background-color'] = '#f7f7f7'; // 3.0主题会员中心、登录页、注册页背景颜色
            }
            return style;
        },
    },
    watch: {
        currentPageModules() {
            if (VITE_APP_MODE !== 'visitor') {
                this.$nextTick(() => {
                    this.fixDetailWebContainerHeight();
                });
            }
        },
    },
    mounted() {
        if (!manageMode && !getUrlParam(window.location.href, 'errno')) {
            this.validateSimultaneousLogin();
        }
        if (!manageMode && window.choiceTips && Object.keys(window.choiceTips).length) {
            this.showJumpLanauage();
        }
        if (!this.oem) {
            !manageMode && initGuideAd();
        }
        if (!manageMode && this.isViewAllowCol) {
            this.viewAllowHandle();
        }
        jumpAnchorModule();
        !manageMode && this.initMobiTipList(Fai.top.mobiTipList);
        !manageMode && initIntegral();

        if (!manageMode) {
            // 浏览器兼容hack
            if (isBaiduBrowser()) {
                this.fixBaiduHack();
            }
        }
        this.fixDetailWebContainerHeight();
        switchJump();
        this.renderUeMap();

        this.initThemeColor();
    },
    updated() {
        this.$nextTick(() => {
            if (this.renderUeMapTimer) {
                clearTimeout(this.renderUeMapTimer);
            }
            this.renderUeMapTimer = setTimeout(() => {
                this.renderUeMap();
            }, 500);
        });
    },
    methods: {
        allowPrintMainNav() {
            const navShow =
                (!this.fullPageNav.hide && !this.independentNav.set) ||
                (this.independentNav.set && !this.independentNav.hide);
            const colType = this.colInfo.type;
            if (isSpecialIndependSetMainNavPageV3(colType)) {
                if (
                    colType === COL.SYS_NEWS_NEW_DETAIL ||
                    colType === COL.SYS_PHOTOGROUP_DETAIL ||
                    colType === COL.SYS_NEWS_DETAIL_V3
                ) {
                    return this.isHiddenHead ? navShow && this.skinPattern?.type === 2 : navShow;
                } else {
                    return navShow && this.skinPattern?.type === 2;
                }
            }
            return navShow;
        },
        logClueSourceContent() {
            if (manageMode) return;
            // 微信静默登录授权上报
            this.silentLogin && clueReuqestVm.authRecordLoginWeChat();
            switch (this.colId) {
                case COL.SYS_INDEX:
                    Clue?.logClueIndexSourceContent();
                    break;

                default:
                    break;
            }
        },
        ...mapMutations(['initMobiTipList']),
        updateLoadingState(data) {
            this.$store.dispatch('manage/updateLoadingState', data);
        },
        afterTransitionEnter() {
            this.updateLoadingState({ loading: false });
        },
        onPageScroll: debounce(function (event) {
            let scrollTop = event.target.scrollTop;
            this.$store.commit('updatePageScrollTop', scrollTop);
        }, 300),
        parseJson(json) {
            return typeof json === 'string' ? JSON.parse(json) : json;
        },
        save(event) {
            if (!this.isActiveSaveBtn) {
                return;
            }
            if (this.isPopupZone) {
                let popupZoneSave = window.Mobi._popupZone.save;
                popupZoneSave && popupZoneSave();
                return;
            }

            floatZone.saveHandler(event);
        },
        cancel(event) {
            if (!this.isActiveCancelBtn) {
                return;
            }

            if (this.isPopupZone) {
                let popupZone = window.Mobi._popupZone.pZone;
                popupZone && popupZone.cancel && popupZone.cancel();
                return;
            }

            floatZone.cancelHandler(event);
        },
        exit(event) {
            if (!this.isActiveExitBtn) {
                return;
            }

            if (this.isPopupZone) {
                let popupZoneExitEdit = window.Mobi._popupZone.exitEdit;
                popupZoneExitEdit && popupZoneExitEdit();
                return;
            }

            floatZone.exitHandler(event);
        },
        initModuleTab() {
            window.$store.dispatch('manage/addPanel/changeActiveTab', 'module');
            window.$store.dispatch('manage/addPanel/changeActiveModuleTab', 'add');

            var $modulePanel = $('#mobiNewModulePanel');

            $modulePanel.parent().css('height', '100%');
            $modulePanel.parent().parent().css('height', '150%');

            $modulePanel.animate({ top: '120px' }, 100, function () {
                $modulePanel.delay(100);
                $modulePanel.animate({ top: '0' }, 300, function () {
                    $modulePanel.parent().css('height', '');
                    $modulePanel.parent().parent().css('height', '100%');
                });
            });

            logDog(200132, 1);
        },
        viewAllowHandle() {
            let allowViewInfo = this.getViewAllowInfo();
            if (isNewsDetailCol(this.colId)) {
                let { viewAllow, isLogin } = allowViewInfo;
                if (!viewAllow) {
                    isLogin ? changeHref('/index.jsp?errno=14') : memberLogin1(13);
                }
            } else if (isProductDetailCol(this.colId)) {
                let { viewAllow } = allowViewInfo;
                if (!viewAllow) {
                    memberLogin1(14);
                }
            }
        },
        getViewAllowInfo() {
            let moduleInfo = this.currentPageModules.find((module) => {
                return viewAllowDetailModuleStyle.includes(module.style);
            });

            let allowViewInfo = {
                viewAllow: true,
            };
            if (isNewsDetailCol(this.colId)) {
                let { viewAllow = true, isLogin } = moduleInfo.renderOptions || {};
                allowViewInfo = {
                    viewAllow,
                    isLogin,
                };
            } else if (isProductDetailCol(this.colId)) {
                let { viewAllow = true } = moduleInfo.extInfo || {};
                allowViewInfo = { viewAllow };
            }

            return allowViewInfo;
        },
        fixBaiduHack() {
            jm('#headerBg, #headerWhiteBg').css('top', '0px'); // 百度浏览器中top值存在导致header有位移，遮挡住下面内容
            jm('#preview').addClass('preview-bd-env');
        },
        validateSimultaneousLogin() {
            checkMemberSameLogin().then((result) => {
                if (result.success === true) {
                    if (result.showTips === true) {
                        this.simultaneousLogin = true;
                    }
                }
            });
        },
        fixDetailWebContainerHeight() {
            // 处理文章/产品详情页有模块情况下，模块被遮挡问题
            if (this.renderModules.length >= 2 && this.openThemeV3) {
                let bottomHeight = 0;
                if (isProductDetailCol(this.colId)) {
                    let serviceGroup = jm('.productMallShop .serviceBtn');
                    let btnGroup = jm('.productMallShop .btnGroup');

                    if (
                        (serviceGroup.length && serviceGroup.css('display') != 'none') ||
                        (btnGroup.length && btnGroup.css('display') != 'none')
                    ) {
                        bottomHeight = parseInt(jm('.productMallShop').height() || 0);
                    }
                }
                if (bottomHeight > 0) {
                    jm('.webModuleContainer').css('margin-bottom', `${bottomHeight}px`);
                }
            }
        },
        renderUeMap() {
            let UeModuleList = Object.values(this.$store.state.currentPageModuleIdMap).filter(({ style }) => {
                return hasUeMap(style);
            });
            window.bizShared.addRenderMapWrapper(
                UeModuleList.map(({ id }) => {
                    return `#module${id}`;
                })
            );
            try {
                window.bizShared.renderMap();
            } catch (err) {
                console.log(err);
            }
        },
        initThemeColor() {
            createStyleSheet('theme', `:root{ --theme-color: ${this.themeColor}}`);
        },
        showJumpLanauage() {
            let { tips, cancelTips, confirmTips, url } = window.choiceTips;
            this.$modal.confirm({
                content: tips,
                confirmButtonText: confirmTips,
                cancelButtonText: cancelTips,
                onConfirm() {
                    jm.cookie('_choseMobiLanguage', true, {
                        expires: 365,
                        path: '/',
                    });
                    Fai.top.window.location.href = url;
                },
                onCancel() {
                    jm.cookie('_choseMobiLanguage', false, {
                        expires: 365,
                        path: '/',
                    });
                },
            });
        },
    },
};
</script>

<style lang="scss">
@import '../styles/preview/index.scss';
.preview-area {
    min-height: 300px;
    margin: auto !important;
    z-index: 1;
    background: #fff;
    position: relative;
    display: block;
    height: 100%;
}
.preview-area_manage {
    max-height: calc(100% - 122px);
    top: 66px;
    width: 375px;
    height: 667px;
    float: initial !important;
}

@media only screen and (max-height: 690px) {
    .preview-area_manage {
        height: 560px;
    }
}

.g_web {
    margin: 0 auto;
    max-width: 768px;
    left: 0px;
    top: 0px;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    position: relative;
    max-height: 100%;
}

#g_body .webTips + .webLeft + .g_web {
    max-height: calc(100% - 1.5rem);
    min-height: initial;
}

#g_body #fk-tipsLimitUv + .webLeft + .g_web {
    max-height: calc(100% - 1.1rem);
    min-height: initial;
}

/*其中webTips高度为1.5rem, fk-tips高度为1.1rem, 都有情况下相加 */
#g_body #fk-tipsLimitUv + .webTips + .webLeft + .g_web {
    max-height: calc(100% - 2.6rem);
}

#g_body.mobiCol10.jz_themeV2 #fk-tipsLimitUv + .webLeft + .g_web,
#g_body.mobiCol11.jz_themeV2 #fk-tipsLimitUv + .webLeft + .g_web {
    height: calc(100% - 1.1rem);
}

#g_body.mobiCol12.jz_themeV3 #fk-tipsLimitUv + .webLeft + .g_web {
    min-height: calc(100% - 1.1rem);
}

#g_body.mobiCol10.jz_themeV2 .webTips + .webLeft + .g_web,
#g_body.mobiCol11.jz_themeV2 .webTips + .webLeft + .g_web {
    height: calc(100% - 1.5rem);
}

#g_body.mobiCol12.jz_themeV3 .webTips + .webLeft + .g_web {
    min-height: calc(100% - 1.5rem);
}

.mobi-app #g_body .webTips + .webLeft + .g_web {
    max-height: 100%;
}

.mobi-app #g_body .webTips + .webLeft + .jz_oldTheme {
    height: 100%;
}

#g_body .webTips + .webLeft + .jz_oldTheme {
    min-height: calc(100% - 1.5rem);
}

.g_web_overflow_x_auto {
    overflow-x: auto;
}

#g_web {
    color: #000;
    font-size: 0.7rem;
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
}

.webModuleContainer {
    position: relative;
    overflow: hidden;
}

#g_body {
    overflow-x: hidden;
    overflow-y: hidden;
    color: #000;
    font-size: 0.7rem;
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    height: 100%;
    /* 处理手机视图fixed定位问题 */
    transform: translateZ(0px);
}

.module-list-fade-enter-active,
.module-list-fade-leave-active {
    transition: opacity 0.3s ease;
}
.module-list-fade-enter,
.module-list-fade-leave-to {
    opacity: 0;
}

.edit_mode_wrap {
    text-align: center;
    position: relative;
    width: 375px;
    left: 0;
    top: 25px;
}

.edit_mode_wrap .jz_button {
    width: 93px;
    margin-right: 30px;
    line-height: 34px;
    cursor: not-allowed;
    color: #ccc;
    border-color: #eaeaea;
    background-color: #fff;
}

.edit_mode_wrap .jz_button:hover {
    color: #ccc;
    border-color: #eaeaea;
    background-color: #fff;
}

.edit_mode_wrap .edit_mode_close {
    width: 115px;
    margin-right: 0;
}

.edit_mode_wrap .jz_button.active {
    color: #333;
    border-color: #e3e2e8;
    cursor: pointer;
}

.edit_mode_wrap .edit_mode_save.active,
.edit_mode_wrap .jz_button.active:hover {
    background-color: #5874d8;
    border-color: #5874d8;
    color: #fff;
}

.edit_mode_wrap .jz_button.active:active {
    background-color: #4a67d1;
    border-color: #4a67d1;
    color: #fff;
}

.webContainerBoxStatic {
    position: static;
}

.webBannerBoxHide {
    display: none;
}

.g_web_ProductNewGroupPage,
.webContainerBoxProductNewGroupPage,
.webContainerBoxProductNewGroupPage .form .formMiddle {
    background: #fff;
}

#webHeaderBox.webHeaderBoxHide {
    display: none;
}

#webContainerBox.hasBottomFixed {
    padding-bottom: 2rem;
}
</style>

<style lang="scss" scoped>
.webTitleClose {
    width: auto;
}
div.webTitleClose {
    height: auto;
}
</style>
