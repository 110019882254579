import request from './request';
import { getRouterDataForFirstPaint } from '@/features/router/util';
import { getRouterData } from '@/features/router/universal';

import { getUrlParamObj } from '@/shared/url';

/**
 *
 * 首屏数据接口
 *
 */
export function getFirstPaintData({ colId, cusUrl }) {
    let params = {
        colId,
        ...getRouterData(),
    };
    if (cusUrl !== undefined) {
        const { newsId, productId } = getRouterData();
        params.colId = -1;
        params.colCusUrl = cusUrl;
        (params.id = newsId || productId), (params.pid = productId);
    }
    getRouterDataForFirstPaint(params);
    // 设计师模式下添加设计师对应的skinId
    if (VITE_APP_MODE !== 'visitor') {
        let paramObj = getUrlParamObj(location.search);
        let _navSkin = paramObj['_navSkin'];
        let _footerNavSkin = paramObj['_footerNavSkin'];
        let _skin = paramObj['_skin'];
        if (_navSkin) {
            params['_navSkin'] = _navSkin;
        }
        if (_footerNavSkin) {
            params['_footerNavSkin'] = _footerNavSkin;
        }
        if (_skin) {
            params['_skin'] = _skin;
        }
    }

    return request
        .get('/api/manage/getFirstPaintData', {
            params: {
                ...params,
            },
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function loadStyle({ colId, cusUrl }) {
    let params = {
        colId,
        ...getRouterData(),
    };
    if (cusUrl !== undefined) {
        const { newsId, productId } = getRouterData();
        params.colId = -1;
        params.colCusUrl = cusUrl;
        (params.id = newsId || productId), (params.pid = productId);
    }
    return request
        .get(`/api/manage/getFirstPaintStyleData`, {
            params: {
                ...params,
                extId: Fai.top._extId,
            },
        })
        .then(({ data = {} }) => {
            return data.data;
        })
        .catch((err) => {
            console.error('获取首屏style接口报错了: ' + err);
        });
}
