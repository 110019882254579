<template>
    <div class="productNewGroup">
        <div class="firstGroup">
            <ul class="firstGroupList">
                <li
                    v-for="(catalog, i) in catalogList"
                    :key="i"
                    class="firstGroupItem"
                    :class="{
                        checkedItem: catalog.name === selectCata || (fixedPG === 1 && i === 0),
                    }"
                    :data-id="i"
                >
                    <p class="firstGroupName">{{ catalog.name }}</p>
                </li>
            </ul>
        </div>
        <div class="unFirstGroup">
            <div class="hideScollBar">
                <div
                    v-for="(catalog, i) in catalogList"
                    :id="'sed_' + i"
                    :key="i"
                    class="secondGroupPanel"
                    :class="{
                        checkedPanel: catalog.name === selectCata || (fixedPG === 1 && i === 0),
                    }"
                >
                    <div class="secondGroupItem">
                        <p class="secondGroupTop" style="display: none">
                            <span class="topLeft"></span>
                            <span class="topCenter"></span>
                        </p>
                        <div class="secndGroupCenter">
                            <div
                                v-for="(catalogGroup, index) in catalog.groupList"
                                :key="index"
                                class="J_groupItem thirdGroupImageItem"
                                :data-id="index"
                            >
                                <a v-if="isImageStyle" :href="catalogGroup.url" target="_self" class="itemImageParent">
                                    <template v-if="catalogGroup.mIcon">
                                        <span
                                            v-if="isFontIcon(catalogGroup.mIcon)"
                                            class="J_itemImage itemImage"
                                            :class="[catalogGroup.iconPath]"
                                        >
                                        </span>
                                        <template v-else>
                                            <img
                                                v-if="catalogGroup.fileInfo"
                                                class="J_itemImage itemImage"
                                                :src="catalogGroup.iconPath"
                                                :data-w="catalogGroup.fileInfo.width"
                                                :data-h="catalogGroup.fileInfo.height"
                                            />
                                            <span v-else class="J_itemImage notImage faisco-icons-pic"></span>
                                        </template>
                                    </template>
                                    <span v-else class="J_itemImage notImage faisco-icons-pic"></span>
                                </a>
                                <a v-else :href="catalogGroup.url" target="_self">
                                    <p class="itemName">{{ catalogGroup.name }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MODULE_ID } from '@/system/sysCol/photoGroupDetail/constants';
import propsMixin from '../mixins/props';
import { mapState } from 'vuex';
import { isFontIcon } from '@/shared/fai';
export default {
    name: 'ProductGroupDetailCatalogView',
    mixins: [propsMixin],
    data() {
        return {
            moduleId: MODULE_ID,
        };
    },
    computed: {
        ...mapState('manage/route', ['query']),
        fixedPG() {
            return this.query?.fixedpg ?? 0;
        },
        selectCata() {
            return this.query?.selectCata ?? void 0;
        },
    },
    methods: {
        isFontIconId(id) {
            return isFontIcon(id);
        },
    },
};
</script>

<style></style>
