<template>
    <div class="J_groupItem" :class="[thirdGroupItemClass]" :data-id="groupItem.id">
        <template v-if="isImageStyle">
            <a
                :id="'imgContainer' + groupItem.id"
                :href="groupItem.url"
                target="_self"
                class="J_imgContainer f-imgContainer"
            >
                <template v-if="groupItem.mIcon">
                    <template v-if="isFontIconId(groupItem.mIcon)">
                        <span
                            v-if="groupItem.mIconColor"
                            class="J_itemImage f-itemImg"
                            :style="{ color: groupItem.mIconColor }"
                        ></span>
                        <span class="J_itemImage f-itemImg"></span>
                    </template>
                    <img
                        v-else-if="groupItem.fileInfo"
                        class="J_itemImage f-itemImg"
                        :src="groupItem.iconPath"
                        :data-w="groupItem.fileInfo.width"
                        :data-h="groupItem.fileInfo.height"
                    />
                    <span v-else class="J_itemImage notImage faisco-icons-pic"></span>
                </template>
                <img v-else class="J_itemImage notImage f-itemImg" :src="defaultImg" data-w="326" data-h="326" />
            </a>
            <a :href="groupUrl" target="_self">
                <p class="f-itemImgName">{{ groupItem.name }}</p>
            </a>
        </template>
        <a v-else :href="groupUrl" target="_self" class="itemImageParent">
            <p class="f-itemImgName">{{ groupItem.name }}</p>
        </a>
    </div>
</template>

<script>
import { isFontIcon } from '@/shared/fai';
import { GROUP_STYLE } from '../constants';
export default {
    name: 'ProductGroupDetailImgGroupItemV3',
    props: {
        groupStyle: {
            type: Number,
            default: -1,
        },
        groupItem: {
            type: Object,
            default: () => ({}),
        },
        panelOptions: {
            type: Object,
            default: () => ({}),
        },
        resRoot: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        isImageStyle() {
            return this.groupStyle !== GROUP_STYLE.TEXT;
        },
        thirdGroupItemClass() {
            return this.isImageStyle ? 'f-groupImgContainer' : 'thirdGroupTextItem';
        },
        prUrl() {
            return this.panelOptions?.prUrl ?? '';
        },
        groupUrl() {
            return `${this.prUrl}?groupId=${this.groupItem.id}`;
        },
        defaultImg() {
            return `${this.resRoot}/image/module/productGroup/defaultImg.jpg`;
        },
    },
    methods: {
        isFontIconId(id) {
            return isFontIcon(id);
        },
    },
};
</script>

<style></style>
