<template>
    <module-frame :module-id="moduleId">
        <component :is="component" v-bind="propsData" />
    </module-frame>
</template>

<script>
import AllGroupView from './components/all-group.vue';
import CatalogView from '../components/catalog.vue';
import ModuleFrame from '@/modules/frame/index.vue';
import { MODULE_ID } from '../constants';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductGroupDetailV3View',
    components: {
        AllGroupView,
        CatalogView,
        ModuleFrame,
    },
    data() {
        return {
            pgType: 0,
            moduleId: MODULE_ID,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        component() {
            if (this.pgType === 0) {
                return AllGroupView;
            } else if (this.pgType === 2) {
                return CatalogView;
            }

            return void 0;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        moduleExtInfo() {
            return this.module?.extInfo ?? {};
        },
        propsData() {
            return {
                groupStyle: this.groupStyle,
                productGroupType: this.productGroupType,
                prUrl: this.prUrl,
                firstGroupList: this.firstGroupList,
                secondGroupMap: this.secondGroupMap,
                topLevel: this.topLevel,
                panelOptions: this.panelOptions,
                isMallTheme: this.isMallTheme,
                hasAuth: this.hasAuth,
            };
        },
        hasAuth() {
            return this.moduleExtInfo.hasAuth;
        },
        groupStyle() {
            return this.moduleExtInfo.groupStyle;
        },
        productGroupType() {
            return this.moduleExtInfo.productGroupType;
        },
        prUrl() {
            return this.moduleExtInfo.prUrl;
        },
        firstGroupList() {
            return this.moduleExtInfo.firstGroupList;
        },
        secondGroupMap() {
            return this.moduleExtInfo.secondGroupMap;
        },
        topLevel() {
            return this.moduleExtInfo.topLevel;
        },
        panelOptions() {
            return this.moduleExtInfo.panelOptions;
        },
        isMallTheme() {
            return this.moduleExtInfo.isMallTheme;
        },
    },
};
</script>

<style lang="scss">
.productNewGroup .firstGroupList .firstGroupItem {
    font-size: 0;
}
</style>
