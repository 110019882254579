import request from './request';

export function getNewsListAndGroup(params = {}) {
    let url = `/api/manage/news/getNewsListAndGroup`;

    return request
        .get(url, {
            params: {
                pageSize: 100,
                pageno: 1,
                ...params,
            },
        })
        .then(({ data }) => {
            return data;
        });
}

export function getNewsList(data) {
    let url = `/api/manage/news/getNewsList`;

    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getSortListForMangae(data) {
    let url = `/api/manage/news/getSortList`;

    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getSortListForGuest(data) {
    let url = `/api/guest/news/getSortList`;

    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}

export function delNewsApi(params = {}) {
    let url = `/api/manage/news/del`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getGroupListApi(params = {}) {
    let url = `/api/manage/news/getNewsListAndGroup`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function setNewsApi(params = {}) {
    let url = `/api/manage/news/setNews`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

/**
 * @description 获取文章详情V3的评论
 * @author json
 * @date 20/07/2021
 * @export
 * @param {*} [params={}]
 * @return {*}
 */
export function getNewsV3Comment(params = {}) {
    let url = `/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/news/getNewsV3Comment`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getNewsPrev(params = {}) {
    return request.get('/api/guest/module/getNewsPreAndNextHtml', { params }).then(({ data }) => {
        return data.nodeData;
    });
}
