export const isIE = () => {
    return !!$.browser.msie;
};

export function isWechat() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}

const MobiOSType = {
    UNKNOWN: 0,
    IPHONE: 1,
    IPAD: 2, // Mozilla
    WEBOS: 3,
    ANDROID: 4,
    ANDROIDICS: 5, //安卓4.0系统的代号  4.0以后的版本
    BLACKBERRY: 6,
    WINDOW_DESKTOP: 7,
    MAC: 8,

    ANDROID_2_3: 9,
    ANDROID_4_0: 10,
    ANDROID_4_1: 11,
    ANDROID_4_2: 12,
    ANDROID_4_3: 13,
    ANDROID_4_4: 14,
    ANDROID_5_0: 15,

    IOS_5_0: 16,
    IOS_5_1: 17,
    IOS_6_0: 18,
    IOS_6_1: 19,
    IOS_7_0: 20,
    IOS_7_1: 21,
    IOS_8_0: 22,
    IOS_8_1: 23,
};

export function getMobiOSType() {
    if (jm.os.IOS_5_0) {
        return MobiOSType.IOS_5_0;
    } else if (jm.os.IOS_5_1) {
        return MobiOSType.IOS_5_1;
    } else if (jm.os.IOS_6_0) {
        return MobiOSType.IOS_6_0;
    } else if (jm.os.IOS_6_1) {
        return MobiOSType.IOS_6_1;
    } else if (jm.os.IOS_7_0) {
        return MobiOSType.IOS_7_0;
    } else if (jm.os.IOS_7_1) {
        return MobiOSType.IOS_7_1;
    } else if (jm.os.IOS_8_0) {
        return MobiOSType.IOS_8_0;
    } else if (jm.os.IOS_8_1) {
        return MobiOSType.IOS_8_1;
    } else if (jm.os.ANDROID_2_3) {
        return MobiOSType.ANDROID_2_3;
    } else if (jm.os.ANDROID_4_0) {
        return MobiOSType.ANDROID_4_0;
    } else if (jm.os.ANDROID_4_1) {
        return MobiOSType.ANDROID_4_1;
    } else if (jm.os.ANDROID_4_2) {
        return MobiOSType.ANDROID_4_2;
    } else if (jm.os.ANDROID_4_3) {
        return MobiOSType.ANDROID_4_3;
    } else if (jm.os.ANDROID_4_4) {
        return MobiOSType.ANDROID_4_4;
    } else if (jm.os.ANDROID_5_0) {
        return MobiOSType.ANDROID_5_0;
    } else if (jm.os.iphone) {
        return MobiOSType.IPHONE;
    } else if (jm.os.ipad) {
        return MobiOSType.IPAD;
    } else if (jm.os.webos) {
        return MobiOSType.WEBOS;
    } else if (jm.os.androidICS) {
        return MobiOSType.ANDROIDICS;
    } else if (jm.os.android) {
        return MobiOSType.ANDROID;
    } else if (jm.os.blackberry) {
        return MobiOSType.BLACKBERRY;
    } else if (jm.os.WINDOW_DESKTOP) {
        return MobiOSType.WINDOW_DESKTOP;
    } else if (jm.os.MAC) {
        return MobiOSType.MAC;
    } else {
        return MobiOSType.UNKNOWN;
    }
}

/**
 * 屏幕类型定义
 * type的定义在JAVA的fai.web.Request中定义，要求一模一样
 */
export const BrowserType = {
    UNKNOWN: 0,
    CHROME: 1,
    FIREFOX: 2, // Mozilla
    MSIE10: 3,
    WEBKIT: 4,
    MSIE9: 5,
    MSIE11: 6,
    OPERA: 7,
    WX_BROWSER: 8,
    UC_BROWSER: 9,
    QQ_BROWSER: 10,
    B360_BROWSER: 11,
    BD_BROWSER: 12,
    SG_BROWSER: 13,
    LB_BROWSER: 14,
    SAFARI: 15,
};

export function getBrowserType() {
    if (jm.os.WXBrowser) {
        return BrowserType.WX_BROWSER;
    } else if (jm.os.UCBrowser) {
        return BrowserType.UC_BROWSER;
    } else if (jm.os.MQQBrowser) {
        return BrowserType.QQ_BROWSER;
    } else if (jm.os.LieBaoFast) {
        return BrowserType.LB_BROWSER;
    } else if (jm.os.BaiDuBrowser) {
        return BrowserType.BD_BROWSER;
    } else if (jm.os.Sougou) {
        return BrowserType.SG_BROWSER;
    } else if (jm.os.B360Browser) {
        return BrowserType.B360_BROWSER;
    } else if (jm.os.ie10) {
        return BrowserType.MSIE10;
    } else if (jm.os.chrome) {
        return BrowserType.CHROME;
    } else if (jm.os.fennec) {
        return BrowserType.FIREFOX;
    } else if (jm.os.safari) {
        return BrowserType.SAFARI;
    } else if (jm.os.ie9) {
        return BrowserType.MSIE9;
    } else if (jm.os.ie11) {
        return BrowserType.MSIE11;
    } else if (jm.os.opera) {
        return BrowserType.OPERA;
    } else if (jm.os.webkit) {
        return BrowserType.WEBKIT;
    } else {
        return BrowserType.UNKNOWN;
    }
}

/**
 * 屏幕类型定义
 * type的定义在JAVA的fai.web.Request中定义，要求一模一样
 */
const ScreenType = {
    OTHER: 0, // 其他分辨率
    W320H480: 1, // 320*480
    W480H800: 2, // 480*800
    W480H854: 3, // 480*854
    W720H1280: 4, // 720*1280
    WINDOW_SCREEN: 5,

    W240H320: 6,
    W600H800: 7,
    W540H960: 8,
    W640H960: 9,
    W768H1024: 10,

    W640H1136: 11,
    W720H1184: 12,
    W1440H2560: 13,
    W800H1280: 14,

    W750H1334: 15,
    W1080H1776: 16,
    W1080H1920: 17,
    W1536H2048: 18,
    W1280H720: 19,
};

export function getScreenType(width, height) {
    if (width == 320 && height == 480) {
        return ScreenType.W320H480; // 320*480
    } else if (width == 480 && height == 800) {
        return ScreenType.W480H800; // 480*800
    } else if (width == 480 && height == 854) {
        return ScreenType.W480H854; // 480*854
    } else if (width == 720 && height == 1280) {
        return ScreenType.W1720H1280; // 720*1280
    } else if (width == 240 && height == 320) {
        return ScreenType.W240H320; // 240*320
    } else if (width == 600 && height == 800) {
        return ScreenType.W600H800; // 600*800
    } else if (width == 540 && height == 960) {
        return ScreenType.W540H960; // 540*960
    } else if (width == 640 && height == 960) {
        return ScreenType.W640H960; // 640*960
    } else if (width == 768 && height == 1024) {
        return ScreenType.W768H1024; // 768*1024
    } else if (width == 640 && height == 1136) {
        return ScreenType.W640H1136; // 640*1136
    } else if (width == 720 && height == 1184) {
        return ScreenType.W720H1184; // 720*1184
    } else if (width == 1440 && height == 2560) {
        return ScreenType.W1440H2560; // 1440*2560
    } else if (width == 800 && height == 1280) {
        return ScreenType.W800H1280; // 800*1280
    } else if (width == 750 && height == 1334) {
        return ScreenType.W750H1334; // 750*1334
    } else if (width == 1080 && height == 1776) {
        return ScreenType.W1080H1776; // 1080*1776
    } else if (width == 1080 && height == 1920) {
        return ScreenType.W1080H1920; // 1080*1920
    } else if (width == 1536 && height == 2048) {
        return ScreenType.W1536H2048; // 1536*2048
    } else if (width == 1280 && height == 720) {
        return ScreenType.W1280H720; // 1280*720
    } else {
        return ScreenType.OTHER;
    }
}

/**
 * 返回屏幕宽高
 */
export function Screen() {
    var ratio = window.devicePixelRatio || 1;
    return {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
    };
}

export function isBaiduBrowser() {
    return navigator.userAgent.indexOf('baidu') > -1;
}
