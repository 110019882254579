<template>
    <module-frame :module-id="moduleId">
        <div id="signupPage">
            <div class="loginHeader webHeaderBg">
                <a class="g_close icon-gClose" @click="closeClick"></a>
                <span class="title pageTitle" v-text="LS.col_member_signup"></span>
            </div>
            <div class="signUpContent">
                <div v-if="manageMode" class="notice">您目前处于网站管理状态，请先退出再注册</div>
                <div id="profileArea">
                    <template v-if="signUpbyMobile">
                        <div :class="commonClassList">
                            <select
                                :id="MOBILE_CT"
                                :name="MOBILE_CT"
                                class="itemEdit userAddItem g_select"
                                @click="mobileCtClick"
                                @blur="mobileCtBlur"
                                @change="mobileCtChange"
                            >
                                <option
                                    v-for="ct in mobileCtLst"
                                    :key="ct.lskey"
                                    :value="ct.ctName"
                                    v-text="ct.ctShowName + ct.ctCode"
                                ></option>
                            </select>
                            <span :class="mobileItem.itemIcon"></span>
                            <span :class="mobileItem.customPropName">手机区号</span>
                        </div>
                        <div :class="commonClassList">
                            <input
                                :id="mobileItem.fieldKey"
                                :name="mobileItem.name"
                                :placeholder="mobileItem.propPlaceholder + LS.mobi_member_Must"
                                class="mustItem itemEdit userAddItem isCheckUAI g_input"
                                type="text"
                                maxlength="50"
                                @keyup="mobileKeyUp"
                            />
                            <span :class="mobileItem.itemIcon"></span>
                            <span :class="mobileItem.customPropName" v-text="mobileItem.name"></span>
                        </div>
                        <div class="g_globalLine">
                            <input
                                id="messageAuthCode"
                                type="text"
                                class="captchaText g_input"
                                maxlength="6"
                                :placeholder="messageAuthCodePlaceholder"
                            />
                            <div
                                id="messageAuthCodeClick"
                                class="fk-sign-getMobileCode fk-sign-getMobileCodeFix"
                                :title="messageAuthCodeTitle"
                                @click="signMobileCodeClick"
                                v-text="LS.mobi_getMobileCode"
                            ></div>
                            <span class="icon-captcha g_mainColor"></span>
                            <div style="clear: both"></div>
                        </div>
                    </template>
                    <template v-else-if="signUpbyMail">
                        <div :class="commonClassList">
                            <input
                                id="email"
                                type="text"
                                maxlength="50"
                                :name="mailItem.name"
                                class="mustItem itemEdit userAddItem isCheckUAI g_input"
                                :placeholder="mailItem.propPlaceholder + LS.mobi_member_Must"
                            />
                            <span :class="mailItem.itemIcon"></span>
                        </div>
                    </template>
                    <template v-else>
                        <div :class="commonClassList">
                            <input
                                id="memberSignupAcct"
                                type="text"
                                maxlength="50"
                                class="mustItem itemEdit userAddItem g_input"
                                :placeholder="memberSignupAcctPlaceholder"
                            />
                            <span class="icon-userIcon g_mainColor"></span>
                            <span class="mustIcon">*</span>
                        </div>
                    </template>
                    <div :class="commonClassList">
                        <input
                            id="memberSignupPwd"
                            type="password"
                            autocomplete="off"
                            class="mustItem itemEdit icon-pswIcon userAddItem g_input"
                            :placeholder="memberSignUpPwdPlaceholder"
                        />
                        <span class="icon-pswIcon g_mainColor"></span><span class="mustIcon">*</span>
                    </div>
                    <div :class="commonClassList">
                        <input
                            id="memberSignupRepwd"
                            type="password"
                            autocomplete="off"
                            class="mustItem itemEdit icon-pswIcon userAddItem g_input"
                            :placeholder="memberSingupRepwdPlaceholder"
                        />
                        <span class="icon-pswIcon g_mainColor"></span><span class="mustIcon">*</span>
                    </div>
                    <template v-for="item in filterList">
                        <template v-if="item.fieldKey == MOBILE">
                            <div :key="item.id" :class="commonClassList">
                                <select
                                    :id="MOBILE_CT"
                                    :name="MOBILE_CT"
                                    class="itemEdit userAddItem g_select"
                                    @click="mobileCtClick"
                                    @blur="mobileCtBlur"
                                    @change="mobileCtChange"
                                >
                                    <option
                                        v-for="ct in mobileCtLst"
                                        :key="ct.lskey"
                                        :value="ct.ctName"
                                        v-text="ct.ctShowName + ct.ctCode"
                                    ></option>
                                </select>
                                <span :class="item.itemIcon"></span>
                                <span :class="item.customPropName">手机区号</span>
                            </div>
                        </template>
                        <div :key="item.id" :class="[commonClassList, fileClass(item)]">
                            <template v-if="item.pt == 0">
                                <input
                                    :id="item.fieldKey"
                                    :name="item.name"
                                    :placeholder="addMustTip(item)"
                                    :class="{
                                        mustItem: item.must == 'isCheck',
                                        isCheckUAI: item.must == 'isCheck',
                                    }"
                                    class="itemEdit userAddItem g_input"
                                    type="text"
                                    maxlength="50"
                                />
                                <span :class="item.itemIcon"></span>
                            </template>
                            <template v-else-if="item.pt == 1">
                                <select
                                    :id="item.fieldKey"
                                    :name="item.name"
                                    :class="{
                                        isCheckUAI: item.must == 'isCheck',
                                    }"
                                    class="itemEdit userAddItem g_select"
                                    @click="itemEditClick"
                                    @blur="itemEditBlur"
                                >
                                    <option value="" v-text="addMustTip(item)"></option>
                                    <option
                                        v-for="prop in item.propOptions"
                                        :key="prop"
                                        :value="prop"
                                        v-text="prop"
                                    ></option>
                                </select>
                                <span :class="item.itemIcon"></span>
                            </template>
                            <template v-else-if="item.pt == 2">
                                <div class="member_file_tips">{{ getFileTips(item) }}</div>
                                <div :id="'member_file_contentwrap-' + item.id" class="member_file_contentwrap">
                                    <input
                                        :id="item.fieldKey"
                                        :name="item.name"
                                        class="itemEdit userAddItem member_file_input"
                                        :class="{
                                            isCheckUAI: item.must == 'isCheck',
                                        }"
                                        :data-name="item.id"
                                    />
                                    <div class="member_file_btn">
                                        <div class="member_file_btnupload uploadify-button"></div>
                                        <span v-text="addFileText"></span>
                                    </div>
                                    <div class="member_file_content">
                                        <div class="member_file_meta">
                                            <div class="member_file_name"></div>
                                            <div class="member_file_size"></div>
                                        </div>
                                        <div class="member_file_icon">
                                            <svg class="member_file_close">
                                                <use xlink:href="#jzm-v25"></use>
                                            </svg>
                                            <a
                                                ref="nofllow"
                                                class="member_file_preview_link"
                                                href="javascript:void(0)"
                                                target="_blank"
                                            >
                                                <svg class="member_file_preview">
                                                    <use xlink:href="#jzm-vicon_preview"></use>
                                                </svg>
                                            </a>
                                            <a
                                                ref="nofllow"
                                                class="member_file_download_link"
                                                href="javascript:void(0)"
                                                target="_blank"
                                            >
                                                <svg class="member_file_download">
                                                    <use xlink:href="#jzm-v16"></use>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <span
                                v-if="item.customPropName != ''"
                                :class="item.customPropName"
                                v-text="item.name"
                            ></span>
                        </div>
                    </template>
                    <div v-if="noRemark" :class="commonClassList">
                        <input
                            id="memberSignupRemark"
                            type="text"
                            class="itemEdit g_input userAddItem"
                            :placeholder="memberSignupRemarkPlaceholder"
                            :maxlength="memberSignupRemarkMaxLength"
                        />
                        <span class="icon-msgIcon g_mainColor"></span>
                    </div>
                    <div v-if="protocolOpen" class="ProtocolLineCtrl g_globalLine">
                        <input
                            id="memberAgreePro"
                            style="position: relative"
                            type="checkbox"
                            class="memberAgreeProCheck"
                        />
                        <label for="memberAgreePro" class="memberAgreeProLabel" @click="checkLabel">
                            <span class="icon-uncheckIcon g_mainColor"></span>{{ LS.mobi_member_Protocol_Msg }}
                        </label>
                        <a
                            id="signUpMProtocol"
                            hidefocus="true"
                            class="memberProtocol g_mainColor"
                            v-text="signUpMProtocolName"
                        ></a>
                    </div>
                    <div class="signupSubmitCtrl g_globalLine">
                        <input
                            type="button"
                            class="g_button sendIcon signupSubmit submitIcon fpw_btn"
                            disabled
                            :value="LS.memberReg"
                            @click="memberSignupSubmitData"
                        />
                    </div>
                </div>
                <div id="checkEmail" class="checkEmail"></div>
                <a id="emailAddr" class="emailAddr" style="display: none" target="_blank">地址</a>
            </div>
        </div>
        <div id="mProPage" class="J_cusBack">
            <div class="loginHeader webHeaderBg profileLoginHeader J_registerHeader">
                <a class="g_close icon-gClose" @click="mProPageClick"></a>
                <span class="title pageTitle"></span>
            </div>
            <div class="mProContent">
                <div class="set-line" style="overflow-x: auto"></div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';

import { checkBit } from '@/shared/fai';
import { mapState } from 'vuex';
import {
    selectAnimationCtrl,
    selectAnimation,
    signUpPersInfoChangeMobileCt,
    checkMustItem,
    initSignUpPage,
    changeSelectDefaultColor,
    selectValueChange,
    checkLabel,
    memberSignupSubmit,
    getSignMobileCode,
} from '../../method';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { changeHref } from '@/features/router/universal';
import { MemberPropDef, MemberDef } from '../../memberDef';
import { memberFileUpload } from '@/system/member/fileUpload';
export default {
    name: 'SignUpV2',
    components: {
        ModuleFrame,
    },
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
            MOBILE: 'mobile',
            EMAIL: 'email',
            MOBILE_CT: 'mobileCt',
            REQUIRED: 0x2,
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        renderOption() {
            return this.module.renderOptions || {};
        },
        memberSignupRemarkMaxLength() {
            return MemberDef.Limit.REMARK_MAXLEN;
        },
        signUpbyMobile() {
            return this.renderOption.signUpbyMobile || false;
        },
        signUpbyMail() {
            return this.renderOption.signUpbyMail || false;
        },
        protocolOpen() {
            return this.renderOption.proOpen || false;
        },
        noRemark() {
            return this.renderOption.noRemark || false;
        },
        proName() {
            return this.renderOption.proName || '';
        },
        mobileCtLst() {
            return this.renderOption.mobileCtNameList || [];
        },
        returnA() {
            return this.renderOption.returnA || '/index.jsp';
        },
        returnUrl() {
            return this.renderOption.returnUrl || '/index.jsp';
        },
        tagWidthClass() {
            return this.renderOption.tagWidthClass || 'tagWidth8';
        },
        propParam() {
            return this.renderOption.propParam || {};
        },
        pleaseInputStr() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        displayList() {
            let list = this.renderOption.displayList || [];
            // 处理下display的数据
            list.forEach((item) => {
                let { flag, pp, fieldKey, pc = '' } = item;
                let must = checkBit(flag, this.REQUIRED) ? 'isCheck' : 'notCheck';
                let propPlaceholder = pp;
                let itemIcon = '';
                let customPropName = '';
                if (fieldKey == 'email') {
                    itemIcon = 'icon-emailIcon g_mainColor';
                } else if (fieldKey == 'phone') {
                    itemIcon = 'icon-customIcon g_mainColor';
                    customPropName = 'customIcon';
                } else if (fieldKey == 'name') {
                    itemIcon = 'icon-nameIcon g_mainColor';
                } else if (fieldKey == 'mobile') {
                    itemIcon = 'icon-iphone2 g_mainColor';
                    customPropName = 'customIcon';
                } else {
                    itemIcon = 'icon-customIcon g_mainColor';
                    customPropName = 'customIcon';
                }

                let propContent = pc.split('\n');
                item.propOptions = propContent;

                item.must = must;
                item.propPlaceholder = propPlaceholder;
                item.itemIcon = itemIcon;
                item.customPropName = customPropName;
            });
            return list;
        },
        mobileItem() {
            let mobileList = this.displayList.filter(
                (item) => item[MemberPropDef.Info.TYPE] == MemberPropDef.Type.SYS_MOBILE
            );
            return mobileList.length ? mobileList[0] : {};
        },
        mailItem() {
            let mailList = this.displayList.filter(
                (item) => item[MemberPropDef.Info.TYPE] == MemberPropDef.Type.SYS_EMAIL
            );
            return mailList.length ? mailList[0] : {};
        },
        filterList() {
            return this.displayList.filter((item) => {
                let { fieldKey } = item;
                if (fieldKey == this.MOBILE && this.signUpbyMobile) {
                    return false;
                }
                if (fieldKey == this.EMAIL && this.signUpbyMail) {
                    return false;
                }
                return true;
            });
        },
        commonClassList() {
            let classList = ['g_globalLine', this.tagWidthClass];
            return classList;
        },
        memberSignupAcctPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_member_Account + this.LS.mobi_member_Must;
        },
        messageAuthCodePlaceholder() {
            return this.LS.mobi_inputMobileCode;
        },
        messageAuthCodeTitle() {
            return this.LS.mobi_getMobileCode;
        },
        memberSignUpPwdPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_member_Password + this.LS.mobi_member_Must;
        },
        memberSingupRepwdPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_memberSignup_retypePassword + this.LS.mobi_member_Must;
        },
        memberSignupRemarkPlaceholder() {
            return this.pleaseInputStr + this.LS.mobi_memberSignup_remark;
        },
        signUpMProtocolName() {
            return `《${this.proName}》`;
        },
        showCaptcha() {
            return this.renderOption.showCaptcha;
        },
        fileItemList() {
            return this.filterList.filter((item) => {
                return item.pt == 2;
            });
        },
        addFileText() {
            return `+ ${this.LS.js_add_file}`;
        },
    },
    mounted() {
        if (this.displayList.length) {
            selectValueChange('#signupPage');
            changeSelectDefaultColor('#c4c9ca', '#333333', '.jz_themeV3 #signupPage .g_select');
        }
        hidePlaceholder();
        checkMustItem('.signUpContent');
        initSignUpPage();
        this.initFileUpload();
    },
    methods: {
        initFileUpload() {
            this.fileItemList.forEach((item) => {
                memberFileUpload({
                    moduleId: this.moduleId,
                    fileSizeLimit: this.propParam[item.id].fileSizeLimit,
                    fileUpInfo: JSON.parse(item.pc).fup || {},
                    propId: item.id,
                });
            });
        },
        getFileTips(item) {
            let fileItemOption = JSON.parse(item.pc).fup || {};
            return fileItemOption.tt;
        },
        fileClass(item) {
            return item.pt == 2 ? ' memeber_file_line memeber_file_line-old' : '';
        },
        closeClick() {
            let isclick = this.returnA.indexOf('onclick') != -1;
            if (isclick) {
                changeHref(document.referrer);
            } else {
                if (VITE_APP_MODE !== 'visitor') {
                    changeHref(this.returnA == '/' ? '/index.jsp' : this.returnA);
                } else {
                    changeHref(this.returnA);
                }
            }
        },
        mobileKeyUp() {},
        signMobileCodeClick() {
            getSignMobileCode(this.showCaptcha);
        },
        checkLabel(e) {
            checkLabel(e.target);
        },
        memberSignupSubmitData() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                memberSignupSubmit(this.returnUrl, this.signUpbyMobile, this.signUpbyMail);
            }
        },
        mProPageClick() {
            jm('#mProPage').hide();
            jm('#signupPage').show();
            jm('#webHeaderBox').show();
        },
        mobileCtClick(e) {
            selectAnimationCtrl(e.target);
        },
        mobileCtBlur(e) {
            selectAnimation(e.target);
        },
        mobileCtChange(e) {
            signUpPersInfoChangeMobileCt(e.target);
        },
        itemEditClick(e) {
            selectAnimationCtrl(e.target);
        },
        itemEditBlur(e) {
            selectAnimation(e.target);
        },
        addMustTip(item) {
            if (item.must == 'isCheck') {
                return item.propPlaceholder + this.LS.mobi_member_Must;
            } else {
                return item.propPlaceholder;
            }
        },
    },
};
</script>
<style lang="scss">
.mobiCol11 {
    #webHeader {
        display: none;
    }
    #webTopBox {
        display: none;
    }
    .loginHeader,
    .ShipDetailsHeader,
    .BanksDetailHeader {
        text-align: center;
        height: 2.25rem;
        line-height: 2.25rem;
        margin: 0;
        padding: 0;
        position: relative;
    }
    .signUpContent {
        margin: 0.75rem 0.5rem 0px 0.5rem;
    }
    #signupPage .signUpContent .itemEdit {
        font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma !important;
    }
    .itemEdit {
        padding-left: 2.1rem;
    }
    .g_locale2052 .itemEdit {
        padding-left: 3.5rem;
    }
    .g_input {
        padding: 0.75rem;
        height: 2.5rem;
        line-height: 1.1rem;
        font-size: 0.7rem;
        background-color: #fff;
        border: 1px solid #ccc;
        margin-top: -1px;
        width: 100%;
        color: rgb(153, 153, 153);
        font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
        border-radius: 2px;
        box-sizing: border-box;
        outline: none;
        z-index: 1;
    }
    .moduleContent .mustIcon {
        position: absolute;
        top: 0.5rem;
        right: -1rem;
        color: red;
    }
}
/*tagWidth4 tagWidth6 tagWidth8 用来控制注册页面中最左端提示文本的显示宽度*/
.g_locale2052 .tagWidth4 .g_input {
    padding-left: 2.85rem;
}
.g_locale2052 .tagWidth4 .g_select {
    padding-left: 2.25rem;
}
.g_locale2052 .tagWidth6 .g_input {
    padding-left: 3.55rem;
}
.g_locale2052 .tagWidth6 .g_select {
    padding-left: 2.95rem;
}
.g_locale2052 .tagWidth8 .g_input {
    padding-left: 4.25rem;
}
.g_locale2052 .tagWidth8 .g_select {
    padding-left: 4rem;
}
</style>
