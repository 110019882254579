<script lang="jsx">
import newsMoreMixin from '../mixins/news-more-mixin';
import { createUrlArgsOnclickStr } from '@/modules/comm';
import { mapGetters } from 'vuex';
export default {
    name: 'NewsMoreItemStyleOther',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        onclickStr() {
            const { ndReqArgs } = this.newsItem;
            console.log(createUrlArgsOnclickStr(ndReqArgs, this.openRemoveUrlArgs, this.hashRemoved));
            return createUrlArgsOnclickStr(ndReqArgs, this.openRemoveUrlArgs, this.hashRemoved);
        },
    },
    methods: {
        createNewsLineWrapperByTwoColumnArrangeWay() {
            let style = this.isNeedTwoColumn ? 'margin-left:5%' : '';
            let newsLineId = `newsLine${this.id}`;
            const { topClassName, topSwitch, title, id } = this;
            const className = `newsLine ${this.lineClass} ${this.topFlagLineClass} wholeLine J_lineH j-lineH`;
            return {
                render() {
                    return (
                        <div
                            style={style}
                            id={newsLineId}
                            topClassName={topClassName}
                            topSwitch={topSwitch}
                            newsId={id}
                            newsName={title}
                            class={className}
                        >
                            {this.$slots.default}
                        </div>
                    );
                },
            };
        },
        createNewsLineWrapper() {
            let newsLineId = `newsLine${this.id}`;
            const className = `newsLine line icon-gline ${this.lineClass} ${this.topFlagLineClass} ${this.firstClassName} ${this.lastClassName} wholeLine J_lineH j-lineH`;
            const { topClassName, id, title, topSwitch } = this;
            return {
                render() {
                    return (
                        <div
                            id={newsLineId}
                            topClassName={topClassName}
                            topSwitch={topSwitch}
                            newsId={id}
                            newsName={title}
                            class={className}
                        >
                            {this.$slots.default}
                        </div>
                    );
                },
            };
        },
        generateWrapper() {
            let renderWrapper;
            if (this.hasTwoColumnArrangeWay) {
                let wrapper = this.createNewsLineWrapperByTwoColumnArrangeWay();
                if (this.showNewsArrayPanel) {
                    renderWrapper = {
                        render() {
                            return <wrapper>{this.$slots.default}</wrapper>;
                        },
                    };
                } else {
                    renderWrapper = {
                        render() {
                            return <wrapper>{this.$slots.default}</wrapper>;
                        },
                    };
                }
            } else {
                renderWrapper = this.createNewsLineWrapper();
            }
            return renderWrapper;
        },
        generateElementsRequired() {
            if (this.showNewsElementsRequire) {
                const comment = this.showCommentRequire ? (
                    <div class="ne_newsComment faisco-icons-comment">
                        <span class="newsCommentCount">{this.totalNewsCommentCount}</span>
                    </div>
                ) : (
                    ''
                );
                const authorStyle = this.showCommentRequire ? {} : { 'margin-left': '0rem' };
                const author = this.showAuthorRequire ? (
                    <div class="ne_newsAuthor faisco-icons-author" style={authorStyle}>
                        <span class="newsAuthorName">{this.newsAuthor}</span>
                    </div>
                ) : (
                    ''
                );
                const showSeparatorLine =
                    (this.showCommentRequire || this.showAuthorRequire) &&
                    (this.showSortRequire || this.showDateRequire);

                return {
                    render: () => {
                        return (
                            <div
                                class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
                                vOn:click={this.gotoHref}
                            >
                                {comment}
                                {author}
                                {showSeparatorLine ? <div class="ne_separatorLine g_ne_separatorLine"></div> : ''}
                                {this.showSortRequire ? <div class="ne_newsSort">{this.groupName}</div> : ''}
                                {this.showDateRequire ? <div class="ne_newsTime">{this.formattedNewsDate}</div> : ''}
                            </div>
                        );
                    },
                };
            } else {
                return;
            }
        },
    },
    render() {
        const wrapper = this.generateWrapper();
        const id = 'lineBody' + this.id;
        const elements = this.generateElementsRequired();
        return (
            <wrapper>
                <div id={id} class="lineBody lineBodyTable">
                    {this.topFlagClass !== '' ? (
                        <div class="wholeLineTFTableCell">
                            <div class={this.topFlagClass}></div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div class="linkTableCell">
                        <div class="linkTable">
                            <a hidefocus="true" href={this.href} target={this.target} domPropsOnclick={this.onclickStr}>
                                {this.title}
                            </a>
                        </div>
                    </div>
                </div>
                <elements></elements>
            </wrapper>
        );
    },
};
</script>

<style scoped lang="scss">
.newsList .line {
    padding-left: 1.3rem;
    cursor: pointer;
}
</style>
