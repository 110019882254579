import { logDog } from '@/shared/log';
import { createManagePageUrl } from '@/shared/url';

export const showTopManageFrame = function (initPage, href, lan, hash, refresh, open = false) {
    top._manageStatus = true;
    // 不是新开页面才检查大保存
    if (!open && Vue.prototype.$designer.checkSaveBar()) {
        return false;
    }

    //信息查询未开通且帮助面板打开时 关闭面板 by mackie 20191009
    if (initPage === 'manageFrameSearchProjectAdd' && Fai.top.$('#fk-helpQueryOuterPanel').length > 0) {
        Fai.top.$('#fk-helpQueryOuterPanel').hide();
        Fai.top.FAI_HELP_QUERY.vue.closePanelClick();
    }

    logDog(200025, 2);

    /**
     * @description 设计器跳转到管理后台页面的方法
     * @param {String} initPage 默认打开的页面. 若无则为manageFrameJZAllSiteStat聚合页
     * @param {String} href 路径
     * @param {String} lan 语言版本
     * @param {String} hash 需要带上的hash值
     */
    function managePageHandler(initPage = 'manageFrameJZAllSiteStat', href, lan, hash) {
        const url = createManagePageUrl(initPage, hash);
        if (open) {
            window.open(url);
        } else {
            Fai.top.window.location.href = url;
        }
    }

    return managePageHandler(initPage, href, lan, hash);
};
