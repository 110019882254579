import { addRequestPrefix, encodeUrl } from '@/shared/util';
import { QuickIn } from '../../utils/quick-in';
import { SimpleDrag } from '../../utils/simple-drag';
import { changeHref } from '@/features/router/universal';
export function initProductGroup(pgStyle, pgType, manageMode) {
    if (pgType == 1 || pgStyle == 0) {
        return false;
    }

    var previewHeight = $('#g_body').height();
    var productSearchModule = jm('#module22');
    var productGroupPanel = jm('.productNewGroup');
    var webCustomerServiceBox = jm('.webCustomerServiceBox');
    var webCustomerServiceBoxHeight = 0;
    if (webCustomerServiceBox.length) {
        webCustomerServiceBoxHeight = webCustomerServiceBox[0].offsetHeight;
    }
    var prUrl = productGroupPanel.attr('_prUrl') || '';
    var PSMHeight = (productSearchModule[0] || {}).offsetHeight,
        isMallTheme = false,
        firstGroup,
        firstGroupItem,
        navWidth,
        itemWidth,
        itemEl,
        turnRight;

    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        isMallTheme = true;
    }

    if (isMallTheme) {
        firstGroup = productGroupPanel.find('.firstGroup');
        jm('#webFooter').find('.footerMenu').hide();
        jm('#webFooter').find('.technical').hide();
        productGroupPanel.height(
            jm('body').height() - jm('#webHeaderBox').height() - jm('#fixedAreaBox').height() + 'px'
        );
    }

    // 等比例缩放图片，并且各种居中。临上线各种改T.T
    var itemImages = jm(productGroupPanel).find('.J_itemImage');
    for (var i = 0; i < itemImages.length; i++) {
        var itemImage = itemImages[i];
        scaleImage(itemImage);
    }

    // fix屏幕高度
    productGroupPanel.css('height', previewHeight - PSMHeight - webCustomerServiceBoxHeight + 'px');
    firstGroupItem = productGroupPanel.find('.firstGroup .firstGroupItem');

    // 绑定点击一级分类切换二级分类事件
    firstGroupItem.off('.productNewGroup').on('click.productNewGroup', function () {
        var checkedItem = productGroupPanel.find('.firstGroup .checkedItem'),
            checkedItemSecondPanel = productGroupPanel.find('.unFirstGroup .checkedPanel');
        var currentItem = jm(this),
            currentItemId = jm(currentItem).attr('data-id'),
            currentItemSecondPanel = jm('#sed_' + currentItemId),
            hideScollBar = jm('.hideScollBar'),
            timer;

        // 修复有三级分类时候，二级分类跳转页面不正常的bug
        if (productGroupPanel.find('.J_hasThreeGroup').length != 0) {
            // 如果有三级分类的时候
            //如果当前点击的一级分类下属没有分类，那就跳转到这个分类的产品筛选结果页
            if (!currentItemSecondPanel.html() && prUrl) {
                changeHref(prUrl + '?groupId=' + currentItemId);
                return false;
            }
            // 如果没有三级分类的时候
            //如果当前点击的一级分类下属没有分类，那就跳转到这个分类的产品筛选结果页
            if (
                (currentItemSecondPanel.html() === '' ||
                    !Array.from(currentItemSecondPanel.find('.f-groupImgWrap')).some((ele) => !!jm(ele).html())) &&
                prUrl
            ) {
                changeHref(prUrl + '?groupId=' + currentItemId);
                return false;
            }
        }

        if (jm(this).hasClass('checkedItem')) {
            return false;
        }

        checkedItem.removeClass('checkedItem');
        checkedItemSecondPanel.removeClass('checkedPanel');
        currentItem.addClass('checkedItem');
        currentItemSecondPanel.addClass('checkedPanel');
        // 删除商城主题色
        checkedItem.find('.firstGroupName').removeClass('f-fontColor');
        currentItem.find('.firstGroupName').addClass('f-fontColor');
        hideScollBar[0].scrollTop = 0;

        if (isMallTheme) {
            turnRight = 0;
            currentItem.find('.J_navSelect').addClass('fk-navSelect');
            turnRight = jm('body')[0].clientWidth / 2 - currentItem[0].getBoundingClientRect().left;
            clearInterval(timer);
            if (turnRight > 0) {
                timer = setInterval(function () {
                    if (turnRight > 0) {
                        turnRight--;
                        firstGroup[0].scrollLeft--;
                    } else {
                        clearInterval(timer);
                    }
                }, 1);
            } else {
                timer = setInterval(function () {
                    if (turnRight < 0) {
                        turnRight++;
                        firstGroup[0].scrollLeft++;
                    } else {
                        clearInterval(timer);
                    }
                }, 1);
            }
        }
    });

    // 绑定粗糙的拖拽控件
    if (pgStyle != 5 && pgStyle != 6) {
        SimpleDrag.__create(productGroupPanel.find('.firstGroupList'));
    } else {
        navWidth = 0;
        firstGroupItem.each(function (index, el) {
            itemEl = jm(el);
            itemWidth = el.offsetWidth + 2 + parseFloat(itemEl.css('margin-right').replace('px', '')); // +2是为了去除四舍五入的情况
            navWidth += itemWidth;
        });

        productGroupPanel.find('.firstGroupList').width(navWidth + 'px');
    }

    if (!manageMode || (!isMallTheme && pgStyle != 1)) {
        return;
    }

    // 增加修改图片的快捷入口
    var items = jm(productGroupPanel).find('.J_groupItem');
    var quickInOptions = {
        key: 'productGroup',
        targets: items,
        type: QuickIn.TYPE.FU,
        popupWindowOption: {
            settings: {
                title: '添加图片',
                maxSize: 5,
                type: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
                imgMode: 2,
                maxChoiceList: 1,
                netFileMode: true,
                openIcon: true,
                tabIndex: 0,
                group: 'thumbImg',
            },
            closeFunc: (target, uploadResult) => {
                editImage.call(null, target, uploadResult, isMallTheme);
            },
        },
    };

    if (pgStyle != 2) {
        QuickIn.__createInstance(quickInOptions);
    }
}

function scaleImage(image, isMallTheme) {
    var imageW = parseInt(jm(image).attr('data-w')),
        imageH = parseInt(jm(image).attr('data-h')),
        width = isMallTheme ? '3.0rem' : '2.8rem',
        height = isMallTheme ? '3.0rem' : '2.9rem';

    if (imageW >= imageH) {
        jm(image).css('width', width).css('height', 'auto').css('vertical-align', 'middle');
    } else if (imageH > imageW) {
        jm(image).css('width', 'auto').css('height', height).css('display', 'block').css('margin', '0 auto');
    }
}

function editImage(target, uploadResult, isMallTheme) {
    if (target.length == 0 || !uploadResult.fileId || uploadResult.fileId.length == 0) {
        return;
    }

    var groupId = parseInt(jm(target).parent().attr('data-id'));
    var fileId = uploadResult.fileId;
    var filePath = uploadResult.filePath;
    var isDefaultColor = uploadResult.isDefaultColor;
    var color = uploadResult.color;
    var url =
        addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
        '/productGroup_h.jsp?cmd=setWafCk_setSingle&id=' +
        groupId +
        '&mIcon=' +
        fileId;
    if (!isDefaultColor) {
        url = url + '&mIconColor=' + encodeUrl(color);
    }

    jm.ajax({
        url,
        type: 'get',
        error() {
            Vue.prototype.$ing('服务繁忙，请稍候重试', false);
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (data.success) {
                var image = jm(target).parent().find('.J_itemImage');
                var imageParent = jm(image).parent();
                var imgClass = isMallTheme ? 'f-itemImg' : 'itemImage';

                imageParent.empty();
                if (top.Fai.isFontIcon(fileId)) {
                    if (!isDefaultColor) {
                        imageParent.append(
                            "<span class='J_itemImage " +
                                imgClass +
                                ' ' +
                                filePath +
                                "' style='color:" +
                                color +
                                "'></span>"
                        );
                    } else {
                        imageParent.append("<span class='J_itemImage " + imgClass + ' ' + filePath + "'></span>");
                    }
                } else {
                    imageParent.append(
                        "<img  class='J_itemImage " +
                            imgClass +
                            "' src='" +
                            filePath +
                            "' data-w='" +
                            uploadResult.fileWidth +
                            "' data-h='" +
                            uploadResult.fileHeight +
                            "' />"
                    );
                }

                scaleImage(jm(imageParent).find('.J_itemImage'), isMallTheme);

                Vue.prototype.$ing(data.msg, true);
            } else {
                Vue.prototype.$ing(data.msg, true);
            }
        },
    });
}
