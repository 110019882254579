<template>
    <div v-if="isBannerShow && render" id="webBanner" class="webBanner" :class="bannerCls" :style="bannerStyle">
        <div v-if="moduleData" id="bannerSwipe" class="swipe bannerSwipe" :data-size="bannerlist.length">
            <div id="bannerSwipeContainer" class="bannerSwipeContainer defaultBanner">
                <div v-for="(item, index) in bannerlist" :id="'bannerItem' + item.ide" :key="item.ide + index">
                    <a
                        :rel="item.nf ? 'nofollow' : void 0"
                        :href="item.lt !== 0 ? getJumpUrl(item) : 'javascript:;'"
                        :src-original="item.p"
                        :vheight="item.h"
                        :vwidth="item.w"
                        class="J_img_lazyload bannerImageDiv"
                        :class="item.t === 0 ? 'systemtBanner' : 'customBanner'"
                    ></a>
                    <div v-if="showDesc" class="bannerDescription"
                        ><div class="description">{{ item.des }}</div></div
                    >
                </div>
            </div>
            <div id="bannerBullet" :class="showDesc ? 'bannerBullet2' : 'bannerBullet'">
                <ul id="bullets" class="bullets">
                    <li v-for="(item, index) in bannerlist" :key="item.id" :class="index === 0 ? 'on' : ''"></li>
                </ul>
            </div>
        </div>
        <loading v-else></loading>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import './bannerStyle.css';
import './bannerAnimateStyle.css';
import loading from '@/components/loading/loading.vue';
import { BannerAnimateFactory, initBannerDefaultDom, initSwipe } from '../tool';
import { logDog } from '@/shared/log';
import { debounce, deepClone } from '@/shared/util';

import { bindImgLazyLoad } from '@/shared/imageLazyload';
export default {
    components: {
        loading,
    },
    data() {
        return {
            inited: false,
            // 每次横幅变化时，需要清除掉诸如轮播等副作用(不在vue管理vue没法接管)
            render: true,
        };
    },
    computed: {
        ...mapState(['openThemeV3']),
        moduleData() {
            return this.$store.state.banner.moduleData || {};
        },
        sysBannerNow() {
            return this.moduleData.sysBannerNow || {};
        },
        devBanerNow() {
            return this.moduleData.bannerNow;
        },
        bannerNow() {
            return this.independentBannerSet ? this.devBanerNow : this.sysBannerNow;
        },
        isBannerShow: {
            get() {
                return this.independentBannerSet ? this.independentBannerShow : this.fullPageBannerShow;
            },
            set(val) {
                return this.independentBannerSet ? (this.independentBannerShow = val) : (this.fullPageBannerShow = val);
            },
        },
        fullPageBannerShow: {
            get() {
                return this.moduleData.fullPageBannerShow;
            },
            set(value) {
                this.moduleData.fullPageBannerShow = value;
            },
        },
        // 是否设置了独立横幅
        independentBannerSet: {
            get() {
                return this.moduleData.independentBannerSet;
            },
            set(value) {
                this.moduleData.independentBannerSet = value;
            },
        },
        independentBannerShow: {
            get() {
                return this.moduleData.independentBannerShow;
            },
            set(value) {
                this.moduleData.independentBannerShow = value;
            },
        },
        bannerId() {
            return this.openThemeV3 ? 'webBanner_v3' : 'webBanner_v2';
        },
        bannerlist: {
            get() {
                return this.bannerNow.b || [];
            },
            set(value) {
                this.bannerNow.b = deepClone(value);
            },
        },
        bannerStyle() {
            if (this.bannerNow.h) {
                return 'display:none';
            }

            let computedBannerShow = false;

            let { independentBannerShow, independentBannerSet, fullPageBannerShow } = this;

            if (fullPageBannerShow) {
                computedBannerShow = true;

                if (independentBannerSet && !independentBannerShow) {
                    computedBannerShow = false;
                }
            } else {
                computedBannerShow = false;

                if (independentBannerSet && independentBannerShow) {
                    computedBannerShow = true;
                }
            }

            if (computedBannerShow) {
                if (this.bannerlist.length > 0) {
                    return "style='display: block; height:6.66rem;'";
                } else {
                    return "style='display: block; height:7.5rem;'";
                }
            } else {
                return "style='display: none;'";
            }
        },
        bannerCls() {
            return ' ' + this.hiddenDefaultBanner;
        },
        hiddenDefaultBanner() {
            return this.bannerlist.length !== 0 ? ' hiddenDefaultBanner' : '';
        },
        showDesc() {
            return this.bannerNow.sd === 1;
        },
    },
    watch: {
        async isBannerShow(newVal) {
            if (newVal) {
                this.render = false;
                await this.$nextTick();
                this.render = true;
                await this.$nextTick();
                this.initBanner();
            }
        },
        bannerlist: {
            deep: true,
            async handler() {
                this.render = false;
                await this.$nextTick();
                this.render = true;
                await this.$nextTick();
                this.initBanner();
            },
        },
        moduleData: {
            deep: true,
            handler() {
                if (Fai.top._bannerChanged === void 0) {
                    Fai.top._bannerChanged = 0;
                }
                Fai.top._bannerChanged = (Fai.top._bannerChanged || 0) + 1;

                if (this.inited) {
                    this.$designer.styleChanged();
                }
            },
        },
    },
    async mounted() {
        if (VITE_APP_MODE !== 'visitor') {
            //ssr那边会挂好vuex，所以不需要mounted加载
            await this.loadBannerInfo(this.$store.state.app.colInfo.id);
        }
        this.initBanner(() => {});
        this.inited = true;

        this.responsiveBanner();
    },
    methods: {
        ...mapActions('banner', ['loadBannerInfo']),
        getJumpUrl({ jumpUrl }) {
            return jumpUrl;
        },
        initBanner: debounce(function () {
            bindImgLazyLoad(`webBanner`);
            Vue.nextTick(() => {
                if (!BannerAnimateFactory().init(this.bannerNow)) {
                    initSwipe('bannerSwipe', this.bannerNow);
                }
            });
        }, 100),
        switchIndependentBanner(independentBannerSet = false) {
            if (independentBannerSet) {
                logDog(200014, 9);
            } else {
                logDog(200014, 8);
            }

            this.moduleData.independentBannerSet = !independentBannerSet;
            this.$designer.styleChanged();
            Fai.top.saveReloadPreview = true;
            this.$designer.saveStyle();
        },
        responsiveBanner() {
            let _this = this;
            let timer = null;
            jm(window).on('resize', function () {
                if (jm(window).width() != window.initialWindowWith) {
                    clearTimeout(timer);
                    timer = setTimeout(function () {
                        if (!BannerAnimateFactory().init(_this.bannerNow)) {
                            initBannerDefaultDom(_this.bannerNow);
                            initSwipe('bannerSwipe', _this.bannerNow);
                        }
                        clearTimeout(timer);
                        timer = null;
                    }, 500);
                }
            });
        },
    },
};
</script>
<style>
.bannerSwipe {
    height: 100%;
}
.bannerImageDiv {
    display: block;
    visibility: visible;
    background-image: url(/image/loading/transparent.png);
}
.bannerSwipe[data-size='1'] #bannerBullet {
    display: none;
}
</style>
