var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { attrs: { id: "mLogin" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg" }, [
        _c("a", {
          staticClass: "g_close icon-gClose",
          on: { click: _vm.returnBackClick },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: { textContent: _vm._s(_vm.LS.col_member_login) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "loginContent" },
        [
          _vm.manageMode
            ? _c("div", { staticClass: "notice" }, [
                _vm._v("您目前处于网站管理状态，请先退出再登录"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "g_globalLine forAcctLogin" }, [
            _c("input", {
              staticClass: "itemEdit g_input mustItem",
              attrs: {
                id: "user",
                type: "text",
                maxlength: _vm.MemberDef.Limit.ACCT_MAXLEN,
                placeholder: _vm.acctPlaceHolder,
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-userIcon g_mainColor" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "g_globalLine forAcctLogin" }, [
            _c("input", {
              staticClass: "itemEdit g_input mustItem",
              attrs: {
                id: "password",
                type: "password",
                maxlength: _vm.MemberDef.Limit.PWD_MAXLEN,
                placeholder: _vm.passwordPlaceHolder,
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
          ]),
          _vm._v(" "),
          _vm.signUpbyMobile
            ? [
                _c("div", { staticClass: "g_globalLine forFastLogin" }, [
                  _c("input", {
                    staticClass: "itemEdit g_input mustItem",
                    attrs: {
                      id: "acquirerInput",
                      type: "text",
                      placeholder: _vm.LS.memberDialogPlzEnterMobile,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-fastUserIcon g_mainColor" }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "g_globalLine forFastLogin",
                    staticStyle: { "font-size": "0" },
                  },
                  [
                    _c("input", {
                      staticClass: "itemEdit g_input mustItem",
                      attrs: {
                        id: "memCodeInput",
                        maxlength: "6",
                        placeholder: _vm.LS.msgBoardValidateCode,
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "icon-fastCodeIcon g_mainColor",
                    }),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: { id: "memCodeSign" },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "memCodeSplitLine",
                      attrs: { id: "memCodeSplitLine" },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      class: { "g-input": _vm.manageMode },
                      attrs: { id: "acquireCode" },
                      domProps: {
                        textContent: _vm._s(_vm.LS.mobi_getMobileCode),
                      },
                      on: { click: _vm.acquireCodeClick },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "loginCaptchaCtrl g_globalLine" }, [
            _c("input", {
              staticClass: "captchaText g_input",
              attrs: {
                id: "loginCaptcha",
                type: "text",
                maxlength: "4",
                placeholder: _vm.LS.mobi_EnterVerificationCode,
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "captchaImg",
              attrs: { id: "loginCaptchaImg", src: _vm.loginCaptchaImg },
              on: { click: _vm.loginCaptchaImgClick },
            }),
          ]),
          _vm._v(" "),
          _vm.mbAutoLogin
            ? _c(
                "div",
                {
                  staticClass: "autoLoginCtrl g_globalLine",
                  staticStyle: { "text-align": "left" },
                },
                [
                  _c("input", {
                    staticClass: "autoLoginCheck",
                    attrs: { id: "autoLogin", type: "checkBox" },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "autoLoginLabel",
                      attrs: { for: "autoLogin" },
                      on: { click: _vm.autoLoginLabelClick },
                    },
                    [
                      _c("span", {
                        staticClass: "icon-uncheckIcon g_mainColor",
                      }),
                      _vm._v(_vm._s(_vm.LS.memberLogin_rememberMe)),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "g_globalLine forAcctLogin" }, [
            _c("input", {
              staticClass: "g_button sendIcon",
              attrs: {
                id: "memberLogin",
                type: "button",
                value: _vm.LS.memberLoginLogin,
              },
              on: { click: _vm.memberLoginClick },
            }),
          ]),
          _vm._v(" "),
          _vm.signUpbyMobile
            ? _c("div", { staticClass: "g_globalLine forFastLogin" }, [
                _c("input", {
                  staticClass: "g_button sendIcon",
                  attrs: { type: "button", value: _vm.LS.memberLoginLogin },
                  on: { click: _vm.fastLoginClick },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "loginBottom g_globalLine" },
            [
              _vm.signUpbyMobile
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "g_mainColor forAcctLogin loginToggle",
                        attrs: { id: "mobileFastLoginToggle" },
                        on: { click: _vm.mobileFastLoginToggleClick },
                      },
                      [_vm._v(_vm._s(_vm.LS.mobileFastLogin))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "g_mainColor forFastLogin loginToggle",
                        attrs: { id: "acctLoginToggle" },
                        on: { click: _vm.acctLoginToggleClick },
                      },
                      [_vm._v(_vm._s(_vm.LS.loginWithAcct))]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.findPwdOpen
                ? _c(
                    "a",
                    {
                      staticClass: "g_mainColor freeRegister",
                      attrs: { href: _vm.findPwdUpUrl },
                    },
                    [_vm._v(_vm._s(_vm.LS.memberFindPwd))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "g_mainColor freeRegister",
                  attrs: { href: _vm.signUpUrl },
                },
                [_vm._v(_vm._s(_vm.LS.memberLogin_freeReg))]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.qqOpen || _vm.sinaOpen || _vm.wxOpen
            ? [
                _c("div", { staticClass: "oLgnPanel" }, [
                  _c("div", { staticClass: "head" }, [
                    _c("div", { staticClass: "leftLine" }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "middle",
                      domProps: {
                        textContent: _vm._s(_vm.LS.mobi_member_OtherLgn),
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "rightLine" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "oLgn" }, [
                    _c(
                      "div",
                      { staticClass: "qqLgnPanel", style: _vm.qqLgnPanelStyle },
                      [
                        _c("div", {
                          staticClass: "qqLgn",
                          attrs: { id: "qqLgn" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "desc",
                          domProps: {
                            textContent: _vm._s(_vm.LS.mobi_member_OtherLgnQQ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "sinaLgnPanel",
                        style: _vm.sinaLgnPanelStyle,
                      },
                      [
                        _c("div", {
                          staticClass: "sinaLgn",
                          attrs: { id: "sinaLgn" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "desc",
                          domProps: {
                            textContent: _vm._s(_vm.LS.mobi_member_OtherLgnWB),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.wxOpen
                      ? _c(
                          "div",
                          {
                            staticClass: "wxLgnPanel",
                            style: _vm.wxLgnPanelStyle,
                          },
                          [
                            _c("div", {
                              staticClass: "wxLgn",
                              attrs: { id: "wxLgn" },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "desc",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.LS.mobi_member_OtherLgnWX
                                ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mobiWxOpen
                      ? _c(
                          "div",
                          {
                            staticClass: "wxLgnPanel",
                            staticStyle: { display: "none" },
                          },
                          [
                            _c("div", {
                              staticClass: "wxLgn",
                              attrs: { id: "wxLgn" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "desc" }, [
                              _vm._v(_vm._s(_vm.LS.mobi_member_OtherLgnWX)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "fillAcctInfo" } },
      [
        _c("div", { staticClass: "loginHeader webHeaderBg" }, [
          _c("span", {
            staticClass: "g_close icon-gClose",
            attrs: { id: "fillInfoBack" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "title pageTitle" }, [
            _vm._v(_vm._s(_vm.LS.mobi_member_OtherLgnFillAcct)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "loginContent" }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "bindOrLogin" } },
      [
        _c("div", { staticClass: "loginHeader webHeaderBg" }, [
          _c("span", {
            staticClass: "g_close icon-gClose",
            attrs: { id: "bindOrLoginBack" },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "title pageTitle",
            domProps: { textContent: _vm._s(_vm.LS.mobi_member_OtherLgnMode) },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "loginContent" }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" }, attrs: { id: "bindNow" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg" }, [
        _c("span", {
          staticClass: "g_close icon-gClose",
          attrs: { id: "bindNowBack" },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: {
            textContent: _vm._s(_vm.LS.mobi_member_OtherLgnBindAcct),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loginContent" }),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" }, attrs: { id: "mSignUp" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg" }, [
        _c("span", {
          staticClass: "g_close icon-gClose",
          attrs: { id: "signUpBack" },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: { textContent: _vm._s(_vm.LS.col_member_signup) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loginContent" }),
      _vm._v(" "),
      _c("div", { staticClass: "checkEmail", attrs: { id: "checkEmail" } }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "emailAddr",
          staticStyle: { display: "none" },
          attrs: { id: "emailAddr", target: "_blank" },
        },
        [_vm._v("地址")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }