<template>
    <module-frame :module-id="moduleId">
        <div>
            <div id="profile" class="profile_v3">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <a class="g_close icon-gClose" @click="closeClick"></a>
                    <span class="title pageTitle" v-text="LS.col_member_center"></span>
                </div>
                <div class="profileContent">
                    <template v-if="sessionMid == 0 && manageMode">
                        <component :is="manageComponent" :module-id="moduleId"></component>
                    </template>
                    <template v-else-if="sessionMid != 0">
                        <component :is="visitorComponent" :module-id="moduleId"></component>
                    </template>
                </div>
            </div>
            <template v-if="memberBulletinOpen">
                <div id="mBulletinConten" class="J_cusBack modifyProfile">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="mBulletinContenClick"></span>
                        <span class="title pageTitle">{{ bulletinTitle }}</span>
                    </div>
                    <template
                        v-if="
                            bulletinContent == null ||
                            bulletinContent == '<p><br /></p>' ||
                            bulletinContent == '<p></p>'
                        "
                    >
                        <div class="hasNoMsg">
                            <img :src="noMsgImgPath" alt="" class="hasNoMsgIcon" />
                        </div>
                        <div class="bulletin_no_cotent_v3" v-text="LS.memberNoMsgV3"></div>
                    </template>
                    <template v-else>
                        <div class="bulletin_cotent_v3" v-html="bulletinContent"></div>
                    </template>
                </div>
            </template>
            <template v-if="isOpenKnowPayment">
                <div id="knowPayList">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="knowPayClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div class="profileContent profile_content_v3">
                        <div v-if="!isOnlyFinishedOrder" class="know_pay_list_kind">
                            <div
                                v-for="(orderNav, index) in orderNavs"
                                :class="{
                                    know_pay_list_kind_item_select: index === selectOrderNavIndex,
                                }"
                                class="know_pay_list_kind_item g_color g_main_color_v3"
                                @click="changeOrderNav(index)"
                                >{{ orderNav }}</div
                            >
                        </div>
                        <div style="height: 0.4266rem; background-color: #f4f4f4"></div>
                        <ul v-if="curKnowList.length" class="productList">
                            <template v-for="item in curKnowList">
                                <li>
                                    <a :href="item.jumpUrl" :onclick="getUrlArgsOnclickStr(item)">
                                        <div class="know_pay_list_item">
                                            <div>
                                                <span class="know_pay_list_item_header">
                                                    <span>订单号：{{ item.code }}</span>
                                                    <span
                                                        class="know_pay_list_item_pay_status g_color g_main_color_v3"
                                                        >{{ getPayStatusText(false, item.payStatus) }}</span
                                                    >
                                                </span>
                                            </div>
                                            <div class="know_pay_list_item_main">
                                                <div
                                                    style="
                                                        position: relative;
                                                        border: solid 1px #eee;
                                                        margin-right: 0.7893rem;
                                                    "
                                                >
                                                    <div
                                                        class="know_pay_list_item_product_img"
                                                        :style="'background-image: url(' + item.picPath + ')'"
                                                    ></div>
                                                    <span class="know_pay_list_item_product_label">
                                                        {{ item.labelText }}
                                                    </span>
                                                </div>
                                                <div class="know_pay_list_item_product_info">
                                                    <span class="know_pay_list_item_product_name">{{
                                                        item.productName
                                                    }}</span>
                                                    <span class="know_pay_list_item_product_buy_time"
                                                        >购买时间：{{ formatDate(item.payTime) }}</span
                                                    >
                                                    <span
                                                        class="know_pay_list_item_product_price g_color g_main_color_v3"
                                                        >¥{{ item.price.toFixed(2) }}</span
                                                    >
                                                </div>
                                            </div>

                                            <span
                                                v-if="getShowAftersale(item)"
                                                class="know_pay_list_item_product_action"
                                                @click.stop.prevent="knowItemProductAction(item, $event)"
                                                >{{ item.refundStatus === 0 ? '申请退款' : '售后进度' }}</span
                                            >
                                        </div>
                                    </a>
                                </li>
                                <div style="height: 0.4266rem; background-color: #f4f4f4"></div>
                            </template>
                        </ul>
                        <div v-else class="empty_tips">
                            <div class="img"></div>
                            <div class="text">无购买记录</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="isOpenMyForm">
                <div id="formList">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="formListClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div class="profileContent profile_content_v3">
                        <ul class="area"></ul>
                    </div>
                </div>
                <div id="formDetail" :class="{ form_detail_preview: formPreviewState }">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="formDetailClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div v-show="!isEditState" class="profileContent profile_content_v3"></div>
                    <div v-if="isEditState">
                        <form-detail-edit @submit-success="onEditSuccess" />
                    </div>
                    <button
                        v-if="openMemberModifySubmit"
                        v-show="!isEditState"
                        class="form_edit_button"
                        @click="setEditState"
                        >{{ LS.edit_form_result }}</button
                    >
                </div>
            </template>
            <div id="viewMyReward" class="J_cusBack viewMyReward">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="viewMyRewardClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="view-reward-content">
                    <ul class="reward-list"></ul>
                </div>
            </div>
            <div id="modifyPersInfo" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="modifyPersInfoClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="profileContent profile_content_v3">
                    <div class="member_basic_info_v3">
                        <div class="member_msg_head_pic_v3 icon-pnameIcon">
                            <img
                                id="memberHeadPicV3"
                                alt=""
                                class="memberMsgHeadPicV3 memberHeadPicV3 J_memberHeadPicV3"
                            />
                        </div>
                        <div class="member_name_and_level">
                            <span class="click_change_icon" v-text="LS.changeHeadPic"></span>
                        </div>
                    </div>
                    <div class="g_globalLine_wrap">
                        <div class="g_globalLine">
                            <div class="g_globalLine">
                                <span class="icon-acctIcon profile_v3_msg_icon g_mainColor"></span>
                                <input
                                    type="text"
                                    class="acct g_input"
                                    :class="acctInputClass"
                                    :value="memberAcct"
                                    maxlength="20"
                                    :disabled="acctIconDisable"
                                />
                            </div>
                            <div v-if="canResetAcct" class="acctDes" v-text="LS.memberProfile_onlyResetOnce"></div>
                        </div>
                    </div>
                    <div v-for="item in displayList" :key="item.fieldKey" class="g_globalLine_wrap">
                        <div v-if="item.isMobile && (!signByMobile || !isCn)" id="editMobile">
                            <div class="g_globalLine mobileLine">
                                <div class="mobileCtVal" v-text="mobileCtCode"></div>
                                <div class="mobileCtBorder"></div>
                                <select
                                    id="mobileCt"
                                    class="g_input itemInput infoItem select_v3 icon-select"
                                    :class="item.mustItemClass"
                                    name="mobileCt"
                                    @click="mobileCtClick"
                                    @blur="mobileCtBlur"
                                    @change="mobileCtChange"
                                >
                                    <option
                                        v-for="ct in mobileCtNameList"
                                        :key="ct.ctName"
                                        :value="ct.ctName"
                                        v-text="ct.ctShowName + ct.ctCode"
                                    ></option>
                                </select>
                                <span :class="item.customPropName" v-text="item.name"></span
                                ><input
                                    id="mobile"
                                    class="g_input itemInput infoItem"
                                    :class="item.mustItemClass"
                                    :name="item.fieldKey"
                                    :value="preMobile"
                                    type="text"
                                    maxlength="11"
                                    :placeholder="item.propPlaceholder"
                                    :propTip="item.propTip"
                                /><span :class="item.itemIcon"></span>
                            </div>
                        </div>
                        <template v-else>
                            <div class="g_globalLine" :class="fileClass(item)">
                                <div
                                    v-if="item.isMobile"
                                    id="editMobileBtn"
                                    class="editMobileBtn"
                                    @click="editMobileBtnClick"
                                    >修改</div
                                >
                                <template v-if="item.pt == 0">
                                    <span
                                        v-if="JSON.parse(item.pce).mc == 0"
                                        style="display: inline-block"
                                        class="g_input"
                                        >{{ item.values || '-' }}</span
                                    >
                                    <input
                                        v-else
                                        :propTip="item.propTip"
                                        class="g_input itemInput infoItem"
                                        :class="singleLineClass(item)"
                                        type="text"
                                        :name="item.fieldKey"
                                        maxlength="50"
                                        :value="item.values"
                                        :placeholder="item.propPlaceholder"
                                        :style="item.disStyle"
                                        :disabled="item.disable"
                                    />
                                    <span class="profile_v3_msg_icon" :class="item.itemIcon"></span>
                                </template>
                                <template v-else-if="item.pt == 1">
                                    <span
                                        v-if="JSON.parse(item.pce).mc == 0"
                                        style="display: inline-block"
                                        class="g_input"
                                        >{{ item.values || '-' }}</span
                                    >
                                    <template v-else>
                                        <div class="mobileCtIcon otherSettingIcon icon-downward"></div>
                                        <input class="g_input selectCover" />

                                        <select
                                            :id="item.fieldKey"
                                            :name="item.fieldKey"
                                            :propTip="item.propTip"
                                            class="g_input itemInput infoItem select_v3 icon-select"
                                            :class="item.mustItemClass"
                                        >
                                            <option value="">{{
                                                item.must
                                                    ? LS.mobi_member_PleaseInput + ' ' + item.name + LS.mobi_member_Must
                                                    : LS.mobi_member_PleaseInput + ' ' + item.name
                                            }}</option>
                                            <option
                                                v-for="option in item.propOptions"
                                                :key="option"
                                                :value="option"
                                                :selected="option == item.values"
                                                >{{ option }}</option
                                            >
                                        </select>
                                    </template>
                                    <span class="profile_v3_msg_icon" :class="item.itemIcon"></span>
                                </template>
                                <template v-else-if="item.pt == 2">
                                    <span v-if="item.customPropName != ''" class="profile_file_label">{{
                                        item.name
                                    }}</span>
                                    <div
                                        :id="'member_file_contentwrap-' + item.id"
                                        class="member_file_contentwrap profile_file_contentwrap"
                                    >
                                        <input
                                            :id="item.fieldKey"
                                            :name="item.fieldKey"
                                            :propTip="item.propTip"
                                            class="infoItem member_file_input"
                                            :class="item.mustItemClass"
                                            :data-name="item.id"
                                            :value="item.values"
                                        />
                                        <div class="member_file_btn" :class="hideAddFileIconClass(item)">
                                            <div class="member_file_btnupload uploadify-button"></div>
                                            <span v-text="addFileText"></span>
                                        </div>
                                        <div class="member_file_content" :class="showFileDetailClass(item)">
                                            <div class="member_file_meta">
                                                <div class="member_file_name">{{ getName(item) }}</div>
                                                <div class="member_file_size">{{ getSize(item) }}</div>
                                            </div>
                                            <div class="member_file_icon">
                                                <svg class="member_file_close" :class="hideDeleteIconClass(item)">
                                                    <use xlink:href="#jzm-v25"></use>
                                                </svg>
                                                <a
                                                    ref="nofllow"
                                                    class="member_file_preview_link"
                                                    href="javascript:void(0)"
                                                    target="_blank"
                                                >
                                                    <svg class="member_file_preview">
                                                        <use xlink:href="#jzm-vicon_preview"></use>
                                                    </svg>
                                                </a>
                                                <a
                                                    ref="nofllow"
                                                    class="member_file_download_link"
                                                    :href="getDownloadUrl(item)"
                                                    target="_blank"
                                                >
                                                    <svg class="member_file_download">
                                                        <use xlink:href="#jzm-v16"></use>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="profile_file_tips">{{ getFileTips(item) }}</div>
                                </template>
                                <span v-if="item.customPropName != '' && item.pt !== 2" :class="item.customPropName">{{
                                    item.name
                                }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="sendBtnWrap">
                        <input
                            type="button"
                            class="fpw_btn g_button g_main_bgColor_v3 sendIcon profileSubmit"
                            :value="LS.mobi_avatarSaveV3"
                            @click="setPersonalInfoClick"
                        />
                    </div>
                </div>
            </div>
            <div class="loginErrorLine">
                <div class="errorTips"> </div>
            </div>
            <template v-if="signByMobile">
                <div id="editMobilePanel" class="J_cusBack mobilePanel modifyPersInfo" style="display: none">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="mobileCodeClick"></span>
                        <span class="title pageTitle">修改手机号</span>
                    </div>
                    <div class="profileContent">
                        <div class="m_pointBoxContainer">
                            <div id="inputMobile">
                                <div class="defMobi">当前手机号：{{ preMobile }}</div>
                                <div class="f_editArea">
                                    <select
                                        id="mobileCt"
                                        class="itemInput infoItem select_v3 icon-select mustItem mobileCtNew"
                                        @click="inputMobileClick"
                                        @blur="inputMobileBlur"
                                        @change="inputMobileChange"
                                    >
                                        <option
                                            v-for="ct in mobileCtNameList"
                                            :key="ct.ctName"
                                            :value="ct.ctName"
                                            :selected="ct.ctCode == mobileCtCode"
                                            v-text="ct.ctCode"
                                        ></option>
                                    </select>
                                    <input
                                        id="mobile"
                                        class="g_input editInput mobileCtInput"
                                        maxlength="11"
                                        placeholder="请输入新的手机号码"
                                        :value="preMobile"
                                        @input="mobileInput"
                                    />
                                    <div id="cleanBtn" class="cleanBtn" :style="cleanStyle" @click="cleanBtnClick"
                                        >x</div
                                    >
                                </div>
                            </div>
                            <div id="mobileValidate" style="display: none">
                                <div class="validateCodeMsg">请输入<span id="newMobile"></span>收到的短信验证码</div>
                                <div class="f_editArea">
                                    <div class="validateCode">验证码</div>
                                    <input
                                        id="validateCodeInput"
                                        class="g_input editInput validateCodeInput"
                                        maxlength="8"
                                        placeholder="请输入验证码"
                                        @input="validateCodeInput"
                                    />
                                    <div
                                        id="cleanValidateCodeInput"
                                        style="display: none"
                                        class="cleanBtn cleanBtnValidateCode"
                                        @click="cleanValidateCodeInputClick"
                                        >x</div
                                    >
                                    <div
                                        id="messageAuthCodeClick"
                                        class="sendValidateCode sendValidateCode1"
                                        :title="LS.mobi_getMobileCode"
                                        @click="messageAuthCodeClick"
                                        v-text="LS.mobi_getMobileCode"
                                    ></div>
                                    <div id="showCaptcha" style="display: none"></div>
                                </div>
                            </div>
                            <div
                                id="saveMobileBtn"
                                class="f_saveBtn mobileCtBtn g_main_bgColor_v3 un-change"
                                @click="saveMobileBtnClick"
                                >确定</div
                            >
                        </div>
                    </div>
                </div>
            </template>
            <div id="modifyPsw" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="modifyPswClick"></span>
                    <span class="title pageTitle" v-text="LS.mobi_membert_ResetPassword"></span>
                </div>
                <div class="profileContent">
                    <div v-if="needOldPwd" class="g_globalLine_wrap">
                        <div class="g_globalLine">
                            <input
                                id="oldPsw"
                                class="g_input itemInput mustItem"
                                type="password"
                                autocomplete="off"
                                maxlength="20"
                                :placeholder="LS.mobi_member_OldPasswordV3"
                            />
                            <span class="icon-cnOldPsw profile_v3_msg_icon"></span>
                        </div>
                    </div>
                    <div class="g_globalLine_wrap">
                        <div class="g_globalLine">
                            <input
                                id="newPsw"
                                class="g_input itemInput mustItem profile_v3_msg_icon"
                                type="password"
                                autocomplete="off"
                                maxlength="20"
                                :placeholder="LS.mobi_memberProfile_password"
                            />
                            <span class="icon-cnNewPsw profile_v3_msg_icon"></span>
                        </div>
                    </div>
                    <div class="g_globalLine_wrap">
                        <div class="g_globalLine">
                            <input
                                id="confirmPsw"
                                class="g_input itemInput mustItem profile_v3_msg_icon"
                                type="password"
                                autocomplete="off"
                                maxlength="20"
                                :placeholder="LS.mobi_memberSignup_retypePasswordV3"
                            />
                            <span class="icon-cnNewPsw profile_v3_msg_icon"></span>
                        </div>
                    </div>
                    <div class="g_globalLine_wrap">
                        <div class="g_globalLine">
                            <input
                                type="button"
                                class="g_button profileSubmit g_main_bgColor_v3"
                                :class="{ sendIcon: !manageMode }"
                                :value="LS.mobi_avatarSaveV3"
                                disabled
                                @click="memberProfileSubmitClick"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id="modifyIntegral" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="modifyIntegralClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="profileContent profile_content_v3">
                    <div class="itgPanel"></div>
                </div>
            </div>
            <div id="modifyIntegralRule" class="J_cusBack modifyProfile">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span class="g_close icon-gClose" @click="modifyIntegralRuleCloseClick"></span>
                    <span class="title pageTitle"></span>
                </div>
                <div class="profileContent profile_content_v3">
                    <div class="itgPanelRule"></div>
                    <div v-html="richContent"></div>
                </div>
            </div>
            <template v-if="isCrownVer">
                <div v-if="openCollection" id="modifyCollection" class="J_cusBack modifyProfile">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="openCollectionClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div class="profileContent profile_content_v3">
                        <img alt="" style="display: none" />
                        <div class="collectionList"></div>
                    </div>
                </div>
                <div v-if="openCoupon" id="modifyCoupon" class="J_cusBack modifyProfile">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="modifyCouponClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div class="couponListPanel"></div>
                </div>
                <div v-if="isOpenCutPrice && !manageMode" id="modifyCutPrice" class="J_cusBack modifyProfile">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="modifyCutPriceCloseClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div class="cutPriceListPanel"></div>
                </div>
                <div v-if="isOpenGroupBuy && !manageMode" id="modifyGroupBuy" class="J_cusBack modifyProfile">
                    <div class="loginHeader webHeaderBg profileLoginHeader">
                        <span class="g_close icon-gClose" @click="modifyGroupBuyCloseClick"></span>
                        <span class="title pageTitle"></span>
                    </div>
                    <div class="groupBuyListPanel"></div>
                </div>
            </template>

            <div id="editMemberHeadPic" class="J_cusBack modifyProfile">
                <div class="loginHeader profileLoginHeader"
                    ><span class="g_close icon-gClose" @click="editMemberHeadPicCloseClick"></span
                ></div>
                <div id="picArea" class="picArea">
                    <img id="headPic" style="" :_src="imgPath" />
                    <div class="cover cover1"></div>
                    <div class="cover cover2"></div>
                    <img
                        id="coverImg"
                        class="coverImg"
                        style="opacity: 0.5"
                        :src="resImageRoot + '/image/member/layer.png'"
                        @dragstart="false"
                    />
                    <div class="cover cover4"></div>
                    <div class="cover cover5"></div>
                    <div class="coverRing"></div>
                    <div id="coverBox" class="coverBox" ondragstart="return false;">
                        <div id="dragLeftTop" class="dragPoint"></div>
                        <div id="dragRightTop" class="dragPoint"></div>
                        <div id="dragLeftBot" class="dragPoint"></div>
                        <div id="dragRightBot" class="dragPoint"></div>
                        <div id="dragTopCenter" class="dragPoint"></div>
                        <div id="dragRightCenter" class="dragPoint"></div>
                        <div id="dragLeftCenter" class="dragPoint"></div>
                        <div id="dragBotCenter" class="dragPoint"></div>
                    </div>
                </div>
                <div class="editHeadPicFooter editHeadPicFooterV3">
                    <div class="editBtn modifyBtn"
                        >{{ LS.mobi_avatarReplace
                        }}<span id="file_upload" class="fileUploadBtn" style="width: 5rem"></span
                    ></div>
                    <div class="editBtn saveBtn" :oldimgid="oldImgId" :mid="sessionMid">{{ LS.mobi_avatarSaveV3 }}</div>
                </div>
            </div>
            <div id="bindOtherAcct" class="J_cusBack modifyProfile" style="display: none">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span id="bindAcctBack" class="g_close icon-gClose"></span>
                    <span class="title pageTitle">{{ LS.mobi_member_OtherLgnBindAcct }}</span>
                </div>
                <div class="loginContent"></div>
            </div>
            <div id="mSignUp" class="J_cusBack modifyProfile" style="display: none">
                <div class="loginHeader webHeaderBg profileLoginHeader">
                    <span id="signUpBack" class="g_close icon-gClose"></span>
                    <span class="title pageTitle">{{ LS.col_member_signup }}</span>
                </div>
                <div class="loginContent"></div>
                <div id="checkEmail" class="checkEmail"></div>
                <a id="emailAddr" class="emailAddr" style="display: none" target="_blank">地址</a>
            </div>
            <div id="requestRefund" class="J_cusBack" style="display: none">
                <div class="loginHeader profileLoginHeader">
                    <span class="g_close icon-gClose" @click="requestRefundCloseClick"></span>
                    <span class="title pageTitle">申请退款</span>
                </div>
                <div>
                    <div class="request_refund" style="display: flex; padding: 0.4266rem 0.64rem 0.64rem">
                        <div class="know_pay_list_item_main" style="border: none">
                            <div style="position: relative; border: solid 1px #eee; margin-right: 0.7893rem">
                                <div
                                    class="know_pay_list_item_product_img"
                                    :style="'background-image: url(' + curKnow.picPath + ')'"
                                ></div>
                                <span class="know_pay_list_item_product_label">
                                    {{ curKnow.labelText }}
                                </span>
                            </div>
                            <div class="know_pay_list_item_product_info">
                                <span class="know_pay_list_item_product_name">{{ curKnow.productName }}</span>
                                <span class="know_pay_list_item_product_buy_time"
                                    >购买时间：{{ formatDate(curKnow.payTime) }}</span
                                >
                                <span class="know_pay_list_item_product_price g_color g_main_color_v3"
                                    >¥{{ curKnow.price.toFixed(2) }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div style="height: 0.4266rem; background-color: #f4f4f4"></div>
                    <div style="padding: 0.4266rem 0.64rem 0.64rem">
                        <div>
                            <textarea
                                v-model="refundReason"
                                class="withdrawal_reason"
                                maxlength="200"
                                placeholder="请输入退款原因"
                            >
                            </textarea>
                            <div>
                                <div ref="uploadBtn" class="upload_btn" style="display: none"></div>
                                <div style="display: flex">
                                    <div
                                        v-show="tmpFileList.length < 3"
                                        style="
                                            width: 4.0533rem;
                                            height: 4.0533rem;
                                            border-radius: 0.1706rem;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #f3f3f3;
                                            margin-right: 0.6826rem;
                                        "
                                        @click.stop="uploadClick"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style="width: 1.28rem; height: 0.9386rem"
                                        >
                                            <circle cx="16" cy="17" r="4" fill="#ccc"></circle>
                                            <path
                                                d="M26,7H23L21,5H11L9,7H6a4,4,0,0,0-4,4V23a4,4,0,0,0,4,4H26a4,4,0,0,0,4-4V11A4,4,0,0,0,26,7ZM6.5,13A1.5,1.5,0,1,1,8,11.5,1.5,1.5,0,0,1,6.5,13ZM16,23a6,6,0,1,1,6-6A6,6,0,0,1,16,23Z"
                                                fill="#ccc"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div
                                        v-for="(img, index) in tmpFileList"
                                        class="refund_photo"
                                        style="width: 4.0533rem; height: 4.0533rem; margin-right: 0.6826rem"
                                    >
                                        <img class="refund_photo_item" :src="img.picPath" />
                                        <div class="refund_photo_imgPreviewerMaskLayer">
                                            <div class="refund_photo_imgViewIcon" @click="previewImg(img)"></div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 32 32"
                                                class="refund_photo_imgRemoveIcon"
                                                @click="delRefundPhoto(index)"
                                            >
                                                <path
                                                    d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm4.71,17.29a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L16,17.41l-3.29,3.3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L14.59,16l-3.3-3.29a1,1,0,0,1,1.42-1.42L16,14.59l3.29-3.3a1,1,0,0,1,1.42,1.42L17.41,16Z"
                                                    fill="rgb(255, 84, 20)"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div ref="uploadBtn2" style="display: none"> </div>
                                <div class="refund_upload_split">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 32 32"
                                        style="width: 0.576rem; height: 0.576rem; margin-right: 0.192rem"
                                    >
                                        <path
                                            d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,23a2,2,0,1,1,2-2A2,2,0,0,1,16,25Zm2.59-14.75L17.18,18a1.2,1.2,0,0,1-2.36,0l-1.41-7.77a2.63,2.63,0,1,1,5.18,0Z"
                                            fill="#ccc"
                                        ></path>
                                    </svg>
                                    <span style="font-size: 0.512rem; color: #999; padding: 0.512rem 0"
                                        >文字不多于200字，图片不多于3张，每个{{ uploadSize }}M以内</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div>
                            <span style="color: #333; font-size: 0.64rem; margin: 0.512rem 0; display: inline-block"
                                >联系方式</span
                            >
                            <textarea
                                v-model="contactInformation"
                                class="request_refund_phone"
                                maxlength="200"
                                placeholder="方便商家联系您"
                            >
                            </textarea>
                        </div>
                    </div>
                    <div
                        class="g_main_bgColor_v3 g_oldThemeBgColor"
                        style="
                            width: 14.72rem;
                            height: 1.92rem;
                            border-radius: 0.1706rem;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            font-size: 0.64rem;
                            color: #fff;
                            margin: auto;
                        "
                        @click="requestRefund"
                    >
                        提交申请
                    </div>
                </div>
            </div>
            <div id="aftersaleRecord" class="J_cusBack" style="display: none">
                <div class="loginHeader profileLoginHeader">
                    <span class="g_close icon-gClose" @click="aftersaleRecordCloseClick"></span>
                    <span class="title pageTitle">售后进度</span>
                </div>
                <div>
                    <div class="g_bgColor g_main_bgColor_v3" style="box-sizing: border-box; padding: 0.64rem">
                        <div
                            style="
                                width: 100%;
                                height: 100%;
                                padding: 0.64rem;
                                border-radius: 0.1706rem;
                                box-sizing: border-box;
                                background-color: #fff;
                            "
                        >
                            <div style="display: flex; align-items: center; margin-bottom: 0.64rem">
                                <svg
                                    v-if="aftersaleRecordStatus === 3"
                                    class="g_color g_main_color_v3"
                                    style="width: 0.8533rem; height: 0.8533rem"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M12,21a9,9,0,0,1,12-8.48V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4h7.35A9,9,0,0,1,12,21ZM8,7H20a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm3,7H8a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M21,14a7,7,0,1,0,7,7A7,7,0,0,0,21,14Zm3.71,5.71-4,4a1,1,0,0,1-1.42,0l-2-2a1,1,0,0,1,1.42-1.42L20,21.59l3.29-3.3a1,1,0,0,1,1.42,1.42Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>

                                <svg
                                    v-else-if="aftersaleRecordStatus === 5"
                                    class="g_color g_main_color_v3"
                                    style="width: 0.8533rem; height: 0.8533rem"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M12,21a9,9,0,0,1,12-8.48V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4h7.35A9,9,0,0,1,12,21ZM8,7H20a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm3,7H8a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M21,14a7,7,0,1,0,7,7A7,7,0,0,0,21,14Zm2.71,8.29a1,1,0,0,1,0,1.42,1,1,0,0,1-1.42,0L21,22.41l-1.29,1.3a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L19.59,21l-1.3-1.29a1,1,0,0,1,1.42-1.42L21,19.59l1.29-1.3a1,1,0,0,1,1.42,1.42L22.41,21Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>

                                <svg
                                    v-else-if="aftersaleRecordStatus === 2 || aftersaleRecordStatus === 1"
                                    class="g_color g_main_color_v3"
                                    style="width: 0.8533rem; height: 0.8533rem"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        d="M12,21a9,9,0,0,1,12-8.48V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V24a4,4,0,0,0,4,4h7.35A9,9,0,0,1,12,21ZM8,7H20a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm3,7H8a1,1,0,0,1,0-2h3a1,1,0,0,1,0,2Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M21,14a7,7,0,1,0,7,7A7,7,0,0,0,21,14Zm4,9H21a1,1,0,0,1-1-1V17a1,1,0,0,1,2,0v4h3a1,1,0,0,1,0,2Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                                <div style="font-size: 0.64rem; color: #333; margin-left: 0.3413rem">{{
                                    statusText
                                }}</div>
                            </div>

                            <div
                                :style="aftersaleRecordStatus === 1 ? 'white-space: pre-line; line-height: 25px' : ''"
                                style="font-size: 0.4693rem; color: #666"
                                >{{ statusTips }}</div
                            >
                            <div v-if="aftersaleRecordStatus === 3" style="font-size: 0.4693rem; color: #666">{{
                                formatDate(latestAftersaleRecord.replyTime)
                            }}</div>
                        </div>
                    </div>
                    <div>
                        <div style="height: 0.4266rem; background-color: #f4f4f4"></div>
                        <div
                            style="
                                height: 1.7066rem;
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #eee;
                                justify-content: space-between;
                                padding: 0 0.64rem;
                                box-sizing: border-box;
                            "
                            ><span style="font-size: 0.64rem; color: #333">售后记录</span
                            ><span
                                class="look_all_aftersale_record"
                                style="font-size: 0.5973rem; color: #999; display: flex; align-items: center"
                                @click="previewAllAftersaleRecord(latestAftersaleRecord)"
                                >查看更多<svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                    style="width: 0.7rem; height: 0.7rem"
                                >
                                    <path
                                        d="M11,28a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L20.59,16,10.29,5.71a1,1,0,0,1,1.42-1.42l11,11a1,1,0,0,1,0,1.42l-11,11A1,1,0,0,1,11,28Z"
                                        fill="#999"
                                    ></path></svg></span
                        ></div>
                        <aftersale-record
                            :price="getProductPrice(latestAftersaleRecord.orderId)"
                            :show-aciton="true"
                            :latest-aftersale-record="latestAftersaleRecord"
                            :wxpay-auto-refund="wxpayAutoRefund"
                            @modify-request="modifyRequest"
                        ></aftersale-record>
                    </div>
                </div>
            </div>
            <div id="aftersaleRecordAll" class="J_cusBack" style="display: none">
                <div class="loginHeader profileLoginHeader">
                    <span class="g_close icon-gClose" @click="aftersaleRecordAllCloseClick"></span>
                    <span class="title pageTitle">售后记录</span>
                </div>
                <div>
                    <aftersale-record
                        v-for="aftersaleRecord in aftersaleRecords"
                        :key="aftersaleRecord.id"
                        :price="getProductPrice(aftersaleRecord.orderId)"
                        :show-aciton="false"
                        :latest-aftersale-record="aftersaleRecord"
                        :wxpay-auto-refund="wxpayAutoRefund"
                    ></aftersale-record>
                </div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import JzProfileManage from './manage/jz-profile-v3.vue';

import JzProfileVisitor from './visitor/jz-profile-v3.vue';

import FormDetailEdit from './formDetailEdit.vue';

import { checkBit } from '@/shared/fai';
import { MemberPropDef } from '../../memberDef';
import { SiteVerDef } from '@/def/ver';

import { selectAnimationCtrl, selectAnimation, checkMustItem, bindOtherAcct } from '../../method';

import {
    persInfoChangeMobileCt,
    clickEditMobile,
    memberProfileSubmit,
    setPersonalInfo,
    modifyListener,
    initMemberUploadImg,
    revertBackground,
    setMyFormTipState,
    loadItgV3,
    initFormItemEvent,
    loadKnowPayList,
    showRenderDetail,
} from '../method';
import { initMemberHeadPicV3 } from '../memberHeadPic';
import mobileEditFuc from '../mobileEditFuc';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { switchJump } from '@/shared/linkSetting/index';
import { MemberDef } from '../../memberDef';
import loadPdCollectionList from '../loadPdCollectionList';
import { changeHref } from '@/features/router/universal';
import { memberFileUpload } from '@/system/member/fileUpload';
import { preprocessUrl } from '@/shared/url';
import { JZUpload } from '@jz/biz-shared';
import { setProfileVM, getProfileVM } from '../eventBus';
import aftersaleRecord from './aftersaleRecord.vue';
import { dayjs } from '@jz/utils';
import { slideshowView } from '@/shared/photo/slides.js';
import { createUrlArgsOnclickStr } from '@/modules/comm';
import { logJZFdp } from '@/api/logDog';
let manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'ProfileV3',
    components: {
        ModuleFrame,
        aftersaleRecord,
        FormDetailEdit,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            manageMode,
            returnUrl: '/index.jsp?mobiV2=true',
            refundReason: '',
            contactInformation: '',
            tmpFileList: [],
            aftersaleRecords: [],
            orderNavs: ['全部', '售后中', '已完成', '已关闭'],
            selectOrderNavIndex: 0,
            existAftersaleOrder: false,
            wxpayAutoRefund: false,
            knowList: [],
            curKnow: {
                price: 0,
            },
            isEditState: false,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'resImageRoot', 'lcid', '_ver', 'resImageRoot']),
        ...mapState('form', ['openMemberModifySubmit']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        indexUrl() {
            return this.renderOptions.indexUrl;
        },
        isOpenGroupBuy() {
            return this.renderOptions.isOpenGroupBuy || false;
        },
        isOpenCutPrice() {
            return this.renderOptions.isOpenCutPrice || false;
        },
        openCollection() {
            return this.renderOptions.openCollection || false;
        },
        openCoupon() {
            return this.renderOptions.openCoupon || false;
        },
        needOldPwd() {
            return this.renderOptions.needOldPwd || false;
        },
        preMobile() {
            return this.renderOptions.preMobile || '';
        },
        mobileCtNameList() {
            return this.renderOptions.mobileCtNameList || [];
        },
        signByMobile() {
            return this.renderOptions.signByMobile || false;
        },
        displayList() {
            return this.renderOptions.displayList || [];
        },
        canResetAcct() {
            return this.renderOptions.canResetAcct || false;
        },
        mobileCtCode() {
            return this.renderOptions.mobileCtCode || '';
        },
        memberAcct() {
            return this.memberInfo.acct || '';
        },
        imgPath() {
            return this.renderOptions.imgPath || '';
        },
        richContent() {
            return this.renderOptions.richContent || '';
        },
        oldImgId() {
            return this.renderOptions.oldImgId || '';
        },
        remarkUpdateTips() {
            return this.renderOptions.remarkUpdateTips || '';
        },
        isOpenMyForm() {
            return this.renderOptions.isOpenMyForm || false;
        },
        isOpenKnowPayment() {
            return this.renderOptions.isOpenKnowPayment || false;
        },
        memberBulletinOpen() {
            return this.renderOptions.memberBulletinOpen || false;
        },
        sessionMid() {
            return this.renderOptions.sessionMid || 0;
        },
        bulletinTitle() {
            return this.renderOptions.bulletinTitle != ''
                ? this.renderOptions.bulletinTitle
                : this.LS.memberBulletinTitle;
        },
        memberInfo() {
            return this.renderOptions.memberInfo || {};
        },
        bulletinContent() {
            return this.renderOptions.bulletinContent || '';
        },
        headPic() {
            return this.renderOptions.headPic || {};
        },
        propParam() {
            return this.renderOptions.propParam || {};
        },
        itgName() {
            return this.renderOptions.itgName || '';
        },
        rulesName() {
            return this.renderOptions.rulesName || '';
        },
        rulesDetail() {
            return this.renderOptions.rulesDetail || '';
        },
        choiceCurrencyVal() {
            return this.renderOptions.choiceCurrencyVal || '￥';
        },
        isOtherLogin() {
            return this.renderOptions.isOtherLogin || '';
        },
        optionList() {
            return this.renderOptions.optionList || [];
        },
        noRemark() {
            return this.renderOptions.noRemark || false;
        },
        signNeenMobile() {
            return this.renderOptions.signNeenMobile;
        },
        statusUpdateTips() {
            return this.LS.member_status_udateTips || '';
        },
        isCrownVer() {
            return (
                this._ver == SiteVerDef.CROWN ||
                this._ver == SiteVerDef.OEM_CROWN ||
                this._ver == SiteVerDef.CROWN_BUY_OUT
            );
        },
        manageComponent() {
            return JzProfileManage;
        },
        visitorComponent() {
            return JzProfileVisitor;
        },
        noMsgImgPath() {
            return `${this.resImageRoot}/image/v2/defaultIcon08.png`;
        },
        acctIconDisable() {
            return this.canResetAcct ? false : true;
        },
        acctInputClass() {
            return this.canResetAcct ? [''] : ['canNotReset'];
        },
        isCn() {
            return this.lcid == 2052;
        },
        cleanStyle() {
            let style = {};
            if (this.preMobile == '') {
                style['display'] = 'none';
            }
            return style;
        },
        fileItemList() {
            return this.displayList.filter((item) => {
                return item.pt == 2;
            });
        },
        latestAftersaleRecord() {
            return this.aftersaleRecords[0] || { imgUrlList: [] };
        },
        aftersaleRecordStatus() {
            return this.latestAftersaleRecord.status;
        },
        statusText() {
            return {
                ['1']: '已申请退款，等待商家审核',
                ['2']: '商家退款中',
                ['3']: '退款完成',
                ['5']: '商家拒绝退款',
            }[this.aftersaleRecordStatus];
        },
        statusTips() {
            return {
                ['1']: '1、商家同意退款后，请确认是否有收到退款，若没有可联系商家 \n 2、商家拒绝退款后，您可以修改申请后再次发起',
                ['2']: '商家同意退款后，请确认是否收到退款，没有可联系商家',
                ['5']: '商家拒绝退款后，您可以修改申请后再次发起',
            }[this.aftersaleRecordStatus];
        },
        isOnlyFinishedOrder() {
            return this.knowList.every((item) => item.payStatus == 1);
        },
        curKnowList() {
            if (this.selectOrderNavIndex !== 0) {
                return this.knowList.filter((item) => {
                    return item.payStatus == [0, 3, 1, 2][this.selectOrderNavIndex];
                });
            } else {
                return this.knowList;
            }
        },
        uploadSize() {
            if (this.$isServer) {
                return 1;
            } else {
                return window._siteVer === 10 ? 1 : 10;
            }
        },
        formPreviewState() {
            return !this.isEditState && this.openMemberModifySubmit;
        },
        addFileText() {
            return `+ ${this.LS.js_add_file}`;
        },
    },
    created() {
        this.displayList.forEach((item) => {
            let { flag, pp = '', fieldKey, pc = '', id } = item;
            let must = checkBit(flag, MemberPropDef.Flag.REQUIRED);
            let mustItemClass = must ? ['mustItem'] : [];
            let isDisabled = false;

            let propPlaceholder = pp == '' ? '' : pp;
            let itemIcon = '';
            let customPropName = '';
            if (propPlaceholder == '' || propPlaceholder == null) {
                propPlaceholder = this.LS.noContentPlaceholder;
            }

            let disStyle = '';
            let disClass = [];

            if (this.signByMobile && fieldKey == 'mobile') {
                disStyle = "disabled='disabled'";
                disClass = ['canNotReset'];
                isDisabled = true;
            }

            if (fieldKey == 'email') {
                itemIcon = 'icon-emailIcon g_mainColor';
            } else if (fieldKey == 'phone') {
                itemIcon = 'icon-customIcon g_mainColor';
                customPropName = 'customIcon';
            } else if (fieldKey == 'name') {
                itemIcon = 'icon-nameIcon g_mainColor';
            } else if (fieldKey == 'mobile') {
                itemIcon = 'icon-iphone2 g_mainColor';
                customPropName = 'customIcon';
            } else {
                itemIcon = 'icon-customIcon g_mainColor';
                customPropName = 'customIcon';
            }

            let propContent = pc.split('\n');
            item.propOptions = propContent;

            item.must = must;
            item.mustItemClass = mustItemClass;
            item.propPlaceholder = propPlaceholder;
            item.itemIcon = itemIcon;
            item.customPropName = customPropName;
            item.disStyle = disStyle;
            item.disClass = disClass;
            item.isMobile = item.type == MemberPropDef.Type.SYS_MOBILE;
            let propParam = this.propParam[id] || {};
            item.values = propParam.values || '';
            item.propTip = propParam.propTip || '';
            item.disable = isDisabled;
        });
    },
    mounted() {
        if (this.sessionMid == 0 && !manageMode) {
            changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=13');
        }
        if (this.isOpenMyForm && !(VITE_APP_MODE !== 'visitor')) {
            initFormItemEvent(this.LS.remark_message, this.LS.no_remark_message, this.LS.memberCurrentStatus);
        }
        if (this.memberInfo.headPic != null) {
            initMemberHeadPicV3(this.memberInfo.headPic);
        }

        if (this.isCrownVer && !(VITE_APP_MODE !== 'visitor')) {
            let productCollectionList = this.memberInfo.productCollections || [];
            productCollectionList.reverse();
            let collection = new loadPdCollectionList(this.sessionMid, productCollectionList);
            collection.init();
        }

        hidePlaceholder();
        switchJump();
        window.msgHeadPic = this.headPic || {};
        window.choiceCurrencyVal = this.choiceCurrencyVal;
        window.selectAry = [];
        this.displayList.forEach((item) => {
            let propType = item[MemberPropDef.Info.PROP_TYPE];
            if (propType == MemberPropDef.PropType.DROP_DOWN) {
                let id = item[MemberPropDef.Info.ID];
                let propParam = this.propParam[id] || {};
                window.selectAry.push({
                    id: item[MemberPropDef.Info.FIELD_KEY],
                    value: propParam.values,
                });
            }
        });

        if (!(VITE_APP_MODE !== 'visitor')) {
            loadItgV3(
                this.sessionMid,
                this.LS.integral_current + this.itgName,
                this.rulesName,
                this.rulesDetail != null && this.rulesDetail != ''
            );
        }

        modifyListener(this.sessionMid, this.headPic);
        checkMustItem('#modifyPsw');
        if (!(VITE_APP_MODE !== 'visitor')) {
            initMemberUploadImg(MemberDef.Limit.IMG_MAX_SIZE, MemberDef.Limit.IMG_TYPE_VALId);
        }
        this.initFileUpload();
        if (this.isOtherLogin != '') {
            bindOtherAcct(
                this.sessionMid,
                this.optionList,
                MemberDef.Limit.REMARK_MAXLEN,
                this.mobileCtNameList,
                this.signNeenMobile
            );
        }
        this.initAfterSalesUpload();
        setProfileVM(this);
    },
    methods: {
        ...mapMutations('form', ['resetFormEdit']),
        hideAddFileIconClass(item) {
            return this.propParam[item.id]?.haveFile ? 'member_file_btn-hide' : '';
        },
        showFileDetailClass(item) {
            return this.propParam[item.id]?.haveFile ? 'member_file_content-show' : '';
        },
        hideDeleteIconClass(item) {
            return this.propParam[item.id]?.haveFile && this.propParam[item.id]?.memberEdit == 0
                ? 'member_file_close-hide'
                : '';
        },
        getName(item) {
            return this.propParam[item.id]?.fileName;
        },
        getSize(item) {
            return this.propParam[item.id]?.fileSize;
        },
        getDownloadUrl(item) {
            return this.propParam[item.id]?.fileDownloadUrl;
        },
        initFileUpload() {
            this.fileItemList.forEach((item) => {
                if (item) {
                    memberFileUpload({
                        moduleId: this.moduleId,
                        fileSizeLimit: this.propParam[item.id]?.fileSizeLimit,
                        fileUpInfo: (item.pc && JSON.parse(item.pc)?.fup) || {},
                        propId: item.id,
                        previewLinkInfo:
                            item.id && this.propParam[item.id] && JSON.parse(this.propParam[item.id].previewLinkInfo),
                    });
                }
            });
        },
        getFileTips(item) {
            let fileItemOption = (item.pc && JSON.parse(item.pc)?.fup) || {};
            return fileItemOption.tt;
        },
        fileClass(item) {
            return item.pt == 2 ? ' memeber_file_line' : '';
        },
        closeClick() {
            changeHref(this.indexUrl);
        },
        mBulletinContenClick() {
            jm('#mBulletinConten').hide();
            jm('#profile').show();
        },
        knowPayClick() {
            jm('#knowPayList').hide();
            revertBackground();
            jm('#profile').show();
        },
        formListClick() {
            this.resetFormEdit();

            jm('#formList').hide();
            setMyFormTipState(this.remarkUpdateTips, this.statusUpdateTips);
            jm('#profile').show();
        },
        formDetailClick() {
            if (this.isEditState) {
                this.isEditState = false;
            } else {
                jm('#formDetail').hide();
                jm('#formList').show();
            }
        },
        viewMyRewardClick() {
            jm('#viewMyReward').hide();
            jm('#profile').show();
        },
        modifyPersInfoClick() {
            jm('#modifyPersInfo').hide();
            jm('#profile').show();
        },
        mobileCtClick(e) {
            selectAnimationCtrl(e.target);
        },
        mobileCtBlur(e) {
            selectAnimation(e.target);
        },
        mobileCtChange() {
            persInfoChangeMobileCt();
        },
        editMobileBtnClick(e) {
            clickEditMobile(e.target, this.signByMobile);
        },
        singleLineClass(item) {
            let classList = [];
            classList.push(...item.disClass, ...item.mustItemClass);
            return classList;
        },
        setPersonalInfoClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                setPersonalInfo(this.sessionMid);
            }
        },
        mobileCodeClick() {
            jm('#editMobilePanel').hide();
            jm('#modifyPersInfo').show();
            mobileEditFuc.revert();
        },
        inputMobileClick(e) {
            selectAnimationCtrl(e.target);
        },
        inputMobileBlur(e) {
            selectAnimation(e.target);
        },
        inputMobileChange() {
            mobileEditFuc.infoInputChange();
        },
        mobileInput() {
            mobileEditFuc.infoInputChange();
        },
        cleanBtnClick() {
            mobileEditFuc.cleanMobileInput();
        },
        validateCodeInput() {
            mobileEditFuc.infoInputChange();
        },
        cleanValidateCodeInputClick() {
            mobileEditFuc.cleanValidateCodeInput();
        },
        messageAuthCodeClick() {
            mobileEditFuc.getMobileCode();
        },
        saveMobileBtnClick() {
            mobileEditFuc.save();
        },
        modifyPswClick() {
            jm('#modifyPsw').hide();
            jm('#profile').show();
        },
        memberProfileSubmitClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                memberProfileSubmit(this.sessionMid, true, this.needOldPwd);
            }
        },
        modifyIntegralClick() {
            jm('#modifyIntegral').hide();
            jm('#profile').show();
        },
        modifyIntegralRuleCloseClick() {
            jm('#modifyIntegralRule').hide();
            jm('#modifyIntegral').show();
        },
        editMemberHeadPicCloseClick() {
            jm('#editMemberHeadPic').hide();
            jm('#modifyPersInfo').show();
            if (jm('#p_footer_nav_wrap').attr('data-display') !== 'none') {
                jm('#p_footer_nav_wrap').show();
            } else {
                jm('#p_footer_nav_wrap').removeAttr('data-display');
            }
        },
        openCollectionClick() {
            jm('#modifyCollection').hide();
            jm('#profile').show();
        },
        modifyCouponClick() {
            jm('#modifyCoupon').hide();
            jm('#profile').show();
        },
        modifyCutPriceCloseClick() {
            jm('#modifyCutPrice').hide();
            jm('#profile').show();
        },
        modifyGroupBuyCloseClick() {
            jm('#modifyGroupBuy').hide();
            jm('#profile').show();
        },
        initAfterSalesUpload() {
            const This = this;
            const fileTypeExts = '*.jpg;*.jpeg;*.bmp;*.png;*.gif';
            const fileSize = window._siteVer === 10 ? 1 : 10;
            JZUpload.initUploadifyButton({
                dom: this.$refs.uploadBtn2,
                fileTypeExts,
                onChange: async (e) => {
                    {
                        let file = e.target.files[0];

                        let jzUpload = new JZUpload({
                            getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey',
                            onError(error) {
                                This.$mobiIng('文件:' + file.name + '，' + error.message);
                                jm(This.$refs.uploadBtn2).find('.selectbtn').val('');
                            },
                            // 取决于后台
                            onComplete(data) {
                                const oFReader = new FileReader();
                                oFReader.onload = () => {
                                    // eslint-disable-next-line vue/no-mutating-props
                                    This.tmpFileList.push({
                                        fileId: data.fileId,
                                        tmpFileId: data.id,
                                        tmpFileName: data.name,
                                        picPath: oFReader.result,
                                    });
                                };
                                oFReader.readAsDataURL(file);

                                setTimeout(function () {
                                    This.$mobiIng(LS.fileUploadSuccess, true);
                                }, 1000);
                                jm(This.$refs.uploadBtn2).find('.selectbtn').val('');
                            },
                            onNext(info) {
                                const percent = Math.ceil(info.total.percent);
                                if (isNaN(percent)) {
                                    return;
                                }
                                if (percent == 100) {
                                    This.$mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', true);
                                } else {
                                    This.$mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', 3, true);
                                }
                            },
                            // faiupload的params
                            params: {
                                aid: Fai.top._aid,
                                folderId: Fai.top._siteId,
                                bssInfo: {
                                    siteId: Fai.top._siteId || 0,
                                    fileSizeLimit: fileSize,
                                    uploadType: 'siteFormFile',
                                },
                                accessKeyInfo: {
                                    isTmp: true,
                                },
                            },
                            // 必填，用于内部业务提示
                            tipsFn(string) {
                                This.$mobiIng(string);
                            },
                            fileTypeExts,
                            uploadConfig: {},
                        });
                        jzUpload.upload(file);
                    }
                },
            });
        },
        async requestRefund() {
            if (jm.trim(this.refundReason) === '') {
                Mobi.ing('请输入退款原因');
                return;
            }
            const itemData = jm('#requestRefund').data('itemData');
            const imgIdList = this.tmpFileList
                .filter((tmpFile) => tmpFile.fileId === undefined)
                .map((tmpFile) => tmpFile.tmpFileId);

            const tmpFileList = this.tmpFileList
                .filter((tmpFile) => tmpFile.fileId !== undefined)
                // eslint-disable-next-line no-unused-vars
                .map(({ picPath, ..._ }) => ({
                    ..._,
                }));
            const { data } = await jzRequest.post('/api/guest/aftersaleRecord/applyKnowProductRefund', {
                data: {
                    orderId: itemData.id,
                    reason: this.refundReason,
                    memberId: itemData.memberId,
                    phone: this.contactInformation,
                    tmpFileList: JSON.stringify(tmpFileList),
                    imgIdList: JSON.stringify(imgIdList),
                },
            });
            if (data.success) {
                Mobi.ing('退款申请提交成功，请耐心等待商家审核');
                loadKnowPayList();
                jm('#requestRefund').hide();
                jm('#knowPayList').show();
                logJZFdp('jz_apply_refunds_c', {
                    jz_content_terminal: 'mobi',
                    jz_free_bool1: tmpFileList.length !== 0,
                    jz_free_bool2: this.contactInformation !== '',
                    jz_free_int1: itemData.price,
                });
            } else {
                Mobi.ing(data.msg);
            }
        },
        requestRefundCloseClick() {
            jm('#requestRefund').hide();
            jm('#knowPayList').show();
        },
        aftersaleRecordCloseClick() {
            jm('#aftersaleRecord').hide();
            jm('#knowPayList').show();
        },
        aftersaleRecordAllCloseClick() {
            jm('#aftersaleRecordAll').hide();
            jm('#aftersaleRecord').show();
        },
        async previewAllAftersaleRecord() {
            const itemData = jm('#aftersaleRecordAll').data('itemData');
            const {
                data: { dataList },
            } = await jzRequest.get('/api/guest/aftersaleRecord/listKnowProductRecords', {
                params: {
                    orderId: itemData.id,
                },
            });
            this.aftersaleRecords = dataList;
            jm('#aftersaleRecordAll').show();
            jm('#aftersaleRecord').hide();
        },
        uploadClick() {
            jm(this.$refs.uploadBtn2)?.find?.('.selectbtn')?.[0]?.click?.();
        },
        delRefundPhoto(index) {
            this.tmpFileList.splice(index, 1);
        },
        resetrefundInfo() {
            this.refundReason = '';
            this.contactInformation = '';
            this.tmpFileList = [];
        },
        async modifyRequest(aftersaleRecord) {
            this.aftersaleRecords = [aftersaleRecord];
            this.contactInformation = aftersaleRecord.phone;
            this.refundReason = aftersaleRecord.reason;
            const { imgUrlList, imgIdList } = aftersaleRecord;
            const tmpFileList = [];
            for (let index = 0; index < imgUrlList.length; index++) {
                tmpFileList.push({
                    picPath: imgUrlList[index],
                    tmpFileId: imgIdList[index],
                });
            }
            this.tmpFileList = tmpFileList;
            jm('#aftersaleRecord').hide();
            jm('#requestRefund').show();
        },
        formatDate(timeStamp) {
            return dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss');
        },
        changeOrderNav(index) {
            this.selectOrderNavIndex = index;
            loadKnowPayList();
        },
        getPayStatusText(hiddenRefundStatus, payStatus) {
            if (!hiddenRefundStatus) {
                return {
                    ['0']: '未支付',
                    ['1']: '已完成',
                    ['2']: '已关闭',
                    ['3']: '售后中',
                }[payStatus];
            } else {
                return payStatus == 1 ? '已完成' : '未支付';
            }
        },
        getShowAftersale(item) {
            return (
                (this.wxpayAutoRefund || (!this.wxpayAutoRefund && item.refundStatus !== 0)) &&
                [16, 17, 18].includes(item.payMode) &&
                item.price !== 0 &&
                dayjs(Date.now()).diff(item.payTime, 'y') < 1
            );
        },
        previewImg(item) {
            slideshowView({
                id: item.tmpFileId,
                previewSrcList: [{ src: item.picPath, title: '', description: '' }],
                logEventConfig: {},
            });
        },
        async knowItemProductAction(item) {
            this.curKnow = item;
            if (item.refundStatus === 0) {
                getProfileVM().resetrefundInfo();
                jm('#knowPayList').hide();
                var e = jm('#requestRefund');
                e.show();

                e.data('itemData', item);
            } else {
                const itemData = item;
                const {
                    data: { data: aftersaleRecord },
                } = await jzRequest.get('/api/guest/aftersaleRecord/latestKonwProductRecord', {
                    params: {
                        orderId: itemData.id,
                    },
                });
                getProfileVM().aftersaleRecords = [aftersaleRecord];
                jm('#requestRefund').data('itemData', itemData);
                jm('#aftersaleRecordAll').data('itemData', itemData);
                jm('#knowPayList').hide();
                // eslint-disable-next-line no-redeclare
                var e = jm('#aftersaleRecord');
                jm('#aftersaleRecord').show();
            }
        },
        getUrlArgsOnclickStr(item) {
            if (this.$isServer) {
                return '';
            } else {
                return createUrlArgsOnclickStr(item.pdReqArgs || '', window._openRemoveUrlArgs);
            }
        },
        getProductPrice(id) {
            return this.knowList.find((know) => know.id === id)?.price;
        },
        setEditState() {
            this.isEditState = true;
        },
        onEditSuccess(editSubmitId) {
            if (editSubmitId > 0) {
                const node = Array.from(jm('#formList .profileContent').find('.item')).find(
                    (node) => parseInt(node.getAttribute('_id')) == editSubmitId
                );
                if (node) {
                    showRenderDetail(jm(node), {
                        remarkLabel: this.LS.remark_message,
                        noRemarkMessageText: this.LS.no_remark_message,
                        memberCurrentStatus: this.LS.memberCurrentStatus,
                        haveRead: true,
                    });
                }
            }
            this.isEditState = false;
        },
    },
};
</script>
<style lang="scss">
@import './profile-v3.scss';
.jz_themeV3.mobiCol12 {
    #webTopBox,
    #webHeader_v3,
    #webBannerBox,
    #webFooterBox,
    #bgMusicWrapper,
    #fixedRightSideBtns {
        display: none;
    }
    .profile_v3 .profileLoginHeader {
        position: relative;
    }
    .profileContent .onLogout {
        width: 100%;
        height: 2rem;
        line-height: 2.5rem;
        background: #fff;
        margin-top: 0.5rem;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 1px solid #ececec;
        border-top: 1px solid #ececec;
    }
    .modifyProfile,
    .viewMyReward {
        display: none;
        background: #f7f7f7;
    }
    #viewMyReward {
        background: #fff;
    }
    .jz_themeV3 #profile .profileContent .icon-pInfoIcon:before,
    .jz_themeV3 #profile .profileContent .icon-cPswIcon:before,
    .jz_themeV3 #profile .profileContent .icon-bangding:before,
    .jz_themeV3 #profile .profileContent .icon-cLogoutIcon:before,
    .jz_themeV3 #profile .profileContent .icon-orderIcon:before,
    .jz_themeV3 #profile .profileContent .icon-addrInfoIcon:before,
    .jz_themeV3 #profile .profileContent .icon-fenxiao:before,
    .jz_themeV3 #profile .profileContent .icon-starIcon:before,
    .jz_themeV3 #profile .profileContent .icon-integralIcon:before,
    .jz_themeV3 #profile .profileContent .icon-cutPrice:before,
    .jz_themeV3 #profile .profileContent .icon-groupBuy:before,
    .jz_themeV3 #profile .profileContent .icon-groupBuySpecial:before,
    .jz_themeV3 #profile .profileContent .icon-groupBuySpecial1:before,
    .jz_themeV3 #profile .profileContent .icon-promoter:before,
    .jz_themeV3 #profile .profileContent .icon-noticeIcon:before,
    .jz_themeV3 .icon-coupons:before {
        display: inline-block;
        text-align: left;
        padding-left: 0.2rem;
        width: 1.5rem;
        position: relative;
        top: 0.08rem;
        font-size: 0.7rem;
    }
    .jz_themeV3 #profile .profileContent .icon-integralIcon:before {
        content: '\b0304';
        color: #3d3d3d;
        font-size: 0.7rem;
    }
    // 个人资料
    #modifyPersInfo {
        background-color: #f1f0f6;
        font-family: 微软雅黑;
    }

    #coverBox {
        #dragLeftTop {
            top: -3px;
            left: -3px;
        }
        #dragRightTop {
            top: -3px;
            right: -3px;
            cursor: ne-resize;
        }
        #dragLeftBot {
            bottom: -3px;
            left: -3px;
            cursor: ne-resize;
        }
        #dragRightBot {
            bottom: -3px;
            right: -3px;
        }
        #dragTopCenter {
            top: -3px;
            left: 50%;
            margin-left: -3px;
            cursor: n-resize;
        }
        #dragRightCenter {
            top: 50%;
            right: -3px;
            margin-top: -3px;
            cursor: e-resize;
        }
        #dragLeftCenter {
            left: -3px;
            top: 50%;
            margin-top: -3px;
            cursor: w-resize;
        }
        #dragBotCenter {
            left: 50%;
            bottom: -3px;
            margin-left: -3px;
            cursor: s-resize;
        }
    }
}

textarea.withdrawal_reason::placeholder,
textarea.request_refund_phone::placeholder {
    color: #999;
}
</style>
<style lang="scss" scoped>
@import './common.scss';
</style>
