/**
 * @fileoverview\
 * 百度自动推送
 */
export function printBaiduPushJs() {
    jm.ajax({
        type: 'post',
        url: '/ajax/mobi_h.jsp',
        data: 'cmd=getWafNotCk_checkBaiduAutomaticPush',
        dataType: 'json',
        success(result) {
            if (result.printBaiduPushJs) {
                var bp = document.createElement('script');
                var curProtocol = window.location.protocol.split(':')[0];
                if (curProtocol === 'https') {
                    bp.src = '//zz.bdstatic.com/linksubmit/push.js';
                } else {
                    bp.src = '//push.zhanzhang.baidu.com/push.js';
                }
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(bp, s);
            }
        },
    });
}
