import actions from './actions';
import mutations from './mutations';

// modules
import app from './modules/app';
import header from './modules/header';
import footer from './modules/footer';
import nav from './modules/nav';
import banner from './modules/banner';
import panelSetting from './modules/panel-setting';
import footerNav from './modules/footerNav';
import backgroundSetting from './modules/background';
import product from './modules/product';
import customSearchResult from './modules/customSearchResult';
import { tree_filter } from './helpers/node-ops';
import floatEffect from './modules/floatEffect';
import editMode from './modules/editMode';
import route from './modules/route';
import popupZone from './modules/popupZone';
import form from './modules/form';

import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';

export function getStoreConfig() {
    return {
        state: {
            currentPageModuleIds: [], // 包含基础模块
            currentPageModuleIdMap: {}, // <id, module>
            htmlFontSize: NEW_MANAGE_HTML_FONTSIZE,
            allNewsCount: 0,
            newsList: [],
            newsGroupList: [],
            columnModuleHiddenList: [], // 当前隐藏的模块ID列表
            delModuleList: [], // 当前删除的模块ID列表
            httpInCodeCount: 0,
            topTips: [],
            mobiTipList: [],
            pageScrollTop: 0,
            popupFormModuleIdMap: {},
            popupFormId: 0,
            showSubTitleSkinIdList: [], // 全站含有副标题的皮肤（主题3.0）
            fullSiteSubTitleType: 0, // 主题3.0全站皮肤副标题是否显示
        },
        getters: {
            currentPageModules: (state, getters) => {
                const visibleModuleIds = state.currentPageModuleIds.filter((id) => {
                    return !state.columnModuleHiddenList.includes(id) && !state.delModuleList.includes(id);
                });
                return visibleModuleIds.map((id) => getters.visiblePageModuleIdMap[id]);
            },
            visiblePageModuleIdMap: (state) => {
                const map = {};

                Object.keys(state.currentPageModuleIdMap).forEach((key) => {
                    let module = state.currentPageModuleIdMap[key];
                    module = tree_filter({ ...module }, (m) => {
                        return !state.columnModuleHiddenList.includes(m.id) && !state.delModuleList.includes(m.id);
                    });
                    if (module !== null) {
                        map[key] = module;
                    }
                });

                return map;
            },
            currentModuleForId: (state) => {
                return function (moduleId) {
                    return state.currentPageModuleIdMap[moduleId];
                };
            },
            getModuleById: (state) => {
                return function (moduleId) {
                    if (VITE_APP_MODE !== 'visitor') {
                        return (window.currentPageModuleIdMap || {})[moduleId];
                    } else {
                        return state.currentPageModuleIdMap[moduleId];
                    }
                };
            },
            getPopupFormModuleById: (state) => {
                return function (formId) {
                    return state.popupFormModuleIdMap[formId];
                };
            },
        },
        actions,
        mutations,
        modules: {
            header,
            footer,
            nav,
            app,
            banner,
            panelSetting,
            footerNav,
            backgroundSetting,
            product,
            customSearchResult,
            floatEffect,
            editMode,
            route,
            popupZone,
            form,
        },
    };
}
