<template>
    <div v-show="showDialog" ref="dialog" class="m-dialog">
        <div class="m-dialog-content">
            <slot name="content"></slot>
        </div>
        <div class="m-dialog-confirm-button" @click="confirm">确定</div>
    </div>
</template>

<script>
export default {
    name: 'Dialog',
    data() {
        return {
            overlayEl: null,
            showDialog: false,
        };
    },
    mounted() {
        this.createOverlay();
        this.showDialog = true;
    },
    methods: {
        createOverlay() {
            const el = document.createElement('div');
            el.classList.add('m-dialog-overlay');
            this.overlayEl = el;
            document.getElementById('preview').prepend(el);
        },
        confirm() {
            this.$emit('confirm');
            this.destroy();
        },
        destroy() {
            this.overlayEl.remove();
            this.overlayEl = null;
            this.$refs['dialog'].remove();
            this.$destroy();
        },
    },
};
</script>

<style lang="scss" scoped>
.m-dialog {
    position: fixed;
    z-index: 2;
    top: 45%;
    left: 50%;
    width: 320px;
    overflow: hidden;
    background: rgba(255, 255, 255);
    border-radius: 8px;
    transform: translate3d(-50%, -50%, 0);
    font-size: 16px;
    color: #333;
    &-content {
        z-index: 2;
        padding: 30px;
        line-height: 1.6;
        text-align: center;
        border-bottom: 1px solid #e5e5e5;
    }
    &-confirm-button {
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
}
</style>
<style>
.m-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
