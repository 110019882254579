// 悬浮效果指导
class JZFloatEffectGuide {
    constructor() {
        this.component = null;
        this.id = 'jzFloatEffectGuide';
        this.vm = null;

        this.init();
    }
    init() {
        let _this = this;
        this.component = {
            el: `#${this.id}`,
            template: `
                <div id="${this.id}" class="${this.id}">
                    <canvas ref="floatEffectCanvas" :style="canvasStyle" class="floatEffectCanvas"></canvas>
                    <div class="floatEffectWrap" :style="wrapStyle">
                        <img :src="textSrc" :style="textStyle"/>
                        <img :src="btnSrc" :style="btnStyle" @click="finshHanlder"/>
                        <img :src="arrowSrc" :style="arrowStyle"/>
                    </div>
                </div>
                `,
            data() {
                return {
                    docW: 0,
                    docH: 0,
                    textStyle: {
                        position: 'absolute',
                    },
                    btnStyle: {
                        position: 'absolute',
                        top: '116px',
                        left: '134px',
                    },
                    arrowStyle: {
                        position: 'absolute',
                        top: '227px',
                        left: '106px',
                    },
                    wrapTop: 0,
                    wrapLeft: 0,
                };
            },
            computed: {
                canvasStyle() {
                    return {
                        width: this.docW + 'px',
                        height: this.docH + 'px',
                    };
                },
                resRoot() {
                    return `${Fai.top._resRoot}/image/guide/floatEffect`;
                },
                textSrc() {
                    return `${this.resRoot}/text.png`;
                },
                arrowSrc() {
                    return `${this.resRoot}/arrow.png`;
                },
                btnSrc() {
                    return `${this.resRoot}/button.png`;
                },
                wrapStyle() {
                    return {
                        top: `${this.wrapTop}px`,
                        left: `${this.wrapLeft}px`,
                    };
                },
            },
            methods: {
                renderCanvas() {
                    let canvas = this.$refs.floatEffectCanvas;
                    let ctx = canvas.getContext('2d');
                    canvas.width = this.docW;
                    canvas.height = this.docH;
                    ctx.clearRect(0, 0, this.docW, this.docH);
                    ctx.save();
                    ctx.fillStyle = 'rgba(0,0,0,0.7)';
                    ctx.rect(0, 0, this.docW, this.docH);
                    ctx.fill();

                    // 微调矩形
                    let rect = this.getPosParm($('.J_floatEffect'));
                    rect.top -= 4;
                    rect.bottom += 4;
                    rect.right -= 4;
                    // 画矩形和虚线框
                    this.drawCircleRect(ctx, rect);
                    this.drawRectDash(ctx, rect);

                    let settingRect = this.getPosParm($('.f-entry-list .f_item').eq(2)[0]);

                    // 计算引导蒙层的位置
                    this.wrapLeft = settingRect.left - 20;
                    this.wrapTop = settingRect.top - 300;

                    settingRect.top -= 4;
                    settingRect.bottom += 4;
                    settingRect.right += 4;
                    settingRect.left -= 4;
                    this.drawRectDash(ctx, settingRect);
                    this.drawCircleRect(ctx, settingRect);
                },
                // 画矩形虚线
                drawRectDash(ctx, rect, { length = 3, distance = 1, padding = 4 } = {}) {
                    let { left: x, right, top: y, bottom } = rect;
                    let w = right - x;
                    let h = bottom - y;
                    ctx.strokeStyle = '#fff';
                    ctx.lineWidth = 1;
                    ctx.setLineDash([length, distance]);
                    ctx.strokeRect(x - padding, y - padding, w + 2 * padding, h + 2 * padding);
                },
                // 画圆角矩形，按照css规则传入rect {left, right, top, bottom, radius}
                drawCircleRect(ctx, rect) {
                    let { left: x, right, top: y, bottom, radius: r = 1 } = rect;
                    let w = right - x;
                    let h = bottom - y;
                    ctx.beginPath();

                    // 圆角矩形算法
                    ctx.moveTo(x + r, y);
                    // 上
                    ctx.arcTo(x + w, y, x + w, y + h, r);
                    // 右
                    ctx.arcTo(x + w, y + h, x, y + h, r);
                    // 下
                    ctx.arcTo(x, y + h, x, y, r);
                    // 左
                    ctx.arcTo(x, y, x + w, y, r);

                    ctx.closePath();
                    ctx.clip();
                    ctx.clearRect(x, y, w, h);
                    ctx.restore();
                },
                updateDocParam() {
                    this.docW =
                        document.documentElement.clientWidth > 1280 ? document.documentElement.clientWidth : 1280;
                    this.docH = document.documentElement.clientHeight;
                },
                getPosParm(el) {
                    let { left, right, top, bottom } = $(el)[0].getBoundingClientRect();
                    return {
                        left,
                        right,
                        top,
                        bottom,
                    };
                },
                finshHanlder() {
                    $.ajax({
                        type: 'get',
                        url: '../ajax/design_h.jsp',
                        data: 'cmd=setWafCk_setHasClickFloatEffectGuide',
                        error() {
                            Fai.ing('系统错误，请刷新页面', false);
                        },
                        success() {
                            Fai.top.hasClickFloatEffectGuide = true;
                            _this.destroy();
                        },
                    });
                },
            },
            mounted() {
                this.updateDocParam();
                this.renderCanvas();
            },
        };
    }
    render() {
        if (this.vm) {
            this.destroy();
        }

        $(`<div id="${this.id}" class="${this.id}"></div>`).appendTo('body');
        this.vm = new Vue(this.component);

        $(window).on('resize.jzFloatEffectGuide', () => {
            // if(document.documentElement.clientWidth > 1280){
            this.render();
            // }
        });
    }

    destroy() {
        if (this.vm) {
            $(this.vm.$el).remove();
            this.vm && this.vm.$destroy();
            this.vm = null;
        }
        $(window).off('resize.jzFloatEffectGuide');
    }
    async run() {
        if (!Fai.top.hasClickFloatEffectGuide) {
            await Vue.prototype.$designer.open({
                panelType: 'setting',
            });
            await this.sleep(800);
            this.render();
        }
    }
    sleep(time) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }
}

let _JZFloatEffectGuide = new JZFloatEffectGuide();

export { _JZFloatEffectGuide as JZFloatEffectGuide };
