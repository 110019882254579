export function getPreviewObject(id) {
    if (!id) return;
    if (typeof id === 'string' && id.indexOf('#') > -1) {
        id = id.replace('#', '');
    }
    return document.getElementById(id);
}

//通过class获取DOM原生对象
export function getPreviewObjectClass(className) {
    if (!className) return;
    return document.getElementsByClassName(className);
}
