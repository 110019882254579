<template>
    <div v-if="backTopOpen" v-show="!isFirstScreen" id="backTop" class="backTop_box" @click="gotoTop">
        <div id="backTopIcon" class="backTop">
            <span
                v-if="backTopStyle === 0"
                class="backTop_icon g_main_bgColor_v3"
                :style="backTopComputedStyle"
                :class="backTopCls"
            ></span>
            <span
                v-else-if="backTopStyle === 1"
                class="backTop_icon g_main_color_v3"
                :style="backTopComputedStyle"
                :class="backTopCls"
            ></span>
            <span
                v-else-if="backTopStyle === 2"
                class="backTop_icon g_main_color_v3"
                :style="backTopComputedStyle"
                :class="backTopCls"
            ></span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timer: null,
        };
    },
    computed: {
        isFirstScreen() {
            if (this.$isServer) {
                return false;
            }

            let state = this.$store.state;
            let height = VITE_APP_MODE !== 'visitor' ? $('#g_web').height() : $('body').height();
            return state.pageScrollTop <= height;
        },
        backTop() {
            return this.$store.state.app.backTop || {};
        },
        backTopOpen() {
            return this.backTop.isOpen === 1;
        },
        backTopCls() {
            return 'icon-backTop' + this.backTopStyle;
        },
        backTopComputedStyle() {
            let _backTopColor = this.backTopColor;
            let styleMap = {
                0: 'color:#fff;' + 'background-color:' + _backTopColor,
                1: 'color:' + _backTopColor + ';background-color:#fff',
                2: 'color:' + _backTopColor + ';background-color:#fff' + ';border:1px solid ' + _backTopColor,
            };

            return styleMap[this.backTopStyle];
        },
        backTopStyle() {
            return this.backTop.style;
        },
        backTopColor() {
            return this.backTop.color;
        },
    },
    mounted() {
        if (this.backTopOpen) {
            this.initBackTop(this.isFirst);
            this.isFirst = false;
        }
    },
    methods: {
        gotoTop() {
            let $body = $('#g_web');
            let body = $body[0];
            let html = $body.parent()[0];
            var scrollTop = body.scrollTop + html.scrollTop,
                speed;

            clearInterval(this.timer);
            this.timer = setInterval(() => {
                speed = scrollTop / 4;
                scrollTop = parseInt(scrollTop - speed);
                body.scrollTop = scrollTop;
                if (scrollTop <= 0) {
                    clearInterval(this.timer);
                }
            }, 1000 / 60);
        },
        change() {
            let $backTop = jm('#backTop');
            let $preview = jm('#g_web');
            const isOpen = !(VITE_APP_MODE !== 'visitor') || !!window.$store.state.app.backTop.isOpen;
            let clientHeight = $preview[0].offsetHeight;
            if (($preview[0].scrollTop > clientHeight || $preview[0].scrollTop > clientHeight) && isOpen) {
                $backTop.show();
            } else {
                $backTop.hide();
            }
        },
        initBackTop(isFirst) {
            let $backTop = jm('#backTop');
            this.change(); //不是刚刚创建
            if (isFirst) {
                // initBackToprManage();
                jm('#g_web').on('scroll', this.change);

                $backTop.on('click', function () {
                    var scrollTop = document.body.scrollTop + document.documentElement.scrollTop,
                        speed,
                        timer;
                    timer = setInterval(function () {
                        speed = scrollTop / 4;
                        scrollTop = parseInt(scrollTop - speed);
                        document.body.scrollTop = scrollTop;
                        document.documentElement.scrollTop = scrollTop;

                        if (scrollTop <= 0) {
                            clearInterval(timer);
                        }
                    }, 1000 / 60);
                });
            }
        },
    },
};
</script>

<style>
.backTop_box {
    z-index: 1;
}

.backTop_box .backTop {
    cursor: pointer;
    margin: auto;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    margin: 0 0.17rem 0.43rem 0;
    font-size: 0;
}

.backTop_box .backTop .backTop_icon {
    display: block;
    text-align: center;
    line-height: 1.6rem;
    height: 1.6rem;
    width: 1.6rem;
    font-size: 0.8rem;
    border-radius: 50%;
}

.backTop_icon.icon-backTop1 {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.backTop_icon.icon-backTop2 {
    background-color: rgba(255, 255, 255, 0.9) !important;
}

.backTop_icon.icon-backTop0:before {
    content: '\b0184';
}

.backTop_icon.icon-backTop1:before {
    content: '\b0185';
    font-size: 0.9rem;
    padding-left: 0.05rem;
}

.backTop_icon.icon-backTop2:before {
    content: '\b0110';
}
</style>
