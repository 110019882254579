<template>
    <component :is="component" :module-id="moduleId"></component>
</template>

<script>
import { mapState } from 'vuex';
import productDetailV3 from './v3/module/productDetailV3.vue';
import productDetailV2 from './v2/module/productDetailV2.vue';
import { Clue } from '@jz/biz-shared';
export default {
    name: 'ProductDetail',
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        component() {
            return this.openThemeV3 ? productDetailV3 : productDetailV2;
        },
    },
    mounted() {
        Clue?.logClueProductDetailSourceContent();
    },
};
</script>
