import { updatePdCollections, batchGetPd, getProductPropList, setMemberCollection } from '@/api/member';
import { prompt } from '@/shared/fai';
import { onTouchAndClickEvent } from '@/shared/event';
import { preprocessUrl as preprocessUrlFix } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
let loadPdCollectionList = function (mid, pIdList) {
    //构造函数
    this.pIdList = pIdList;
    this.mid = mid;
    this.panel = jm('#modifyCollection');
};

var PRO = loadPdCollectionList.prototype,
    mid,
    pIdList,
    panel,
    pageSize = 10,
    pageLastIndex,
    priceShowAuth = [];

PRO.init = function () {
    (pIdList = this.pIdList), (panel = this.panel);
    mid = this.mid;

    if (pIdList.length == 0) {
        emptyCollection();
        return;
    }

    //检查更新收藏列表，判断是否有产品被删除
    updateCollections();
    var subList = pIdList.slice(0, pageSize);
    pageLastIndex = subList.length;
    loadPdDataList(subList);
    // initEvent();

    //  window.onload = lazyLoadImg();
};

function emptyCollection() {
    if (pIdList.length == 0) {
        panel
            .find('.profileContent')
            .html("<div class='noCollIcon'></div><div class='noCollTip'>" + LS.notCollect + '</div>');
    }
}
/**
 * 进入页面时传collectionList 到后台检查，有产品已删除的就更新List
 */
function updateCollections() {
    updatePdCollections(jm.toJSON(pIdList)).then((result) => {
        if (result.success) {
            pIdList = result.list;
        }
    });
}
//获取分页的数据
function loadPdDataList(sublist) {
    batchGetPd(jm.toJSON(sublist)).then((result) => {
        if (result.success) {
            //addToJsonArray(result.list);
            loadCollectionDom(sublist, result.list);
        }
    });
}

async function checkPropShowPrice(lid) {
    var isExist = false,
        info;
    jm.each(priceShowAuth, function (i, n) {
        if (lid == n.lid) {
            isExist = true;
            info = n;
        }
    });
    if (!isExist) {
        info = await getProductProp(lid);
    }
    return info;
}

async function getProductProp(lid) {
    var info = {
        lid,
    };
    let result = await getProductPropList(lid);
    if (result.success) {
        var showPrice = false;
        jm.each(result.list, function (i, n) {
            if (n.fieldKey == 'mallPrice') {
                showPrice = true;
            }
        });
        info.showPrice = showPrice;
        priceShowAuth.push(info);
    }
    return info;
}

async function deleteCollectionList(pid) {
    var flag = false;
    let result = await setMemberCollection({
        mid,
        pid,
        collection: false,
    });
    if (result && result.success) {
        flag = true;
    }
    return flag;
}
function loadCollectionDom(subList, items = []) {
    var contentHtml = [];
    let url = preprocessUrlFix({ path: '', oldPath: '' });

    let checkPropPromiseList = items.map((data) => {
        return checkPropShowPrice(data.lid);
    });
    Promise.all(checkPropPromiseList).then((authList) => {
        items.forEach((data, index) => {
            let authInfo = authList[index];
            contentHtml.push('<li>');
            contentHtml.push('  <div class="pdImg">');
            if (data.isShowKnowPayLabelText) {
                contentHtml.push(`<span class="quality_content_label">${data.knowPayLabelText}</span>`);
            }
            contentHtml.push(
                '      <a href="javascript:;" _href="' +
                    url +
                    'pd.jsp?pid=' +
                    data.id +
                    '&returnUrl=' +
                    url +
                    'profile.jsp' +
                    '" >'
            );
            contentHtml.push('          <img src="' + data.picPath + '" />');
            contentHtml.push('      </a>');
            contentHtml.push('  </div>  ');
            contentHtml.push('  <div class="pdName">' + data.name + '</div>');
            if (data.mallPrice != -1.0 && authInfo.showPrice) {
                contentHtml.push(
                    '  <div class="pdPrice"><div class="mallPrice">' +
                        Fai.top.choiceCurrencyVal +
                        ' ' +
                        data.mallPrice +
                        '</div></div>'
                );
            }
            contentHtml.push(
                '  <div class="cancelCollection" data-id="' + data.id + '">' + LS.cancelCollect + '</div>'
            );
            contentHtml.push('</li>');
        });
        panel.find('.collectionList').empty().append(contentHtml.join(''));

        initEvent();
    });
}

function initEvent() {
    // //删除收藏
    onTouchAndClickEvent('.cancelCollection', function () {
        var self = jm(this);
        var options = {
            textClass: 'confirm',
            content: LS.sureCancelCollect,
            insertSelector: '#g_body',
            callback() {
                var pid = self.attr('data-id');
                var index = pIdList.indexOf(Number(pid));
                if (index > -1) {
                    pIdList.splice(index, 1);
                }
                if (deleteCollectionList(pid)) {
                    pageLastIndex--;
                    self.parents('li').remove();
                }
                if (panel.find('.collectionList li').length < 6 && pIdList.length > pageLastIndex) {
                    var subList = pIdList.slice(pageLastIndex, pageLastIndex + pageSize);
                    pageLastIndex = pageLastIndex + subList.length;
                    if (subList.length > 0) {
                        loadPdDataList(subList);
                    }
                }
                emptyCollection();
                jm('#mobiPrompt').remove();
                jm('#mobiPrompt_Bg').remove();
            },
        };
        prompt(options);
    });

    jm('.pdName,.pdPrice,.pdImg').click(function () {
        var href = jm(this).parent().find('a').attr('_href');
        changeHref(href);
        jm.cookie('_fromCollect', true, { path: '/' });
    });
    jm(window).on('scroll', function () {
        if (pIdList.length > pageLastIndex) {
            //当内容滚动到底部时加载新的内容
            var docHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
            if (jm(window)[0].scrollY + jm(window).height() + 100 >= docHeight) {
                var subList = pIdList.slice(pageLastIndex, pageLastIndex + pageSize);
                pageLastIndex = pageLastIndex + subList.length;
                if (subList.length > 0) {
                    loadPdDataList(subList);
                }
            }
        }
    });

    if (jm.getCookie('_openCollect')) {
        //打开收藏页面
        var itemName = jm('#collection').find('.itemName').html();
        jm('#modifyCollection').find('.title').html(itemName);

        jm('#profile').hide();
        jm('#modifyCollection').show();
        jm.cookie('_openCollect', '', { expires: -1, path: '/' });
    }
}

export default loadPdCollectionList;
