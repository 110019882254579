import { MobiModule } from '@/modules/Module';
import BgMusicView from './Index.vue';

class _BackgroundMusic extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return BgMusicView;
    }
}

export const BackgroundMusic = new _BackgroundMusic();
