<template>
    <div v-if="_bgMusicOpenNow" class="fk_bgMusic" :data-headhidden="headHideV3">
        <div
            v-if="mobiBgMusic.isOpen && allowedBackTop"
            v-show="setting.isHidden === MobiBgMusicSettingDef.ISHIDDEN"
            @click="switchToPlay"
        >
            <div id="bgMusic">
                <div id="bgm_icon" class="bgm_icon" :class="posCls" :style="_bgMusicHidden">
                    <div class="bgm_icon_inner"></div>
                </div>
                <audio
                    id="bgmAudio"
                    ref="audio"
                    :src="audioSrc"
                    :data-original="filePath"
                    :autoplay="_bgMusicAutoPlay"
                    :loop="_bgMusicLoop"
                    @durationchange="durationchange"
                    @ended="ended"
                ></audio>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { COL } from '@/def/col';
import { bgmPause, bgmPlay, MobiBgMusicSettingDef } from '@/system/bgMusic/comm';
import { scrollBgmPosition } from '@/shared/bgMusic/index';

import { getAudioResourceHandler } from '@/shared';

export default {
    props: {
        moduleId: {
            type: String,
            default: 'bgMusic',
        },
    },
    data() {
        return {
            MobiBgMusicSettingDef,
            musicOn: false,
        };
    },
    computed: {
        ...mapState('app', ['colId', 'allowedBackTop', 'isJzCdnFlowLimit']),
        ...mapState('header', ['headStyle']),
        headHideV3() {
            return !!this.headStyle.hh;
        },
        filePath() {
            return this.$store.state.app.mobiBgMusic.filePath;
        },
        audioSrc() {
            return this.isJzCdnFlowLimit ? '' : this.filePath;
        },
        mobiBgMusic() {
            return this.$store.state.app.mobiBgMusic || {};
        },
        setting() {
            return this.mobiBgMusic.setting || {};
        },
        _bgMusicOpenNow() {
            if (this.mobiBgMusic.isOpen === 1 && this.mobiBgMusic.isGlobal === 0 && this.colId !== COL.SYS_INDEX) {
                return false;
            }
            return this.mobiBgMusic.isOpen;
        },
        _bgMusicAutoPlay() {
            return this.setting.isAuto === 0;
        },
        _bgMusicLoop() {
            return this.setting.isLoop === 0;
        },
        _bgMusicHidden() {
            return this.setting.isHidden === 1 ? 'opacity:0;' : '';
        },
        isGlobal() {
            return this.mobiBgMusic.isGlobal === 0;
        },
        posCls() {
            let dirMap = {
                0: 'bgm_rightUp',
                1: 'bgm_rightDown',
                2: 'bgm_leftUp',
                3: 'bgm_leftDown',
            };
            // return res;
            let cls = `bgm_icon_wrap ${dirMap[this?.$store.state?.app?.mobiBgMusic?.setting?.position || 0]}`;
            return cls;
        },
    },
    mounted() {
        if (this._bgMusicOpenNow) {
            this.initBgm(this.mobiBgMusic.filePath);
        } else {
            this.initBgmCookie();
        }

        if (this.isJzCdnFlowLimit) {
            const AudioResourceHandler = getAudioResourceHandler();
            AudioResourceHandler.add(this.$refs['audio']);
        } else {
            if (this._bgMusicAutoPlay) {
                this.bgmPlay();
            }
        }
    },
    methods: {
        initBgmCookie() {
            jm.cookie('_bgmIsPause', null, { path: '/' });
        },
        editOK(mobiBgMusic) {
            this.$store.commit('updateMobiBgMusic', mobiBgMusic);
            this.$designer.styleChanged();
            this.$forceUpdate();
        },
        editCancel() {},
        switchToPlay() {
            var tmp_bgm_icon_inner = jm('#bgMusic .bgm_icon_inner');
            if (tmp_bgm_icon_inner.hasClass('bgm_on')) {
                this.bgmPause();
                jm.cookie('_bgmIsPause', 1, { path: '/' });
                this.musicOn = false;
            } else {
                this.bgmPlay();
                jm.cookie('_bgmIsPause', 0, { path: '/' });
                this.musicOn = true;
            }
        },
        bgmPlay() {
            bgmPlay();
        },
        bgmPause() {
            bgmPause();
        },

        /*
         *	本地Session记录音乐最后停止播放的时间，与Site那边的Site.callMusicUnload一致
         *	param:
         *		audioId：音频id
         *		sessionTimeId：记录音频最后播放时间的sessionTimeId
         */
        recordLastPlayerTime(audioId, sessionTimeId) {
            var bgAudio = document.getElementById(audioId),
                tmpTime = parseFloat(bgAudio ? bgAudio.currentTime : new Date());
            sessionStorage[sessionTimeId] = tmpTime;
        },

        // 取出音频最后播放的时间
        callLastPlayerTime(sessionTimeId, defaultTime) {
            var lastPlayerTime = parseFloat(sessionStorage[sessionTimeId]);
            return lastPlayerTime >= 0 ? lastPlayerTime : defaultTime;
        },

        ended() {
            var bgm_icon_inner = jm('#bgMusic .bgm_icon_inner');
            bgm_icon_inner.removeClass('bgm_on');
        },
        durationchange() {
            var lastPlayerTime = this.callLastPlayerTime('bgplayerTime', 0);
            if (this.$refs.audio.duration >= lastPlayerTime) {
                this.$refs.audio.currentTime = lastPlayerTime;
            }
            this.recordLastPlayerTime('bgmAudio', 'bgplayerTime');
        },
        initBgm() {
            // 每次刷新页面前执行
            jm(window)
                .off('beforeunload.bgMusic')
                .on('beforeunload.bgMusic', () => {
                    // 记录Player最后播放时间
                    this.recordLastPlayerTime('bgmAudio', 'bgplayerTime');
                });
            if (jm.cookie('_bgmIsPause')) {
                var _bgmIsPause = parseInt(jm.cookie('_bgmIsPause'));
                if (_bgmIsPause === 1) {
                    this.bgmPause();
                    this.musicOn = false;
                } else {
                    this.bgmPlay();
                    this.musicOn = true;
                }
            } else if (jm('#bgmAudio').attr('autoplay')) {
                // 手机访问才执行这段代码
                if (VITE_APP_MODE !== 'visitor') {
                    var tmp_bgm_icon_inner = jm('#bgMusic .bgm_icon_inner');
                    jm('body')
                        .off('touchstart.bgm')
                        .on('touchstart.bgm', function (e) {
                            //事件源不是图标，且音乐图标不是暂停状态，则播放音乐
                            // 当点击图标音乐开始时，先触发tocuhstart，此时音乐开始播放，在触发click，音乐就停止，因此要满足事件源不是图标。PS：click是手机系统延迟300ms左右比tocuhstart慢触发的
                            //为什么不用jm('#bgmAudio')[0].paused判断音乐是否停止，是为了避免当图标暂停了，此时paused为true，再次点击body，就会继续播放音乐
                            if (!parseInt(jm.cookie('_bgmIsPause')) && !(e.target === tmp_bgm_icon_inner[0])) {
                                this.bgmPlay();
                            }
                        });

                    //不连续播放则删除该触屏播放事件
                    if (!jm('#bgmAudio').attr('loop')) {
                        jm('#bgmAudio')
                            .off('playing.bgm')
                            .on('playing.bgm', function () {
                                jm('body').off('touchstart.bgm');
                            });
                    }
                }
                this.bgmPlay();
                this.musicOn = true;
            }

            window.onscroll = function () {
                scrollBgmPosition();
            };

            document.addEventListener(
                'play',
                (e) => {
                    const isClickBGM = !!jm(e.target).parents('#bgMusic').length;
                    if (!isClickBGM) {
                        this.bgmPause();
                        jm.cookie('_bgmIsPause', 1, { path: '/' });
                    }
                },
                true
            );
            document.addEventListener(
                'pause',
                (e) => {
                    const isClickBGM = !!jm(e.target).parents('#bgMusic').length;
                    if (!isClickBGM) {
                        if (this.musicOn) {
                            this.bgmPlay();
                            jm.cookie('_bgmIsPause', 0, { path: '/' });
                        }
                    }
                },
                true
            );
        },
    },
};
</script>

<style lang="scss">
.jz_themeV3 .bgm_icon {
    z-index: 10;
}

.bgm_icon_wrap {
    width: 1.25rem;
    height: 1.25rem;
    position: fixed;
    z-index: 1;
}
.bgm_icon,
.bgm_icon_box {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.25rem solid transparent;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);
}

#bgm_icon {
    transition: all 150ms;
}

.bgm_icon_inner {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: url(/image/bgMusic/musicIcon.png?v_20140271750) rgba(0, 0, 0, 0.4) no-repeat;
    background-size: 60%;
    background-position: 0.2rem 0.25rem;
}

.bgm_rightDown,
.bgm_rightUp {
    margin-left: 13.75rem;
}

.bgm_leftDown,
.bgm_leftUp {
    margin-left: 0.5rem;
}

.fk_bgMusic[data-headhidden='true'] {
    .bgm_rightUp,
    .bgm_leftUp {
        top: 10px;
    }
}

.bgm_rightUp,
.bgm_leftUp {
    top: 60px;
}
.bgm_rightDown,
.bgm_leftDown {
    bottom: 60px;
}

.bgm_on {
    background-image: url(/image/bgMusic/musicIcon.png?v_20140271750);
    animation: spin 2s linear infinite;
}

.bgm_off {
    background-image: url(/image/bgMusic/musicIcon.png?v_20140271750);
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
