import request from '@/api/request';
import { preprocessUrl } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
import { clueReuqestVm } from '@jz/biz-shared';

export const autoLoginMemberSign = function (lcid) {
    let sign = jm.cookie('loginMemberSign' + lcid, { path: '/' });
    if (sign != null && sign != 'undefined') {
        request.post('/ajax/member_h.jsp?cmd=autoLoginMember').then(async (result) => {
            const { data = {} } = result;
            if (data.success) {
                var urlParams = new URLSearchParams(window.location.search);
                var returnUrl = urlParams.get('returnUrl');
                await clueReuqestVm.authRecordLoginOther();

                if (returnUrl != null && returnUrl != 'undefined') {
                    changeHref(returnUrl);
                } else {
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                }
            }
        });
    }
};
