<template>
    <module-frame
        :module-id="moduleId"
        :class="{
            has_bottom_fixed: hasRecommendedModule && (isOpen || shareOn),
        }"
    >
        <div :id="'newsNewDetailPanel' + moduleId" class="newsNewDetailPanel newsDetailV3Panel detailPanelV3">
            <news-detail-header />
            <news-detail-content :info="newsInfo" />
        </div>
        <div v-if="isOpen && (allowSubmitMessage || shareOn)" class="newsBottom">
            <div v-if="allowSubmitMessage" id="postComment" class="postComment"
                ><span class="icon-postComm"></span>{{ mobi_productPostCommentTheme3Ls }}</div
            >
            <span
                v-if="shareOn"
                id="newsDetailShare"
                class="icon-share g_iconMiddle g_round newsShare icon-defaultColor"
            ></span>
        </div>
        <comment />
    </module-frame>
</template>
<script>
//////  ModuleNewsDetailTheme3.java 迁移至此。

import { mapState, mapGetters, mapMutations } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import NewsDetailHeader from './component/header.vue';
import NewsDetailContent from './component/content.vue';
import comment from './component/comment.vue';
import { newsDetailMixins } from './mixins';
import { RecommendedNewsListModuleId } from '@/def/module';

export default {
    components: {
        ModuleFrame,
        NewsDetailHeader,
        NewsDetailContent,
        comment,
    },
    mixins: [newsDetailMixins],
    inject: ['moduleId'],
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['LS']),
        ...mapGetters(['getModuleById']),
        pageElementList() {
            //页面元素列表
            return this.content.pageElementList;
        },
        shareOn() {
            // 开启分享
            return this.content.ss == 0;
        },
        isOpen() {
            //是否开通评论
            return this.options.isOpen || false;
        },
        newsInfo() {
            return this.options.newsInfo;
        },
        mobi_productPostCommentTheme3Ls() {
            return this.LS.mobi_productPostCommentTheme3;
        },
        hasRecommendedModule() {
            return this.getModuleById(RecommendedNewsListModuleId) != null;
        },
        // 推荐文章列表模块 & 底部有 fixed 元素
        hasBottomFixed() {
            return this.hasRecommendedModule && (this.isOpen || this.shareOn);
        },
    },
    watch: {
        module: {
            async handler() {
                await Vue.nextTick();
                this.init();
            },
            deep: true,
        },
        currentPageModules() {
            if (VITE_APP_MODE !== 'visitor') {
                this.$nextTick(() => {
                    this.fixPageBottom();
                });
            }
        },
    },
    mounted() {
        this.init();
        this.fixPageBottom();
    },
    methods: {
        ...mapMutations('app', ['setPageBottomFixed']),
        fixPageBottom() {
            // 只要有评论或者或者分享，都应该加一个padding处理fixed元素遮挡
            this.setPageBottomFixed(this.isOpen || this.shareOn);
        },
    },
};
</script>

<style lang="scss">
.formStyle49.form {
    border: inherit;
    margin: 0;
    padding: 0;
    border-radius: inherit;
}
.formStyle49 .titleText {
    left: auto;
    top: auto;
}
.formStyle49 .textContent {
    font-weight: normal;
    color: inherit;
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    background: none;
}
.formStyle49 .formBannerTitle {
    background: inherit;
}
.formStyle45 .formMiddle {
    background: inherit;
}
.formStyle49 .titleCenter {
    border-bottom: inherit;
    text-align: inherit;
    justify-content: inherit;
}
.formStyle49 .icon-titleText {
    display: none;
}
.formStyle49 .titleText::before {
    display: none;
}
.formStyle49 .titleMoreIcon {
    display: none;
}
.formStyle49 .formBannerMore {
    position: absolute;
    top: 0;
    right: 0;
}
.formStyle49 .titleMore {
    color: inherit;
    font-size: inherit;
}
.formStyle49 .textContent:after {
    display: none;
}
#module27.formStyle49:not(.has_bottom_fixed) .formMiddleContent {
    margin-bottom: 0;
}
</style>
