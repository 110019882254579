<template>
    <div>
        <div style="width: 100%; height: 0.5px"></div>
        <div class="notice2">请退出网站管理状态,再进入该页面。</div>
        <div class="memberBasicInfoArea">
            <div class="memberBasicInfo">
                <div class="icon-pnameIcon">
                    <img id="memberHeadPic" :src="siteResRoot + '/image/tx1.png#default'" />
                </div>
                <span class="memberName">123</span>
                <span v-if="memberLevelAuthInfo.allow" class="memberLevelName">{{ memberLvName }}</span>
            </div>
        </div>
        <ul class="modifyArea">
            <li v-if="integralOpen" id="integral">
                <div class="itemName"><span class="icon-integralIcon"></span>{{ LS.integral_mine + itgName }}</div>
                <div class="clickIcon"><span class="icon-clickIcon"></span></div>
            </li>
            <li v-if="isOpenKnowPayment" id="knowPayment">
                <div class="itemName">
                    <svg style="left: 0.5rem">
                        <use xlink:href="#jzm-vicon_mall" /></svg
                    ><span style="margin-left: 0.6rem"></span>{{ LS.minePayKnowProducts }}
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="openCollection" id="collection">
                <div class="itemName"> <span class="icon-starIcon"></span>{{ LS.my_collection }} </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isOpenCoupon" id="coupon">
                <div class="itemName"> <span class="icon-coupons"></span>{{ LS.integral_mine + LS.coupons }} </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isOpenMyForm" id="myForm">
                <div class="itemName">
                    <svg><use xlink:href="#jzm-v28" /></svg><span v-text="LS.mobi_member_myForm"></span>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="memberBulletinOpen" id="latestNews" orderlist="1">
                <div class="itemName">
                    <span class="icon-noticeIcon"></span>{{ LS.memberBulletinTitle }}
                    <div id="bulletinTitle" sessionmid="2" class="bulletinTitle"></div>
                </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isCn && hasPrize && isOpenLottery" id="myreward">
                <div class="itemName"> <span class="icon-pInfoIcon"></span>{{ LS.mobi_member_MyReward }} </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li id="personalInfo">
                <div class="itemName"> <span class="icon-pInfoIcon"></span>{{ LS.mobi_membert_PersonalInfo }} </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li id="password">
                <div class="itemName"> <span class="icon-cPswIcon"></span>{{ LS.mobi_membert_ResetPassword }} </div>
                <div class="clickIcon">
                    <span class="icon-clickIcon"></span>
                </div>
            </li>
            <li v-if="isOtherLogin" id="changeOtherLoginAcct">
                <div class="itemName">
                    <span class="icon-cPswIcon"></span>{{ LS.mobi_membert_ChangeOtherLoginAcct }}
                </div>
                <div class="clickIcon">
                    <span class="icon-bangding"></span>
                </div>
            </li>
        </ul>
        <div class="onLogout"> <span class="icon-cLogoutIcon"></span>{{ LS.mobi_member_onLogout }} </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
export default {
    name: 'JZProfileV2',
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState('app', ['LS', 'lcid']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        renderOptions() {
            return this.module.renderOptions || {};
        },
        memberLvName() {
            return this.renderOptions.memberLvName || '';
        },
        integralOpen() {
            return this.renderOptions.integralOpen || false;
        },
        isOpenKnowPayment() {
            return this.renderOptions.isOpenKnowPayment || false;
        },
        openCollection() {
            return this.renderOptions.openCollection || false;
        },
        isOpenMyForm() {
            return this.renderOptions.isOpenMyForm || false;
        },
        hasPrize() {
            return this.renderOptions.hasPrize || false;
        },
        isOpenLottery() {
            return this.renderOptions.isOpenLottery || false;
        },
        isOtherLogin() {
            return this.renderOptions.isOtherLogin || '';
        },
        siteResRoot() {
            return this.renderOptions.siteResRoot || '';
        },
        memberBulletinOpen() {
            return this.renderOptions.memberBulletinOpen || false;
        },
        itgName() {
            return this.renderOptions.itgName || '';
        },
        isOpenCoupon() {
            return this.renderOptions.isOpenCoupon || false;
        },
        isCn() {
            return this.lcid == 2052;
        },
        memberLevelAuthInfo() {
            return this.renderOptions.memberLevelAuthInfo ?? {};
        },
    },
};
</script>
