// 引用时，必须用Fai.top.Mobi.sortData,这样才能保证所有地方修改的是同一份数据。 因为有的是弹窗调用，js重新load了一遍。
var sortData = {
    width: 0, // 宽
    height: 0, // 高
    stopOffset: { top: 0, left: 0 }, // 停止拖拽后的相对位置
    originalParent: null, // 移动前的父亲
    originalParentId: '', // 移动前的父亲id
    overParent: null, // 占位所在的父亲
    overParentId: '', // 占位所在的父亲id
    cloneHelper: null, // cloneHelper对象
    uiHelper: null, // 正式的helper对象
    receiveId: '', // 接收模块的id
    receiveParent: null, // 接收模块对象
    receive: '', // 标记被那个容器(tab,form)接收
    prevModule: null, // 标记模块的前一个同辈模块，用于相同容器内拖拽时，不改变位置
    from: '', // 标记从哪个容器(tab,form)拖拽出来
    sortCache: {}, // 拖拽产生的cash
};

const globalSortDataApi = {
    setWidth(value) {
        sortData.width = value;
    },
    getWidth() {
        return sortData.width;
    },
    setHeight(value) {
        sortData.height = value;
    },
    getHeight() {
        return sortData.height;
    },
    setStopOffset(value) {
        sortData.stopOffset = value;
    },
    getStopOffset() {
        return sortData.stopOffset;
    },
    setOriginalParent(value) {
        sortData.originalParent = value;
    },
    getOriginalParent() {
        return sortData.originalParent;
    },
    setOverParent(value) {
        sortData.overParent = value;
    },
    getOverParent() {
        return sortData.overParent;
    },
    setOverParentId(value) {
        sortData.overParentId = value;
    },
    getOverParentId() {
        return sortData.overParentId;
    },
    setOriginalParentId(value) {
        sortData.originalParentId = value || '0';
    },
    getOriginalParentId() {
        return sortData.originalParentId;
    },
    setCloneHelper(value) {
        sortData.cloneHelper = value;
    },
    getCloneHelper() {
        return sortData.cloneHelper;
    },
    setUiHelper(value) {
        sortData.cloneHelper = value;
    },
    getUiHelper() {
        return sortData.uiHelper;
    },
    setReceiveId(value) {
        sortData.receiveId = value;
    },
    getReceiveId() {
        return sortData.receiveId;
    },
    setReceiveParent(value) {
        sortData.receiveParent = value;
    },
    getReceiveParent() {
        return sortData.receiveParent;
    },
    setFrom(value) {
        sortData.from = value;
    },
    getFrom() {
        return sortData.from;
    },
    setReceive(value) {
        sortData.receive = value;
    },
    getReceive() {
        return sortData.receive;
    },
    setPrevModule(value) {
        sortData.prevModule = value;
    },
    getPrevModule() {
        return sortData.prevModule;
    },
    getSortHelper() {
        var helper = sortData.cloneHelper || sortData.helper;
        return helper;
    },
    getSortCash() {
        return sortData.sortCache;
    },
    reset() {
        sortData = {
            width: 0, // 宽
            height: 0, // 高
            stopOffset: { top: 0, left: 0 }, // 停止拖拽后的相对位置
            originalParent: null, // 移动前的父亲
            originalParentId: '', // 移动前的父亲id
            overParent: null, // 占位所在的父亲
            overParentId: '',
            cloneHelper: null,
            receiveId: '',
            receiveParent: null,
            receive: '',
            prevModule: null, // 标记模块的前一个同辈模块，用于相同容器内拖拽时，不改变位置
            from: '', // 记录模块来自哪里,
            sortCache: {},
        };
    },
};

export { globalSortDataApi };
