import { fileUpload2 } from '@/shared/fileUpload';
import './quick-in.scss';

/*
 * 快捷入口控件
 * Mobi.QuickIn = {
 *       TYPE    : Object,                          // 入口类型定义，静态数据。例：FU-->fileUpload入口
 *       __data  : Object
 *       {                                          // 控件数据池
 *           count: int,                            // 控件实例计数器
 *           init: boolean,                         // 标志控件是否已经初始化
 *           instances: Object,                     // 实例池
 *           {
 *               key: String,                       // 实例对应的key，暂时没用到
 *               targets: element,                  // 实例绑定事件的元素集
 *               type: int,                         // 实例类型-->Mobi.QuickIn.TYPE.FU or other type
 *               popupWindowOption: Object          // 弹窗相关的参数
 *               {
 *                   settings: Object,              // 调用Fai.popupWindow时传的Options
 *                   closeFunc: Function            // 弹窗关闭时，调用的回调函数，由closeFuncProxy调用，接收两个参数：target(触发事件的元素)，result(弹窗关闭时传回的结果集)
 *               }
 *           }
 *       },
 *       __html  : Object,                          // 入口控件html存储
 *       __func  : Object,                          // 对外函数接口，目前暂时没有用到
 *       __createInstance: Function                 // 创建QuickIn实例函数。接收一个参数：options-->Mobi.QuickIn.__data.instances
 * }
 *
 * 支持动态的增加快捷入口，例如修改图片的快捷入口.
 * 基本原理：根据入口类型，对目标元素集插入快捷入口的html，并且对快捷入口绑定鼠标点击和鼠标移入移出事件，点击快捷入口弹出弹窗，关闭弹窗，执行closeFuncProxy，获取触发入口元素和结果集
 *           再将这两个数据传递给options中的closeFunc执行真正的closeCallback。(加多一层代理的目的是为了解决，有时候关闭弹窗同时要修改对应的元素时，获取元素的问题)
 */
export const QuickIn = {
    TYPE: {
        FU: 1,
    },
    __data: {},
    __html: {},
    __func: {},
    __createInstance: new Function(),
};

function initQuickIn() {
    if (!QuickIn.__data.init) {
        initQuickInData();
    }

    QuickIn.__data.init = true;
}

function initQuickInData() {
    QuickIn.__data.count = 0;
    QuickIn.__data.init = false;
    QuickIn.__data.instances = {};

    QuickIn.__html = "<div class='quick_in' data-id='_$dataId' data-type='_$dataType'></div>";
}

QuickIn.__createInstance = function (options) {
    _initIns(options);
};

function _initIns(options) {
    initInsData(options);
    initInsEvent();
}
function initInsData(options) {
    var insHtml = QuickIn.__html.replace('_$dataId', QuickIn.__data.count).replace('_$dataType', options.type);

    for (var i = 0; i < options.targets.length; i++) {
        var elem = options.targets[i];
        $(elem).append(insHtml);
    }

    QuickIn.__data.instances[QuickIn.__data.count] = options;
}
function initInsEvent() {
    var insOptions = QuickIn.__data.instances[QuickIn.__data.count];
    var closeTarget;

    $(insOptions.targets)
        .find('.quick_in')
        .unbind('click.quickIn')
        .bind('click.quickIn', function () {
            closeTarget = $(this);
            var $icon = $(closeTarget).parents('.thirdGroupImageItem').find('.itemImage');
            switch (insOptions.type) {
                case QuickIn.TYPE.FU:
                    fileUpload2(
                        '',
                        $.extend(
                            {
                                oldIcon: {
                                    filePath: matchFontIconFilePath($icon),
                                    color: $icon.css('color'),
                                },
                            },
                            insOptions.popupWindowOption.settings
                        ),
                        closeFuncProxy
                    );
                    break;
                default:
                    alert('not found quickIn type!');
                    break;
            }

            function matchFontIconFilePath($el) {
                var filePath = '';
                try {
                    const className = $el.attr('class') || '';
                    filePath = className.match(/faisco-icons-\w{7}/gi).pop();
                } catch (err) {
                    return filePath;
                }
                return filePath;
            }

            function closeFuncProxy(result) {
                var data = $.parseJSON(result);
                if (data) {
                    insOptions.popupWindowOption.closeFunc(closeTarget, data.data[0]);
                }
            }
        });

    $(insOptions.targets)
        .off('.quickIn')
        .on('mouseenter.quickIn', function () {
            $(this).find('.quick_in').show();
        })
        .on('mouseleave', function () {
            $(this).find('.quick_in').hide();
        });
}

initQuickIn();
