import { Header } from './header/module';
import { Nav } from './nav/module';
import { Banner } from './banner/module';
import { BackTop } from './backTop/module';
import { BackgroundBox } from './background/module';
import { WebFooter } from './footer/module';
import { FooterNav } from './footerNav/module';
import { BackgroundMusic } from './bgMusic/module';
import { FloatEffect } from './floatEffect/container/module';
import { FloatEffectIcon } from './floatEffect/icon/module';
import { MsgBoard } from './sysCol/msgBoard/module';
import { ProductResult } from './productResult/module';
import { NewsDetailV3 } from './newsDetail/newsDetailV3/visitor-module';
import { NewNewsDetail } from './newsDetail/newNewsDetail/visitor-module';
import { NewsDetail } from './newsDetail/newsDetail/visitor-module';
import { MemberSingUp } from './member/signup/visitor-module';
import { MemberLogin } from './member/login/visitor-module';
import { MemberFindPwd } from './member/findPw/visitor-module';
import { MemberProfile } from './member/profile/visitor-module';
import { MODULE_STYLE } from '@/def/module';
import { productDetail } from './sysCol/productDetail/module';
import { productDetail as productDetailV1 } from './sysCol/productDetail/v1/module';
import { PhotoGroupDetail } from './sysCol/photoGroupDetail/module';
import { ProductGroupDetail } from './sysCol/productGroupDetail/module';
import { PhotoGroupResult } from './sysCol/photoGroupResult/visitor';
import { NewsMore } from './sysCol/newsMore/module';
import { Locater } from './locater/module';
import { CookiePolicy } from './cookiePolicy/module';

/**
 * 访客态公共系统模块类
 */
export const SystemModules = {
    Header,
    Nav,
    Banner,
    BackTop,
    BackgroundBox,
    WebFooter,
    FooterNav,
    BackgroundMusic,
    NewsDetailV3,
    NewNewsDetail,
    NewsDetail,
    ProductResult,
    FloatEffect,
    FloatEffectIcon,
    MemberSingUp,
    MemberLogin,
    MemberFindPwd,
    MemberProfile,
    PhotoGroupResult,
    Locater,
};

/**
 * 访客态公共系统模块组件
 */
const SystemComponents = {};
Object.keys(SystemModules).forEach((Module) => {
    SystemComponents[Module] = SystemModules[Module].render();
});

/**
 * 访客态系统页面才会加载的模块
 */
export const SystemColModules = {
    [MODULE_STYLE.SYS_MSG_BOARD]: MsgBoard,
    [MODULE_STYLE.NEWS_DETAIL_V3]: NewsDetailV3,
    [MODULE_STYLE.NEWS_NEW_DETAIL]: NewNewsDetail,
    [MODULE_STYLE.NEWS_DETAIL]: NewsDetail,
    [MODULE_STYLE.PRODUCT_RESULT]: ProductResult,
    [MODULE_STYLE.MEMBER_SIGNUP]: MemberSingUp,
    [MODULE_STYLE.MEMBER_LOGIN]: MemberLogin,
    [MODULE_STYLE.MEMBER_FIND_PWD]: MemberFindPwd,
    [MODULE_STYLE.PRODUCT_NEW_DETAIL]: productDetail,
    [MODULE_STYLE.PRODUCT_DETAIL]: productDetailV1,
    [MODULE_STYLE.PHOTOGROUP_DETAIL]: PhotoGroupDetail,
    [MODULE_STYLE.MEMBER_PROFILE]: MemberProfile,
    [MODULE_STYLE.SYS_PRODUCT_NEW_GROUP]: ProductGroupDetail,
    [MODULE_STYLE.SYS_PHOTOGROUP_RESULT]: PhotoGroupResult,
    [MODULE_STYLE.NEWS_RESULT]: NewsMore,
    [MODULE_STYLE.SYS_COOKIE]: CookiePolicy,
};

/**
 * 访客态所有系统模块视图组件
 */
export { SystemComponents };
