import request from './request/index';

export const setPopupZone = (options) => {
    return request
        .post('/api/manage/popupZone/setPopupZone', {
            data: {
                ...options,
            },
            params: {
                id: options.id,
                style: 62,
                colId: options.colId,
                extId: options.extId,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getModuleListByStyle = (moduleStyle) => {
    return request
        .get('/api/manage/popupZone/getModuleListByStyle', {
            params: {
                moduleStyle,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getPopupZoneModule = (options) => {
    return request
        .get('/api/manage/popupZone/getPopupZoneModule', {
            params: {
                ...options,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const addPopupZoneModule = (options) => {
    return request
        .post('/api/manage/popupZone/addPopupZoneModule', {
            data: options,
        })
        .then((res) => {
            return res.data;
        });
};

export const delPopupZone = (idList) => {
    return request
        .get('/api/manage/module/delModules', {
            params: {
                idList,
            },
        })
        .then((res) => {
            return res.data;
        });
};
