import { request, postByFormUrlEncoded } from './request';
import { isArray } from '@jz/utils';

export const logDog = (dogId, dogSrc) => {
    return postByFormUrlEncoded({
        url: '/ajax/log_h.jsp?cmd=wafNotCk_dog',
        data: {
            dogId,
            dogSrc,
        },
    })
        .catch(() => {})
        .then(() => {});
};

export const logJZFdp = (event, properties) => {
    request.post('/ajax/log_h.jsp', {
        params: {
            cmd: 'wafNotCk_logJZFdpTrack',
            event: event,
            properties: JSON.stringify(properties),
        },
    });
};

export const logJZFdpList = (list) => {
    if (!isArray(list)) return;
    request
        .post('/ajax/log_h.jsp', {
            data: {
                cmd: 'wafNotCk_logJZFdpListTrack',
                fdpList: JSON.stringify(list),
            },
        })
        .catch((value) => {
            console.log(value);
        });
};

export function logCorpDog(dogId, dogSrc) {
    return request
        .get('/ajax/log_h.jsp?cmd=wafNotCk_logCorpDog&dogId=' + dogId + '&dogSrc=' + dogSrc, {
            params: {},
        })
        .catch(() => {})
        .then(() => {});
}

/**
 * 客户通导流埋点
 * @param {string} event  - 埋点事件
 * @param {Object} props  - 埋点参数
 * @returns
 */
export function logFdpByCustomerEntry(event, props) {
    return request
        .get('/ajax/log_h.jsp', {
            params: {
                cmd: 'wafNotCk_logFdpByCustomerEntry',
                event,
                properties: {
                    ...props,
                    jz_free_text0: '智能名片',
                },
            },
        })
        .catch((err) => {
            console.error(`logGdpByCustomerEntry error: ${err}`);
        });
}
