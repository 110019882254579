import linkSettingMethods from './methods/linkSetting';
import onlineMap from './methods/onlineMap/index';
import lottery from './methods/lottery/index';
import { changePropValue, changeLibValue } from '@/system/productResult/util';
import { onlineServiceClickCallBack } from '@/system/onlineService';
import { PZ } from '@/modules/popupZone/src/index';
import { initJumpWxApp, initWxConfig } from './methods/wx';
import { mobiIng } from '@/shared/fai';
import { logDog, logJZFdp, logCorpDog } from '@/api/logDog';

import { getCommentAjaxPagenation } from '@/modules/shared/product/comment';
import { getCommentAjaxPagenationV3 } from '@/modules/shared/product/productV3';
import { pbCommTableImgDelete } from '@/modules/shared/product/upload';
import { siteFormValidation } from '@/modules/mobiForm/util';
import { floatZone } from '@/system/floatEffect/container/module/floatZone';
import { ValidateCode } from '@/shared/validate-code';
import { disableSafeMode } from './methods/safeMode';
import { isSupportWebp } from '@/shared/imageLazyload';
import { addFontFaceToHead2 } from '@/shared/fontFace';
import { updatePopupThemeModules } from './methods/theme-module';
import { setCtrlStyle } from '@/shared/style';
import { showBookingPanel } from './methods/bookingPanel';
import { getCommentDiv, removeCommentDiv, newsCommentAddCom } from '@/modules/shared/comment';

import { bindOtherAcct } from '@/system/member/method';
import { getNewsCommentAjaxPagenation } from './methods/news';
import { mobiPATipFix } from '@/system/shared/mobiPATipFix';
import { mobiFileDownload } from '@/shared/fixSiteFunc';
import { toggleItemLine } from './methods/util';
import { pdCommentAddCom } from './methods/product';

export function registerMobiVisitorFunction() {
    if (typeof window == 'object') {
        if (window.Mobi == null) window.Mobi = {};
        let Mobi = window.Mobi;
        Object.assign(Mobi, linkSettingMethods);
        Object.assign(Mobi, onlineMap);
        Object.assign(Mobi, lottery);
        Mobi._popupZone = PZ;
        Mobi.changePropValue = changePropValue;
        Mobi.changeLibValue = changeLibValue;
        Mobi.onlineServiceClickCallBack = onlineServiceClickCallBack;
        Mobi.initJumpWxApp = initJumpWxApp;
        Mobi.initWxConfig = initWxConfig;
        Mobi._needFaitest = false;
        Mobi.showSiteTitleBgGloable = {
            showSiteTitleBgFlag: false,
        };

        Mobi.pbCommTableImgDelete = pbCommTableImgDelete;
        Mobi.pdCommentAddCom = pdCommentAddCom;

        // 产品详情评论相关
        Mobi.getCommentAjaxPagenation = getCommentAjaxPagenation;
        Mobi.getCommentAjaxPagenationV3 = getCommentAjaxPagenationV3;

        Mobi.ing = mobiIng;

        Mobi.siteFormValidation = siteFormValidation;
        Mobi.closeFloatZone = floatZone.closeFloatZone;

        Mobi.changeValidateCode = ValidateCode.change;

        Mobi.logDog = logDog;
        Mobi.logJZFdp = logJZFdp;
        Mobi.logCorpDog = logCorpDog;
        Mobi.disableSafeMode = disableSafeMode;
        Mobi.isSupportWebp = isSupportWebp;

        Mobi.addFontFaceToHead2 = addFontFaceToHead2;
        Mobi.updatePopupThemeModules = updatePopupThemeModules;
        Mobi.setCtrlStyle = setCtrlStyle;

        Mobi.showBookingPanel = showBookingPanel;

        Mobi.getCommentDiv = getCommentDiv;
        Mobi.removeCommentDiv = removeCommentDiv;

        Mobi.newsCommentAddCom = newsCommentAddCom;

        Mobi.bindOtherAcct = bindOtherAcct;

        Mobi.getNewsCommentAjaxPagenation = getNewsCommentAjaxPagenation;
        Mobi.mobiPATipFix = mobiPATipFix;

        // 文件下载
        Mobi.fileDownload = mobiFileDownload;
        Mobi.toggleItemLine = toggleItemLine;
    }
}
