import { removeCtrlStyleCssList, setCtrlStyleCssList } from '../style';
import { LAYOUT, TYPE as LAYOUT_TYPE } from '@/shared/nav/types';
const Layout = {
    DRAWER_ALL_COVER: 1,
    // 抽屉-全铺
    DRAWER_PART_COVER: 2,
    // 抽屉-半铺
    DRAWER_LEFT: 3,
    // 抽屉-左展开
    DRAWER_RIGHT: 4,
    // 抽屉-右展开
    SUSPENSION_ALL_COVER: 5,
    // 悬浮-全铺展开
    LABEL_SLIDE: 6,
    // 标签-水平滑动
    LABEL_PANEL: 7,
    // 标签-下拉面板
    SUSPENSION_LEFT: 8,
    // 悬浮 左展开
    SUSPENSION_BOX: 9,
    // 悬浮 -小抽屉
    DRAWER_PART_COVER_SLIDE: 10, // 抽屉-横向平铺
}; // 加了布局也要相应维护这里

export const closeNavV3 = function (layoutId) {
    var $navButton = jm('#navButton'),
        $webHeaderBox = jm('#webHeader_v3'),
        $mask = jm('#main_nav_mask');
    jm('#navbar').removeClass('open');
    jm('#navExistOffPanel').removeClass('navPanelOpen');
    $navButton.removeClass('navButtonOpen');
    removeClass('jz_navOpen');
    removeClass('moveRight');
    jm('#webTopBox').removeClass('jz_navWebTopOpen');
    $mask.hide();

    function removeClass(className) {
        jm('#webBannerBox').removeClass(className);
        jm('#webContainerBox').removeClass(className);
        jm('#customerServiceDiv').removeClass(className);
        jm('#webFooter').removeClass(className);
        jm('#bgm_icon').removeClass(className);
        $webHeaderBox.removeClass(className);
        jm('#J_footer_nav').removeClass(className);
        jm('#webFooter_v3').removeClass(className);
        jm('#web_header_fixed_box').removeClass(className);
    }

    if (!$webHeaderBox.hasClass('J_header_is_hide')) {
        if (layoutId == Layout.DRAWER_ALL_COVER || layoutId == Layout.DRAWER_PART_COVER) {
            $navButton.prependTo($webHeaderBox.find('.J_headerFixBox'));
        }
    }
};

export const setAdvanceStyleList = function (css) {
    if (Fai.top._uiMode && Fai.top._reqNavSkinId > -1) {
        assembleCls('.jz_themeV3', css);
        setUIAdvanceStyleList(css);
    } else {
        setCtrlStyleCssList('mobiStyleFullSiteModule', 'g_body', css);
    }
};

function assembleCls(preFixClass, css) {
    for (var i = 0; i < css.length; i++) {
        css[i].cls = preFixClass + ' ' + css[i].cls;
    }
}

export const setUIAdvanceStyleList = function (css) {
    setCtrlStyleCssList('jzNavSkinFrame', '', css);
};

export const removeAdvanceStyleList = function (css) {
    if (Fai.top._uiMode && Fai.top._reqNavSkinId > -1) {
        assembleCls('.jz_themeV3', css);
        removeUIAdvanceStyleList(css);
    } else {
        removeCtrlStyleCssList('mobiStyleFullSiteModule', 'g_body', css);
    }
};

export const removeUIAdvanceStyleList = function (css) {
    removeCtrlStyleCssList('jzNavSkinFrame', '', css);
};

export const removeFooterNavStyleList = function (css) {
    var uiDesignMode = Fai.top._uiMode && Fai.top._reqFooterNavSkinId > -1;

    if (!uiDesignMode) {
        removeCtrlStyleCssList('footerNavStyle', 'g_body', css);
    } else {
        assembleCls('.jz_themeV3', css);
        removeCtrlStyleCssList('jzFooterNavSkinFrame', '', css);
    }
};

export const setFooterNavStyleList = function (css) {
    var uiDesignMode = Fai.top._uiMode && Fai.top._reqFooterNavSkinId > -1;

    if (!uiDesignMode) {
        setCtrlStyleCssList('footerNavStyle', 'g_body', css);
    } else {
        assembleCls('.jz_themeV3', css);
        setCtrlStyleCssList('jzFooterNavSkinFrame', '', css);
    }
};

export function autoSetNavBarBottom(layoutId) {
    var $navBar = jm('#navbar'),
        tmpHeight;
    if (layoutId == 4) {
        if (Fai.top._openOnlineService) {
            jm('#navButton').css(
                'bottom',
                (parseInt(jm('#customerServiceDiv').height()) + 15) / window._htmlFontSize + 'rem'
            );
            $navBar.css('bottom', (parseInt(jm('#customerServiceDiv').height()) + 35) / window._htmlFontSize + 'rem');
        } else {
            // 这里不应该把高度也清掉的~，先这样处理
            if (/height/.test($navBar.attr('style'))) {
                tmpHeight = $navBar.height();
                $navBar.removeAttr('style');
                $navBar.css('height', tmpHeight + 'px');
            } else {
                $navBar.removeAttr('style');
            }
            jm('#navButton').removeAttr('style');
        }
    } else {
        jm('#navButton').removeAttr('style');
        $navBar.removeAttr('style');
    }
}

export function getMainNavLayoutId() {
    if (VITE_APP_MODE !== 'visitor') {
        return window.$store.state.nav.skinPattern.layout;
    } else {
        return jm('#navbar').attr('layout');
    }
}

export function scrollNavFix(headerStyle) {
    var navbar = document.getElementById('navbar'),
        g_body = document.getElementById('g_body'),
        beforeScrollTop = g_body.scrollTop,
        webHeader_v3 = jm('#webHeader_v3'),
        webTipHeight = jm('.J_marqueeContainer').length ? parseInt(jm('.J_marqueeContainer').height()) : 0,
        tipsLimitHeight = jm('.fk-mobiTips').length ? parseInt(jm('.fk-mobiTips').height()) : 0,
        headerHeight = parseInt(webHeader_v3.height()) || 0;

    const scrollEl = jm('#g_web');

    scrollEl.off('touchstart.nav').on(
        'touchstart.nav',
        function () {
            navScroll();
        },
        false
    );
    scrollEl.off('touchmove.nav').on(
        'touchmove.nav',
        function () {
            navScroll();
        },
        false
    );
    scrollEl.off('touchend.nav').on(
        'touchend.nav',
        function () {
            navScroll();
        },
        false
    );
    scrollEl.off('touchcancel.nav').on(
        'touchcancel.nav',
        function () {
            navScroll();
        },
        false
    );
    scrollEl.off('scroll.nav').on(
        'scroll.nav',
        function () {
            navScroll();
        },
        false
    );

    function navScroll() {
        if (Fai.top._openThemeV3) {
            navScrollV3();
        } else {
            navScrollV1();
        }
    }

    function navScrollV1() {
        var scrollTop = jm('#g_web')[0].scrollTop,
            webContainerToBrowserTop = document.getElementById('webContainerBox').offsetTop - scrollTop; //webContainer到浏览器顶部的距离

        if (scrollTop > beforeScrollTop) {
            //向下滚动时
            if (webContainerToBrowserTop < 0) {
                jm(navbar).addClass('scroll_fix');
            }
        } else {
            //向上滚动时
            if (webContainerToBrowserTop >= 0) {
                jm(navbar).removeClass('scroll_fix');
            }
        }

        beforeScrollTop = scrollTop;
    }

    function navScrollV3() {
        const manageMode = VITE_APP_MODE !== 'visitor';
        var scrollTop = jm('#g_web')[0].scrollTop,
            webContainerToBrowserTop = document.getElementById('webContainerBox').offsetTop - scrollTop, //webContainer到浏览器顶部的距离
            $navbar = jm(navbar),
            hasHeader = webHeader_v3.css('display') != 'none',
            tipsHeight = parseInt(webTipHeight + tipsLimitHeight),
            hasWebTip = tipsHeight > 0,
            navbarPosTop = 0;
        if (manageMode) {
            headerStyle = Fai.top._pattern.hs.styleId || Fai.top._defaultPattern.hs.styleId || 4;
            headerHeight = parseInt(webHeader_v3.height()) || 0;
        } else {
            headerStyle = headerStyle || 4;
        }
        if (scrollTop > beforeScrollTop) {
            //向下滚动时
            if (webContainerToBrowserTop < 0) {
                if (
                    webHeader_v3.length > 0 &&
                    hasHeader &&
                    (headerStyle == 1 || headerStyle == 2 || headerStyle == 3 || headerStyle == 4)
                ) {
                    navbarPosTop += headerHeight;
                }

                if (hasWebTip && !manageMode) {
                    navbarPosTop += tipsHeight;
                }

                $navbar.addClass('scroll_fix');
            }
        } else {
            //向上滚动时
            if (webContainerToBrowserTop >= 0) {
                $navbar.removeClass('scroll_fix');
                navbarPosTop = 0;
            } else {
                if (
                    webHeader_v3.length > 0 &&
                    hasHeader &&
                    (headerStyle == 1 || headerStyle == 2 || headerStyle == 3 || headerStyle == 4)
                ) {
                    navbarPosTop += headerHeight;
                }

                if (hasWebTip && !manageMode) {
                    navbarPosTop += tipsHeight;
                }
            }
        }

        let navbarPosLeft = 0;
        if (window.innerWidth > $navbar.width()) {
            if (window.innerHeight - $navbar.height() > webContainerToBrowserTop) {
                navbarPosLeft = (window.innerWidth - parseInt($navbar.width())) / 2;
            } else {
                navbarPosLeft = 0;
            }
        }

        if (hasHeader || hasWebTip) {
            $navbar.css({
                top: navbarPosTop + 'px',
                left: navbarPosLeft + 'px',
            });
        }

        beforeScrollTop = scrollTop;
    }
}

export function getV3NameSpaceClass(pattern) {
    var layout = pattern.layout,
        type = pattern.type,
        typeDef = LAYOUT_TYPE,
        layoutDef = LAYOUT,
        classes = '',
        className = '';

    switch (type) {
        case typeDef.DRAWER:
            className += ' jz_nav-drawer';
            break;
        case typeDef.LABEL:
            className += ' jz_nav-label';
            break;
        case typeDef.SUSPENSION:
            className += ' jz_nav-suspension';
            break;
    }

    switch (layout) {
        case layoutDef.DRAWER_ALL_COVER:
            className += ' jz_nav-drawer-cover jz_nav-drawer-allCover';
            break;
        case layoutDef.DRAWER_PART_COVER:
            className += ' jz_nav-drawer-cover jz_nav-drawer-halfCover';
            break;
        case layoutDef.DRAWER_LEFT:
            className += ' jz_nav-drawer-left';
            break;
        case layoutDef.DRAWER_RIGHT:
            className += ' jz_nav-drawer-right';
            break;
        case layoutDef.SUSPENSION_ALL_COVER:
            className += ' jz_nav-suspension-allCover';
            break;
        case layoutDef.LABEL_SLIDE:
            className += ' jz_nav-label-slide';
            break;
        case layoutDef.LABEL_PANEL:
            className += ' jz_nav-label-panel';
            break;
        case layoutDef.SUSPENSION_LEFT:
            className += ' jz_nav-suspension-left';
            break;
        case layoutDef.SUSPENSION_BOX:
            className += ' jz_nav-suspension-box';
            break;
        case layoutDef.DRAWER_PART_COVER_SLIDE:
            className += ' jz_nav-drawer-partCoverSlide';
            break;
    }

    classes +=
        'jz_nav-drawer jz_nav-label jz_nav-suspension jz_nav-drawer-cover jz_nav-drawer-allCover jz_nav-drawer-halfCover ';
    classes +=
        'jz_nav-drawer-left jz_nav-drawer-right jz_nav-suspension-allCover jz_nav-label-slide jz_nav-label-panel ';
    classes += 'jz_nav-suspension-left jz_nav-suspension-box jz_nav-drawer-partCoverSlide ';
    return {
        classes,
        className,
    };
}
