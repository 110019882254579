import { getPreviewObject } from './getPreviewObject';

export function autoFixGWebTop(layoutId) {
    if (Fai.top._openThemeV3) {
        // 修正文章详情顶部固定情况下的高度
        let tipsEl = jm('.fk-mobiTips');
        let webTipsEl = jm('#webTips');
        let height = 0;
        if (tipsEl.length) {
            height += parseInt(tipsEl.height());
        }
        if (webTipsEl.length) {
            height += parseInt(webTipsEl.height());
        }
        // 处理文章详情
        if (Fai.top._colId === 28) {
            jm('.newsDetailV3Panel .productDetailHeader').css('top', height + 'px'); // 头部固定定位下移
        }

        // 纠正 g_web 的max-height
        let $onlyShare = jm('.only_share');
        let $gWeb = jm('#g_web');

        if ($onlyShare.length) {
            height += parseInt($onlyShare.height());
            $gWeb.css('max-height', `calc(100% - ${height}px)`);
        }
        return;
    }

    if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000) {
        jm('#fk-gWebPlaceholdPT').remove();
        return;
    }

    if (Fai.top._pageId == 21) {
        // 文章详情不需要
        return;
    }
    // 文章详情部分1.0主题要高度占位
    if (Fai.top._pageId === 28) {
        // 先移除再添加，避免切换主题时残留高度
        jm('#fk-gWebPlaceholdPT').remove();
        if (![1014, 1010, 1009, 1008, 1007, 1006, 1003].includes(Fai.top._templateFrameId)) {
            return;
        }
    }

    var web_top = getWebTop(),
        g_web = getPreviewObject('g_web'),
        webTop = getPreviewObject('webTop'),
        webHeaderBox = getPreviewObject('webHeaderBox');
    webHeaderBox.style.top = '';
    webTop.style.top = '';
    if (jm('#fk-tipsLimitUv').length != 0) {
        web_top = web_top + parseInt(jm('#fk-tipsLimitUv').height());
    }
    if (layoutId !== 2 && layoutId !== 7 && layoutId !== 8) {
        jm('#fk-gWebPlaceholdPT').remove();
        return;
    }

    //bug：layout为7的主题隐藏网站标题栏后g_web会出现此方法创建的空白padding，这里临时修补一下，待优化
    if (layoutId == 7) {
        var webHeader = jm('#webHeader'),
            navbar = getPreviewObject('navbar');
        if (web_top > 0) {
            navbar.style.top = web_top + 'px';
        }
        if (webHeader.css('display') === 'none') {
            return;
        }
    }

    webHeaderBox.style.top = web_top + 'px';
    webTop.style.top = web_top + 'px';
    //原来时候不管怎样都会出现滚动条，20160114 1426
    //g_web.style.paddingTop = (webHeaderBox.offsetHeight + web_top) + "px";
    var placeholdPT = getPreviewObject('fk-gWebPlaceholdPT');

    var headerH = getPreviewObject('webHeader');
    if (placeholdPT) {
        placeholdPT.style.height = headerH.offsetHeight + 'px';
    } else {
        placeholdPT = jm("<div id='fk-gWebPlaceholdPT' style='height:" + headerH.offsetHeight + "px;'></div>");
        jm(g_web).prepend(placeholdPT);
    }
}

function getWebTop() {
    var g_web_Top = 0,
        mobiTipsMarquee = document.getElementById('mobiTipsMarquee');

    if (!mobiTipsMarquee) {
        return g_web_Top;
    } else {
        var webTipsViewHeight = document.getElementById('webTips').offsetHeight;
        g_web_Top = webTipsViewHeight;
        return g_web_Top;
    }
}
