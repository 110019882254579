import { ing } from './fai';

export function fileDownloadCdnLimitHandler() {
    if (global.Vue.prototype.$isServer) return;

    if (window.isJzDownloadCdnFlowLimit === true && !window._oem) {
        const blogUrl = `//${window.Hosts.jzHome}/blog/1247639`;
        // 提示
        if (window._manageMode === true) {
            ing(
                `您的文件下载流量过大，导致下载速度缓慢，<a href="${blogUrl}" style="text-decoration: underline; color:red;" target="_blank">详情可了解>></a>`
            );
        } else {
            Mobi.ing('网站文件下载速度缓慢，请联系网站管理员。');
        }
        return true;
    }
    return false;
}
