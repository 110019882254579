import { isNewsDetailCol, isProductDetailCol } from '@/def/col';
import { getRouterData } from '@/features/router/universal';

/**
 * @description 首屏请求，文章详情页需要传入_nnd
 * @author json
 * @date 02/08/2021
 * @export
 * @param {*} [params={}]
 */
export function getRouterDataForFirstPaint(params = {}) {
    const id = params.colId;
    const isNewsDetail = isNewsDetailCol(id);
    const isProductDetail = isProductDetailCol(id);
    if (isNewsDetail || isProductDetail) {
        let { _nnd, _npd } = getRouterData(id);
        typeof _nnd != 'undefined' && (params._nnd = _nnd);
        typeof _npd != 'undefined' && (params._npd = _npd);
    }
}

/**
 * @description 通过首屏接口重新获取页面所需数据
 * @param data 首屏接口数据
 */

export const refreshPageWindowData = (data) => {
    window._templateFrameId = data._templateFrameId;
    window.independentBackGroundSet = data.independentBackGroundSet;
    window.isKnowPayProductDetailPage = data.isKnowPayProductDetailPage;
    window.independentBannerSet = data.independentBannerSet;
    window.independentBannerShow = data.independentBannerShow;
    window.independentSetFooterBar = data.independentSetFooterBar;
    window.independentSetNav = data.independentSetNav;
    window._independentNavHide = data._independentNavHide;
    window.footerNavIndepentSet = data.footerNavIndepentSet;
    window.footerNavIndepentSetHide = data.footerNavIndepentSetHide;
    window.independentSetFooter = data.independentSetFooter;
    window.independentSetFooterHide = data.independentSetFooterHide;
    window.isDisabledFloatEffectPage = data.isDisabledFloatEffectPage;
    window.isKnowPayProductDetailPage = data.isKnowPayProductDetailPage;
    window._locaterList = data._locaterList;
    window.topBarDrainImg = data.topBarDrainImg;
    window.topBarUpdateList = data.topBarUpdateList;
    // window._rapidMobiPanelData = data._rapidMobiPanelData || {};
    window._footerNavSkinList = data._footerNavSkinList;
    window._footerNavSkinPattern = data._footerNavSkinPattern;
    window._footerNavDefaultData = data._footerNavDefaultData;
    window._pattern = data._pattern;
    window._defaultPattern = data._defaultPattern;
    window.newUserGuide = data.newUserGuide;
    window._pageBannerData = data._pageBannerParam;
    window._templateBannerData = data._templateBannerData;
    window._freeVisitorHost = data._freeVisitorHost;
    window._changeStyleNum = 0;
    window._getVipVipStartTime = data._getVipVipStartTime;
    window._backgroundInfo = data._backgroundInfo;
    window._singleModuleSkinDefaultData = data._singleModuleSkinDefaultData;
    window._cubeNavDefList = data._cubeNavDefList;
    window.salemanInfo = data.salemanInfo;
    window.navDesinger = data.navDesinger;
    window._navSkinList = data._navSkinList;
    window.imagePageDefList = data.imagePageDefList;
    window._columnStyleJson = data._columnStyleJson || data._colInfo.columnStyle;
    window.isWXAddFansAuthAllow = data.isWXAddFansAuthAllow;
    window.seoMobiData = data.seoMobiData;
    window.domainBind = data.domainBind;
    window._openMobiRapid = data._openMobiRapid;
    window.wxAddFansUpdateUrl = data.wxAddFansUpdateUrl;
    window._adm = data._adm;
    window._showFirstOpenRapid = data._showFirstOpenRapid;
    window._isOpenRapid = data._isOpenRapid;
    window.mobi_cloneAid = data.mobi_cloneAid;
    window.isPayOverSeven = data.isPayOverSeven;
    window.openHd = data.openHd;
    window.showPayFormFlowModule = data.showPayFormFlowModule;
    window._openMsgSearch = data._openMsgSearch;
    window._colInfo = data._colInfo;
    window._extId = data._colInfo.extId;
    window.independentSetHead = data.independentSetHead;
    window.independentSetHeadHide = data.independentSetHeadHide;

    window.isFloatEffectAllow = data.isFloatEffectAllow;
    window.isIndependentShowFloatEffectType = data.isIndependentShowFloatEffectType;
    window.isIndependentShowFloatEffect = data.isIndependentShowFloatEffect;
    window.isShowFloatEffect = data.isShowFloatEffect;

    window._allProductCount = data._allProductCount;
    window._allNewsCount = data._allNewsCount;
    window.fullPageNavHide = data.fullPageNavHide;
    window._formList = data._formList;

    window.$store.commit('panelSetting/independentSetFooterNavDataChange', Fai.top._colInfo.other.in.isfn);
    window.$store.commit('panelSetting/independentSetHeadChange', window.independentSetHead);
    window.$store.commit('panelSetting/independentSetHeadHideChange', window.independentSetHeadHide);
};

/**
 * 文章和产品的自定义链接处理，需要把产品ID 或 文章ID返回
 */
export function getNewsOrProductIdQuery(el) {
    if ($(el).attr('newsid')) {
        return {
            newsId: parseInt($(el).attr('newsid'), 10),
        };
    }
    if ($(el).attr('productid')) {
        const id = parseInt($(el).attr('productid'), 10);
        return {
            productId: id,
            newProductId: id,
        };
    }

    const $newsLineParent = $(el).parents('.newsLine').eq(0);
    const $productItemParent = $(el).parents('.J_product_item').eq(0);

    if ($newsLineParent.length === 1) {
        return {
            newsId: parseInt($newsLineParent.attr('newsid'), 10),
        };
    } else if ($productItemParent.length === 1) {
        const id = parseInt($productItemParent.attr('productid'), 10);
        return {
            productId: id,
            newProductId: id,
        };
    }

    return {
        productId: undefined,
        newProductId: undefined,
        newsId: undefined,
    };
}
