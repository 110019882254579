<template>
    <component :is="FooterNav" v-if="isShowFooterNav" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { COL } from '@/def/col';
export default {
    name: 'FooterNavVisitor',
    data() {
        return {};
    },
    computed: {
        ...mapGetters('app', ['isDependentCol']),
        ...mapState('app', ['openThemeV3', 'colId', 'templateDesignType']),
        FooterNav() {
            return this.openThemeV3 ? () => import('./v3/index.vue') : () => import('./v2/index.vue');
        },
        isShowFooterNav() {
            return this.openThemeV3
                ? true
                : !this.isDependentCol || (this.colId == COL.SYS_PRODUCT_NEW_GROUP && this.templateDesignType != 2);
        },
    },
};
</script>
<style lang="scss">
@import './index.scss';
</style>
