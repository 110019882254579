import { isObject, isArray } from '@jz/utils';

import { request } from '@/api/request';
class jzFdpLogIns {
    constructor() {}
    static logFdpAfterSave() {
        let saveList = [].concat(this.fdpList);
        Object.keys(this.fdpMap).forEach((item) => {
            saveList.push(this.fdpMap[item]);
        });
        if (saveList.length) {
            jzFdpLogIns.fdpRequest(saveList);
            this.fdpList = [];
            this.fdpMap = {};
        }
    }

    static addFdpLog(option) {
        const { event, properties } = option;
        const isPropertyObject = isObject(properties);
        const isEventNameString = typeof event === 'string';
        if (isPropertyObject && isEventNameString) {
            this.fdpList.push({
                event,
                properties,
            });
        }
    }

    static addRepeatFdpLog(option) {
        const { sign, event, properties } = option;
        const isPropertyObject = isObject(properties);
        const isEventNameString = typeof event === 'string';
        if (isPropertyObject && isEventNameString) {
            this.fdpMap[sign] = {
                event,
                properties,
            };
        }
    }

    static getRepeatFdpLog() {
        return this.fdpMap;
    }

    static getNormalFdpLog() {
        return this.fdpList;
    }

    static logJzFdp(event, properties) {
        let ajax = null;
        ajax = VITE_APP_MODE !== 'visitor' ? $.ajax : jm.ajax;
        if (ajax) {
            ajax({
                type: 'POST',
                url: '/ajax/log_h.jsp',
                data: `cmd=wafNotCk_logJZFdpTrack&event=${event}&properties=${encodeURIComponent(
                    JSON.stringify(properties)
                )}`,
            });
        }
    }

    static pushFdpRequest(item) {
        if (isObject(item)) {
            this.requestList.push(item);
        }
    }

    static clearTimeout() {
        clearTimeout(this.requestTimer);
        this.requestTimer = null;
    }

    static clearRequestData() {
        this.requestList.length = 0;
    }

    static fdpRequest(list) {
        if (!isArray(list)) return;
        request
            .post('/ajax/log_h.jsp', {
                data: {
                    cmd: 'wafNotCk_logJZFdpListTrack',
                    fdpList: JSON.stringify(list),
                },
            })
            .catch((value) => {
                console.log(value);
            });
    }

    static logFdpRequest(event, properties, listLength = 10, delay = 3000) {
        if (jzFdpLogIns.requestList.length < listLength) {
            jzFdpLogIns.pushFdpRequest({
                event,
                properties,
            });
            if (jzFdpLogIns.requestTimer) return;
            jzFdpLogIns.requestTimer = setTimeout(() => {
                jzFdpLogIns.fdpRequest(jzFdpLogIns.requestList);
                jzFdpLogIns.clearRequestData();
                jzFdpLogIns.clearTimeout();
            }, delay);
        } else {
            jzFdpLogIns.requestTimer && jzFdpLogIns.clearTimeout();
            jzFdpLogIns.fdpRequest(jzFdpLogIns.requestList);
            jzFdpLogIns.clearRequestData();
            jzFdpLogIns.pushFdpRequest({
                event,
                properties,
            });
            return;
        }
    }
}

jzFdpLogIns.requestTimer = null;
jzFdpLogIns.requestList = [];

jzFdpLogIns.fdpList = []; // 存储可重复的 fdpLog
jzFdpLogIns.fdpMap = {}; // 存储不可重复的 fdpLog

export default jzFdpLogIns;
