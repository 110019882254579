<template>
    <find-pwd :module-id="moduleId"></find-pwd>
</template>
<script>
import FindPwd from './module/index.vue';
export default {
    name: 'FindPwdVisitor',
    components: {
        FindPwd,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
};
</script>
