<template>
    <div :class="className">右上角“管理分类”可添加分类</div>
</template>

<script>
export default {
    name: 'ProductGroupDetailAddBtn',
    props: {
        horizonal: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            return this.horizonal ? 'fk-addGroupTip3' : 'fk-addGroupTip2';
        },
    },
};
</script>

<style></style>
