import request from './request';

export function getPhotoGroupList(params = {}) {
    return request
        .get('/api/manage/photo/getPhotoGroupList', {
            params,
        })
        .catch(function () {
            Fai.ing('系统错误', false);
        });
}

export function getPhotoGroupData(params = {}) {
    return request.get(`/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/photo/getPhotoGroupData`, {
        params,
    });
}

export function getMultiPhotoListData(data = {}) {
    return request.post('/ajax/module_h.jsp?cmd=getWafNotCk_getMultiPhotoListData', {
        data,
    });
}
export function getPhotoListData(params = {}) {
    return request.get(`/api/${VITE_APP_MODE !== 'visitor' ? 'manage' : 'guest'}/photo/getPhotoListData`, {
        params,
    });
}

export function getPhotoInfo(params = {}) {
    return request.get('/api/manage/photo/getPhotoDetailDesription', {
        params,
    });
}
