const HistoryStorage = {
    KEY: 'manage_history',
    addHistory(item) {
        let histories = this.getHistories();
        if (!histories) {
            histories = [item];
        } else {
            histories.push(item);
        }
        window.sessionStorage.setItem(this.KEY, this.serialize(histories));
    },
    delHistory() {
        let histories = this.getHistories();
        histories.pop();
        window.sessionStorage.setItem(this.KEY, this.serialize(histories));
    },
    getHistories() {
        return this.deSerialize(window.sessionStorage.getItem(this.KEY));
    },
    serialize(history = []) {
        return JSON.stringify(history);
    },
    deSerialize(historyWithSerialize = '') {
        return JSON.parse(historyWithSerialize) || [];
    },
};

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    query: {},
    history: VITE_APP_MODE !== 'visitor' ? HistoryStorage.getHistories() : [],
});

/**
 * =================================================
 *
 * Getters
 *
 * =================================================
 */
const getters = {
    historyLength(state) {
        return state.history.length;
    },
};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    updateQuery({ commit }, query) {
        commit('updateQuery', query);
    },
    addHistory({ commit }, item) {
        commit('addHistory', item);
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    updateQuery(state, query) {
        state.query = query;
    },
    addHistory(state, item) {
        state.history.push(item);
        HistoryStorage.addHistory(item);
    },
    delHistory(state) {
        state.history.pop();
        HistoryStorage.delHistory();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
