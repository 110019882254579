/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

export const SET_FORM_DETAIL = 'setFormDetail';
export const EDIT_FORM = 'editFormDetail';
export const RESET_FORM_EDIT = 'resetFormEdit';

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    formDetails: {}, // <formId, { submitContentList: [],... }>
    editingFormId: -1,
    editingSubmitResultId: -1,
    openMemberModifySubmit: false,
});
/**
 * =================================================
 *
 * getters
 *
 * =================================================
 */
const getters = {};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [SET_FORM_DETAIL](state, { id, detail }) {
        state.formDetails = {
            ...state.formDetails,
            [id]: detail,
        };
    },
    [EDIT_FORM](state, { formId, submitResultId, openMemberModifySubmit = false }) {
        state.editingFormId = formId;
        state.editingSubmitResultId = submitResultId;
        state.openMemberModifySubmit = openMemberModifySubmit;
    },
    [RESET_FORM_EDIT](state) {
        state.editingFormId = -1;
        state.editingSubmitResultId = -1;
        state.openMemberModifySubmit = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
