export function bgmPlay() {
    var bgm_icon_inner = jm('#bgMusic .bgm_icon_inner');
    var tmpAudio = document.getElementById('bgmAudio');
    if (tmpAudio && tmpAudio.getAttribute('src') !== '') {
        let playPromise = tmpAudio.play();
        if (playPromise != null) {
            playPromise
                .then(() => {})
                .catch((err) => {
                    console.log(err);
                });
        }
    }
    bgm_icon_inner.addClass('bgm_on');
    // tmpAudio.click();
}

export function bgmPause() {
    var bgm_icon_inner = jm('#bgMusic .bgm_icon_inner');
    var tmpAudio = document.getElementById('bgmAudio');
    tmpAudio?.pause();
    bgm_icon_inner.removeClass('bgm_on');
}

export const MobiBgMusicSettingDef = {
    ISAUTO: 0,
    ISLOOP: 0,
    ISHIDDEN: 0,
    POSITION: 0,
};

export function bgmEditOk(context, mobiBgMusic, bgMusicEditSetting) {
    context.$store.commit('updateMobiBgMusic', mobiBgMusic);
    bgMusicEditSetting.visible = false;
    context.$designer.styleChanged();
    context.$forceUpdate();
    //自动播放音乐
    if (mobiBgMusic.setting.isAuto === MobiBgMusicSettingDef.ISAUTO) {
        //如果是默认播放，就进行自动播放
        bgmPlay();
    } else {
        bgmPause();
    }
}
