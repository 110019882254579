import { context } from '@jz/utils';
import { isNull, startInterval, stopInterval } from '@/shared/fai';
import { logDog } from '../log';

export const bg = (id, opacity, zIndex, extClass = '', needScroll) => {
    try {
        // url带参_noCover, 支持代建网站去掉弹窗的灰度遮罩层
        if (context._noCover == 1) {
            logDog(200106, 1);
            return;
        }
    } catch (e) {
        console.log(e);
    }
    var html = '',
        opacityHtml = '',
        zIndexHtml = '';
    //背景遮罩一弹的时候0.5  二弹以上的都是0.15
    // J_popup_bg可以给其他地方的遮罩设置，用于解决遮罩重叠时的颜色加深问题
    if (context.$('.popupBg').length > 0 || context.$('.J_popup_bg').length > 0) {
        opacity = 0.15;
    }
    if (opacity) {
        opacityHtml = 'filter: alpha(opacity=' + opacity * 100 + '); opacity:' + opacity + ';';
    }
    if (zIndex) {
        zIndexHtml = ' z-index:' + (zIndex - 1);
    }

    //加上popupBgForWin为了将z-index提到9032  避免直接修改popupBg影响其他使用了popupBg的地方
    html =
        '<div id="popupBg' +
        id +
        '" class="J_popup_bg popupBg popupBgForWin ' +
        extClass +
        '" style=\'' +
        opacityHtml +
        zIndexHtml +
        "' onclick='Fai.logDog(200046, 40);'>" +
        +'</div>';

    context.$(html).appendTo('body');
    //__noGmain
    !needScroll && context.$('html').addClass('popupStopScroll' + id);
    if (
        context._isTemplateVersion2 &&
        context._useBannerVersionTwo &&
        !context._bannerData.h &&
        typeof Site != 'undefined' &&
        typeof context._bannerV2Data != 'undefined' &&
        context._bannerV2Data.bl &&
        context._bannerV2Data.bl.length > 1
    ) {
        Site.bannerV2.stopBannerInterval();
    }
    stopInterval(null);
};

export const removeBg = (id) => {
    if (id) {
        context.$('#popupBg' + id).remove();
        //__noGmain
        context.$('html').removeClass('popupStopScroll' + id);
    } else {
        context.$('.popupBg').not('.popupZoneDelOnlyById').last().remove();
    }
    if (
        context._isTemplateVersion2 &&
        context._useBannerVersionTwo &&
        !context._bannerData.h &&
        typeof Site != 'undefined' &&
        typeof context._bannerV2Data != 'undefined' &&
        context._bannerV2Data.bl &&
        context._bannerV2Data.bl.length > 1
    ) {
        Site.bannerV2.startBannerInterval();
    }
    startInterval(null);
};

export const removeBodyBg = (id) => {
    if (id) {
        $('#popupBg' + id).remove();
        //__noGmain
        context.$('html').removeClass('popupStopScroll' + id);
    } else {
        $('.popupBg').not('.popupZoneDelOnlyById').last().remove();
    }
};

export const bodyBg = function (id, opacity, options) {
    // url带参_noCover, 支持代建网站去掉弹窗的灰度遮罩层
    if (context._noCover == 1) {
        return;
    }
    var html = '',
        opacityHtml = '',
        opt = options || {},
        extClass = opt.extClass || '';

    if (opacity) {
        opacityHtml = 'filter: alpha(opacity=' + opacity * 100 + '); opacity:' + opacity + ';';
    }

    html = '<div id="popupBg' + id + '" class="popupBg ' + extClass + '" style=\'' + opacityHtml + "' >" + +'</div>';
    $(html).appendTo('body');
    //__noGmain
    context.$('html').addClass('popupStopScroll' + id);
};

export const setPopupWindowChange = function (id, change) {
    if (isNull(context._popupOptions)) {
        return;
    }
    if (isNull(context._popupOptions['popup' + id])) {
        return;
    }
    context._popupOptions['popup' + id].change = change;
};

export const closePopupWindow = function (id, closeArgs, msg) {
    if (id) {
        if (isNull(context._popupOptions['popup' + id])) {
            return;
        }
        var popupOption = context._popupOptions['popup' + id];
        if (popupOption.customChange && popupOption.customConfirm) {
            popupOption.customConfirm(function () {
                // 将关闭权限交给自定义confirm
                popupOption.customChange = false;
                closePopupWindow(id, closeArgs, msg);
            });
            return;
        }

        if (popupOption.change) {
            var _msg = typeof msg == 'undefined' || msg == '' ? '您的修改尚未保存，确定要离开吗?' : msg;
            if (typeof window.Vue !== 'undefined' && window.Vue.prototype.$createDialog) {
                new window.Vue().$createDialog({
                    content: _msg,
                    confirmButton: { text: '确 定' },
                    cancelButton: { text: '取 消' },
                    onConfirm: function () {
                        _doClosePopupWindow(id);
                    },
                });
            } else {
                if (window.confirm(_msg)) {
                    _doClosePopupWindow(id);
                }
            }
        } else {
            _doClosePopupWindow(id);
        }
    } else {
        removeBg();
        context.$('.formDialog').remove();
        context.$(context.window).off('scroll.popup');
    }

    function _doClosePopupWindow(id) {
        var popupOption = context._popupOptions['popup' + id];

        if (isNull(popupOption) || $.isEmptyObject(popupOption)) {
            return;
        }

        if (popupOption.refresh) {
            context.location.reload();
            return;
        }
        // remove first
        context.$('#ing').remove();
        var options = popupOption.options;
        if (!isNull(options.closeFunc)) {
            if (closeArgs) {
                options.closeFunc(closeArgs);
            } else {
                options.closeFunc(context._popupOptions['popup' + id].closeArgs);
            }
        }
        context._popupOptions['popup' + id] = {};
        //animate start
        var ppwObj = context.$('#popupWindow' + id);
        if (options.animate) {
            closePopupWindowAnimate(ppwObj, options.animateTarget, options.animateOnClose);
        }
        //animate finish

        // for ie9 bug
        context.setTimeout(() => {
            closePopupWindow_Internal(id);
        });
        context.$(context.window).off('scroll.popup');
    }
};

export const closePopupWindow_Internal = function (id) {
    if (typeof id == 'undefined') {
        //在关闭窗口之前，先把swfuplaod销毁了
        context.$('.popupBg').not('.popupZoneDelOnlyById').last().remove();
        var formDialogLast = context.$('.formDialog').last(),
            popupIdTry = formDialogLast.attr('id');
        if (popupIdTry && popupIdTry.replace('popupWindow', '')) {
            context.$('html').removeClass('popupStopScroll' + popupIdTry.replace('popupWindow', ''));
        }
        formDialogLast.remove();
    } else {
        //在关闭窗口之前，先把swfuplaod销毁了
        removeBg(id);
        context.$('#popupWindowIframe' + id).remove(); // 这里尝试先remove iframe再后面remove div，看焦点会不会丢失
        context.$('#popupWindow' + id).remove();
        context.$('html').removeClass('popupStopScroll' + id);
    }
};

export const closePopupWindowAnimate = function (ppwObj, target, onclose) {
    var animateDiv = $('<div>');
    context.$('body').append(animateDiv);
    animateDiv.css({
        border: '1px solid #ff4400',
        position: 'absolute',
        'z-index': '9999',
        top: ppwObj.offset().top,
        left: ppwObj.offset().left,
        height: ppwObj.height() + 'px',
        width: ppwObj.width() + 'px',
    });
    var guide = context.$('body').find(target);
    if (guide.length < 1) {
        guide = animateDiv;
    }
    animateDiv.animate(
        {
            top: guide.offset().top + 'px',
            left: guide.offset().left + 'px',
            width: guide.width() + 'px',
            height: guide.height() + 'px',
        },
        'slow',
        function () {
            if (typeof onclose == 'function') {
                onclose();
            }
            animateDiv.remove();
        }
    );
};

/*
click 				点击触发事件
msg 				点击关闭时弹出消息
*/
export const addPopupWindowBtn = function (id, btnOptions) {
    var win = context.$('#popupWindow' + id);
    win.find('.formBtns').show();
    var btnId = 'popup' + id + btnOptions.id;
    var btns = win.find('.formBtns td');
    var btn = btns.find('#' + btnId);
    if (btn.length > 0) {
        btn.remove();
    }
    if (btnOptions.click != 'help') {
        if (typeof btnOptions['extClass'] != 'undefined') {
            var extClass = btnOptions['extClass'];
            context
                .$(
                    "<input id='" +
                        btnId +
                        "' type='button' value='" +
                        btnOptions.text +
                        "' class='abutton faiButton' extClass='" +
                        extClass +
                        "'></input>"
                )
                .appendTo(btns);
        } else {
            context
                .$(
                    "<input id='" +
                        btnId +
                        "' type='button' value='" +
                        btnOptions.text +
                        "' class='abutton faiButton'></input>"
                )
                .appendTo(btns);
        }
    }
    btn = btns.find('#' + btnId);
    if (typeof btn.faiButton == 'function') {
        btn.faiButton();
    }
    if (btnOptions.callback && Object.prototype.toString.call(btnOptions.callback) === '[object Function]') {
        btn.click(function () {
            btnOptions.callback();
            if (isNull(btnOptions.msg)) {
                closePopupWindow(id);
            } else {
                closePopupWindow(id, undefined, btnOptions.msg);
            }
        });
    }

    if (btnOptions.click == 'close') {
        btn.click(function () {
            if (isNull(btnOptions.msg)) {
                closePopupWindow(id);
            } else {
                closePopupWindow(id, undefined, btnOptions.msg);
            }
        });
    } else if (btnOptions.click == 'help') {
        if (win.find('a.formH').length == 0) {
            win.append(
                "<a class='formH' href='" +
                    btnOptions.helpLink +
                    "' target='_blank' title='" +
                    btnOptions.text +
                    "'></a>"
            );
        }
    } else {
        btn.click(btnOptions.click);
    }

    if (btnOptions.disable) {
        btn.attr('disabled', true);
        btn.faiButton('disable');
    }

    // 捕捉弹窗输入Enter键，符合要求则触发保存
    // 性能问题：每次关闭弹窗，keydown函数会自动跟随窗体销毁
    $(document).keydown(function (e) {
        if (e.keyCode == 13) {
            var saveBtn = win.find('#popup' + id + 'save'),
                focusElem;

            if (saveBtn.length > 0 && !saveBtn.prop('disabled')) {
                focusElem = $(':focus');
                if (focusElem.is("input[type='text']") || focusElem.is('textarea')) {
                    return;
                }
                saveBtn.trigger('click');
            }
        }
    });
};

export const enablePopupWindowBtn = function (id, btnId, enable) {
    var win = context.$('#popupWindow' + id);
    btnId = 'popup' + id + btnId;
    var btn = win.find('#' + btnId);
    if (enable) {
        btn.removeAttr('disabled');
        if (typeof btn.faiButton === 'function') btn.faiButton('enable');
    } else {
        btn.attr('disabled', true);
        if (typeof btn.faiButton === 'function') btn.faiButton('disable');
    }
};

/*
弹出框（在当前页面内显示）
title				弹出框的标题
content				内容
width				内容区宽度（数字/字符串）
height				内容区高度（数字/字符串）
opacity				背景遮盖层的透明度，默认0.3
displayBg			是否加背景遮盖层，默认true
bannerDisplay		是否显示标题栏和边框，默认true
window_extClass 弹窗扩展class

closeFunc			关闭popup window时执行的函数
*/
export const popupBodyWindow = function (options) {
    var settings = {
        title: '',
        width: 500,
        height: 300,
        bannerDisplay: true,
        overflow_y: 'inherit',
        opacity: '0.3',
        displayBg: true,
        window_extClass: '',
        bg_extClass: '', // 遮罩层自定义类
    };
    settings = $.extend(settings, options);

    var contentWidth = parseInt(settings.width);
    var contentHeight = parseInt(settings.height);
    var scrollTop = $('body').scrollTop();
    if (scrollTop == 0) {
        scrollTop = $('html').scrollTop();
    }
    var browserWidth = document.documentElement.clientWidth;
    if (!$.browser.msie) {
        browserWidth = document.body.clientWidth;
    }
    var browserHeight = document.documentElement.clientHeight;

    var bannerStyle = '';
    var bgDisplay = '';
    if (!settings.bannerDisplay) {
        bannerStyle = 'display:none;';
        bgDisplay = 'background:none;';
    }

    var leftMar = 20;
    if (settings.leftMar != null) {
        leftMar = settings.leftMar;
    } else {
        if (context.__noGmain) {
            leftMar = (document.documentElement.scrollWidth - contentWidth) / 2;
        } else {
            leftMar = (browserWidth - contentWidth) / 2;
        }
    }

    var topMar = 20;
    if (settings.topMar != null) {
        topMar = settings.topMar;
    } else {
        topMar = (browserHeight - contentHeight - 80) / 2;
    }

    var content = '';
    if (settings.content != null) {
        content = settings.content;
    }

    var id = parseInt(Math.random() * 10000);

    //加背景
    if (settings.displayBg) {
        bodyBg(id, settings.opacity, { extClass: settings.bg_extClass });
    }

    var winStyle = 'left:' + leftMar + 'px; top:' + (topMar + scrollTop) + 'px;';

    var formMSGStyle =
        'position:relative;width:' +
        contentWidth +
        'px;height:' +
        contentHeight +
        'px;overflow-y:' +
        settings.overflow_y;

    var html = [
        '<div id="popupWindow' + id + '" class="formDialog ' + settings.window_extClass + '" style="' + winStyle + '">',
        '<div class="formTL" style=\'' + bannerStyle + "'>",
        '<div class="formTR">',
        '<div class="formTC">' + settings.title + '</div>',
        '</div>',
        '</div>',
        '<div class="formBL" style=\'' + bgDisplay + "'>",
        '<div class="formBR" style=\'' + bgDisplay + "'>",
        '<div class="formBC" id="formBC" style="height:auto;' + bgDisplay + '">',
        '<div class="formMSG" style="' + formMSGStyle + '">',
        content,
        '</div>',
        "<table cellpadding='0' cellspacing='0' class='formBtns'>",
        "<tr><td align='center' class='formBtnsContent'></td></tr>",
        '</table>',
        '</div>',
        '</div>',
        '</div>',
        "<a class=\"formX\" href='javascript:;' hidefocus='true' onclick='return false;'></a>",
        '</div>',
    ];
    $(html.join('')).appendTo('body');

    var popupWin = $('#popupWindow' + id);

    popupWin.ready(function () {
        $('.formDialog').draggable({ handle: '.formTL' });
        $('.formTL').disableSelection();
        $('.formX').click(function () {
            if (settings.beforeClose) {
                settings.beforeClose();
            }
            closePopupBodyWindow(id);
            //对急速建站弹出来的特殊处理
            context.$('#popupBgTitle' + id).remove();
        });
    });
    popupWin.data('settings', settings);

    return id;
};

export const closePopupBodyWindow = function (id) {
    if (id) {
        removeBodyBg(id);
        var popup = $('#popupWindow' + id);
        var settings = popup.data('settings');
        if (settings && typeof settings.closeFunc == 'function') {
            settings.closeFunc();
        }
        popup.remove();
        $('body').focus();
    } else {
        removeBodyBg();
        $('.formDialog').remove();
    }
};

export const addPopupBodyWindowBtn = function (id, btnOptions) {
    var win = $('#popupWindow' + id);
    win.find('.formBtns').show();
    var btnId = 'popup' + id + btnOptions.id;
    var btns = win.find('.formBtns td');
    var btn = btns.find('#' + btnId);
    if (btn.length > 0) {
        btn.remove();
    }

    if (win.find('.popupButtons').length != 1) {
        $("<span class='popupButtons'></span>").appendTo(btns);
    }
    if (win.find('.popupCheckboxs').length === 1) {
        //存在checkBox   button向右飘
        $(win.find('.popupButtons')[0]).css('margin-right', '10px').css('float', 'right').css('margin-top', '-3px');
    }

    var buttonHtml = '';
    if (typeof btnOptions['extClass'] != 'undefined') {
        var extClass = ' ' + btnOptions['extClass'];
        buttonHtml =
            "<input id='" +
            btnId +
            "' type='button' value='" +
            btnOptions.text +
            "' class='abutton faiButton' extClass='" +
            extClass +
            "'></input>";
    } else {
        buttonHtml =
            "<input id='" +
            btnId +
            "' type='button' value='" +
            btnOptions.text +
            "' class='abutton faiButton'></input>";
    }

    $(buttonHtml).appendTo($(btns).find('.popupButtons'));

    btn = btns.find('#' + btnId);
    if (typeof btn.faiButton == 'function') {
        btn.faiButton();
    }

    if (btnOptions.click == 'close') {
        btn.click(function () {
            closePopupBodyWindow(id);
        });
    } else {
        btn.click(btnOptions.click);
    }
    if (btnOptions.disable) {
        btn.attr('disabled', true);
        btn.faiButton('disable');
    }
};

export const addPopupBodyWindowCheckBox = function (id, options) {
    var win = $('#popupWindow' + id);
    win.find('.formBtns').show();
    var btnId = 'popup' + id + options.id;
    var btns = win.find('.formBtns td');
    var btn = btns.find('#' + btnId);
    if (btn.length > 0) {
        btn.remove();
    }

    var checkboxHtml =
        "<input id='" + btnId + "' type='checkbox' /><label for='" + btnId + "'>" + options.text + '</label>';
    if (win.find('.popupCheckboxs').length != 1) {
        btns.removeAttr('align').css('line-height', '22px');
        $(btns.find('.popupButtons')[0]).css('margin-right', '10px').css('float', 'right');
        $("<span class='popupCheckboxs'>" + checkboxHtml + '</span>').appendTo(btns);
    } else {
        $(checkboxHtml).appendTo($(win.find('.popupCheckboxs')[0]));
    }

    if (options.init === 'checked') {
        $('#' + btnId).attr('checked', 'checked');
    }

    btn = btns.find('#' + btnId);

    btn.click(options.click);
    if (options.disable) {
        btn.attr('disabled', true);
    }
};

export const enablePopupBodyWindowBtn = function (id, btnId, enable) {
    var win = $('#popupWindow' + id);
    btnId = 'popup' + id + btnId;
    var btn = win.find('#' + btnId);
    if (enable) {
        btn.removeAttr('disabled');
        btn.faiButton('enable');
    } else {
        btn.attr('disabled', true);
        btn.faiButton('disable');
    }
};
