import { topTipMarquee } from '@/preview/scripts/init-page';
export function ingTips(str) {
    var msg = str == null || str == '' ? '正在处理...' : str;
    var html = '';
    html = "<div id='mobiIng' class='mobiIng'></div>";
    if (jm('#mobiIng').length == 0) {
        jm(html).appendTo('#g_body');
    }
    var ing = jm('#mobiIng');

    var id = parseInt(Math.random() * 10000);
    var tips =
        '<div id="' +
        id +
        '" class="mobiTips">' +
        '<div id="mobiMsg' +
        id +
        '" class="mobiMsg">' +
        msg +
        '</div>' +
        '</div>';

    ing.find('.mobiTips').remove();
    jm('#mobiIngTigs').find('.mobiTips').remove();
    jm(tips).appendTo(ing);
    window.setTimeout(function () {
        ing.css('visibility', 'hidden');
        ing.css('opacity', '0');
    }, 2500);
    window.setTimeout(function () {
        jm('#' + id).remove();
        ing.css('visibility', 'visible');
        ing.css('opacity', '1');
    }, 3000);
}

export function ing(str, iconClass, keepOpen) {
    //iconClass:  默认为警告 0:警告 1:成功 2:失败
    var msg = str == null || str == '' ? '正在处理...' : str;
    var iconstr = '';
    var hasTipsDiv = false;
    if (iconClass == '' || iconClass == null || iconClass == 0) {
        // 警告
        iconstr = "<div class='faisco-icons-S000359 iconMargin'></div>";
    } else if (iconClass == 1) {
        // 成功
        iconstr = "<div class='faisco-icons-S000357 iconMargin'></div>";
    } else if (iconClass == 2) {
        // 失败
        iconstr = "<div class='faisco-icons-S000358 iconMargin'></div>";
    } else {
        iconstr = '';
    }
    keepOpen = keepOpen ? keepOpen : false; //是否保持提示不关闭，网络很差时避免提示闪烁

    var html = '';
    html = "<div id='mobiIngTigs' class='mobiIngTigs'></div>";
    if (jm('#mobiIngTigs').length == 0) {
        jm(html).appendTo('body');
    } else {
        hasTipsDiv = true;
    }
    var ing = jm('#mobiIngTigs');

    var id = parseInt(Math.random() * 10000);
    var tips =
        '<div id="' +
        id +
        '" class="mobiTips" \'>' +
        '<div id="mobiMsg' +
        id +
        '" class="mobiMsg">' +
        iconstr +
        "<div class=''>" +
        msg +
        '</div>' +
        '</div>' +
        '</div>';

    ing.html(tips);

    if (hasTipsDiv) {
        ing.css('visibility', 'inherit');
        ing.css('opacity', '1');
    }

    //网络很差时避免提示闪烁，保持提示不关闭（主要针对文件上传）
    if (!keepOpen) {
        window.setTimeout(function () {
            ing.css('visibility', 'hidden');
            ing.css('opacity', '0');
        }, 2500);
        window.setTimeout(function () {
            jm('#' + id).remove();
            ing.remove();
        }, 1500);
    }
}

export const showResizeDockTip = function (event, helper) {
    //提示框
    var $resizeDockTip = $('#resizeDockTip');
    if ($resizeDockTip.length < 1) {
        $resizeDockTip = $("<div id='resizeDockTip' class='resizeDockTip'><div class='dockContent'></div></div>");
        $('body').append($resizeDockTip);
    }

    var content = $resizeDockTip.find('.dockContent'),
        height = parseInt(helper.css('height').replace('px', '')),
        width = parseInt(helper.css('width').replace('px', ''));

    content.html('高度：' + height + 'px' + '<br/>宽度：' + width + 'px');

    var left = event.pageX,
        top = event.pageY,
        dockHeight = $resizeDockTip.height(),
        dockWidth = $resizeDockTip.width(),
        bodyWidth = $('body').width();

    left = left - dockWidth / 2;
    top = top + dockHeight / 2;

    if (left < 0) {
        left = 0;
    } else if (left > bodyWidth - (dockWidth + 1)) {
        left = bodyWidth - (dockWidth + 1);
    }

    $resizeDockTip.css('left', left + 'px');
    $resizeDockTip.css('top', top + 'px');
    $resizeDockTip.show();
};

export const hideResizeDockTip = function () {
    if ($('#resizeDockTip').length) {
        $('#resizeDockTip').hide();
    }
};

function showManageTopTips(type) {
    if (Fai.top.httpInCodeCount > 0 && type == 1) {
        window.$store.state.manage.isShowManageHttpTopTips = true;
    }
}

function showVisitorTopTips(type) {
    window.$store.commit('addTopTip', {
        type,
    });

    jm('.detailHeaderColor.detailHeaderColor').css('top', '1.5rem');
    jm('.productDetailHeader').css('top', '1.5rem');
    topTipMarquee();
}

export const showTopTips = function (type) {
    VITE_APP_MODE !== 'visitor' ? showManageTopTips(type) : showVisitorTopTips(type);
};

export const mobiAlert = (function () {
    var timer = null;
    var duration = 3000;

    function close() {
        var el = document.getElementById('alert');
        if (!el) return;
        fadeOut(el, null, function () {
            el.parentElement.removeChild(el);
        });
    }

    function fadeIn(el, duration, cb) {
        duration = typeof duration === 'number' ? duration : 300;
        el.style.transition = 'opacity ' + duration + 'ms linear';
        el.style.opacity = 0;
        setTimeout(function () {
            el.style.opacity = 1;
        }, 0);
        cb && setTimeout(cb, duration);
    }

    function fadeOut(el, duration, cb) {
        duration = typeof duration === 'number' ? duration : 300;
        el.style.transition = 'opacity ' + duration + 'ms linear';
        el.style.opacity = 1;
        setTimeout(function () {
            el.style.opacity = 0;
        }, 0);
        cb && setTimeout(cb, duration);
    }

    return function mobiAlert(msg) {
        clearTimeout(timer);
        timer = setTimeout(close, duration);

        if (document.getElementById('alert')) {
            var el = document.getElementById('alert');
            el.childNodes[0].childNodes[0].textContent = msg;
            return;
        }

        var imageRoot = window._resImageRoot;

        if (!imageRoot) {
            try {
                imageRoot = window.top._resImageRoot;
            } catch (e) {
                imageRoot = '';
            }
        }

        var containerEl = document.createElement('div');
        var tipsEl = document.createElement('div');
        var msgEl = document.createElement('div');
        var closeEl = document.createElement('div');

        containerEl.appendChild(tipsEl);
        tipsEl.appendChild(msgEl);
        tipsEl.appendChild(closeEl);

        containerEl.id = 'alert';
        msgEl.setAttribute('data-role', 'msg');

        containerEl.style.cssText = 'position:fixed; top:50px; left: 50%; transform: translateX(-50%); z-index:9999;';
        tipsEl.style.cssText =
            'height: 40px; line-height: 40px; margin:3px 0; font-size: 13px; font-family: "Microsoft Yahei","微软雅黑",宋体, 新宋体,sans-serif; color: #ff8e1e;  background-color: #fff5dc; z-index:9999; box-shadow: 0px 5px 10px -5px #b3b3b3; -moz-box-shadow: 0px 5px 10px -5px #b3b3b3; -webkit-box-shadow: 0px 5px 10px -5px #b3b3b3; position: relative; white-space: nowrap;';
        msgEl.style.cssText =
            'display: inline-block; margin-left: 15px; word-break:keep-all; white-space:nowrap; vertical-align: middle;';
        closeEl.style.cssText =
            'height: 40px; margin: 0px 10px 0px 7px; width:8px; cursor:pointer; background:url(' +
            imageRoot +
            '/image/close02.png?v=201711250607) center no-repeat; display: inline-block; vertical-align: middle;';

        msgEl.textContent = msg;

        closeEl.addEventListener('click', close);
        closeEl.addEventListener('mouseenter', function (e) {
            e.currentTarget.style.background =
                'url(' + imageRoot + '/image/close03.png?v=201711250607) center no-repeat';
        });
        closeEl.addEventListener('mouseleave', function (e) {
            e.currentTarget.style.background =
                'url(' + imageRoot + '/image/close02.png?v=201711250607) center no-repeat';
        });

        jm('#g_body')[0].appendChild(containerEl);
        fadeIn(containerEl);
    };
})();
