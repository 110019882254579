import { initFloatZone, getFloatZone, saveFloatZone } from '@/api/floatEffect';
import Vue from 'vue';
import { isModuleV2 } from '@/manage/module-edit-panel/utils/module';
import { getModuleAttr } from '@/features/global-mobi-function/methods/util';
import { getModuleAttrV3 } from '@/manage/contextmenu/utils/module';
import { getModuleAttrPatternStg } from '@/features/global-mobi-function/methods/util';
import { getSimpleTextContent, getFloagBtnContent } from '@/manage/utils/module';
import { mergeFonts, analysisCustomFontFaceFamily } from '@/shared/fontFace';
import { MODULE_STYLE } from '@/def/module';
import { resetModuleAddState } from '@/modules/comm';
import { successHandle } from '@/shared/fai';
import { deepClone } from '@/shared/util';

class FloatZone {
    constructor() {
        // 用来标记进入编辑态的class
        this.editClass = 'jz_floatZoneEdit';

        this.isEdit = false;
        this.moduleContentClass = 'float_zone_wrap';

        // 记录模块,在拖拽完成后注入，添加完模块后销毁(与拖拽逻辑耦合)
        // this.modulePosition = null;

        // 模块拖拽标记
        this.dragChange = 'dragChange';

        this.oldModuleList = [];
    }
    async edit() {
        // 处理topbar、sidebar、add-panel、我的模块中模块管理、preview下的按钮组 等布局
        await window.$store.dispatch('editMode/enterEditMode', {
            isFloatZone: true,
        });

        window.$store.commit('floatEffect/updateEditStatus', true);

        this.isEdit = true;

        await this.initLayout();
        // 初始化拖拽

        // 待模块渲染完成，添加拖拽的placeholder
        Vue.nextTick(() => {
            this.initAddModuleDragable();
        });

        this.initModuleAddInfo();
    }
    initModuleAddInfo() {
        window.$store.dispatch('manage/moduleAdd/changeModuleAddInfo', {
            modulePosition: 'inFloatZone',
            inContainerId: this.getFloatZoneId(),
            containerStyle: MODULE_STYLE.FLOAT_ZONE,
        });
    }
    saveHandler(event) {
        event.stopPropagation();
        // 保存
        this.save();

        // 将按钮状态重置
        this.deactiveBtn();
    }
    cancelHandler(event) {
        event.stopPropagation();
        // 取出后台数据重置当前数据
        this.cancelEdit();
    }
    exitHandler(event) {
        event.stopPropagation();
        // 退出弹窗
        this.exitEdit();
    }
    resetModulePanelLayout() {
        window.$store.dispatch('manage/addPanel/changeActiveModuleTab', 'add');
    }
    getFloatZoneId() {
        return window.$store.state.floatEffect.moduleId;
    }
    hasInitContainer() {
        return this.getFloatZoneId() !== -1;
    }
    async initVisitLayout() {
        window.$store.commit('floatEffect/updateIsRender', false);

        let data = await this.ajaxDom();

        await Vue.nextTick();

        // 强制刷新子模块，清除拖拽的副作用
        window.$store.commit('floatEffect/updateIsRender', true);

        let { id, moduleInfo, inModuleListInfo, script } = data;

        if (this.hasInitContainer()) {
            window.$store.commit('resetModule', { module: moduleInfo });

            moduleInfo.children.forEach((module) => {
                window.$store.commit('resetModule', { module });
            });
        } else {
            // 提交模块数据
            window.$store.commit('addModule', { ctx: {}, module: moduleInfo });

            moduleInfo.children.forEach((module) => {
                window.$store.commit('addModule', { ctx: {}, module });
            });
        }
        // 记录旧模块列表
        this.oldModuleList = deepClone(moduleInfo.content.moduleList);
        // 删除现有的模块

        // 提交模块ID
        window.$store.commit('floatEffect/updateModuleId', id);

        // 先重置列表再提交
        this.resetHistoryModules();
        // 提交模块列表
        await window.$store.dispatch('editMode/loadModules', inModuleListInfo);

        let { content } = moduleInfo;
        let { moduleList } = content;

        if (VITE_APP_MODE !== 'visitor') {
            // 提交隐藏模块列表
            const hideModules = moduleList.filter((module) => module.s === false).map((module) => module.id);
            window.$store.commit('editMode/addHideModule', hideModules);
        }

        // 进入设计器时，更新悬浮容器id，用于检测下一次进入时是新增还是获取旧的悬浮容器
        Fai.top._floatZoneId = id;

        if (this.isEdit) {
            Vue.nextTick(() => {
                moduleList.forEach((module) => {
                    // 激活子模块交互
                    this.activeModuleAction(module.id);
                });
            });
        }
        if (!(VITE_APP_MODE !== 'visitor')) {
            Vue.nextTick(() => {
                eval(script);
            });
        }
    }
    resetVisitLayout() {
        this.$visitBody.removeClass(this.editClass);
    }
    async initLayout() {
        // 调整访客态布局
        this.$visitBody = $('#g_body');
        this.$visitBody.addClass(this.editClass);
        await this.initVisitLayout();
        // // 调整mobiDesign布局
    }
    async resetLayout() {
        // 重置全局布局

        // 重置模块面板布局
        this.resetModulePanelLayout();

        this.isEdit = false;
        await this.initVisitLayout();
    }
    async exitEdit() {
        await window.$store.dispatch('editMode/exitEditMode');
        window.$store.commit('floatEffect/updateEditStatus', false);
        this.resetVisitLayout();
        resetModuleAddState();
        this.isEdit = false;
        this.resetLayout();

        Vue.prototype.$designer.open({
            panelType: 'JzFloatEffectEdit',
        });
    }
    async cancelEdit() {
        Fai.top.Fai.ing('正在取消...');

        // 重置历史模块
        this.resetHistoryModules();
        // 重置删除模块
        window.$store.commit('editMode/resetDeleteModule');

        resetModuleAddState();

        await this.initVisitLayout();

        this.initModuleAddInfo();

        Fai.top.Fai.ing('取消成功', true);

        Vue.nextTick(() => {
            this.initAddModuleDragable();
        });

        // 将按钮状态重置
        this.deactiveBtn();
    }
    ajaxDom(options = {}) {
        // 这里会抹平第一次创建悬浮容器和后续获取悬浮容器的数据差异，最终返回数据是一致的
        if (!this.hasInitContainer()) {
            return this.initFloatZone();
        } else {
            return this.getFloatZone(options);
        }
    }
    initFloatZone() {
        return new Promise((resolve, reject) => {
            initFloatZone({
                params: {
                    style: 103,
                    colId: Fai.top._colId,
                    extId: Fai.top._extId,
                },
            })
                .catch(function () {
                    Fai.top.Fai.ing('打开悬浮容器失败！', false);
                    reject();
                })
                .then(function (response) {
                    const { data: res } = response;
                    res.script = '';

                    resolve(res);
                });
        });
    }
    getFloatZone() {
        // const { isEditFloatZone = true } = options;
        return getFloatZone({
            params: {
                style: 103,
                colId: Fai.top._colId,
                extId: Fai.top._extId,
                floatZoneId: this.getFloatZoneId(),
                isEditFloatZone: this.isEdit,
            },
        })
            .catch(function () {
                Fai.top.Fai.ing('打开悬浮容器失败！', false);
            })
            .then(function (response) {
                const { data: res } = response;
                let { rtInfo } = res;

                let { moduleId: id, moduleInfo, dom: div, script, inModuleListInfo } = rtInfo;

                // 在这里适配数据和新增时返回的数据一致
                let result = {
                    id,
                    moduleInfo,
                    div,
                    script,
                    inModuleListInfo,
                };
                return result;
            });
    }
    addModule(moduleInfo) {
        let id = moduleInfo.id;
        // let $module = null;

        // 更新模块列表
        window.$store.dispatch('editMode/addModule', moduleInfo);

        // 更新模块在容器的信息
        let top = 0;
        let left = 0;
        // 标注为拖拽过的，保存重现计算位置
        // $module = $('#module' + id);
        // $module.data(this.dragChange, true);
        // if (!this.modulePosition) {
        //     $module.css({ position: 'absolute', top, left });
        // } else {
        //     // 设置模块到记录拖拽位置的中点
        //     ({ top, left } = this.modulePosition);

        //     let moduleHeight = 0;
        //     let moduleWidth = 0;

        //     //按钮的宽高
        //     if ($module.hasClass('formStyle54')) {
        //         moduleHeight = $module[0].offsetHeight;
        //         moduleWidth =
        //             $module.find('.floatBtn').width() +
        //             $module[0].offsetWidth -
        //             $module.width();
        //     } else if ($module.hasClass('formStyle52')) {
        //         moduleHeight = $module.height();
        //         moduleWidth = $module.find('.fkEditor').width();
        //     } else if ($module.hasClass('formStyle53')) {
        //         moduleHeight = $module.height();
        //         moduleWidth = $module.find('.floatImgWrap').width();
        //     }

        //     top = top - moduleHeight / 2;
        //     if (top < 0) {
        //         top = 0;
        //     }
        //     left = left - moduleWidth / 2;
        //     if (left < 0) {
        //         left = 0;
        //     }

        //     $module.css({
        //         position: 'absolute',
        //         top,
        //         left,
        //     });
        // }

        // set到moduleList
        window.$store.state.currentPageModuleIdMap[window.$store.state.floatEffect.moduleId].content.moduleList.push({
            id,
            l: left,
            t: top,
            s: true,
        });
        // this.modulePosition = null;

        // 触发大保存
        let moduleAttr = getModuleAttr(id);
        moduleAttr.pattern.changed = true;

        Vue.prototype.$designer.styleChanged();

        Vue.nextTick(() => {
            this.activeModuleAction(id);
        });
    }
    // 激活子模块交互
    activeModuleAction(id) {
        let $module = $('#module' + id);
        $module.attr('_infloatzone', this.getFloatZoneId());

        // 这里为了兼容目前的层叠控制逻辑,在编辑悬浮弹窗时，一律设置为绝对定位
        $module.css({
            position: 'absolute',
            // 'z-index': 99
        });

        this.initDragble($module);
    }
    initDragble(elem) {
        let module = $(elem);

        if (module.length < 1) return;

        let style = parseInt(module.attr('_modulestyle'));
        let id = parseInt(module.attr('id').replace('module', ''));

        module.draggable('destroy').draggable({
            containment: 'parent',
            cursor: 'move',
            axis: 'x,y',
            scroll: false,
            distance: 3,
            tolerance: 2,
            smartguides: '.form',
            cancel: '.formLockEdit, input, textarea, select, .ui-resizable-handle',
            start() {
                // 利用pointer-events禁用掉原本拖拽结束后会触发的事件
                module.css({
                    'pointer-events': 'none',
                });
            },
            drag() {
                if (style == 52 || style == 53 || style == 54) {
                    Fai.top.$('#fkEditor-toolBar-' + id).hide();
                }
            },
            stop: () => {
                let module = $('#module' + id);
                let moduleAttr = getModuleAttr(id);

                // 标记模块是否被拖拽
                module.data(this.dragChange, true);
                moduleAttr.pattern.changed = true;
                Vue.prototype.$designer.styleChanged();

                module.css({
                    'pointer-events': 'auto',
                });
            },
        });
    }
    save() {
        // 保存悬浮容器模块
        let floatZoneInfo = this.getFloatZoneInfo();
        // 保存子模块
        let inModules = this.getInModuleContent();
        // 记录悬浮容器Id
        let floatZoneId = this.getFloatZoneId();

        saveFloatZone({
            params: {
                style: 103,
                colId: Fai.top._colId,
                extId: Fai.top._extId,
                id: floatZoneId,
            },
            data: {
                content: floatZoneInfo,
                inModules: JSON.stringify(inModules),
                floatZoneId,
                delModuleList: JSON.stringify(window.$store.state.editMode.delModules),
            },
        })
            .catch(function () {
                Fai.top.Fai.ing('服务繁忙，请稍候重试', false);
            })
            .then(function (response) {
                const { data } = response;
                if (successHandle(data, '保存成功', '系统错误', '', 3, 1)) {
                    // 成功回调
                }
            });
    }
    getFloatZoneInfo() {
        let content = {};
        let moduleList = [];
        let oldModuleList = this.getModuleInfoList();

        const $preview = Fai.top.$('#preview');
        const prviewHeight = $preview.height();

        // 记录模块的位置和所属悬浮弹窗
        $(`#module${this.getFloatZoneId()}`)
            .find(`.form`)
            .each((_, el) => {
                let inZoneModuleId = parseInt($(el).attr('id').replace('module', ''));
                let inModuleInfo = {};
                let $inModule = $(el);
                let dragChange = $inModule.data(this.dragChange);
                let oldModuleInfo = this.oldModuleList.find((module) => module.id === inZoneModuleId);

                if (!oldModuleInfo) {
                    dragChange = true;
                }

                inModuleInfo.id = inZoneModuleId;
                // 先取2位数，乘100后，js可能是出现小数，所以要再取整一次比如0.14 * 100 !== 14
                inModuleInfo.t = dragChange
                    ? Math.trunc(this.toFixedFix($inModule[0].offsetTop / prviewHeight, 4) * 10000)
                    : oldModuleInfo.t;
                inModuleInfo.l = dragChange ? $inModule[0].offsetLeft : oldModuleInfo.l;
                inModuleInfo.s = $inModule.is(':visible');

                moduleList.push(inModuleInfo);
            });

        // 将隐藏的模块合并到新的moduleList中,新的模块数据会覆盖旧的
        oldModuleList.forEach((moduleInfo) => {
            let { s: isShowModule, id } = moduleInfo;
            let $module = $(`#module${this.getFloatZoneId()}`).find(`#module${id}`);

            // 避免原本隐藏而被显示的模块被收集,这里需要排除删除的模块
            if (!isShowModule && $module.length === 0 && !window.$store.state.editMode.delModules.includes(id)) {
                moduleList.push(moduleInfo);
            }
        });

        // 如果在删除列表的，必须从moduleList中删除，否则会因为上一步导致moduleList含有被删除的模块信息

        // 去重
        let hash = Object.create(null);
        let finalModuleList = [];

        moduleList.forEach((moduleInfo) => {
            let { id } = moduleInfo;
            if (!hash[id]) {
                finalModuleList.push(moduleInfo);
                hash[id] = true;
            }
        });

        content.moduleList = finalModuleList;
        return content;
    }
    getInModuleContent() {
        let moduleInfoList = [];
        let topWin = Fai.top;
        let content;

        $(`#module${this.getFloatZoneId()}`)
            .find('.form')
            .each(function (_, el) {
                let $inModule = $(el);
                let inModuleId = parseInt($(el).attr('id').replace('module', ''));

                const simpleTextStyle = 52;
                const floatImgStyle = 53;
                const floatBtnStyle = 54;
                const isSimpleText = $inModule.hasClass(`formStyle${simpleTextStyle}`);
                const isFloatBtn = $inModule.hasClass(`formStyle${floatBtnStyle}`);
                const isFloatImg = $inModule.hasClass(`formStyle${floatImgStyle}`);

                const isFloatBtnV2 = isFloatBtn && isModuleV2(floatBtnStyle);

                const isFloatImgV2 = isFloatImg && isModuleV2(floatImgStyle);

                let FaiAttr = getModuleAttr(inModuleId);
                let moduleInfo = {};
                let moduleChanged = false;
                let moduleAttr = getModuleAttr(inModuleId);

                // if (isFloatBtnV2) {
                //     FaiAttr =
                //         window.$store.state.currentPageModuleIdMap[inModuleId];
                // }

                let module = window.$store.getters.getModuleById(inModuleId);

                if (Fai.top._openThemeV3 && (isFloatImgV2 || isFloatBtnV2)) {
                    let moduleAttrV3 = getModuleAttrV3(inModuleId);
                    if (moduleAttrV3.patternChanged) {
                        moduleInfo.patternV3 = moduleAttrV3.patternV3;
                        moduleChanged = true;
                    }
                }

                if (moduleAttr.pattern.changed) {
                    moduleInfo.pattern = topWin.$.toJSON(getModuleAttrPatternStg(inModuleId));
                    moduleChanged = true;
                }

                if (FaiAttr.contentChange || module.contentChange) {
                    content = module.content || {};

                    if (isSimpleText) {
                        let oldFontList = content.fontList;
                        let oldFontResIdList = oldFontList.map((fontRes) => fontRes.resId);
                        content = getSimpleTextContent($inModule);
                        // 获取文本字体
                        let editor = $inModule.find('.fkEditor');
                        let fontList = mergeFonts(editor);

                        // 将信息保存到content字段里
                        content.fontList = fontList;
                        content.oldFontResIdList = oldFontResIdList;
                        const moduleData = window.$store.state.currentPageModuleIdMap[inModuleId] || {};
                        content.jumpMode = moduleData.content.jumpMode;
                        content.jumpWxAppData = moduleData.content.jumpWxAppData;

                        // 保存是否选择了主题色
                        content.tcc = content.text.indexOf('_themecolor') === -1 ? 0 : 1;
                        if (content.tcc == 1) {
                            content.themeColor = Fai.top._themeColor;
                        }
                    }

                    // 按钮模块
                    if (isFloatBtn) {
                        content = getFloagBtnContent($inModule);
                        const moduleData = window.$store.state.currentPageModuleIdMap[inModuleId] || {};
                        content.jumpMode = moduleData.content.jumpMode;
                        content.jumpWxAppData = moduleData.content.jumpWxAppData;
                    }

                    moduleInfo.content = content;
                    moduleChanged = true;
                }
                // 特殊处理按钮模块  保存字体有变化的按钮
                if (moduleAttr.pattern.changed && isFloatBtn) {
                    // 获取改变前的fontList
                    content = module.content;
                    let oldFontList = content.fontList;
                    let oldFontResIdList = oldFontList.map((fontRes) => fontRes.resId);

                    content = getFloagBtnContent($inModule);

                    // 获取模块设置的字体
                    let flBtn = $inModule.find('.floatBtn');
                    let fontFamily = flBtn.css('font-family') || '';
                    // 将信息保存到content的字段中去
                    let fontList = [];
                    const { flag, id, random } = analysisCustomFontFaceFamily(fontFamily);
                    if (flag === 'fontface') {
                        const fontText = flBtn.text();
                        fontList.push({
                            id: random,
                            fontId: +id,
                            fontFamily,
                            substring: Fai.top.window.Fai.unique(fontText),
                        });
                    }

                    // 保存fontList oldFontResIdList
                    content.fontList = fontList;
                    content.oldFontResIdList = oldFontResIdList;
                    const moduleData = window.$store.state.currentPageModuleIdMap[inModuleId] || {};
                    content.jumpMode = moduleData.content.jumpMode;
                    content.jumpWxAppData = moduleData.content.jumpWxAppData;
                    moduleInfo.content = content;
                    moduleChanged = true;
                }

                if (moduleChanged) {
                    moduleInfo.id = inModuleId;
                    moduleInfoList.push(moduleInfo);
                }
            });

        return moduleInfoList;
    }
    delModule(id) {
        // 删除模块列表
        window.$store.commit('editMode/deleteModule', id);

        // 删除模块
        window.$store.commit('editMode/addDeleteModule', id);
        const moduleInfoList = this.getModuleInfoList();
        let moduleIndex = moduleInfoList.findIndex((moduleInfo) => moduleInfo.id === id);

        if (moduleIndex !== -1) {
            moduleInfoList.splice(moduleIndex, 1);
        }

        this.activeBtn();
    }
    resetHistoryModules() {
        // 重置模块列表
        window.$store.commit('editMode/resetModule');
        // 重置隐藏模块
        window.$store.commit('editMode/resetHideModules');
    }
    activeBtn() {
        window.$store.dispatch('editMode/activeBtn');
    }
    deactiveBtn() {
        window.$store.dispatch('editMode/deactiveBtn');
    }
    // 获取指定精度的小数
    toFixedFix(n, prec) {
        let k = Math.pow(10, prec);
        return Math.round(n * k, 10) / k;
    }
    getModuleInfoList() {
        const {
            content: { moduleList },
        } = window.$store.state.currentPageModuleIdMap[window.$store.state.floatEffect.moduleId];
        return moduleList;
    }
    hideModule(id) {
        window.$store.commit('editMode/addHideModule', id);
        Vue.set(
            this.getModuleInfoList().find((moduleInfo) => moduleInfo.id === id),
            's',
            false
        );

        this.activeBtn();
    }
    showModule(id) {
        window.$store.commit('editMode/showModule', id);
        Vue.set(
            this.getModuleInfoList().find((moduleInfo) => moduleInfo.id === id),
            's',
            true
        );

        Vue.nextTick(() => {
            this.activeModuleAction(id);
        });

        this.activeBtn();
    }
    initAddModuleDragable() {
        const moduleId = this.getFloatZoneId();
        const $module = $(`#module${moduleId}`);

        if ($module.find('.J_floatZoneSortContainer').length < 1) {
            $(`<div moduleId='${moduleId}' class='J_floatZoneSortContainer fk-floatZoneSortContainer'></div>`).appendTo(
                $module
            );
        } else {
            $module.find('.J_floatZoneSortContainer').attr('moduleId', moduleId);
        }
    }
    closeFloatZone() {
        jm('#floatZoneContainer').remove();
    }
    initFloatZoneData() {
        if (Fai.top._floatZoneId !== 0) {
            window.$store.commit('floatEffect/updateModuleId', Fai.top._floatZoneId);
            floatZone.initVisitLayout();
        }
    }
}

const floatZone = new FloatZone();
if (typeof window !== 'undefined') {
    window.floatZone = floatZone;
}
export { floatZone };
