<template>
    <module-frame :module-id="moduleId">
        <div v-show="loaded" class="msgBoard">
            <div class="msgTop">
                <template v-if="manageMode">
                    <div class="noticeArea">
                        <div class="notice">
                            {{ msgCanNotSubmitText }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="noticeArea">
                        <div class="notice" style="display: none"></div>
                    </div>
                </template>
                <template v-for="tmpProp in displayList">
                    <template v-if="defaultCreator !== '' && tmpProp.type === MSGBOARD_PROP_DEF.TYPE.SYS_NAME">
                        <div :key="tmpProp.id" class="g_globalLine">
                            <input
                                :id="tmpProp.fieldKey"
                                :type="tmpProp.fieldKey | inputTypeFilter"
                                :value="defaultCreator"
                                style="color: #c8c8c8"
                                :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                                class="msg_ipt g_input"
                                :class="[
                                    getBitMemory(tmpProp.flag, MSGBOARD_PROP_DEF.FLAG.REQUIRED) ? 'msg_isMust' : '',
                                ]"
                                disabled
                            />
                            <span :class="[iconClassFilter(tmpProp.fieldKey)]"></span>
                        </div>
                    </template>
                    <template v-else-if="tmpProp.id == MSGBOARD_PROP_DEF.TYPE.SYS_EMAIL">
                        <div :key="tmpProp.id" class="g_globalLine">
                            <input
                                :id="tmpProp.fieldKey"
                                :type="tmpProp.fieldKey | inputTypeFilter"
                                :value="defaultEmail"
                                :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                                :placeholder="addMust(tmpProp[MSGBOARD_PROP_DEF.INFO.NAME], tmpProp.flag)"
                                class="msg_ipt g_input"
                                :class="mustClassStr(tmpProp.flag)"
                            />
                            <span :class="[iconClassFilter(tmpProp.fieldKey)]"></span>
                        </div>
                    </template>
                    <template v-else-if="tmpProp.id == MSGBOARD_PROP_DEF.TYPE.SYS_PHONE">
                        <div :key="tmpProp.id" class="g_globalLine">
                            <input
                                :id="tmpProp.fieldKey"
                                onkeyup="value=value.replace(/[^\d]/g,'')"
                                :type="tmpProp.fieldKey | inputTypeFilter"
                                :value="defaultPhone"
                                :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                                :placeholder="addMust(tmpProp[MSGBOARD_PROP_DEF.INFO.NAME], tmpProp.flag)"
                                class="msg_ipt g_input"
                                :class="mustClassStr(tmpProp.flag)"
                            />
                            <span :class="[iconClassFilter(tmpProp.fieldKey)]"></span>
                        </div>
                    </template>
                    <div v-else :key="tmpProp.fieldKey" class="g_globalLine">
                        <input
                            :id="tmpProp.fieldKey"
                            :type="tmpProp.fieldKey | inputTypeFilter"
                            :maxlength="MSGBOARD_DEF.LIMIT.REQ_PROP_MAXLEN"
                            :placeholder="addMust(tmpProp[MSGBOARD_PROP_DEF.INFO.NAME], tmpProp.flag)"
                            class="msg_ipt g_input"
                            :class="mustClassStr(tmpProp.flag)"
                        />
                        <span :class="[iconClassFilter(tmpProp.fieldKey)]"></span>
                    </div>
                </template>
                <div class="g_globalLine g_globalLine_textArea">
                    <textarea
                        id="reqContent"
                        class="g_textArea msg_textArea msg_isMust"
                        :maxlength="MSGBOARD_DEF.LIMIT.REQ_CONTENT_MAXLEN"
                        :placeholder="msgBoardContentText"
                    ></textarea>
                    <span class="icon-mmsgIcon"></span>
                </div>
                <div style="clear: both"></div>
                <div class="g_globalLine" :class="captchaHideClass">
                    <input
                        id="msgBoardCaptcha"
                        type="text"
                        class="captchaText g_input"
                        maxlength="4"
                        :placeholder="enterVerificationCodeText"
                    />
                    <img
                        id="msgBoardCaptchaImg"
                        class="captchaImg"
                        :src="validateCodeSrc"
                        @click="changeValidateCode"
                    />
                </div>
                <template v-if="manageMode">
                    <div class="g_globalLine g_globalLine_subm">
                        <input type="button" class="g_button sendIcon msgSubmitButton submitIcon" :value="submitText" />
                        <span class="mustSpan">&nbsp;&nbsp;</span>
                    </div>
                </template>
                <template v-else>
                    <div class="g_globalLine g_globalLine_subm">
                        <input
                            type="button"
                            class="g_button sendIcon msgSubmitButton submitIcon"
                            :class="{
                                'not-allowed-button': allowSubmit === false,
                            }"
                            :value="submitText"
                            @click="addMsgHandler"
                        />
                        <span class="mustSpan">&nbsp;&nbsp;</span>
                    </div>
                </template>
            </div>
            <!-- 评论区 start  -->
            <div v-if="showComments" class="msgBottom" :class="{ msgBottomDisplay: msgList.length === 0 }">
                <div class="spaceRow"></div>
                <div class="msgBottomTop">
                    <div class="g_globalLine mtitle">
                        <div class="mbTitle userMsgIcon" style="display: inline-block">
                            <span class="icon-userMsgIcon"></span>{{ msgBoardUserCommentsText }}
                        </div>
                    </div>
                    <template v-for="msgInfo in msgList">
                        <div :id="msgInfo.id | msgItemIdText" :key="msgInfo.id" class="jz_msgItem">
                            <div class="jz_seperate_line"></div>
                            <div class="msgArea g_panel">
                                <div class="msgTitle msgTitleHeight">
                                    <span class="textAndIcon">
                                        <div class="msgUser" :title="msgInfo.msgName">
                                            {{ msgInfo.msgName }}
                                        </div>
                                        <template
                                            v-if="!openMsgAuto && !checkBit(msgInfo.flag, MSGBOARD_DEF.FLAG.PUBLIC)"
                                        >
                                            <div class="jz_private_label"> 未公开 </div>
                                        </template>
                                    </span>
                                    <div class="msgTime" :title="msgInfo.msgTitle2">
                                        {{ formatDate(msgInfo.reqTime) }}
                                    </div>
                                </div>
                                <div class="msgContent" v-html="msgInfo.reqContent"></div>
                                <template v-if="msgInfo.rspContent">
                                    <div class="placeholderDiv"></div>
                                    <div class="reqArea g_replyPanel">
                                        <div class="msgArrow"></div>
                                        <div class="admReq">
                                            <!-- 没有rspTime数据的, 但代码里有, 页面不显示, 后面要显示再处理 -->
                                            <!-- {{ msgInfo.rspTime }} -->
                                            {{ msgBoardReplyText }}
                                        </div>
                                        <div class="admReqContent" v-html="msgInfo.rspContent"></div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div style="clear: both"></div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <pagination
                v-if="pagination.total !== 0 && showComments"
                :module-id="moduleId"
                :total-size="pagination.total"
                :page-size="module.content.page"
                :pageno="pagination.pageNo"
                :open-theme-v3="false"
                :ajax-pagenation="true"
                @on-change="changePage"
            />
        </div>
    </module-frame>
</template>

<script>
// created from fai/webmobi/moduleprinterkit/ModuleMsgBoard.java
import ModuleFrame from '@/modules/frame/index.vue';
import { LinkSettingUtil } from '@/shared/linkSetting/index';
import { setHrefEventHasReqArgs } from '@/modules/comm';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import msgBoardMixin from '../mixins/msg-board';
import pagination from '@/components/pagenation.vue';
import { mapState } from 'vuex';

export default {
    name: 'MsgBoardV1',
    components: {
        ModuleFrame,
        pagination,
    },
    mixins: [msgBoardMixin],
    data() {
        return {
            loaded: false,
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        captchaHideClass() {
            if (this.showCaptcha) {
                return 'J_msgBoardCaptcha';
            } else {
                return 'J_msgBoardCaptcha msgBoardCaptchaHide';
            }
        },
        enterVerificationCodeText() {
            return this.LS.mobi_EnterVerificationCode;
        },
        submitText() {
            return this.LS.mobi_Submit;
        },
        msgBoardContentText() {
            return this.LS.msgBoardContent;
        },
    },
    async mounted() {
        await this.loadMsgBoardInfo();
        this.loaded = true;
        if (VITE_APP_MODE !== 'visitor') {
            await this.initModuleMsgBoardItemManage();
        }
        this.initPagination();
        hidePlaceholder();
        LinkSettingUtil.switchJump();
        setHrefEventHasReqArgs(jm('.msgBottomTop'));
    },
    methods: {
        addMust(name = '', flag) {
            const must = this.getBitMemory(flag, this.MSGBOARD_PROP_DEF.FLAG.REQUIRED);
            return must ? `${name}${this.LS.mobi_member_Must}` : name;
        },
        iconClassFilter(val) {
            if (val === 'reqEmail') {
                return 'icon-memailIcon';
            } else if (val === 'reqPhone') {
                return 'icon-mphoneIcon';
            } else {
                return 'icon-mnameIcon';
            }
        },
    },
};
</script>
