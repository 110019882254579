import { getStyle } from '@/shared/util';
/**
 * created from Mobi.headBoxFixedV3
 * @param {Boolean} init
 */
export function headBoxFixedV3(init) {
    if (init) {
        jm('#g_web').off('touchstart.hbf').on('touchstart.hbf', change);
        jm('#g_web').off('touchmove.hbf').on('touchmove.hbf', change);
        jm('#g_web').off('touchend.hbf').on('touchend.hbf', change);
        jm('#g_web').off('touchcancel.hbf').on('touchcancel.hbf', change);
        jm('#g_web').off('scroll.hbf').on('scroll.hbf', change);
    }

    change();
    function change() {
        var header2 = jm('#webHeader_v3.headStyle2'),
            banner = jm('#webBanner'),
            scrollTop = getScrollTop(),
            headBg,
            headH,
            bannerH,
            nav = jm('#navbar'),
            isTopLabelNav =
                nav.length && nav.attr('type') == 3 && nav.attr('navPos') == 1 && nav.css('display') != 'none';
        if (!header2.length) {
            return;
        }
        headBg = header2.find('.headBg_v3');
        headH = parseInt(getStyle(header2[0], 'height').replace('px', ''));
        if (!banner.length || isTopLabelNav) {
            headBg.css('background-color', 'rgba(255,255,255,1)');
            header2.addClass('turnDefault');
        } else {
            bannerH = parseInt(getStyle(banner[0], 'height').replace('px', ''));
            if (!bannerH) {
                headBg.css('background-color', 'rgba(255,255,255,1)');
                header2.addClass('turnDefault');
            } else if (headH) {
                var opacity = (scrollTop - bannerH + headH) / headH;
                if (opacity < 0) {
                    opacity = 0;
                }
                headBg.css('background-color', 'rgba(255,255,255,' + opacity + ')');

                if (scrollTop > bannerH) {
                    header2.addClass('turnDefault');
                } else {
                    header2.removeClass('turnDefault');
                }
            }
        }
    }

    //获取滚动条的距离
    function getScrollTop() {
        return jm('#g_web')[0]?.scrollTop ?? 0;
    }
}
