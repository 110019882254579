<template v-if="hasPrev || hasNext">
    <div :id="'paginationDiv' + moduleId">
        <div class="g_separator next_separator"></div>
        <div class="newsNext">
            <div v-if="hasNext"
                >{{ newsNextOne }}：<a
                    hidefocus="true"
                    class="newsLink"
                    :href="nextNewsUrl"
                    @click="setUrlArgToCookie($event, true)"
                    v-html="nextNewsTitle"
                ></a
            ></div>
            <div v-else :style="paginationStyle" class="prevNextContent"
                >{{ newsNextOne }}：<span>{{ mobi_newslast }}</span></div
            >
        </div>
        <div class="newsPrev">
            <div v-if="hasPrev" class="prevNextContent"
                >{{ newsPrevOne }}：<a
                    hidefocus="true"
                    class="newsLink"
                    :href="prevNewsUrl"
                    @click="setUrlArgToCookie($event, false)"
                    v-html="prevNewsTitle"
                ></a
            ></div>
            <div v-else :style="paginationStyle" class="prevNextContent"
                >{{ newsPrevOne }}：<span>{{ mobi_newsFirst }}</span></div
            >
        </div>
    </div>
</template>

<script>
import { encodeHtml } from '@/shared/util';
import { newsDetailMixins } from '../mixins';
import { setUrlArgToCookie } from '@/modules/comm';
import { changeHref } from '@/features/router/universal';
import { mapState } from 'vuex';
import { getNewsPrev } from '@/api/news';

export default {
    name: 'Pagination',
    mixins: [newsDetailMixins],
    inject: ['moduleId'],
    props: {
        openThemeV3: {
            type: Boolean,
        },
    },
    data() {
        return {
            prevNextData: {
                hasNext: false,
                hasPrev: false,
                prev: {},
                next: {},
            },
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        newsNextOne() {
            return this.LS.newsNextOne;
        },
        mobi_newslast() {
            return this.LS.mobi_newslast;
        },
        newsPrevOne() {
            return this.LS.newsPrevOne;
        },
        mobi_newsFirst() {
            return this.LS.mobi_newsFirst;
        },
        pagination() {
            return this.options.prevNextArgs || {};
        },
        nextNewId() {
            return this.pagination.nextNewId || -1;
        },
        hasNext() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.hasNext;
            }
            return this.nextNewId != -1;
        },
        prevNewId() {
            return this.pagination.prevNewId || -1;
        },
        hasPrev() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.hasPrev;
            }
            return this.prevNewId != -1;
        },
        nextNewsUrl() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.next.nextNewsUrl;
            }
            return this.pagination.nextNewsUrl;
        },
        prevNewsUrl() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.prev.prevNewsUrl;
            }
            return this.pagination.prevNewsUrl;
        },
        prevReqArgs() {
            return this.pagination.prevReqArgs || null;
        },
        nextReqArgs() {
            return this.pagination.nextReqArgs || null;
        },
        paginationStyle() {
            return this.openThemeV3
                ? {
                      color: '#b3b3b3',
                  }
                : {};
        },
        isOpenNewMobiNewsPreAndNext() {
            return this.options.isOpenNewMobiNewsPreAndNext === true && this.options.isShowPrevNext;
        },
        nextNewsTitle() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.next.nextNewsTitle;
            }
            return this.pagination.nextNewsTitle;
        },
        prevNewsTitle() {
            if (this.isOpenNewMobiNewsPreAndNext) {
                return this.prevNextData.prev.prevNewsTitle;
            }
            return this.pagination.prevNewsTitle;
        },
    },
    mounted() {
        if (this.isOpenNewMobiNewsPreAndNext) {
            this.getPrevAndNext();
        }
    },
    methods: {
        getPrevAndNext() {
            let fullUrl = window.location.pathname + window.location.search;
            let anchor = window.location.hash;

            if (VITE_APP_MODE !== 'visitor') {
                const { h: href } = window.$router.currentRoute.query;
                const [url, hash = ''] = href.split('#');
                fullUrl = url;
                anchor = `#${hash}`;
            }

            const params = {
                moduleId: this.module.id,
                newsId: this.newsId,
                fullUrl,
                anchor,
            };
            getNewsPrev(params).then((nodeData) => {
                this.prevNextData.hasNext = nodeData.hasNext;
                this.prevNextData.hasPrev = nodeData.hasPrev;
                this.prevNextData.prev = nodeData.prev;
                this.prevNextData.next = nodeData.next;
            });
        },
        encodeHtml,
        changeRoute(href) {
            changeHref(href);
        },
        setUrlArgToCookie(event, isNext = false) {
            let args = isNext ? this.nextReqArgs : this.prevReqArgs;
            if (this.options.openStaticUrl && args != null) {
                setUrlArgToCookie(event.target, args);
            }
        },
    },
};
</script>

<style></style>
