import {
    setCtrlStyleCssList,
    removeCtrlStyleCssList,
    removeModuleStyleCssList,
    setModuleStyleCssList,
} from '@/shared/style';
import { getModuleAttrPattern, getModuleAttr } from './util';
import { getThemeColor } from '@/shared/theme-color';

export const updatePopupThemeModule = function (moduleList) {
    if (!Fai.top._isOpenThemeColor) return;
    var themeColor = getThemeColor(); // 判断模块是否跟随主题色

    if (moduleList != null) {
        moduleList.forEach(function (module) {
            var styleId = module.style;
            module.moduleId = module.id; // 将此弹框模块 push 到 themeModuleList 中

            Fai.top.$store.commit('manage/theme/addThemeModule', {
                moduleId: module.id,
                styleId: styleId,
                pattern: module.pattern,
            }); // 更新弹窗模块的颜色

            switch (styleId) {
                case 54:
                    updateThemeFloatBtn(module, themeColor, true, true);
                    break;

                case 52:
                    updateThemeText(module, themeColor);
                    break;

                default:
                    console.log('other module with styleId' + styleId);
                    break;
            }
        });
    }
};

export const updateThemeFloatBtn = function (module, themeColor) {
    var isSelected = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var isPopupModule = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    if (module == null) return;
    var id = module.moduleId;
    var doc = document;
    var el = $(doc.querySelectorAll('.formMiddleContent' + id)).find('a');
    var contentLink = isPopupModule ? module.pattern.cl : getModuleAttrPattern(id).contentLink;
    var content = getModuleAttr(id).content; // 更新按钮文本

    if (el != null && contentLink != null && contentLink.ftc === 1) {
        if (isSelected) contentLink.c = themeColor;
        el.css('color', themeColor);
    } // 更新按钮边框

    if (content != null && content.b != null && content.b.btc === 1) {
        if (isSelected) content.b.c = themeColor;
        removeModuleStyleCssList(id, [
            {
                cls: '.floatBtnStyle .middle',
                key: 'border',
            },
        ]);
        setModuleStyleCssList(id, [
            {
                cls: '.floatBtnStyle .middle',
                key: 'border',
                value: content.b.w + 'px solid ' + themeColor,
            },
        ]);
    } // 更新按钮阴影

    if (content != null && content.sh != null && content.sh.stc === 1) {
        var data = content.sh;

        if (isSelected) {
            content.sh.c = themeColor;
        }

        var shadowStr = '';
        shadowStr = data.dx + 'px ' + data.dy + 'px ' + data.f + 'px ' + themeColor;
        removeModuleStyleCssList(id, [
            {
                cls: '.floatBtnStyle .middle',
                key: '-moz-box-shadow',
            },
            {
                cls: '.floatBtnStyle .middle',
                key: '-webkit-box-shadow',
            },
            {
                cls: '.floatBtnStyle .middle',
                key: 'box-shadow',
            },
        ]);
        setModuleStyleCssList(id, [
            {
                cls: '.floatBtnStyle .middle',
                key: '-moz-box-shadow',
                value: shadowStr,
            },
            {
                cls: '.floatBtnStyle .middle',
                key: '-webkit-box-shadow',
                value: shadowStr,
            },
            {
                cls: '.floatBtnStyle .middle',
                key: 'box-shadow',
                value: shadowStr,
            },
        ]);
    }
};

export const updateThemeText = function (module, themeColor) {
    var moduleId = module.moduleId;
    var doc = document; // 获取对应模块的dom节点

    var el = $(doc.querySelectorAll('.formMiddleContent' + moduleId)); // 含有 class='themeColor' 的style颜色改为主题色

    el.find('span').each(function (index, el) {
        var $el = $(el);
        var isThemeColor = $el.attr('_themecolor');
        var style = $el.attr('style');

        if (isThemeColor && style != '') {
            $(el).css('color', themeColor);
        }
    });
};

export const updatePopupThemeModules = function (moduleList) {
    if (moduleList == null) return;

    if (VITE_APP_MODE !== 'visitor') {
        updatePopupThemeModule(moduleList);
    } else {
        var themeColor = Fai.top._themeColor;
        moduleList.forEach(function (module) {
            var style = module.style;

            switch (style) {
                case 54:
                    updatePopupFloatBtnModule(module, themeColor);
                    break;

                case 52:
                    updatePopupSimpleTextModule(module, themeColor);
                    break;

                default:
                    console.log('not aim styleId' + style);
                    break;
            }
        });
    }
};

export const updatePopupFloatBtnModule = function (module, themeColor) {
    var id = module.id;
    var doc = document;
    var moduleDom = doc.querySelector('.formMiddleContent' + id);
    var el = jm(moduleDom).find('a');
    var contentLink = module.pattern.cl;
    var content = module.content; // 更新按钮文本

    if (el != null && contentLink != null && contentLink.ftc === 1) {
        contentLink.c = themeColor;
        el.css('color', themeColor);
    } // 更新按钮边框

    if (content != null && content.b != null && content.b.btc === 1) {
        content.b.c = themeColor;
        removeCtrlStyleCssList('mobiStyleModule', 'module' + id, [
            {
                cls: '.floatBtnStyle .middle',
                key: 'border',
            },
        ]);
        setCtrlStyleCssList('mobiStyleModule', 'module' + id, [
            {
                cls: '.floatBtnStyle .middle',
                key: 'border',
                value: content.b.w + 'px solid ' + themeColor,
            },
        ]);
    } // 更新按钮阴影

    if (content != null && content.sh != null && content.sh.stc === 1) {
        var data = content.sh;
        content.sh.c = themeColor;
        var shadowStr = '';
        shadowStr = data.dx + 'px ' + data.dy + 'px ' + data.f + 'px ' + themeColor;
        removeCtrlStyleCssList('mobiStyleModule', 'module' + id, [
            {
                cls: '.floatBtnStyle .middle',
                key: '-moz-box-shadow',
            },
            {
                cls: '.floatBtnStyle .middle',
                key: '-webkit-box-shadow',
            },
            {
                cls: '.floatBtnStyle .middle',
                key: 'box-shadow',
            },
        ]);
        setCtrlStyleCssList('mobiStyleModule', 'module' + id, [
            {
                cls: '.floatBtnStyle .middle',
                key: '-moz-box-shadow',
                value: shadowStr,
            },
            {
                cls: '.floatBtnStyle .middle',
                key: '-webkit-box-shadow',
                value: shadowStr,
            },
            {
                cls: '.floatBtnStyle .middle',
                key: 'box-shadow',
                value: shadowStr,
            },
        ]);
    }
};

export const updatePopupSimpleTextModule = function (module, themeColor) {
    var moduleId = module.id;
    var doc = document; // 获取对应模块的dom节点

    var formMiddle = doc.querySelector('.formMiddleContent' + moduleId);
    var el = jm(formMiddle).find('.simpleText'); // 含有 class='themeColor' 的style颜色改为主题色

    el.find('span').each(function (index, span) {
        var $el = jm(span);
        var isThemeColor = $el.attr('_themecolor');
        var style = $el.attr('style');

        if (isThemeColor && style != '') {
            $el.css('color', themeColor);
        }
    });
};
