export function customPopup(options) {
    var defaultOptions = {
        content: '',
        insertSelector: 'body',
    };

    options = jm.extend(defaultOptions, options);

    var id = parseInt(Math.random() * 10000);

    var prelude = "<div id='mobiPrelude" + id + "' class='mobiPrelude'></div>",
        html = "<div id='mobiPrompt" + id + "' class='mobiPrompt'></div>",
        bg = "<div id='mobiPrompt_Bg" + id + "' class='mobiPrompt_Bg' style='opacity: 0.6;'></div>";

    jm(prelude).appendTo(options.insertSelector);
    jm(html).appendTo(options.insertSelector);
    jm(bg).appendTo(options.insertSelector);

    var prompt = jm('#mobiPrompt' + id),
        prompt_Bg = jm('#mobiPrompt_Bg' + id);

    prelude = jm('#mobiPrelude' + id);

    var preludePanel = [];
    preludePanel.push("<div class='mobiPrelude_inner' style='background: transparent;'>");
    if (typeof options.prelude == 'undefined' || options.prelude == null) {
        options.prelude = '';
    }
    preludePanel.push(options.prelude || '');
    preludePanel.push('</div>');
    prelude.append(preludePanel.join(''));

    var tipsPanel = [];
    tipsPanel.push("<div class='mobiPrompt_inner' style='background: transparent; box-shadow: none;'>");
    tipsPanel.push(options.content);
    tipsPanel.push('</div>');
    prompt.append(tipsPanel.join(''));

    if (options.callback) {
        prompt.find('.J-prompt-callback').on('click', function () {
            prelude.removeClass('mobiPreludeShow');
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prelude.remove();
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
            options.callback();
        });
    }

    if (options.cancelCallback) {
        prompt.find('.J-prompt-cancel').on('click', function () {
            prelude.removeClass('mobiPreludeShow');
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prelude.remove();
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
            options.cancelCallback();
        });
    } else {
        prompt.find('.J-prompt-cancel').on('click', function () {
            prelude.removeClass('mobiPreludeShow');
            prompt.removeClass('mobiPromptShow');
            prompt_Bg.removeClass('mobiPromptBgShow');
            setTimeout(function () {
                prelude.remove();
                prompt.remove();
                prompt_Bg.remove();
            }, 200);
        });
    }

    window.setTimeout(function () {
        prompt_Bg.addClass('mobiPromptBgShow');
        prelude.addClass('mobiPreludeShow');
        if (options.mainFrameTimeout) {
            window.setTimeout(function () {
                prelude.removeClass('mobiPreludeShow');
                prelude.remove();
                prompt.addClass('mobiPromptShow');
            }, options.mainFrameTimeout);
        } else {
            prompt.addClass('mobiPromptShow');
        }
    }, 100);
}
