export function showCookiePolicyPopup() {
    if (!Fai.top.cookiePolicyOpen || VITE_APP_MODE !== 'visitor') {
        return;
    }

    $(
        `<style>
            #allowCookiePopup.mobi {
                max-width: 768px;
                margin: 0 auto;
            }
        </style>`
    ).appendTo('head');

    window.bizShared.openAllowCookiePopup({
        isMobi: true,
        cookieUrl: Fai.top.cookieColUrl,
    });
}
