import { isWechat } from '@/shared/browser';

import { mobiIng } from '@/shared/fai';
import { showShare, showCreateShareGrapic } from './share/index';
import mapDetail from './onlineMap/mapDetail';
import { jumpToModulePosition, jumpAnimation } from './moduleJump/index';
import { baiduBridgeCodes, showBaiduBridge, isOpenBaiduBridge, showBaiduBridgeV2 } from './baiduBridge';

import { showJumpPhone, showMoreQQ, getQQUrl } from '@/shared/onlienService';

import { popupWindowVersionTwo } from '@/shared/popupWindow/index';
import { mobiAlert } from '@/shared/tips';
import { request } from '@/api/request';
import { copyDataToClipboard } from '@/shared/clipboard';
import { decodeHtml } from '@/shared/util';
export const showWeChatService = function (code, wechatOption) {
    var weChatTip;
    if (VITE_APP_MODE !== 'visitor' && Vue.prototype.$designer.checkSaveBar()) {
        return false;
    }
    if (isWechat()) {
        weChatTip = LS.weChatCodeTip;
    } else {
        weChatTip = LS.scanWeChatCodeTip;
    }

    let tips = weChatTip;
    if (wechatOption != null) {
        tips = wechatOption.tips;
    }
    let content = `
        <div class='weChatCodeBg'>
            <div class='weChatBg'></div>
        </div>
        <div class='weChatCodePanel'>
            <div class='weChatBackground'>
                <img class='weChatCode'  src='${code}'>
                <span class='weChatCodeFont'>${tips}</span>
            </div>
        </div>
    `;

    jm('.weChatCodeBg').remove();
    jm('.weChatCodePanel').remove();
    jm('#g_web').append(content);

    jm('html,body').css('overflow', 'hidden');
    jm('html,body').css('height', '100%');

    jm('#g_web').on('touchmove', function (event) {
        event.preventDefault();
    });

    jm('#g_web')
        .find('.weChatCodeBg, .weChatCodePanel')
        .click(function () {
            jm('#g_web').find('.weChatCodeBg').hide();
            jm('#g_web').find('.weChatCodePanel').hide();
            jm('html,body').css('overflow', '');
            jm('#g_web').off('touchmove');
        });
};

/*
type 1:电话服务、2:信息服务
*/
const triggerServiceNumber = function (type, number) {
    if (jm.os.supportsTouch) {
        if (type == 1) {
            // 这里进行 百度小程序兼容， swan 来自引入的脚本
            var isBaiduMiniAppEnv = window.navigator.userAgent.indexOf('swan/') != -1;
            if (typeof swan != 'undefined' && isBaiduMiniAppEnv) {
                // eslint-disable-next-line no-undef
                swan.webView.getEnv(function (res) {
                    if (res.smartprogram) {
                        //判断是否是小程序环境
                        // eslint-disable-next-line no-undef
                        swan.makePhoneCall({
                            phoneNumber: number,
                        });
                    } else {
                        window.location.href = 'tel:' + number; //web环境
                    }
                });
            } else {
                window.location.href = 'tel:' + number;
            }
        } else if (type == 2) {
            window.location.href = 'sms:' + number;
        }
    } else {
        mobiAlert('该功能无法在桌面浏览器中启动');
    }
};

//如果有多个客服电话，则弹出多个
const showPhonesBg = function () {
    var phoneBox = jm('.J_showMorePhone');

    if (phoneBox.length < 1) {
        return;
    }

    phoneBox.on('click.phone', function () {
        showJumpPhone();
    });
};

//如果有多个客服QQ，则弹出多个
const showQqBg = function () {
    var qqBox = jm('.J_showMoreQq');

    if (qqBox.length < 1) {
        return;
    }

    qqBox.on('click.qq', function () {
        showMoreQQ();
    });
};

export const deviceTypeIsMobi = function () {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
    var bIsAndroid = sUserAgent.match(/android/i) == 'android';
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        return true;
    } else {
        return false;
    }
};

const openQQ = function (accout) {
    if (VITE_APP_MODE !== 'visitor') {
        Fai.top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
        return;
    }
    let href = getQQUrl(accout);
    window.location.href = href;
};

const copyAccountAndOpenWX = function (account) {
    if (!Fai.top.isWXAddFansAuthAllow) {
        mobiIng('网站版本已过期，请联系管理员', 0);
        return;
    }
    jm('#wx_addFans_popup_wrap').remove();
    const UA = window.navigator.userAgent;
    const IS_WX = /MicroMessenger/.test(UA);
    copyDataToClipboard({
        data: account,
        sCb() {
            if (IS_WX) {
                mobiIng('复制微信号成功', 1);
            } else {
                // 唤起App
                let dialogStr = `
          <div id="wx_addFans_popup_wrap" class="wx_addFans_popup_wrap">
            <div class="wx_addFans_popup">
              <div class="text_wrap">
                <span class="success_text">${LS.js_wxCopySuccess}</span>
                <span class="countdown">${LS.js_wxJump}</span>
              </div>
              <div class="btn_wrap">
                <div class="cancel_btn">${LS.js_cancel}</div>
                <div class="open_btn">${LS.js_open}</div>
              </div>
            </div>
          </div>
        `;
                let $dialog = jm(dialogStr);
                jm('#g_body').append($dialog);
                // 阻止滚动穿透
                $dialog.on('touchmove', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                });

                $dialog.find('.cancel_btn').on('click', () => {
                    $dialog.remove();
                });

                $dialog.find('.open_btn').on('click', () => {
                    window.location.href = 'weixin://';
                    $dialog.remove();
                });
            }
        },
        fCb() {
            mobiIng('复制微信号失败，请手动复制', 1);
        },
    });
};

const showMapService = function (addresStr) {
    var addresStrObj, addressName, addressDetail, cityStr, addressStr;
    if (addresStr) {
        addresStrObj = JSON.parse(addresStr);
        addressName = decodeHtml(addresStrObj.an);
        addressDetail = decodeHtml(addresStrObj.ad);
        cityStr = addresStrObj.c;
        addressStr = addresStrObj.a;
    } else {
        addresStrObj = Fai.top._onlineServiceJson.map;
        cityStr = addresStrObj.city;
        addressStr = addresStrObj.d_address;
    }

    var resultPromise = Mobi.mobiMapModule.bMapSearch('', {}, cityStr, addressStr);

    resultPromise
        .then(function (result) {
            let { title, address, point } = result;
            var location = {
                name:
                    typeof addressName == 'undefined' || (!addresStrObj.inl && addressName == '') ? title : addressName,
                province: cityStr,
                areaDetail: addressStr,
                detail: addressDetail || address,
                tel: addresStrObj.t,
                other: '',
                bmp: {
                    x: point.lng,
                    y: point.lat,
                },
                distance: '0',
            };
            renderMapOption(location, addresStrObj);
        })
        .catch(function () {
            // 如果搜索不到用户的地址，给一个默认的地址
            var location = {
                name: '新增地址',
                province: '广州市',
                areaDetail: '天河路250号',
                detail: '广州市天河路250号',
                tel: '',
                other: '',
                bmp: {
                    x: 113.333394,
                    y: 23.139114,
                },
                distance: '0',
            };
            // 拼装在线地图option
            renderMapOption(location, addresStrObj);
        });
};

const renderMapOption = function (location, addresStrObj) {
    var module = {};
    module.content = {};
    module.content.isShowTel = addresStrObj.ts;
    module.otherInfo = {
        title: LS.js_mobiMapDetailTitle,
        route: LS.js_mobiMapDetailRoute,
        call: LS.js_mobiMapDetailCall,
    };
    var options = {
        manageMode: VITE_APP_MODE !== 'visitor',
        isJumpLink: true,
    };

    mapDetail.render(location, options, module);
};

const setJumpServ = function (id, popupID) {
    //编辑时，解除点击事件，避免选定
    Fai.top.$('.fk-menuItem' + id).unbind('click.miClick');
    Fai.top.$('#popupWindowIframe' + popupID)[0].contentWindow.editJumpServ(id);
};
/*****链接控件删除客服********/

const delJumpServ = function (id, popupID) {
    //编辑时，解除点击事件，避免选定
    Fai.top.$('.fk-menuItem' + id).unbind('click.miClick');
    popupWindowVersionTwo.createPopupWindow({
        title: '删除客服',
        width: 320,
        height: 180,
        frameScrolling: false,
        noframeUrl: true,
        noHeadLine: true,
        popUpTip: '删除该客服会影响到所有链接了该客服的模块，确定要删除吗?',
        popUpWinZIndex: 9999,
        closeFunc: function closeFunc(reData) {
            if (reData && reData.select) {
                Fai.top.$('#popupWindowIframe' + popupID)[0].contentWindow.delJumpServ(id);
            }
        },
    });
};

const PhotoDetailSwipeForJumpCtrl = function (groupId) {
    //由链接控件绑定到图册的情况
    var photoData = [];
    request.get(`/ajax/getPhotoData_h.jsp?groupId=${groupId}&cmd=getWafNotCk_getPhotoData`).then((data) => {
        let result = data.data;
        if (result && result.success) {
            if (result.info != null) {
                photoData = result.info;
            }
        }
        var images = [],
            img;
        for (var i = 0, len = photoData.length; i < len; i++) {
            img = new Image();
            if (i < 3) {
                img.src = photoData[i].picPath;
            }
            images.push(img);
        }

        if (images.length < 1) {
            return;
        }
        // 修复点击产品详情图片不能出现幻灯片bug
        var triggerEventType = 'click';
        let options = {
            imageScaleMethod: 'fitNoUpscale',
            jQueryMobile: true, // 修复由于滚动后遮罩位置resetPosition，导致无法遮住window导致的bug @ken 20180131
            enableMouseWheel: false,
            resetToolbarPosition: true,
            loop: false,
            photoJson: photoData,
            triggerEventType,
            captionAndToolbarAutoHideDelay: 0,
            getImageCaption(index, length) {
                var captionText, captionEl;
                captionText = index + ' / ' + length;
                captionEl = document.createElement('div');
                captionEl.className = 'ps-page';
                captionEl.appendChild(document.createTextNode(captionText));
                return captionEl;
            },
            getToolbar() {
                return (
                    '<div class="ps-toolbar-close ps-toolbar-close-style icon-toolbar-close-style"></div>' +
                    '<div class="ps-toolbar-base">' +
                    '<div class="ps-toolbar-base-title" id="wrapperTitle"></div>' +
                    '<div class="ps-toolbar-base-detail icon-toolbar-base-detail" id="wrapperTitleDetail"></div>' +
                    '</div>' +
                    '<div class="ps-toolbar-base-describe" id="wrapperBasic"><div id="scrollerBasic"></div>' +
                    '</div>'
                );
            },
            uiDetailCallBack(srcElement, parentEl, elChlidTitleName) {
                var self = this;
                jm(srcElement).html('');
                var indexJson = photoData[instance.currentIndex],
                    groupId = indexJson['groupId'],
                    id = indexJson['id'],
                    name = indexJson['name'];

                jm(elChlidTitleName).html(name);
                jm(parentEl).addClass(PhotoSwipe.Carousel.CssClasses.itemLoading);
                request
                    .post(`/ajax/photo_h.jsp?cmd=getWafNotCk_getInfo&id=${id}&groupId=${groupId}`)
                    .then((data) => {
                        let result = data.data;
                        jm(srcElement).html(result);

                        var imageDom = srcElement.querySelectorAll('img');
                        //console.log(parentEl);
                        if (imageDom.length === 0) {
                            self.detailScroll && self.detailScroll.destroy();
                            self.detailScroll = null;
                            self.destroyScroll = new iScroll(parentEl);
                            jm(parentEl).removeClass(PhotoSwipe.Carousel.CssClasses.itemLoading);
                        } else {
                            for (var i = 0; i < imageDom.length; i++) {
                                var image = new Image();
                                image.src = imageDom[i].src;
                                image.onload = function () {
                                    if (i === imageDom.length) {
                                        self.detailScroll && self.detailScroll.destroy();
                                        self.detailScroll = null;
                                        self.destroyScroll = new iScroll(parentEl);
                                        jm(parentEl).removeClass(PhotoSwipe.Carousel.CssClasses.itemLoading);
                                    }
                                };
                            }
                        }
                    })
                    .catch(() => {
                        mobiIng(LS.js_systemError);
                    });
            },
        };
        let PhotoSwipe = window.Code.PhotoSwipe;
        if (VITE_APP_MODE !== 'visitor') {
            options.target = jm('#g_body')[0];
        }
        let instance = PhotoSwipe.attach(images, options);
        instance &&
            instance.addEventHandler(PhotoSwipe.EventTypes.onCaptionAndToolbarShow, function () {
                if (this.scroll) this.scroll.destroy();
                this.scroll = new iScroll('wrapperBasic');
            });
        jm(images[0]).trigger('click');
        instance.addEventHandler(PhotoSwipe.EventTypes.onDisplayImage, function (e) {
            let currentIndex = e.target.currentIndex,
                img;
            if (currentIndex > 1) {
                let nextImage = photoData[currentIndex + 1];
                let target = e.target.cache.images[e.target.currentIndex + 1];
                if (target) {
                    img = target.imageEl;
                    img.src = nextImage.picPath;

                    recoverPhotoSwipeImgSize(img); //恢复图片尺寸 by jser 2017-6-27
                }
            }
        });
    });
};

//恢复PhotoSwipe图片尺寸 by jser 2017-6-27
const recoverPhotoSwipeImgSize = function (img) {
    var $img = jm(img),
        isNeedFix = false,
        isComplete = img.complete; //判断图片是否加载过

    if (img.style.width === '0px') {
        isNeedFix = true;
        $img.css('width', '');
    }
    if (img.style.height === '0px') {
        isNeedFix = true;
        $img.css('height', '');
    }

    if (!isNeedFix) {
        return;
    }

    if (isComplete) {
        setImgCenter($img);
    } else {
        $img.hide(); //隐藏
        $img.parent().addClass('ps-carousel-item-loading');
        $img.one('load.adjust', function () {
            var $img = jm(this);
            $img.parent().removeClass('ps-carousel-item-loading');
            $img.show(); //显示
            setImgCenter($img);
        });
    }
    //设置图片位于页面居中 by jser 2017-6-23
    function setImgCenter($img) {
        var clientW = document.body.clientWidth,
            clientH = document.body.clientHeight,
            imgW = $img.width(),
            imgH = $img.height();

        $img.css({
            height: imgH + 'px',
            width: imgW + 'px',
            left: (clientW - imgW) / 2 + 'px',
            top: (clientH - imgH) / 2 + 'px',
        });
    }
};

export default {
    triggerServiceNumber,
    showPhonesBg,
    showQqBg,
    showMoreQQ,
    showJumpPhone,
    openQQ,
    copyAccountAndOpenWX,
    showMapService,
    showShare,
    showCreateShareGrapic,
    jumpToModulePosition,
    baiduBridgeCodes,
    isOpenBaiduBridge,
    showBaiduBridge,
    showBaiduBridgeV2,
    showWeChatService,
    setJumpServ,
    delJumpServ,
    PhotoDetailSwipeForJumpCtrl,
    jumpAnimation,
};
