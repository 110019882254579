import { MobiModule } from '@/modules/Module';
import { MODULE_STYLE } from '@/def/module';
import ProductResultView from './module/index.vue';

class _CookiePolicy extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.SYS_COOKIE, options);
    }

    render() {
        return ProductResultView;
    }
}

export const CookiePolicy = new _CookiePolicy();
