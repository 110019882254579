/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    independentSetFooterNavData: (typeof Fai === 'object' && Fai.top?._colInfo?.other?.in?.isfn) ?? false,
    independentSetHead: false,
    independentSetHeadHide: false,
    footerNavAutoHide: false,
    mobiTitleClose: false,
    isIndependentShowFloatEffect: false,
    isIndependentShowFloatEffectType: false,
    middlePanelLoadCount: 0,
    fullPageNavHide: false,
    languageType: false,
    fullPageBannerShow: false,
    isShowFloatEffect: true,
    // Fai.top._pattern && Fai.top._pattern.hs.hh
    headHideV3: 0,
    independentNavHide: false,
    independentBannerShow: false,
    independentBannerSet: false,
    independentBackGroundSet: false,
    backToTopType: false,
    isDisabledFloatEffectPage: false,
    isFloatEffectAllow: false,
});

/**
 * =================================================
 *
 * getters
 *
 * =================================================
 */

const getters = {
    getColName(state) {
        return state.colName;
    },
    getMobiTitleClose(state) {
        return state.mobiTitleClose;
    },
    getIndependentNavHide(state) {
        return state.independentNavHide;
    },
    getIndependentBannerShow(state) {
        return state.independentBannerShow;
    },
    getIndependentBannerSet(state) {
        return state.independentBannerSet;
    },
    getFooterNavAutoHide(state) {
        return state.footerNavAutoHide;
    },
    getIndependentBackGroundSet(state) {
        return state.independentBackGroundSet;
    },
    getIsIndependentShowFloatEffect(state) {
        return state.isIndependentShowFloatEffect;
    },
    getIsIndependentShowFloatEffectType(state) {
        return state.isIndependentShowFloatEffectType;
    },
    getMiddlePanelLoadCount(state) {
        return state.middlePanelLoadCount;
    },
    getFullPageNavHide(state) {
        return state.fullPageNavHide;
    },
    getLanguageType(state) {
        return state.languageType;
    },
    getBackToTopType(state) {
        return state.backToTopType;
    },
    getFullPageBannerShow(state) {
        return state.fullPageBannerShow;
    },
    getIsShowFloatEffect(state) {
        return state.isShowFloatEffect;
    },
    getHeadHide(state) {
        return state.headHideV3;
    },
    isShowFloatEffect(state, getters) {
        const isShowFloatEffect = getters.getIsIndependentShowFloatEffectType
            ? getters.getIsIndependentShowFloatEffect
            : getters.getIsShowFloatEffect;

        return isShowFloatEffect && !state.isDisabledFloatEffectPage && state.isFloatEffectAllow;
    },
};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    updateIndependentBannerSet(context, independentBannerSet) {
        context.commit('independentBannerSetChange', independentBannerSet);
    },
    updateIndependentBannerShow(context, independentBannerShow) {
        context.commit('independentBannerShowChange', independentBannerShow);
    },
    updateIndependentNavHide(context, independentNavHide) {
        context.commit('independentNavHideChange', independentNavHide);
    },
    updateFooterNavAutoHide(context, footerNavAutoHide) {
        context.commit('footerNavAutoHideChange', footerNavAutoHide);
    },
    updateIndependentBackGroundSet(context, independentBackGroundSet) {
        context.commit('independentBackGroundSetChange', independentBackGroundSet);
    },
    updateHeadHidev3(context, headHideV3) {
        context.commit('headHideV3Change', headHideV3);
    },
    updateFullPageBannerShow(context, fullPageBannerShow) {
        context.commit('fullPageBannerShowChange', fullPageBannerShow);
    },
    updateFullPageNavHide(context, fullPageNavHide) {
        context.commit('fullPageNavHideChange', fullPageNavHide);
    },
    updateBackToTopType(context, backToTopType) {
        context.commit('backToTopTypeChange', backToTopType);
    },
    updateMobiTitleClose(context, mobiTitleClose) {
        context.commit('mobiTitleCloseChange', mobiTitleClose);
    },
    updateLanguageType(context, languageType) {
        context.commit('languageTypeChange', languageType);
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    independentSetFooterNavDataChange(state, independSet) {
        state.independentSetFooterNavData = independSet;
    },
    independentSetHeadChange(state, independentSetHead) {
        state.independentSetHead = independentSetHead;
    },
    independentSetHeadHideChange(state, independentSetHeadHideChange) {
        state.independentSetHeadHide = independentSetHeadHideChange;
    },
    changeIsIndependentShowFloatEffect(state, isIndependentShowFloatEffect) {
        state.isIndependentShowFloatEffect = isIndependentShowFloatEffect;
    },
    changeIsIndependentShowFloatEffectType(state, isIndependentShowFloatEffectType) {
        state.isIndependentShowFloatEffectType = isIndependentShowFloatEffectType;
    },
    changeIsShowFloatEffect(state, isShowFloatEffect) {
        state.isShowFloatEffect = isShowFloatEffect;
    },
    independentBannerShowChange(state, payload) {
        state.independentBannerShow = payload.independentBannerShow;
    },
    changeIsDisabledFloatEffectPage(state, payload) {
        state.isDisabledFloatEffectPage = payload;
    },
    changeIsFloatEffectAllow(state, payload) {
        state.isFloatEffectAllow = payload;
    },
    mobiTitleCloseChange(state, payload) {
        state.mobiTitleClose = payload.mobiTitleClose;
    },
    independentBannerSetChange(state, payload) {
        state.independentBannerSet = payload.independentBannerSet;
    },
    independentBackGroundSetChange(state, payload) {
        state.independentBackGroundSet = payload.independentBackGroundSet;
    },
    independentNavHideChange(state, payload) {
        state.independentNavHide = payload.independentNavHide;
    },
    footerNavAutoHideChange(state, footerNavAutoHide) {
        state.footerNavAutoHide = footerNavAutoHide;
    },
    headHideV3Change(state, headHideV3) {
        state.headHideV3 = headHideV3;
    },
    fullPageBannerShowChange(state, payload) {
        state.fullPageBannerShow = payload.fullPageBannerShow;
    },
    backToTopTypeChange(state, payload) {
        state.backToTopType = payload.backToTopType;
    },
    languageTypeChange(state, payload) {
        state.languageType = payload.languageType;
    },
    fullPageNavHideChange(state, payload) {
        state.fullPageNavHide = payload.fullPageNavHide;
    },
    updatePanelSettingData(state, payload) {
        state.independentNavHide = payload._independentNavHide;
        state.headHideV3 = payload._pattern.hs.hh;
        state.independentBannerShow = payload.independentBannerShow;
        state.independentBannerSet = payload.independentBannerSet;
        state.independentBackGroundSet = payload.independentBackGroundSet;
        state.backToTopType = payload._other.mobiBackTop.isOpen;
        state.independentSetHead = payload.independentSetHead;
        state.independentSetHeadHide = payload.independentSetHeadHide;
    },
    updateMiddelLoadCount(state) {
        state.middlePanelLoadCount = state.middlePanelLoadCount + 1;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
