//主题 3.0的语言版本切换的逻辑
export function languageCtrlV3() {
    var $languaSwitch,
        $languageSelect = jm('#languageSelect');

    function switchLan(e) {
        if ($languageSelect.find('.lanItem').length < 1) {
            return;
        }

        if (parseInt(jm('#languageSelect').height()) > 0) {
            closeSwitch();
        } else {
            if (VITE_APP_MODE !== 'visitor') {
                Vue.prototype.$layer.removeAllEditLayer();
            }
            openSwitch();
        }

        e.stopPropagation();
        return false;
    }

    if (VITE_APP_MODE !== 'visitor') {
        setTimeout(function () {
            $languaSwitch = $('#languaSwitch');
            $languaSwitch.unbind('click').bind('click', switchLan);
        }, 2000);
    } else {
        $languaSwitch = jm('#languaSwitch');
        $languaSwitch.unbind('click').bind('click', switchLan);
    }

    function closeSwitch() {
        $languageSelect.height(0);
        $languageSelect.css('background', 'rgba(255,255,255,0)').css('overflow', 'hidden');
    }

    function openSwitch() {
        var tmpHeight = parseFloat($languageSelect.find('.lanContainer').height()),
            itemCurrent = jm('#languageSelect .lanContainer').find('div[currentlan]'); //目前选中的语言
        $languageSelect.height(tmpHeight + 'px');
        $languageSelect.css('background', 'rgba(255,255,255,0.9)').css('overflow', 'unset');

        itemCurrent.addClass('current');
    }

    //绑定事件
    if ($languageSelect.find('.lanItem').length > 0) {
        $languageSelect
            .find('.lanItem')
            .unbind('click')
            .bind('click', function (e) {
                if (jm('#languageSelect').length < 1 || parseInt(jm('#languageSelect').height()) == 0) {
                    return;
                }
                e.stopPropagation();
                if (!(VITE_APP_MODE !== 'visitor')) {
                    // 管理态在 Mobi.initMultiLanguage 中绑定了跳转事件
                    document.location.href = jm(this).attr('value');
                }
                jm(this).addClass('current');
            })
            .unbind('mouseover')
            .bind('mouseover', function () {
                if (jm('#languageSelect').length < 1 || parseInt(jm('#languageSelect').height()) == 0) {
                    return;
                }
                $languageSelect.find('.lanItem').removeClass('current');
            });

        jm('body')
            .unbind('click')
            .bind('click', function (event) {
                var targetId = event.target || event.srcElement;

                if (targetId.id === 'languageSelect') {
                    return;
                }
                closeSwitch();
                // // 搜狗浏览器的<a>标签父元素不允许event.stopPropagation()\event.preventDefault()等方法，否则链接不跳转 @ken 20180320
                // if (
                //     !(
                //         navigator.userAgent
                //             .toLowerCase()
                //             .indexOf('sogoumobilebrowser') > -1
                //     )
                // ) {
                //     event.stopPropagation();
                // }
            });
    }
}
