import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { ing } from '@/shared/fai';
import { request } from '@/api/request/index';

// cmd 有两个值 news 和 newsV3
export const getNewsCommentAjaxPagenation = function (nid, pageno, cmd) {
    if (!nid) return;
    request
        .post(`/ajax/other_h.jsp?cmd=${cmd}`, {
            data: {
                id: nid,
                pageno,
                ajaxPage: true,
            },
        })
        .catch(() => {
            ing('error');
        })
        .then((res) => {
            let data = res.data;
            if (!data.html) data.html = '';
            jm('#newsDetailPanel .commentBox').remove();
            jm('#newsDetailPanel').append(data.html);
            bindImgLazyLoad('newsDetailPanel');
            var ch = parseInt(jm('#newsDetailPanel .commentBox').offset().top);
            var hy = 0;
            var pdHeader = jm('.productDetailHeader');
            if (pdHeader.css('display') == 'none') {
                hy = ch;
            } else {
                hy = ch - parseInt(pdHeader.height());
            }
            window.scrollTo(0, hy);
        });
};
