export const MemberDef = {
    Info: {
        AID: 'aid', // int
        WID: 'wid', // int
        ID: 'id', // int
        ACCT: 'acct', // varchar(50)
        PWD: 'pwd', // binary(16)
        NAME: 'name', // varchar(50)
        EMAIL: 'email', // varchar(50)
        PHONE: 'phone', // varchar(50)
        MOBILE: 'mobile', // varchar(50)   // 会员新增手机项
        MOBILE_CT: 'mobileCt', // varchar(10)   // 会员的手机号码所在国家地区,数据在SiteProfDef中MobileCountry
        REMARK: 'remark', // varchar(200)
        SIGNUP_TIME: 'signupTime', // timestamp
        LOGIN_TIME: 'loginTime', // timestamp
        LOGIN_COUNT: 'loginCount', // int
        PROP0: 'prop0', // varchar(50), 自定义数据项
        PROP1: 'prop1', // varchar(50), 自定义数据项
        PROP2: 'prop2', // varchar(50), 自定义数据项
        PROP3: 'prop3', // varchar(50), 自定义数据项
        PROP4: 'prop4', // varchar(50), 自定义数据项
        PROP5: 'prop5', // varchar(50), 自定义数据项
        PROP6: 'prop6', // varchar(50), 自定义数据项
        PROP7: 'prop7', // varchar(50), 自定义数据项
        PROP8: 'prop8', // varchar(50), 自定义数据项
        PROP9: 'prop9', // varchar(50), 自定义数据项
        PROP10: 'prop10', // varchar(50), 自定义数据项
        PROP11: 'prop11', // varchar(50), 自定义数据项
        PROP12: 'prop12', // varchar(50), 自定义数据项
        PROP13: 'prop13', // varchar(50), 自定义数据项
        PROP14: 'prop14', // varchar(50), 自定义数据项
        PROP15: 'prop15', // varchar(50), 自定义数据项
        PROP16: 'prop16', // varchar(50), 自定义数据项
        PROP17: 'prop17', // varchar(50), 自定义数据项
        PROP18: 'prop18', // varchar(50), 自定义数据项
        PROP19: 'prop19', // varchar(50), 自定义数据项

        //以下4个字段为2013 0503合并buddy member逻辑从buddyDef迁移过来的
        MEMBER_TYPE: 'memberType', //int default 1	用户类型
        GROUP_ID_BIT: 'groupIdBit', //int default 0
        FLAG: 'flag', //int falg

        GROUP_ID_LIST: 'groupIdList', // 动态数据FaiList<Integer>

        LEVEL: 'level', // int 会员等级id

        BULLETIN_READ_TIME: 'bulReadTime', // 会员公告最后一次访问时间
        QQ_OPENID: 'qqOpenId', // varchar(50), qq openId
        SINA_OPENID: 'sinaOpenId', // varchar(50), sina openId
        WX_OPENID: 'wxOpenId', // varchar(50), wx openId
        MOBI_WX_OPENID: 'mobiWxOpenId', // varchar(50), mobiWX openId site和mobi打通微信登录后，site使用此字段
        WX_NEW_OPENID: 'wxNewOpenId', // varchar(50), 唯建站公众号迁移到另一个公众号  存储迁移生成的新的openId
        FAI_OPENID: 'faiOpenId', // varchar(50), 打通凡科建站和凡科平台openId
        WXMALLAPP_OPENID: 'wxAppOpenId', // varchar(50), 小程序的会员openId
        WXMALLAPP_UNIONID: 'wxAppUnionId', // varchar(50), 小程序的会员unionId

        CURRENT_INTEGRAL: 'currentIgt', // int(50), 当前积分
        TOTAL_INTEGRAL: 'totalIgt', // int(50), 累计积分

        ADD_INTEGRAL: 'addItg', // 临时字段，用于增加积分记录
        SIGN_BY_MOBILE: 'signByMobile', // 临时字段
        SIGN_BY_OTHER_LOGIN: 'signByOtherLogin', //临时字段
        SIGN_ALLOW_ALL: 'signAllowAll', //临时字段 用于第三方注册会员当不开启相关会员资料项不需要认证
        LOGIN_TYPE: 'loginType', //临时字段 用于第三方注册会员时，防止并发问题而重复添加member，在svr锁内进行排重。
        AUTH_TYPE: 'authType', //临时字段 用于邮箱注册防止并发问题而重复添加member，在svr锁内进行排重。
        IS_OPEN_ITG: 'isOpenItg', //临时字段 用于告知svr是否开启积分功能

        // 推广员相关字段
        PROMOTE_CREATE_TIME: 'promoteCreateTime', //被推广时间,这段时间订单推广员有收益
        PROMOTE_MEMBER_ID: 'promoteMemberId', //该会员的推广员的memberId，该id与上面的时间挂钩

        OTHER: 'other', // blob 扩展字段(对外透明)

        ADDRINFOList: 'addrInfoList', //收货地址(临时字段,存放于other) FaiList
        HEAD_PIC: 'headPic', //会员头像(临时字段,存放于other)
        PRODUCT_COLLECTIONS: 'productCollections', //产品收藏(临时字段,存放于other)
        COUPON_LIST: 'couponList', //优惠劵     (临时字段,存放于other) FaiList

        // 分销相关字段
        DIST_VALID_TIME: 'distValidTime', //分销有效的开始时间
        DIST_ID: 'distId', //该会员的分销商的Id，该id与上面的时间挂钩

        //memberExpand表
        // 会员储值相关字段
        CURRENT_DEPOSIT: 'currentDeposit', // double 会员当前储值
        TOTAL_DEPOSIT: 'totalDeposit', // double 会员总计储值
        TRADE_PWD: 'tradePwd', // binary(16) 交易密码

        PWD_AID: 'pwdAid', // 记录校验会员密码时，加盐用到的aid（来源于最开始添加该会员的网站，用于克隆场景）
        TRADE_PWD_AID: 'tradePwdAid', // 记录校验交易密码时，加盐用到的aid（来源于最开始添加该会员的网站，用于克隆场景）

        IS_MANAGE_ADD: 'isManageAdd', //是否是b端用户直接在会员管理页添加的
        OLD_LEVEL_BEFORE_BUY_CARD: 'oldLevelBeforeBuyCard', //int 购买会员卡前的会员等级id，用户会员卡过期时恢复会员等级，默认-1
        VIEWER_ID: 'viewerId', //易销传过来的viewerId
    },
    Limit: {
        PWD_MINLEN: 4,
        PWD_MAXLEN: 20,
        ACCT_MAXLEN: 50,
        NAME_MAXLEN: 50,
        EMAIL_MAXLEN: 50,
        PHONE_MAXLEN: 50,
        REMARK_MAXLEN: 200,
        FIELD_MAXLEN: 50,
        //合并buddy member逻辑从buddyDef迁移
        COUNT_MAX: 1000000,
        IMG_MAX_SIZE: 5,
        IMG_TYPE_VALId: '*.jpg,*.jpeg,*.bmp,*.gif,*.png',
        //收货地址最多条数限制
        ADDR_LIMIT: 30,
    },
    AuthType: {
        NEED_AUTH: 1, // 需要通过管理员验证
        ALLOW_ALL: 2, // 注册立即成为会员
        NEED_MAIL_AUTH: 3, //通过邮箱验证
        NEED_MOBILE_AUTH: 4, // 通过手机验证
    },
};

export const MemberPropDef = {
    Info: {
        AID: 'aid', // int
        WID: 'wid', // int
        ID: 'id', // auto_increment
        NAME: 'name', // varchar(20)
        FIELD_KEY: 'fieldKey', // varchar(20)
        TYPE: 'type', // tinyint
        FLAG: 'flag', // tinyint

        OTHER: 'other', // BLOB(对外透明)

        PROP_TYPE: 'pt', //资料项种类(临时字段)
        PROP_CONTENT: 'pc', //资料项内容(临时字段)

        PROP_PLACEHOLDER: 'pp', // 外部使用，不入库
    },
    Flag: {
        HIDDEN: 0x1, // 是否隐藏 （改名：为注册时填写）
        REQUIRED: 0x2, // 是否必填

        DISABLE: 0x4, // 是否启用
        OTHER_LOGIN_REQUIRED: 0x8, //第三方登录填写
        SET_OTHER_LOGIN_REQUIRED: 0x10, //用户是否设置过第三方登录填写(区分新旧用户)

        DELETED: 0x20, // 标记电话资料项是否被删除, 仅对保留电话资料项的旧用户生效. 其他资料项仍然走删库逻辑
    },
    Limit: {
        CUS_COUNT_MAX: 20, // 这个数量需要和member表的prop字段数目一致

        NAME_MINLEN: 1,
        NAME_MAXLEN: 20,

        CONTENT_LIST_INPUT_COUNT_MAX: 100,
        DROPDOWN_CHILDREN_MAX: 100,
    },
    Type: {
        CUS: 1,
        SYS_NAME: 2, // 姓名
        SYS_EMAIL: 3, // email
        SYS_PHONE: 4, // 联系电话
        SYS_MOBILE: 5, // 手机
    },
    PropType: {
        SINGLE_LINE: 0, // 单行文本
        DROP_DOWN: 1, // 下拉框
    },
};

export const MobileCountry = {
    LSKEY: 'lskey', // 对应多语言版本里面的key
    CT_NAME: 'ctName', // 国家的名称:简称  如中国大陆:ch
    CT_SHOW_NAME: 'ctShowName', //显示的名称：如：中国大陆
    CT_CODE: 'ctCode', // 国家的手机区号

    CHINESE: 'ch', // 中国大陆
    HONGKONG: 'hk', // 香港
    MACAU: 'mc', // 澳门
    TAIWAN: 'tw', // 台湾
    KOREA: 'kr', // 韩国
    JAPAN: 'jp', // 日本
    AMERICA: 'ac', // 美国
    CANADA: 'cnd', // 加拿大
    ENGLAND: 'el', // 英国
    MALAYSIA: 'mls', // 马来西亚
    THAILAND: 'tl', // 泰国
    VIETNAM: 'vtn', // 越南
    FRENCH: 'fc', // 法国
    PHILIPPINES: 'plp', // 菲律宾
    INDONESIA: 'idns', // 印度尼西亚
    ITALY: 'itl', // 意大利
    RUSSIA: 'rs', // 俄罗斯
    ZEALAND: 'zl', // 新西兰
    NETHERLANDS: 'nl', // 荷兰
    SWEDISH: 'swd', // 瑞典
    AUSTRALIA: 'atl', // 澳大利亚
    GERMANY: 'gm', // 德国
    SINGAPORE: 'snp', //新加坡
    FINLAND: 'fld', //芬兰
    DENMARK: 'dmk', //丹麦
    ISRAEL: 'isr', //以色列
};
