export const MODULE_ID = 21;

export const GROUP_STYLE = {
    DEFUALT: 0,
    IMAGE: 1, // 三级导航-垂直
    TEXT: 2, // 一级导航-垂直
    ONE_LEVEL_HORIZON: 3,
    TWO_LEVEL_VERTICAL: 4,
    TWO_LEVEL_HORIZON: 5,
    THREE_LEVEL_HORIZON: 6,
};
