import { MobiModule } from '@/modules/Module';
import BackTopModuleView from './backtop.vue';

class _BackTop extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return BackTopModuleView;
    }
}

export const BackTop = new _BackTop();
