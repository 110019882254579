<template>
    <module-frame :module-id="moduleId">
        <div id="mLogin">
            <div class="loginHeader webHeaderBg">
                <a class="g_close icon-gClose" @click="returnBackClick"></a>
                <span class="title pageTitle" v-text="LS.col_member_login"></span>
            </div>
            <div class="loginContent">
                <div v-if="manageMode" class="notice">您目前处于网站管理状态，请先退出再登录</div>
                <div class="g_globalLine forAcctLogin">
                    <input
                        id="user"
                        class="itemEdit g_input mustItem"
                        type="text"
                        :maxlength="MemberDef.Limit.ACCT_MAXLEN"
                        :placeholder="acctPlaceHolder"
                    />
                    <span class="icon-userIcon g_mainColor"></span>
                </div>
                <div class="g_globalLine forAcctLogin">
                    <input
                        id="password"
                        class="itemEdit g_input mustItem"
                        type="password"
                        :maxlength="MemberDef.Limit.PWD_MAXLEN"
                        :placeholder="passwordPlaceHolder"
                    />
                    <span class="icon-pswIcon g_mainColor"></span>
                </div>
                <template v-if="signUpbyMobile">
                    <div class="g_globalLine forFastLogin">
                        <input
                            id="acquirerInput"
                            type="text"
                            class="itemEdit g_input mustItem"
                            :placeholder="LS.memberDialogPlzEnterMobile"
                        />
                        <span class="icon-fastUserIcon g_mainColor"></span>
                    </div>
                    <div class="g_globalLine forFastLogin" style="font-size: 0">
                        <input
                            id="memCodeInput"
                            class="itemEdit g_input mustItem"
                            maxlength="6"
                            :placeholder="LS.msgBoardValidateCode"
                        />
                        <span class="icon-fastCodeIcon g_mainColor"></span>
                        <input id="memCodeSign" style="display: none" />
                        <div id="memCodeSplitLine" class="memCodeSplitLine"></div>
                        <div
                            id="acquireCode"
                            :class="{ 'g-input': manageMode }"
                            @click="acquireCodeClick"
                            v-text="LS.mobi_getMobileCode"
                        ></div>
                    </div>
                </template>
                <div class="loginCaptchaCtrl g_globalLine">
                    <input
                        id="loginCaptcha"
                        type="text"
                        class="captchaText g_input"
                        maxlength="4"
                        :placeholder="LS.mobi_EnterVerificationCode"
                    />
                    <img id="loginCaptchaImg" class="captchaImg" :src="loginCaptchaImg" @click="loginCaptchaImgClick" />
                </div>
                <div v-if="mbAutoLogin" class="autoLoginCtrl g_globalLine" style="text-align: left">
                    <input id="autoLogin" type="checkBox" class="autoLoginCheck" />
                    <label for="autoLogin" class="autoLoginLabel" @click="autoLoginLabelClick"
                        ><span class="icon-uncheckIcon g_mainColor"></span>{{ LS.memberLogin_rememberMe }}</label
                    >
                </div>
                <div class="g_globalLine forAcctLogin">
                    <input
                        id="memberLogin"
                        type="button"
                        class="g_button sendIcon"
                        :value="LS.memberLoginLogin"
                        @click="memberLoginClick"
                    />
                </div>
                <div v-if="signUpbyMobile" class="g_globalLine forFastLogin">
                    <input
                        type="button"
                        class="g_button sendIcon"
                        :value="LS.memberLoginLogin"
                        @click="fastLoginClick"
                    />
                </div>
                <div class="loginBottom g_globalLine">
                    <template v-if="signUpbyMobile">
                        <span
                            id="mobileFastLoginToggle"
                            class="g_mainColor forAcctLogin loginToggle"
                            @click="mobileFastLoginToggleClick"
                            >{{ LS.mobileFastLogin }}</span
                        >
                        <span
                            id="acctLoginToggle"
                            class="g_mainColor forFastLogin loginToggle"
                            @click="acctLoginToggleClick"
                            >{{ LS.loginWithAcct }}</span
                        >
                    </template>
                    <a v-if="findPwdOpen" class="g_mainColor freeRegister" :href="findPwdUpUrl">{{
                        LS.memberFindPwd
                    }}</a>
                    <a class="g_mainColor freeRegister" :href="signUpUrl">{{ LS.memberLogin_freeReg }}</a>
                </div>
                <template v-if="qqOpen || sinaOpen || wxOpen">
                    <div class="oLgnPanel">
                        <div class="head">
                            <div class="leftLine"></div>
                            <div class="middle" v-text="LS.mobi_member_OtherLgn"></div>
                            <div class="rightLine"></div>
                        </div>
                        <div class="oLgn">
                            <div class="qqLgnPanel" :style="qqLgnPanelStyle">
                                <div id="qqLgn" class="qqLgn"></div>
                                <div class="desc" v-text="LS.mobi_member_OtherLgnQQ"></div>
                            </div>
                            <div class="sinaLgnPanel" :style="sinaLgnPanelStyle">
                                <div id="sinaLgn" class="sinaLgn"></div>
                                <div class="desc" v-text="LS.mobi_member_OtherLgnWB"></div>
                            </div>
                            <div v-if="wxOpen" class="wxLgnPanel" :style="wxLgnPanelStyle">
                                <div id="wxLgn" class="wxLgn"></div>
                                <div class="desc" v-text="LS.mobi_member_OtherLgnWX"></div>
                            </div>
                            <div v-if="mobiWxOpen" class="wxLgnPanel" style="display: none">
                                <div id="wxLgn" class="wxLgn"></div>
                                <div class="desc">{{ LS.mobi_member_OtherLgnWX }}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div id="fillAcctInfo" style="display: none">
            <div class="loginHeader webHeaderBg">
                <span id="fillInfoBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle">{{ LS.mobi_member_OtherLgnFillAcct }}</span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="bindOrLogin" style="display: none">
            <div class="loginHeader webHeaderBg">
                <span id="bindOrLoginBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.mobi_member_OtherLgnMode"></span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="bindNow" style="display: none">
            <div class="loginHeader webHeaderBg">
                <span id="bindNowBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.mobi_member_OtherLgnBindAcct"></span>
            </div>
            <div class="loginContent"></div>
        </div>
        <div id="mSignUp" style="display: none">
            <div class="loginHeader webHeaderBg">
                <span id="signUpBack" class="g_close icon-gClose"></span>
                <span class="title pageTitle" v-text="LS.col_member_signup"></span>
            </div>
            <div class="loginContent"></div>
            <div id="checkEmail" class="checkEmail"></div>
            <a id="emailAddr" class="emailAddr" style="display: none" target="_blank">地址</a>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';

import { MemberDef } from '../../memberDef';
import {
    loginAcquireCode,
    changeValidateCode,
    memberLogin,
    fastLogin,
    mobileFastLoginToggle,
    acctLoginToggle,
    initQQLogin,
    initWBLogin,
    wxLogin,
    checkHaveMultipleMember,
    manageLogin,
    memberOtherBindOrLogin,
    wbWinListener,
    qqWinListener,
    wxOpenIdLogin,
    loadReturnUrlForLogin,
} from '../method';
import { checkLabel, checkMustItem } from '../../method';
import { returnBack } from '../../util';
import { changeHref } from '@/features/router/universal';
import { showMemberLoginMsg } from '@/shared/member';
import { hidePlaceholder } from '@/shared/misc/placeholder';
let manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'LoginV2',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            normalTurnBack: true,
            systemIndexUrl: '/index.jsp',
            manageMode,
            MemberDef,
            errno: null,
        };
    },
    computed: {
        ...mapState('app', ['LS', 'lcid', 'aid']),
        ...mapGetters(['getModuleById']),
        moduleInfo() {
            return this.getModuleById(8) || {};
        },
        renderOptions() {
            return this.moduleInfo.renderOptions || {};
        },
        wxOpen() {
            return this.renderOptions.wxOpen || false;
        },
        mobiWxOpen() {
            return this.renderOptions.mobiWxOpen || false;
        },
        qqOpen() {
            return this.renderOptions.qqOpen || false;
        },
        sinaOpen() {
            return this.renderOptions.sinaOpen || false;
        },
        signUpByMail() {
            return this.renderOptions.signUpByMail || false;
        },
        signUpbyMobile() {
            return this.renderOptions.signUpByMobile || false;
        },
        findPwdOpen() {
            return this.renderOptions.findPwdOpen || false;
        },
        fromMerchants() {
            return this.renderOptions.fromMerchants || false;
        },
        mbAutoLogin() {
            return this.renderOptions.mbAutoLogin || false;
        },
        memJumpOpen() {
            return this.renderOptions.memJumpOpen || false;
        },
        noRemark() {
            return this.renderOptions.noRemark || false;
        },
        aesKey() {
            return this.renderOptions.aesKey || '';
        },
        authType() {
            return this.renderOptions.authType || 0;
        },
        qqAppId() {
            return this.renderOptions.qqAppId || '';
        },
        qqMobiReUri() {
            return this.renderOptions.qqMobiReUri || '';
        },
        sinaReUri() {
            return this.renderOptions.sinaReUri || '';
        },
        displayList() {
            return this.renderOptions.displayList || [];
        },
        wxLoginUrl() {
            return this.renderOptions.wxLoginUrl || '';
        },
        appId() {
            return this.renderOptions.appId || '';
        },
        encryptKey() {
            return this.renderOptions.encryptKey || '';
        },
        _siteDemo() {
            return this.renderOptions._siteDemo || false;
        },
        loginType() {
            return this.renderOptions.loginType || '';
        },
        loginTypeMode() {
            return this.renderOptions.loginTypeMode || false;
        },
        memberInfo() {
            return this.renderOptions.memberInfo;
        },
        faiOpenIdGreen() {
            return this.renderOptions.faiOpenIdGreen || 0;
        },
        downloanFid() {
            return this.renderOptions.downloanFid || 0;
        },
        downloanMid() {
            return this.renderOptions.downloanMid || 0;
        },
        findPwdUpUrl() {
            return this.renderOptions.findPwdUpUrl || '';
        },
        signUpUrl() {
            return this.renderOptions.signUpUrl || '';
        },
        lotteryId() {
            return this.renderOptions.lotteryId || 0;
        },
        phoneNumber() {
            return this.renderOptions.phoneNumber || '';
        },
        fromHd() {
            return this.renderOptions.fromHd || false;
        },
        turnBackUrl() {
            return this.renderOptions.turnBackUrl || '/';
        },
        returnUrl() {
            return this.renderOptions.returnUrl || '/';
        },
        encodeReturnUrl() {
            return this.renderOptions.turnBackUrl || '/';
        },
        lanCode() {
            return this.renderOptions.lanCode || 'cn';
        },
        avator() {
            return this.renderOptions.avator;
        },
        faiOpenId() {
            return this.renderOptions.faiOpenId;
        },
        uid() {
            return this.renderOptions.uid;
        },
        uname() {
            return this.renderOptions.uname;
        },
        AppKey() {
            return this.renderOptions.AppKey;
        },
        migrateWJZ() {
            return this.renderOptions.migrateWJZ;
        },
        isloginJump() {
            return this.renderOptions.isloginJump || false;
        },
        pleaseInputStr() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        acctPlaceHolder() {
            let placeholder = this.pleaseInputStr + this.LS.mobi_member_Account;
            if (this.signUpbyMobile) {
                placeholder = this.LS.memberLogin_acct_mobile;
            } else if (this.signUpbyMail) {
                placeholder = this.LS.memberLogin_acct_mail;
            }
            return placeholder;
        },
        passwordPlaceHolder() {
            return this.pleaseInputStr + this.LS.mobi_member_Password;
        },
        loginCaptchaImg() {
            return `/validateCode.jsp?v=${Math.random() * 1000}`;
        },
        qqLgnPanelStyle() {
            let style = {};
            if (this.qqOpen) {
                if (this.sinaOpen && this.wxOpen) {
                    style['margin-left'] = '1.9rem';
                } else if (!this.sinaOpen && !this.wxOpen) {
                    style['margin-left'] = '6rem';
                }
            } else {
                style['display'] = 'none';
            }
            return style;
        },
        sinaLgnPanelStyle() {
            let style = {};
            if (this.sinaOpen) {
                if (this.qqOpen) {
                    return style;
                } else if (this.wxOpen) {
                    style['margin-left'] = '3.8rem';
                } else {
                    style['margin-left'] = '6rem';
                }
            } else {
                style['display'] = 'none';
            }
            return style;
        },
        wxLgnPanelStyle() {
            let style = {};
            if (this.wxOpen) {
                if (!(this.qqOpen || this.sinaOpen)) {
                    style['margin-left'] = '6rem';
                }
            } else {
                style['display'] = 'none';
            }
            return style;
        },
        signUpbyMail() {
            return this.renderOptions.signUpByMail || false;
        },
    },
    mounted() {
        this.memberLoginTips();
        if (!(VITE_APP_MODE !== 'visitor')) {
            if (this.isloginJump) {
                // 处理第三方已经登录，后面再登录不上问题
                changeHref(this.returnUrl);
            }
            let urlSearchParams = new URLSearchParams(document.location.search);
            let returnUrl = urlSearchParams.get('returnUrl') || '';
            let noReturn = urlSearchParams.get('noReturn');
            if (returnUrl == '' && !noReturn && !this.fromHd) {
                let href = loadReturnUrlForLogin(document.location.href);
                document.location.href = href;
            }
        }
        hidePlaceholder();
        checkMustItem('.loginContent');
        checkHaveMultipleMember(this.authType);
        this.handleLoginType();
        if (this.qqOpen) {
            if (!(VITE_APP_MODE !== 'visitor') && !this._siteDemo) {
                initQQLogin(
                    this.qqAppId,
                    this.qqMobiReUri,
                    this.displayList,
                    this.noRemark,
                    MemberDef.Limit.REMARK_MAXLEN,
                    'qqLgn',
                    this.aesKey,
                    this.returnUrl
                );
            }
        }
        if (this.sinaOpen) {
            if (!(VITE_APP_MODE !== 'visitor') && !this._siteDemo) {
                initWBLogin(
                    this.AppKey,
                    this.sinaReUri,
                    this.displayList,
                    this.noRemark,
                    MemberDef.Limit.REMARK_MAXLEN,
                    'sinaLgn',
                    this.aesKey
                );
            }
        }
        if (this.wxOpen) {
            wxLogin(
                'wxLgn',
                this.aid,
                this.lanCode,
                this.wxLoginUrl,
                this.appId,
                this.encryptKey,
                this.aesKey,
                VITE_APP_MODE !== 'visitor',
                this._siteDemo,
                this.migrateWJZ,
                '',
                '',
                this.returnUrl
            );
        }

        manageLogin();
    },
    methods: {
        returnBackClick() {
            if (this.normalTurnBack) {
                returnBack();
            } else if (this.memJumpOpen) {
                changeHref(this.systemIndexUrl);
            } else {
                if (!this.fromHd) {
                    changeHref(this.turnBackUrl);
                }
            }
        },
        acquireCodeClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                loginAcquireCode();
            }
        },
        loginCaptchaImgClick() {
            changeValidateCode(jm('#loginCaptchaImg'));
        },
        autoLoginLabelClick(e) {
            checkLabel(e.target);
        },
        memberLoginClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                if (this.downloanMid == '' || this.downloanFid == '') {
                    memberLogin(this.returnUrl, null, null, this.fromMerchants, this.lotteryId, this.phoneNumber);
                } else {
                    memberLogin(
                        this.returnUrl,
                        this.downloanMid,
                        this.downloanFid,
                        this.fromMerchants,
                        this.lotteryId,
                        this.phoneNumber
                    );
                }
            }
        },
        fastLoginClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                if (this.downloanFid == '' || this.downloanMid == '') {
                    fastLogin(this.returnUrl, null, null, this.fromMerchants);
                } else {
                    fastLogin(this.returnUrl, this.downloanMid, this.downloanFid, this.fromMerchants);
                }
            }
        },
        mobileFastLoginToggleClick() {
            mobileFastLoginToggle();
        },
        acctLoginToggleClick() {
            acctLoginToggle();
        },
        handleLoginType() {
            if (this.loginType != '') {
                switch (this.loginType) {
                    case 'other_qq':
                        if (
                            this.loginTypeMode &&
                            (this.memberInfo == null || Object.keys(this.memberInfo).length == 0)
                        ) {
                            memberOtherBindOrLogin(
                                this.displayList,
                                this.noRemark,
                                MemberDef.Limit.REMARK_MAXLEN,
                                this.uid,
                                this.uname,
                                this.avator,
                                this.loginType,
                                this.returnUrl
                            );
                        } else {
                            qqWinListener(
                                this.displayList,
                                this.noRemark,
                                MemberDef.Limit.REMARK_MAXLEN,
                                this.uid,
                                this.uname,
                                this.avator,
                                this.returnUrl
                            );
                        }
                        break;
                    case 'other_wb':
                        if (
                            this.loginTypeMode &&
                            (this.memberInfo == null || Object.keys(this.memberInfo).length == 0)
                        ) {
                            memberOtherBindOrLogin(
                                this.displayList,
                                this.noRemark,
                                MemberDef.Limit.REMARK_MAXLEN,
                                this.uid,
                                this.uname,
                                this.avator,
                                this.loginType,
                                this.returnUrl
                            );
                        } else {
                            wbWinListener(
                                this.displayList,
                                this.noRemark,
                                MemberDef.Limit.REMARK_MAXLEN,
                                this.uid,
                                this.uname,
                                this.avator,
                                this.returnUrl
                            );
                        }
                        break;
                    case 'other_wx':
                        if (!this.loginTypeMode || this.faiOpenIdGreen) {
                            wxOpenIdLogin(
                                this.displayList,
                                this.noRemark,
                                MemberDef.Limit.REMARK_MAXLEN,
                                this.uid,
                                this.uname,
                                this.avator,
                                this.returnUrl,
                                this.faiOpenIdGreen,
                                this.faiOpenId
                            );
                        } else {
                            memberOtherBindOrLogin(
                                this.displayList,
                                this.noRemark,
                                MemberDef.Limit.REMARK_MAXLEN,
                                this.uid,
                                this.uname,
                                this.avator,
                                this.loginType,
                                this.returnUrl,
                                this.faiOpenIdGreen,
                                this.faiOpenId
                            );
                        }
                        break;
                    default:
                        '';
                }
            }
        },
        memberLoginTips() {
            let errno = 0;
            if (VITE_APP_MODE !== 'visitor') {
                let route = this.$store.state.route;
                let { data = {} } = route.query;
                let urlSearchParams = JSON.parse(data);
                errno = urlSearchParams['errno'];
            } else {
                let urlSearchParams = new URLSearchParams(document.location.search);
                errno = urlSearchParams.get('errno');
            }
            if (errno != null && (this.loginType == '' || this.loginType == null)) {
                showMemberLoginMsg(errno, false);
            }
        },
    },
};
</script>
