import { mapGetters } from 'vuex';
import { encodeHtml } from '@/shared/util';
import { getRouterData } from '@/features/router/universal';

export const productResultMixins = {
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        content() {
            return this.module.content;
        },
        searchList() {
            return this.options.searchList;
        },
    },
    methods: {
        highLightKeyword(str = '', keyword) {
            if (keyword == null) {
                return str;
            }
            return String.prototype.replaceAll
                ? String(str).replaceAll(
                      keyword,
                      `<span class='g_mainColor g_main_color_v3'>${encodeHtml(keyword)}</span>`
                  )
                : str;
        },
        getKeyInRounter(key) {
            return getRouterData()[key];
        },
        encodeHtml,
    },
};
