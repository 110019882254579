import request from './request/index';

export const getLotteryInfo = (id) => {
    return request
        .get('/api/manage/linkSetting/getLotteryInfo', {
            params: {
                id,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getSiteDownloadInfo = (ide, url) => {
    return request
        .get('/api/manage/linkSetting/getSiteDownloadInfo', {
            params: {
                ide,
                url,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getMobiDownloadInfo = (option) => {
    return request
        .get('/api/manage/linkSetting/getMobiDownloadInfo', {
            params: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getColModuleIdList = (_colId, extId) => {
    return request
        .get('/api/manage/linkSetting/getColModulesId', {
            params: {
                _colId,
                extId,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const participantLottery = (lotteryId, phone) => {
    return request
        .post('/ajax/lottery_h.jsp?cmd=addWafCk_participantLottery', {
            params: {
                lotteryId,
                phone,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getShareLinkList = () => {
    return request
        .get('/api/manage/linkSetting/getShareLinkList', {
            params: {
                url: location.href,
            },
        })
        .then((res) => {
            return res.data;
        });
};
