import { bindTapEvent } from '../event';
import { slideshowView } from '../photo/slides';
import { showPanel } from '../popup-panel';

export function initWebPage(options) {
    var triggerId = options.triggerId,
        returnSeltFun = options.returnSeltFun || false,
        callback = options.callback,
        isFisrt = false,
        callbackAlways = options.callbackAlways || false,
        scrollTop = 0;

    let JMWebPagePanel = jm('#webPagePanel' + triggerId);

    if (JMWebPagePanel.length !== 0) {
        JMWebPagePanel.remove();
    }

    // 用于详情页、图文海报需要每次都点击
    var panelList = ['newsDetailShare', 'newsDetailV3Share', 'productDetailShare', 'graphicPosterItem'];

    jm('#' + triggerId)
        .unbind('click.initWebPage')
        .bind('click.initWebPage', function () {
            showPanel(options, true);
            if (panelList.indexOf(triggerId) != -1 || callbackAlways) {
                callback && callback(); // 图文海报需要每次都点击
            } else {
                if (!isFisrt) {
                    callback && callback();
                    if (triggerId === 'productInfoSize') {
                        bindTapEvent('.productMallOptionImageContent,.mallOptionImg', 'tap', function (e) {
                            const initialIndex = 0;
                            const imgList = [],
                                imgItem = {},
                                target = jm(e.target),
                                imgSrc =
                                    target.attr('src') ||
                                    (target.css('background-image')
                                        ? target
                                              .css('background-image')
                                              .replace(/^url\("/g, '')
                                              .replace(/"\)$/g, '')
                                        : null);
                            if (
                                (target.hasClass('productMallOptionImageContent') ||
                                    target.hasClass('mallOptionImg')) &&
                                imgSrc
                            ) {
                                imgItem.src = imgSrc;
                                imgList.push(imgItem);
                            }
                            slideshowView({
                                id: triggerId,
                                previewSrcList: imgList.length ? imgList : Fai.top.previewSrcList,
                                zIndex: 9999,
                                initialIndex,
                                logEventConfig: {
                                    event: 'jz_hdpzj',
                                    properties: {
                                        jz_content_terminal: 'mobi-产品详情-购物选项',
                                        jz_version: Fai.top.jzVersion,
                                    },
                                },
                            });
                        });
                        return;
                    }
                    isFisrt = true;
                }
            }

            //otop = scrollTop;
            //otop = e.pageY - document.body.clientHeight;
            //otop = (otop < 0)?0:otop;
            window.scrollTo(0, scrollTop);
        });

    if (returnSeltFun) {
        return function () {
            showPanel(options, true);
            if (!isFisrt) {
                callback && callback();
                isFisrt = true;
            }
        };
    }
}

export function destroyWebPage(triggerId) {
    jm('#' + triggerId).unbind('click.initWebPage');
    jm('#webPagePanel' + triggerId).remove();
}
