export const insertSort = function (arr, key) {
    // 降序
    var len = arr.length;
    for (var i = 1; i < len; i++) {
        var temp = arr[i];
        var j = i - 1; //默认已排序的元素
        while (j >= 0 && arr[j][key] < temp[key]) {
            //在已排序好的队列中从后向前扫描
            arr[j + 1] = arr[j]; //已排序的元素小于新元素，将该元素移到一下个位置
            j--;
        }
        arr[j + 1] = temp;
    }
    return arr;
};

export const initModuleAttr = (styleId, modulePattern = {}) => {
    let attr = {
        pattern: {},
        data: {},
    };

    // init data
    var data = attr.data;
    if (typeof data.changed == 'undefined') {
        data.changed = false;
    }
    // init pattern
    var pattern = attr.pattern;
    if (typeof pattern.changed == 'undefined') {
        pattern.changed = false;
    }

    if (typeof pattern.animation == 'undefined') {
        pattern.animation = modulePattern && modulePattern.a ? modulePattern.a : { t: 0 };
    }

    if (typeof pattern.border == 'undefined') {
        if (styleId == 31) {
            //所有魔方导航的边框初始化为隐藏
            pattern.border = modulePattern && modulePattern.d ? modulePattern.d : { y: 1 };
        } else {
            pattern.border = modulePattern && modulePattern.d ? modulePattern.d : { y: 0 };
        }
    }
    if (typeof pattern.bannerType == 'undefined') {
        pattern.bannerType = modulePattern.e || 0;
    }
    if (typeof pattern.bannerAutoHeight == 'undefined') {
        pattern.bannerAutoHeight = modulePattern && modulePattern.bh ? modulePattern.bh : true;
    }
    if (typeof pattern.bannerText == 'undefined') {
        pattern.bannerText = modulePattern && modulePattern.bt ? modulePattern.bt : { y: 0 };
    }
    if (typeof pattern.bannerMore == 'undefined') {
        pattern.bannerMore = modulePattern && modulePattern.bm ? modulePattern.bm : { y: 0 };
    }
    if (typeof pattern.bannerBg == 'undefined') {
        pattern.bannerBg = modulePattern && modulePattern.bg ? modulePattern.bg : { y: 0 };
    }
    if (typeof pattern.outside == 'undefined') {
        if (modulePattern && modulePattern.o) {
            if (modulePattern.o.y == null) {
                modulePattern.o.y = 0;
            }
        }
        pattern.outside = modulePattern && modulePattern.o ? modulePattern.o : { y: 0 };
    }
    if (typeof pattern.inner == 'undefined') {
        pattern.inner = modulePattern && modulePattern.i ? modulePattern.i : { y: 0 };
    }
    if (typeof pattern.contentText == 'undefined') {
        pattern.contentText = modulePattern && modulePattern.ct ? modulePattern.ct : { y: 0 };
    }
    if (typeof pattern.contentLink == 'undefined') {
        pattern.contentLink = modulePattern && modulePattern.cl ? modulePattern.cl : { y: 0 };
        if (pattern.cl != null) {
            pattern.contentLink.ftc = pattern.cl.ftc || 0; // 添加按钮是否跟随主题色
        }
    }
    if (typeof pattern.contentBg == 'undefined') {
        if (styleId == 31) {
            //所有魔方导航的内容背景初始化为隐藏
            pattern.contentBg = modulePattern && modulePattern.cb ? modulePattern.cb : { y: 1 };
        } else {
            pattern.contentBg = modulePattern && modulePattern.cb ? modulePattern.cb : { y: 0 };
        }
    }

    // 添加 pattern.contentBg.f pattern.contentBg.p 不为 null，避免初次切换图片背景不生效问题
    pattern.contentBg.f = pattern.contentBg.f || '';
    pattern.contentBg.p = pattern.contentBg.p || '';
    pattern.contentBg.c = pattern.contentBg.c || '#000';

    if (typeof pattern.transparent == 'undefined') {
        pattern.transparent = modulePattern && modulePattern.tran ? modulePattern.tran : 100;
    }
    if (typeof pattern.contentRowHeight == 'undefined') {
        pattern.contentRowHeight = modulePattern && modulePattern.crh ? modulePattern.crh : { y: 0 };
    }

    if (typeof pattern.inputTxtBg == 'undefined') {
        if (modulePattern && modulePattern.ti) {
            pattern.inputTxtBg = modulePattern.ti.b;
        } else {
            pattern.inputTxtBg = { y: 0 };
        }
    }
    if (typeof pattern.inputTxtBorder == 'undefined') {
        if (modulePattern && modulePattern.ti) {
            pattern.inputTxtBorder = modulePattern.ti.d;
        } else {
            pattern.inputTxtBorder = { y: 0 };
        }
    }
    if (typeof pattern.inputTxtTip == 'undefined') {
        if (modulePattern && modulePattern.ti) {
            pattern.inputTxtTip = modulePattern.ti.pt;
        } else {
            pattern.inputTxtTip = { y: 0 };
        }
    }
    return attr;
};

export const initMyModuleInfo = (module) => {
    let obj = {};
    if (module != null) {
        obj = {
            id: module.id,
            display: true,
            defaultName: '',
            name: module.name,
            style: module.style,
            type: module.type,
            valid: module.valid,
        };
    }
    return obj;
};
