var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModuleFrame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", {
      staticClass: "cookie_policy_content",
      domProps: { innerHTML: _vm._s(_vm.cookiePolicyContent) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }