import { context, deepExtendClone, isFunction } from '@jz/utils';
import { closePopupWindow } from './utils';
import { PopupWindow } from './popupWindow2';
import { isDevEnv, REQUEST_BASE_URL } from '@/shared/env';
export const createPopupWindow = function (opt) {
    let frameSrcUrl = opt.frameSrcUrl || '';
    let absoulte = frameSrcUrl.startsWith('http') || frameSrcUrl.startsWith('//');
    if (!absoulte) {
        let prefix = isDevEnv ? REQUEST_BASE_URL : '';
        opt.frameSrcUrl = prefix + opt.frameSrcUrl;
    }

    var popup = new PopupWindow(opt);
    //页面监控
    var jsMonitor, id;
    id = popup.popupWindowId;
    if (typeof opt.frameSrcUrl != undefined) {
        $.ajax({
            url: '/ajax/log_h.jsp?cmd=wafNotCk_monitorManageMode&siteId=' + context.siteId,
            type: 'get',
            success: function (result) {
                var res = $.parseJSON(result);
                if (typeof res.jsData != undefined && res.jsData != '') {
                    jsMonitor = res.jsData;
                    context.$('#popupWindow' + id).append(jsMonitor);
                }
            },
        });
    }

    return popup;
};

export const renderPopupWinBtnComponent = function (opt) {
    var settings = {
            popupId: 0,
            initEvents: {},
        },
        popupId;
    settings = deepExtendClone(settings, opt);
    popupId = settings.popupId;

    var btnPanel = context.$('#popupWindow' + popupId).find('.pWBtns'),
        vMethods = {
            close: function () {
                closePopupWindow(popupId);
            },
        };
    for (var key in settings.initEvents) {
        vMethods[key] = settings.initEvents[key];
    }

    var btnVm = new window.Vue({
        el: btnPanel[0],
        data: {},
        methods: vMethods,
    });
    return btnVm;
};

export const addPopupWinBtnComponentDom = function (opt) {
    var settings = {
            popupId: 0,
            id: '',
            text: '',
            disable: false,
            active: false,
            style: '',
            click: 'close',
        },
        popupId,
        btnPanel,
        btnId,
        btn;
    settings = deepExtendClone(settings, opt);

    popupId = settings.popupId;
    btnPanel = context.$('#popupWindow' + popupId).find('.pWBtns');
    btnId = 'popup' + popupId + settings.id;
    btn = btnPanel.find('#' + btnId);

    if (btn.length > 0) btn.remove();

    var btnHtml = '';
    btnHtml += '<button-component';
    btnHtml += ' class="pWBtn"';
    btnHtml += ' style="' + settings.style + '"';
    btnHtml += ' id="' + btnId + '"';
    btnHtml += ' global-oper';
    btnHtml += ' :active="' + settings.active + '"';
    btnHtml += ' :disabled="' + settings.disable + '"';
    btnHtml += ' @click.stop="' + settings.click;
    btnHtml += '">';
    btnHtml += settings.text;
    btnHtml += '</button-component>';

    context.$(btnHtml).appendTo(btnPanel);
};

export const addPopupWindowButton = function (opt) {
    var settings = {
            popupId: 0,
            id: '',
            extClass: '',
            text: '',
            msg: '',
            popUpWinClass: '',
            popUpWinZIndex: 0,
            disable: false,
            click: function () {},
            callback: null,
        },
        popupId,
        popupWin,
        btnPanel,
        btnId,
        btn;

    settings = deepExtendClone(settings, opt);

    popupId = settings.popupId;
    popupWin = context.$('#popupWindow' + popupId);
    btnPanel = popupWin.find('.pWBtns');
    btnId = 'popup' + popupId + settings.id;
    btn = btnPanel.find('#' + btnId);

    if (btn.length > 0) {
        btn.remove();
    }

    if (settings.click != 'help') {
        if (typeof settings['extClass'] != 'undefined') {
            var extClass = settings['extClass'];
            context
                .$(
                    "<input id='" +
                        btnId +
                        "' type='button' value='" +
                        settings.text +
                        "' class='editbutton' extClass='" +
                        extClass +
                        "' />"
                )
                .appendTo(btnPanel);
        } else {
            context
                .$("<input id='" + btnId + "' type='button' value='" + settings.text + "' class='editbutton' />")
                .appendTo(btnPanel);
        }
    }

    btn = btnPanel.find('#' + btnId);

    if (typeof btn.faiButton == 'function') {
        btn.faiButton();
    }

    if (isFunction(settings.callback)) {
        btn.click(function () {
            settings.callback();
            if (Fai.isNull(settings.msg)) {
                closePopupWindow(popupId);
            } else {
                closePopupWindow(popupId, undefined, settings.msg);
            }
        });
    }

    if (settings.click == 'close') {
        btn.click(function () {
            if (Fai.isNull(settings.msg)) {
                closePopupWindow(popupId);
            } else {
                closePopupWindow(popupId, undefined, settings.msg);
            }
        });
    } else {
        btn.click(settings.click);
    }

    if (settings.disable) {
        btn.attr('disabled', true);
        btn.faiButton('disable');
    }

    // 捕捉弹窗输入Enter键，符合要求则触发保存
    // 性能问题：每次关闭弹窗，keydown函数会自动跟随窗体销毁
    $(document).keydown(function (e) {
        if (e.keyCode == 13) {
            var saveBtn = popupWin.find('#popup' + popupId + 'save'),
                focusElem;

            if (saveBtn.length > 0 && !saveBtn.prop('disabled')) {
                focusElem = $(':focus');
                if (focusElem.is("input[type='text']") || focusElem.is('textarea')) {
                    return;
                }
                saveBtn.trigger('click');
            }
        }
    });
};

export const addTitleTips = function (opt) {
    var settings = {
            popupId: 0,
            id: '',
            text: '未添加提示',
        },
        popupId,
        tipsId,
        // tips,
        titlePanel,
        close,
        tipsPanel;

    settings = deepExtendClone(settings, opt);

    popupId = settings.popupId;
    titlePanel = context.$('#popupWindow' + popupId).find('.pWHead_title');
    tipsId = 'popup' + popupId + settings.id;
    // tips = titlePanel.find('#' + tipsId);

    // if (tips.length > 0) btn.remove();

    var titleHtml = '';
    titleHtml += "<div class='pWHead_title_tips' id='" + tipsId + "'>";
    titleHtml += "<div class='msg'>" + settings.text + '</div>';
    titleHtml += "<div class='close'></div>";
    titleHtml += '</div>';

    tipsPanel = context.$(titleHtml).appendTo(titlePanel);
    close = tipsPanel.find('.close');

    close.on('click', function () {
        tipsPanel.remove();
    });
};
