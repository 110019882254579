<template>
    <div v-if="floatZoneContanier && isRender && !isEdit" id="floatZoneContainer" class="floatZoneContainer">
        <module-frame :module-id="moduleId">
            <component
                :is="componentsTable[module.style]"
                v-for="module in renderModules"
                v-if="getIsShowModule(module)"
                :key="module.id"
                :module-id="module.id"
            />
        </module-frame>
    </div>
    <module-frame v-else-if="floatZoneContanier && isRender" :module-id="moduleId">
        <div class="float_zone_wrap">
            <component
                :is="componentsTable[module.style]"
                v-for="module in renderModules"
                v-show="getIsShowModule(module)"
                :key="module.id"
                :module-id="module.id"
            />
        </div>
    </module-frame>
</template>

<script>
import { mapGetters } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import { MODULE_STYLE } from '@/def/module';
import { addFontFaceToHead2 } from '@/shared/fontFace';

import { floatZone } from './floatZone';
import { JZFloatEffectGuide } from '@/manage/guide/floatEffect';

function fixFixedPostion() {
    const $body = jm('body');
    const $main = jm('#g_web');
    const isExitFloatZone = $body.length && $main.length;
    const isFixPosition = isPCPreview();
    // 仅当不是frame而且是pc访问时，生效
    const dx = ($body.offset().width - $main.offset().width) / 2;
    if (isFixPosition && isExitFloatZone) {
        // 悬浮容器
        // 需要的偏移量
        jm('.floatZoneContainer .jz_float_zone_module .form').each((_, el) => {
            let left = jm(el).offset().left;
            const fixedLeft = left + dx;
            jm(el).css({
                left: `${fixedLeft}px`,
            });
        });
    }
}
function isPCPreview() {
    const isInFrame = self != top;
    const isPC = (function () {
        var userAgentInfo = navigator.userAgent;
        var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    })();

    return !isInFrame && isPC;
}

export default {
    components: {
        ModuleFrame,
    },
    props: {
        componentsTable: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {};
    },

    computed: {
        ...mapGetters(['getModuleById']),
        renderModules() {
            const inFloatZoneModules = [];

            const inFloatZoneModuleIds =
                this.floatZoneContanier?.content?.moduleList
                    ?.filter((info) => info.s ?? true)
                    ?.map((info) => info.id) ?? [];

            inFloatZoneModuleIds.forEach((moduleId) => {
                inFloatZoneModules.push(this.getModuleById(moduleId));
            });

            return inFloatZoneModules.filter((module) => !this.$store.state.editMode.delModules.includes(module.id));
        },
        floatZoneContanier() {
            return this.$store.state.currentPageModuleIdMap[this.$store.state.floatEffect.moduleId];
        },
        isEdit() {
            return this.$store.state.floatEffect.isEdit;
        },
        moduleId() {
            return this.$store.state.floatEffect.moduleId;
        },
        isRender() {
            return this.$store.state.floatEffect.isRender;
        },
    },
    watch: {
        floatZoneContanier() {
            // 在渲染之后去fix访客态悬浮的left位置
            if (!(VITE_APP_MODE !== 'visitor')) {
                this.$nextTick(() => {
                    fixFixedPostion();
                });
            }
            // 渲染最顶部的工具入口
            if (!this.isEdit && VITE_APP_MODE !== 'visitor' && this.floatZoneContanier) {
                Vue.nextTick(() => {
                    this.initModuleLayer();
                });
            }
        },
    },
    mounted() {
        // 访客态自己控制
        if (!(VITE_APP_MODE !== 'visitor')) {
            floatZone.initFloatZoneData();
        }
        //  访客态自己控制
        if (VITE_APP_MODE !== 'visitor') {
            this.execScript();
        }
    },
    methods: {
        getIsShowModule({ id }) {
            const moduleInfo = this.floatZoneContanier.content.moduleList.find((module) => module.id === id);

            return moduleInfo && (moduleInfo.s ?? true);
        },
        initModuleLayer() {
            const bottomModuleId = this.getBottomModuleId();
            const $bottomModule = jm(`#module${bottomModuleId}`);
            Vue.prototype.$layer.bindHoverShowToolbarEvent(`module${bottomModuleId}`, $bottomModule, [
                {
                    text: '编辑悬浮容器',
                    title: '编辑悬浮容器',
                    display: 'text',
                    iconClass: 'fk-tabEditTool',
                    onClick: () => {
                        Vue.prototype.$designer.open({
                            panelType: 'JzFloatEffectEdit',
                        });
                    },
                },
                {
                    text: '隐藏',
                    title: '隐藏悬浮容器',
                    display: 'icon',
                    iconClass: 'close',
                    onClick: () => {
                        Fai.ing('点击隐藏悬浮容器，隐藏后可以从“设置”-“基本设置”-“全站设置”重新选择。', true);
                        this.hideFloatContainer();
                    },
                },
            ]);
        },
        execScript() {
            this.renderModules.forEach((module) => {
                let { style } = module;
                let { fontList = [] } = module.content;
                if (style == MODULE_STYLE.FLOAT_BTN || style == MODULE_STYLE.SIMPLE_TEXT) {
                    addFontFaceToHead2(fontList);
                }
            });
        },
        getBottomModuleId() {
            const moduleList = this.floatZoneContanier.content.moduleList;
            // 记录最上方位置的子模块id，在此模块开启编辑入口
            let bottomModuleId = 0;
            let minTop = 0;

            // 拼装模块定位信息，在前端使用
            for (let i = 0; i < moduleList.length; i++) {
                let module = moduleList[i];
                let moduleId = module.id;
                let isShowModule = module.s ?? true;
                let top = module.t;

                // 隐藏的不输出
                if (!isShowModule) {
                    continue;
                }

                if (i == 0) {
                    minTop = top;
                    bottomModuleId = moduleId;
                }
                // 如果top相同，找到最后一个(层级是最高的)
                if (top <= minTop) {
                    minTop = top;
                    bottomModuleId = moduleId;
                }
            }
            return bottomModuleId;
        },
        hideFloatContainer() {
            JZFloatEffectGuide.run();
            Fai.top._rootStore.commit('panelSetting/changeIsShowFloatEffect', false);
            Vue.prototype.$designer.styleChanged();
        },
    },
};
</script>

<style>
.floatZoneContainer {
    background: url(/image/module/floatZone/bg.jpg?v=201809101152) repeat !important;
}
</style>
