export let mobiPATipFix = {};
mobiPATipFix.allFixWXPAList = [];
mobiPATipFix.hasInit = false;
mobiPATipFix.fixWXPublicAttentionTip = function (show, pa_QRCodePath, pa_style) {
    mobiPATipFix.hasInit = true;
    var $wxPublicAttentionService = jm('#wxPublicAttentionService');
    if (pa_style) {
        $wxPublicAttentionService.show();
    }
    var bottom = 0,
        $close = $wxPublicAttentionService.find('.close'),
        $guanzhu = $wxPublicAttentionService.find('.clickContent'),
        tipHtml = [
            '<div id="wxTopTip" class="wxTopTip">',
            '</div>',
            '   <div id="wxPage" class="wxPage">',
            '       <div class="pic" id="wxPagePic">',
            // '           <img src="' + Fai.top._resRoot + '/image/wxPage.png?v=201712151528" >',
            '           <img class="img" src="' + pa_QRCodePath + '"/>', // 处理bug：ID1024832，涉及文件: base.src.css
            '       </div>',
            '       <div class="pic1">',
            '           <img class="img" src="' + pa_QRCodePath + '"/>',
            '       </div>',
            '       <div class="text">',
            '           长按识别图中二维码快来关注我吧',
            '       </div>',
            '   </div>',
        ];
    $close.bind('click', function () {
        $wxPublicAttentionService.hide();
        jm.cookie('_hideWXPublicAttentionTip', 1, { expires: 1, path: '/' });
    });
    $guanzhu.bind('click', function () {
        jm('body').append(tipHtml.join(''));
        if (pa_QRCodePath == '') {
            jm('#wxPage').find('.img').hide();
        }
        jm('#wxTopTip').bind('click', function () {
            jm(this).remove();
            jm('#wxPage').remove();
        });
        jm('#wxPagePic').bind('mouseup', function (event) {
            event.stopPropagation();
            event.preventDefault();
        });
    });

    //文章详情
    /*if(jm("#webContainerBox").find(".newsNewDetailPanel").length != 0 || jm("#fixedAreaBox").find(".productMallShop").length == 0){
        return;
    }*/
    if (show) {
        if (top.Fai.top._other.pa.st != 1) {
            $wxPublicAttentionService.hide();
        } else {
            $wxPublicAttentionService.show();
        }
    }
    if (!Fai.top._openThemeV3) {
        if (Fai.top._templateFrameId >= 2000) {
            if (jm('#serviceBtnOuter').length > 0 && jm('#serviceBtnOuter').css('display') != 'none') {
                bottom = jm('#serviceBtnOuter').height();
            } else if (jm('#fixedAreaBox').length > 0 && jm('#fixedAreaBox').css('display') != 'none') {
                bottom = jm('#fixedAreaBox').height();
            }
        } else if (Fai.top._templateFrameId >= 1000 && Fai.top._templateFrameId < 2000) {
            if (jm('#webCustomerServiceBox').length > 0 && jm('#webCustomerServiceBox').css('display') != 'none') {
                bottom = jm('#customerServiceDiv').height();
            } /*else if(jm("#navbar").css("display") != "none"){
                bottom = jm("#navbar").height();
            }*/
        } else {
            if (jm('#webCustomerServiceBox').length > 0 && jm('#webCustomerServiceBox').css('display') != 'none') {
                bottom = jm('#customerServiceDiv').height();
            } /*else if(jm("#navbar").css("display") != "none"){
                bottom = jm("#navbar").height();
            }*/
        }
    } else {
        if (
            jm('#p_footer_nav_wrap').length &&
            jm('#p_footer_nav_wrap').css('display') != 'none' &&
            jm('#J_footer_nav').length &&
            jm('#J_footer_nav').attr('_issuspension') != 'true'
        ) {
            bottom = jm('#J_footer_nav').height();
        } else {
            bottom = 0;
        }
    }
    $wxPublicAttentionService.css('bottom', bottom + 'px');

    if (mobiPATipFix.allFixWXPAList.length) {
        jm(mobiPATipFix.allFixWXPAList).each(function (index, value) {
            mobiPATipFix.doAllFixWXPA(value.number, value.moduleId, value.other);
        });
    }
};

mobiPATipFix.doAllFixWXPA = function (number, moduleId, other) {
    var $wxPublicAttentionService = jm('#wxPublicAttentionService'),
        bottom = 0;
    if (number == 1) {
        if (Fai.top._openOnlineService) {
            bottom = jm('#customerServiceDiv').height();
        }
        $wxPublicAttentionService.css('bottom', bottom + 'px');
    } else if (number == 2) {
        if (other) {
            var moduleNewBottom = jm('#module' + moduleId).find('.newsBottom');
            var newsBottom = moduleNewBottom.length > 0 ? moduleNewBottom : jm('.newsBottom');
            bottom = parseInt(newsBottom.height());
            setTimeout(function () {
                $wxPublicAttentionService.css('bottom', bottom + 'px');
            }, 0);
        } else if (Fai.top._openThemeV3 && jm('.only_share').length > 0 && jm('.only_share').css('display') != 'none') {
            bottom = parseInt(jm('.only_share').height());
            setTimeout(function () {
                $wxPublicAttentionService.css('bottom', bottom + 'px');
            }, 0);
        }
    } else if (number == 3) {
        if (Fai.top._openThemeV3) {
            if (jm('#productBottomBox').length > 0 && jm('#productBottomBox').css('display') != 'none') {
                bottom = parseInt(jm('#productBottomBox').offset().height);
                $wxPublicAttentionService.css('bottom', bottom + 'px');
            }
        } else if (other) {
            bottom = parseInt(jm('.productMallShop').offset().height);
            $wxPublicAttentionService.css('bottom', bottom + 'px');
        }
    }
};

export const allFixWXPA = function (number, moduleId, other) {
    if (mobiPATipFix.hasInit) {
        mobiPATipFix.doAllFixWXPA(number, moduleId, other);
        return;
    }
    mobiPATipFix.allFixWXPAList.push({
        number,
        moduleId,
        other,
    });
};
