import { MobiModule } from '@/modules/Module';
import { MODULE_STYLE } from '@/def/module';
import PhotoGroupResultVisitorVue from './module/visitor.vue';

class _PhotoGrupResult extends MobiModule {
    constructor() {
        super(MODULE_STYLE.SYS_PHOTOGROUP_RESULT, { system: true });
    }

    render() {
        return PhotoGroupResultVisitorVue;
    }
}

export const PhotoGroupResult = new _PhotoGrupResult();
