<template>
    <div>
        <template v-if="getIsAgreeRefund(aftersaleRecordStatus)">
            <div class="agree_refund_wrap">
                <div class="g_color g_main_color_v3 agree_refund_text"> 商家同意退款 </div>
                <div class="agree_refund_deal_time_wrap">
                    <span class="agree_refund_deal_time_label">处理时间</span>
                    <span class="agree_refund_deal_time">{{ formatDate(latestAftersaleRecord.replyTime) }}</span>
                </div>
            </div>
        </template>
        <template v-if="getIsRejectRefund(aftersaleRecordStatus)">
            <div class="reject_refund_wrap">
                <div class="g_color g_main_color_v3 reject_refund_text"> 商家拒绝退款 </div>
                <div class="reject_refund_deal_time_wrap">
                    <span class="reject_refund_deal_time_label">处理时间</span>
                    <span class="reject_refund_deal_time">{{ formatDate(latestAftersaleRecord.replyTime) }}</span>
                </div>
                <div class="reject_refund_reason_wrap">
                    <span class="reject_refund_reason" style="">拒绝原因</span>
                    <div style="font-size: 0.64rem; color: rgb(51, 51, 51); word-break: break-word">
                        <v-clamp autoresize :max-lines="3">
                            {{ latestAftersaleRecord.rejectReason }}
                            <template #after="{ toggle, expanded, clamped }">
                                <span
                                    v-if="clamped || expanded"
                                    style="cursor: pointer"
                                    class="g_color g_main_color_v3"
                                    @click="toggle"
                                >
                                    {{ expanded ? '收起内容' : '查看全部' }}
                                </span>
                            </template>
                        </v-clamp>
                    </div>
                </div>
            </div>
        </template>
        <div class="request_refund_wrap">
            <div class="g_color g_main_color_v3 request_refund_text"> 发起了退款申请，等待商家处理 </div>
            <div class="request_refund_request_time_wrap">
                <span class="request_refund_request_time_label">申请时间</span>
                <span class="request_refund_request_time">{{ formatDate(latestAftersaleRecord.createTime) }}</span>
            </div>
            <div class="request_refund_reason_wrap">
                <span class="request_refund_reason_label">退款原因</span>
                <div class="request_refund_reason_text">
                    <v-clamp autoresize :max-lines="3">
                        {{ latestAftersaleRecord.reason }}
                        <template #after="{ toggle, expanded, clamped }">
                            <span
                                v-if="clamped || expanded"
                                style="cursor: pointer"
                                class="g_color g_main_color_v3"
                                @click="toggle"
                            >
                                {{ expanded ? '收起内容' : '查看全部' }}
                            </span>
                        </template>
                    </v-clamp>
                </div>
            </div>
            <div v-if="latestAftersaleRecord.imgUrlList.length" class="request_refund_cert_list_wrap">
                <div style="margin-left: 2.5rem">
                    <img
                        v-for="(img, index) in latestAftersaleRecord.imgUrlList"
                        :key="img"
                        class="request_refund_cert_img"
                        :src="img"
                        @click="previewImg(index)"
                    />
                </div>
            </div>

            <div class="request_refund_action">
                <div
                    v-if="wxpayAutoRefund && aftersaleRecordStatus === 5 && showAciton"
                    class="modify_request_refund g_main_bgColor_v3 g_oldThemeBgColor"
                    @click="modifyRequest"
                    >修改申请</div
                >
                <div
                    v-if="aftersaleRecordStatus === 5 && showAciton"
                    class="cancel_request_refund"
                    @click="cancelRequestRefund"
                    >撤销申请</div
                >
                <div
                    v-if="aftersaleRecordStatus === 1 && showAciton"
                    class="cancel_request_refund"
                    @click="cancelRequestRefund"
                    >撤销申请</div
                ></div
            >
        </div>
    </div>
</template>

<script>
import { loadKnowPayList } from '../method';
import { slideshowView } from '@/shared/photo/slides.js';
import VClamp from 'vue-clamp';
import { dayjs } from '@jz/utils';
import { prompt } from '@/shared/fai';
import { logJZFdp } from '@/api/logDog';
export default {
    components: {
        VClamp,
    },
    props: ['latestAftersaleRecord', 'showAciton', 'price', 'wxpayAutoRefund'],
    computed: {
        aftersaleRecordStatus() {
            return this.latestAftersaleRecord.status;
        },
    },
    methods: {
        getIsAgreeRefund(aftersaleRecordStatus) {
            return aftersaleRecordStatus === 2 || aftersaleRecordStatus === 3 || aftersaleRecordStatus === 4;
        },
        getIsRejectRefund(aftersaleRecordStatus) {
            return aftersaleRecordStatus === 5;
        },
        formatDate(timeStamp) {
            return dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss');
        },
        async cancelRequestRefund() {
            prompt({
                textClass: 'confirm',
                content: '确定撤销此次退款申请吗？',
                callback: async () => {
                    await jzRequest.post('/api/guest/aftersaleRecord/cancelKnowProductRefund', {
                        data: {
                            id: this.latestAftersaleRecord.id,
                        },
                    });
                    jm('#aftersaleRecord').hide();
                    jm('#knowPayList').show();
                    loadKnowPayList();
                    logJZFdp('jz_cancellation_refunds', {
                        jz_content_terminal: 'mobi',
                        jz_free_int1: this.price,
                    });
                },
                insertSelector: '#g_body',
            });
        },
        modifyRequest() {
            this.$emit('modify-request', this.latestAftersaleRecord);
        },
        previewImg(index) {
            const id = this.latestAftersaleRecord.imgIdList[index];
            const src = this.latestAftersaleRecord.imgUrlList[index];
            slideshowView({
                id,
                previewSrcList: [{ src, title: '', description: '' }],
                logEventConfig: {},
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.agree_refund {
    &_text {
        height: 1.7066rem;
        display: flex;
        align-items: center;
        font-size: 0.64rem;
        border-bottom: 1px solid #eee;
        padding-left: 0.5973rem;
    }
    &_wrap {
        width: 14.72rem;
        margin: 0.64rem;
        box-shadow: 0 0.0426rem 0.32rem rgb(0 0 0 / 10%);
        border-radius: 0.1706rem;
    }
    &_deal_time {
        font-size: 0.64rem;
        color: #333;
        &_wrap {
            padding-left: 0.5973rem;
            height: 1.7066rem;
            display: flex;
            align-items: center;
        }
        &_label {
            color: #999;
            font-size: 0.64rem;
            margin-right: 0.5973rem;
        }
    }
}
.reject_refund {
    &_wrap {
        width: 14.72rem;
        margin: 0.64rem;
        box-shadow: 0 0.0426rem 0.32rem rgb(0 0 0 / 10%);
        border-radius: 0.1706rem;
    }
    &_text {
        height: 1.7066rem;
        display: flex;
        align-items: center;
        font-size: 0.64rem;
        border-bottom: 1px solid rgb(238, 238, 238);
        padding-left: 0.5973rem;
    }

    &_deal_time {
        font-size: 0.64rem;
        color: rgb(51, 51, 51);
        &_wrap {
            padding-left: 0.5973rem;
            height: 1.7066rem;
            display: flex;
            align-items: center;
        }
        &_label {
            color: rgb(153, 153, 153);
            font-size: 0.64rem;
            margin-right: 0.5973rem;
        }
    }
    &_reason {
        color: rgb(153, 153, 153);
        font-size: 0.64rem;
        margin-right: 0.5973rem;
        flex-shrink: 0;
        &_wrap {
            padding: 0 0.5973rem 0.5973rem;
            display: flex;
            align-items: flex-start;
        }
    }
}
.request_refund {
    &_wrap {
        width: 14.72rem;
        margin: 0.64rem;
        box-shadow: 0 0.0426rem 0.32rem rgb(0 0 0 / 10%);
        border-radius: 0.1706rem;
    }
    &_text {
        height: 1.7066rem;
        display: flex;
        align-items: center;
        font-size: 0.64rem;
        border-bottom: 1px solid rgb(238, 238, 238);
        padding-left: 0.5973rem;
    }
    &_request_time {
        font-size: 0.64rem;
        color: rgb(51, 51, 51);
        &_wrap {
            padding-left: 0.5973rem;
            height: 1.7066rem;
            display: flex;
            align-items: center;
        }
        &_label {
            color: rgb(153, 153, 153);
            font-size: 0.64rem;
            margin-right: 0.5973rem;
        }
    }
    &_reason {
        &_wrap {
            padding: 0 0.5973rem 0.5973rem;
            display: flex;
            align-items: flex-start;
        }
        &_label {
            color: rgb(153, 153, 153);
            font-size: 0.64rem;
            margin-right: 0.5973rem;
            flex-shrink: 0;
        }
        &_text {
            font-size: 0.64rem;
            color: rgb(51, 51, 51);
            word-break: break-word;
        }
    }
    &_cert {
        &_list {
            &_wrap {
                padding: 0.64rem;
                border-bottom: 1px solid #eee;
                display: flex;
                justify-content: flex-start;
                padding-top: 0;
            }
        }
        &_img {
            width: 2.9866rem;
            height: 2.9866rem;
            margin-left: 0.512rem;
            border: solid 1px #eee;
        }
    }
    &_action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
<style lang="scss">
.request_refund_reason_text,
.reject_refund_reason_wrap {
    & span[aria-label] {
        white-space: pre-wrap;
    }
}
</style>
