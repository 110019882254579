import request from './request';

export function getHeaderV3() {
    return request.get('/api/manage/header/headerV3').then(({ data = {} }) => {
        return data.data;
    });
}

export function getHeaderV2(colId) {
    return request.get('/api/manage/header/headerV2', { params: { colId } }).then(({ data = {} }) => {
        return data.data;
    });
}

export function getTopStyleData() {
    return request.get('/ajax/design_h.jsp', {
        params: {
            cmd: 'getWafNotCk_getTopStyleData',
        },
    });
}

export function getTitleHeadLine() {
    return request.get('/ajax/design_h.jsp?cmd=getWafNotCk_getTitleHeadline').then(({ data = {} }) => data);
}

export function getIde(jumpUrl) {
    return request
        .get('/ajax/design_h.jsp', {
            cmd: 'getWafNotCk_getIde',
            jumpUrl,
        })
        .then(({ data = {} }) => data);
}

export function getTitleLogo() {
    return request.get('/ajax/design_h.jsp?cmd=getWafNotCk_getTitleLogo').then(({ data = {} }) => data);
}
