import request from './request';

export const signUp = (info) => {
    return request
        .post('/ajax/member_h.jsp?cmd=addWafCk_add', {
            data: {
                info: JSON.stringify(info),
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const sendMemberActiveMail = (memName, memEmail) => {
    return request
        .post('/ajax/mail_h.jsp?cmd=wafNotCk_sendMemberActiveMail', {
            data: {
                memName,
                memEmail,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const loginMember = (option) => {
    return request
        .post('/ajax/login_h.jsp?cmd=wafNotCk_loginMember', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const proceedAfterMail = (info) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_proceedAfterMail', {
            data: {
                info: JSON.stringify(info),
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const proceedAfterMobile = (info) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_proceedAfterMobile', {
            data: {
                info: JSON.stringify(info),
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getProContent = () => {
    return request.get('/ajax/member_h.jsp?cmd=getWafNotCk_getProContent').then((res) => {
        return res.data;
    });
};

export const sendMobileLoginCode = (memName, news = true) => {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_sendMobileLoginCode', {
            data: {
                memName,
                new: news,
            },
        })
        .then((res) => {
            return res.data;
        });
};

// 校验手机短信验证码
export const checkMobileCodeGeneral = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_checkMobileCodeGeneral', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const loginMemberByMobile = (option) => {
    return request
        .post('/ajax/login_h.jsp?cmd=wafNotCk_loginMemberByMobile', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const checkHaveMultipleMemberAjax = (authType, acct) => {
    return request
        .post('/ajax/login_h.jsp?cmd=getWafNotCk_checkHaveMultipleMember', {
            data: {
                authType,
                acct,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const loginLog = (option) => {
    return request
        .post('/ajax/login_h.jsp?cmd=wafNotCk_loginLog', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const otherLoginMember = (option) => {
    return request
        .post('/ajax/login_h.jsp?cmd=wafNotCk_otherLoginMember', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const bindOtherAcctFirstAjax = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_bindOtherAcctFirst', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const otherAdd = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=addWafCk_otherAdd', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getMAcctMail = (mAcct) => {
    return request
        .post('/ajax/member_h.jsp?cmd=getWafNotCk_getMAcctMail', {
            data: {
                mAcct,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const sendMemberPwdEmail = (memName, news = true) => {
    return request
        .post('/ajax/mail_h.jsp?cmd=wafNotCk_sendMemberPwdEmail', {
            data: {
                memName,
                new: news,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const sendMemberPwdMobileCode = (memName, news = true) => {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_sendMemberPwdMobileCode', {
            data: {
                memName,
                new: news,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const setMemberPwdGeneral = (newPw, memId) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_setMemberPwdGeneral', {
            data: {
                newPw,
                memId,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const checkPwdEmailCode = (option) => {
    return request
        .post('/ajax/mail_h.jsp?cmd=wafNotCk_checkPwdEmailCode', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};
export const setMemberPwdByCode = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_setMemberPwdByCode', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const setMemberPwdByMobileCode = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_setMemberPwdByMobileCode', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const checkFindPwdByMobileCode = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_checkFindPwdByMobileCode', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getMobileCode = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=getWafNotCk_getMobileCode', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const setMemberMobile = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_setMemberMobile', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const checkCaptcha = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_checkCaptcha&captchaCode', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const setProfileInfo = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_set', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const setCimg = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_cimg', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const logoutMember = () => {
    return request.post('/ajax/login_h.jsp?cmd=wafNotCk_logoutMember').then((res) => {
        return res.data;
    });
};

export const getParticipantLotteryListForMember = (pageNo = 1, pageSize = 10) => {
    return request
        .get('/ajax/lottery_h.jsp?cmd=getWafNotCk_getParticipantLotteryListForMember', {
            params: {
                pageNo,
                pageSize,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getKnowProductOrderList4member = () => {
    const data = {
        pageNo: 1,
        pageSize: 30,
    };

    return request
        .post('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductOrderList4member', {
            data,
        })
        .then((res) => {
            return res.data;
        });
};

export const getSubmitList = () => {
    return request.post('/ajax/member_h.jsp?cmd=getWafNotCk_getSubmitList').then((res) => {
        return res.data;
    });
};

export const setBulletinReadTime = (id) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_setBulletinReadTime', {
            data: {
                id,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const changeReadStatus = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_changeReadStatus', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getIntegralInfo = (page, type) => {
    return request
        .get('/ajax/member_h.jsp?cmd=getWafNotCk_getIntegralInfo', {
            params: {
                page,
                type,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const lookCoupon = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_lookCoupon', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const updatePdCollections = (ids) => {
    return request
        .post('/ajax/product_h.jsp?setWafCk_updateCollections', {
            data: {
                ids,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const batchGetPd = (ids) => {
    return request
        .post('/ajax/product_h.jsp?cmd=getWafNotCk_batchGetPd', {
            data: {
                ids,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const getProductPropList = (lid) => {
    return request
        .post('/ajax/productProp_h.jsp?cmd=getWafNotCk_list', {
            data: {
                lid,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const setMemberCollection = (option) => {
    return request
        .post('/ajax/member_h.jsp?cmd=setWafCk_setMemberCollection', {
            data: {
                ...option,
            },
        })
        .then((res) => {
            return res.data;
        });
};

export const checkMemberSameLogin = () => {
    return request.get('/ajax/login_h.jsp?cmd=wafNotCk_checkMemberSameTimeLogin').then((res) => {
        return res.data;
    });
};
