import request from './request';

const manageMode = VITE_APP_MODE !== 'visitor';
export function getAllProducts(params = {}) {
    let url = `/api/manage/product/getAllProducts`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getProductSimpleInfo(params = {}) {
    let url = `/api/manage/product/getProductSimpleInfo`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getProductSimpleInfoByName(params = {}) {
    let url = `/api/manage/product/getProductSimpleInfoByName`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getAjaxPageModuleV2InfoApi(params = {}, data = {}) {
    let url = `/api/manage/product/getAjaxPageModuleV2Info`;

    return request
        .post(url, {
            data,
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function delProductApi(params = {}) {
    let url = `/api/manage/product/del`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function batchSetTopApi(params = {}) {
    let url = `/api/manage/product/batchSetTop`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getScreenPrInfoApi(data = {}) {
    let url = `/api/${manageMode ? 'manage' : 'guest'}/product/getScreenPrInfo`;

    return request
        .post(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}
