import { getUrlParam } from '@/shared/url';
import { isWechat } from '@/shared/browser';

export function addDistCode() {
    var ua = Fai.top.navigator.userAgent;
    var isPhone = /(iPhone|iPad|iPod|iOS)/i.test(ua);
    var currentUrl = window.location.href;
    if (currentUrl.indexOf('dist') > 0) {
        return;
    }
    // 防止微信支付回来丢失id等信息
    if (
        currentUrl.indexOf('mstl.jsp') > 0 ||
        currentUrl.indexOf('mdetail.jsp') > 0 ||
        currentUrl.indexOf('mlist.jsp') > 0 ||
        currentUrl.indexOf('h-mstl') > 0 ||
        currentUrl.indexOf('h-mdetail') > 0 ||
        currentUrl.indexOf('h-mlist') > 0
    ) {
        return;
    }
    var distCode = getDistCodeByArgOrCookie();
    if (distCode == '') {
        return;
    }
    var urlParam = '?dist=' + distCode;
    if (currentUrl.indexOf('?') > 0) {
        urlParam += '&' + currentUrl.split('?')[1];
    }
    if (isPhone && isWechat()) {
        currentUrl = currentUrl.split('?')[0];
        window.location.replace(currentUrl + urlParam);
        return;
    }
    if (history.replaceState) {
        history.replaceState(null, '', urlParam);
    }
}

export function bindMemberDistId() {
    var currentUrl = window.location.href;
    if (currentUrl.indexOf('dist') < 0) {
        return;
    }
    //处理分销统计cookie
    if (currentUrl.indexOf('?') > 0) {
        var distCode = getDistCodeByArgOrCookie();
        if (distCode == '') {
            var returnUrl = getUrlParam(currentUrl, 'returnUrl') || '';
            if (returnUrl != '') {
                distCode = getUrlParam(returnUrl, 'dist') || '';
            }
        }
        var oldDistUVRecord = jm.cookie('oldDistUVRecordKey') || '';
        if (distCode != '') {
            jm.ajax({
                type: 'get',
                url: '/ajax/dist_h.jsp?cmd=setWafCk_bindMemberDistTime&distCode=' + distCode,
                success() {
                    var cookieDistCode = jm.cookie('_distCode') || '';
                    var memberId = Fai.top.sessionMemberId || 0;
                    cookieDistCode = cookieDistCode + '_' + memberId;
                    if (oldDistUVRecord != cookieDistCode) {
                        jm.cookie('distUVRecordKey', '', { path: '/' });
                    }
                    distUVDailyTotal();
                },
            });
        }
    }
}

function distUVDailyTotal() {
    var distCode = jm.cookie('_distCode') || '';
    var memberId = Fai.top.sessionMemberId || 0;
    var distUVRecordKey = jm.cookie('distUVRecordKey') || '';
    if (distCode == '' || distUVRecordKey != '') {
        return;
    }
    var distUVRecordAndMid = distCode + '_' + memberId;
    jm.ajax({
        type: 'get',
        url: '/ajax/dist_h.jsp?cmd=addWafCk_distUVDailyTotal&distCode=' + distCode,
        success(res) {
            var result = jm.parseJSON(res);
            if (result.success) {
                var exdate = new Date();
                exdate.setHours(23);
                exdate.setMinutes(59);
                exdate.setSeconds(59);
                document.cookie = 'distUVRecordKey=' + result.distUVRecordCookie + ';expires=' + exdate.toGMTString();
                document.cookie = 'oldDistUVRecordKey=' + distUVRecordAndMid + ';expires=' + exdate.toGMTString();
            }
        },
    });
}

function getDistCodeByArgOrCookie() {
    var distCode = '';
    var currentUrl = window.location.href;
    distCode = getUrlParam(currentUrl, 'dist') || '';
    if (distCode == '') {
        distCode = jm.cookie('_distCode') || '';
    }
    return distCode;
}
