const state = () => ({
    backgroundImg: {},
});

const getters = {};

const mutations = {
    updateBackgroundImg(state, payload) {
        let obj = {
            filePath: '',
            _backgroundPreFilePath: '',
        };
        Object.assign(obj, payload);
        state.backgroundImg = obj;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
