import { MobiModule } from '@/modules/Module';
import productDetailView from './module/productDetail.vue';

class productDetailModule extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return productDetailView;
    }
}

export const productDetail = new productDetailModule();
