import { preprocessUrl } from '@/shared/url';

import { changeHref } from '@/features/router/universal';
export function bindTopIconBack() {
    if (Fai.top._templateFrameId === 2004) {
        var topBack = jm('.J_to_back');

        if (!topBack.length) return;

        topBack.off('click.tb').on('click.tb', function () {
            if (VITE_APP_MODE !== 'visitor') {
                jm(Fai.top.document.getElementById('jz_designerOperate').querySelector('.j_designerBack')).click();
            } else {
                if (window.history.length <= 1) {
                    changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp');
                } else {
                    window.history.back();
                }
            }
        });
    }
}

export const repeatValueMapping = {
    2: 'cover',
    3: '100% 100%',
    4: 'inherit',
    5: 'no-repeat',
};

export const createLogoImgBorderStyle = function (logoStyleId, isMallTheme) {
    const mallThemeLogoBorder = '0.1rem solid rgba(256, 256, 256, 0.6);background-clip:content-box;';
    let style = {};
    if ([1, 2, 4, 5].includes(logoStyleId)) {
        isMallTheme && (style.border = 'none');
    } else if (logoStyleId === 3 || logoStyleId === 6) {
        style.border = isMallTheme ? mallThemeLogoBorder : '2px solid #FFF;box-shadow:0 0 6px rgba(0,0,0,0.4)';
    }
    return style;
};

export const createLogoImgShadowStyle = function (logoStyleId) {
    return logoStyleId === 2 || logoStyleId === 5
        ? {
              boxShadow: '1px 1px 2px rgba(0,0,0,0.4)',
          }
        : {};
};

export const createLogoImgRadiusStyle = function (logoStyleId, isMallTheme) {
    const mallThemeLogoRadius = '5px';
    let style = {};
    if (logoStyleId === 1 || logoStyleId === 2) {
        style.borderRadius = '3px';
    } else if (logoStyleId === 3) {
        style.borderRadius = isMallTheme ? mallThemeLogoRadius : '3px';
    } else if ([4, 5, 6].includes(logoStyleId)) {
        style.borderRadius = '100%';
    }
    return style;
};
