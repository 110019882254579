import { isArray, context } from '@jz/utils';
import { LanCode } from '@jz/biz-shared';
import { isIE } from './browser';
export const ing = function (str, autoClose, autoTime, iconType) {
    var msg = str == null || str == '' ? '正在处理...' : str;
    var html = '';
    var ingStyle = 'position:fixed; top:50px; left: 50%; margin:0px auto; width:auto;  z-index:9999;';
    var animateStyle =
        'transition: opacity ease .6s; -moz-transition: opacity ease .6s; -webkit-transition: opacity ease .6s; -o-transition: opacity ease .6s; opacity: 0; -webkit-opacity: 0; -moz-opacity: 0; -khtml-opacity: 0; filter:alpha(opacity=0);';
    html = `<div id='ing' style='${ingStyle}${animateStyle}'></div>`;

    if ($('#ing').length == 0) {
        $(html).appendTo('body');
    }

    var ing = $('#ing');
    var bodyTop = $(document).scrollTop();

    if (isIE() && bodyTop == 0) {
        bodyTop = $('html').scrollTop();
    }

    var iconClass = iconType == 'success' ? 'icon_success' : iconType == 'warning' ? 'icon_warning' : '';
    var iconHtml = iconClass !== '' ? '<div class="icon ' + iconClass + '"></div>' : '';
    var id = parseInt(Math.random() * 10000);
    let tips = `
      <div id='${id}' class='tips'>
        ${iconHtml}
        <div class="msg">${msg}</div>
        <div class="close"></div>
      </div>
  `;
    ing.find('.tips').remove();
    $(tips).appendTo(ing); // 居中处理
    var ingWidth = $(ing).width();
    $(ing).css('left', (document.documentElement.clientWidth - ingWidth) / 2);

    if (autoClose) {
        removeIng(autoClose, id, autoTime);
    }
    /* 渐变出现 */
    $('#ing').css({
        opacity: 1,
    });

    $('#ing')
        .find('.close')
        .bind('mouseenter', function () {
            $(this).addClass('close_hover');
        })
        .bind('mouseleave', function () {
            $(this).removeClass('close_hover');
        })
        .on('click', () => {
            removeIng(false, id);
        });
};

export function registerIngApi(Vue) {
    Vue.prototype.$ing = ing;
    Vue.prototype.$mobiIng = mobiIng;
}

// from Mobi.ing 与 Fai.ing 不同
export function mobiIng(str, iconClass, keepOpen, duration = 1500) {
    //iconClass:  默认为警告 0:警告 1:成功 2:失败
    var msg = str == null || str == '' ? '正在处理...' : str;
    var iconstr = '';
    var hasTipsDiv = false;
    if (iconClass == '' || iconClass == null || iconClass == 0) {
        // 警告
        iconstr = "<div class='faisco-icons-S000359 iconMargin'></div>";
    } else if (iconClass == 1) {
        // 成功
        iconstr = "<div class='faisco-icons-S000357 iconMargin'></div>";
    } else if (iconClass == 2) {
        // 失败
        iconstr = "<div class='faisco-icons-S000358 iconMargin'></div>";
    } else {
        iconstr = '';
    }
    keepOpen = keepOpen ? keepOpen : false; //是否保持提示不关闭，网络很差时避免提示闪烁

    var html = '';
    html = "<div id='mobiIngTigs' class='mobiIngTigs'></div>";
    if (jm('#mobiIngTigs').length == 0) {
        jm(html).appendTo('body');
    } else {
        hasTipsDiv = true;
    }
    var ing = jm('#mobiIngTigs');

    var id = parseInt(Math.random() * 10000);
    var tips =
        '<div id="' +
        id +
        '" class="mobiTips" \'>' +
        '<div id="mobiMsg' +
        id +
        '" class="mobiMsg">' +
        iconstr +
        "<div class=''>" +
        msg +
        '</div>' +
        '</div>' +
        '</div>';

    ing.html(tips);

    if (hasTipsDiv) {
        ing.css('visibility', 'inherit');
        ing.css('opacity', '1');
    }

    //网络很差时避免提示闪烁，保持提示不关闭（主要针对文件上传）
    if (!keepOpen) {
        window.setTimeout(function () {
            ing.css('visibility', 'hidden');
            ing.css('opacity', '0');
        }, duration + 1000);
        window.setTimeout(function () {
            jm('#' + id).remove();
            ing.remove();
        }, duration);
    }
}
const removeIng = (autoClose, id, autoTime) => {
    if (autoClose) {
        if (typeof id != 'undefined' && $('#' + id).length > 0) {
            setTimeout(
                function () {
                    $('#' + id).fadeOut(1000);
                },
                autoTime ? autoTime : 3000
            );
            setTimeout(
                function () {
                    $('#' + id).remove();
                },
                autoTime ? autoTime + 1500 : 4500
            );
        } else {
            $('.tips').fadeOut(1000);
            setTimeout(
                function () {
                    $('#ing').remove();
                },
                autoTime ? autoTime : 3000
            );
        }
    } else {
        if (typeof id != 'undefined' && $('#' + id).length > 0) {
            $('#' + id).fadeOut(500);
            setTimeout(function () {
                $('#' + id).remove();
            }, 1000);
        } else {
            $('.tips').fadeOut(500);
            setTimeout(function () {
                $('#ing').remove();
            }, 1000);
        }
    }

    $('#ing').css('opacity', 0);
};

export const fkEval = (data) => {
    return eval(data);
};

export const isNull = (key) => typeof key == 'undefined' || key == null;

export const addUrlParams = (url, param) => {
    if (isNull(param)) {
        return url;
    }
    if (url.indexOf('?') < 0) {
        return url + '?' + param;
    }
    return url + '&' + param;
};

let intervalFunc = [];

// 启动时间处理函数，id为null表示启动所有
export const startInterval = function (id) {
    if (!isArray(intervalFunc)) {
        return;
    }
    for (var i = 0; i < intervalFunc.length; ++i) {
        var x = intervalFunc[i];
        if (id == null || x.id == id) {
            if (x.timer) {
                clearInterval(x.timer);
            }
            if (x.type == 1) {
                if (id == 'marquee1168') {
                    x.func();
                }
                x.timer = setInterval(x.func, x.interval);
            } else {
                x.timer = setTimeout(x.func, x.interval);
            }
        }
    }
};

// 停止时间处理函数，id为null表示停止所有
export const stopInterval = function (id) {
    if (!isArray(intervalFunc)) {
        return;
    }
    for (var i = 0; i < intervalFunc.length; ++i) {
        var x = intervalFunc[i];
        if (id == null || x.id == id) {
            if (x.timer) {
                clearInterval(x.timer);
            }
        }
    }
};

export function isFontIcon(iconId) {
    if (!iconId || iconId.length == 0 || iconId.length < 'FontIcon_'.length) {
        return false;
    }

    return (
        iconId.substring(0, 'NewFontIcon_'.length) == 'NewFontIcon_' ||
        iconId.substring(0, 'FontIcon_'.length) == 'FontIcon_'
    );
}

export function rgb2hex(rgb) {
    if (!rgb) {
        return '';
    }
    if (rgb.charAt(0) == '#') {
        return rgb;
    }

    var ds = rgb.split(/\D+/);
    var decimal = Number(ds[1]) * 65536 + Number(ds[2]) * 256 + Number(ds[3]);
    var s = decimal.toString(16);

    while (s.length < 6) {
        s = '0' + s;
    }

    return '#' + s;
}

function isUrl(str_url, supAbsuRoot) {
    if (typeof supAbsuRoot == 'undefined') {
        supAbsuRoot = true;
    }

    if (supAbsuRoot && str_url.length >= 1 && str_url.charAt(0) == '/') {
        return true;
    }

    if (supAbsuRoot && str_url.length >= 1 && str_url.charAt(0) == '#') {
        return true;
    }

    var re = /^(\w+:).+/;
    var result = re.test(str_url);
    return result;
}

export function fixUrl(url, supAbsuRoot) {
    if (isUrl(url, supAbsuRoot)) {
        return url;
    }

    // eslint-disable-next-line @jz/no-use-http-prefix
    return 'http://' + url;
}

export const getLanCode = function () {
    var lcid = context._lcid,
        lanCode = '';
    lcid = lcid ? lcid : 2052;
    lanCode = LanCode?.getCodeByLcid(lcid);

    //startsWith ie不支持
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }

    //当前路径url不包含lanCode时，表明当前默认语言版本
    var url = context?.location?.pathname || '';
    if (!url.startsWith('/' + lanCode)) {
        lanCode = '';
    }
    return lanCode;
};

export const getCssInt = function (ctrl, css) {
    if (ctrl.css(css)) {
        var tmp = parseInt(ctrl.css(css).replace('px', ''));
        if (isNaN(tmp)) {
            return 0;
        }
        return tmp;
    } else {
        return 0;
    }
};

export function addBookmark(title, url) {
    title = title == '' ? document.title : title;
    url = url == '' ? '//' + window.location.host : url;

    try {
        try {
            window.sidebar.addPanel(title, url, '');
        } catch (e) {
            window.external.AddFavorite(url, title);
        }
    } catch (e1) {
        alert('收藏网站失败，请使用Ctrl+D进行添加。');
    }
}

/*
ajax提交成功返回的结果的处理
尚未登录，则返回success:false,login:true,loginUrl:url
jumpUrl:处理成功后跳转的页面，如果为null或者""，则不跳转
successMsg/failureMsg: 如果successMsg或failureMsg为空，则使用ajax返回的结果
jumpMode: 跳转页面的模式（0--当前页面跳转(默认)	1--top页面跳转 2--parent页面跳转, 3--不跳转， 4--指定位置刷新（用eval执行传过来的语句)），5--当前页面跳转(地址不去锚，暂时用于Mobi端)
alertMode: 弹出对话框的模式(0--默认的alert模式 1--便签模式Fai.ing("") 2--便签模式且不自动关闭tips Fai.ing("", false),3-不弹出)
*/
export const successHandle = function (resultData, successMsg, failureMsg, jumpUrl, jumpMode, alertMode) {
    Fai.top.$('#ing').find('.tips').remove();

    // 兼容jQ的ajax和jzQuest调用
    const result = typeof resultData == 'string' ? JSON.parse(resultData) : resultData;

    var output = '';
    if (result.success) {
        if (result.msg) {
            output = result.msg;
        }
        if (successMsg != '') {
            output = successMsg;
        }
        if (output && output != '') {
            if (alertMode == 0) {
                removeIng(true);
                alert(output);
            } else if (alertMode == 1) {
                ing(output, true);
            } else if (alertMode == 2) {
                ing(output, false);
            } else if (alertMode == 3) {
                // 不处理
            } else {
                removeIng(true);
                alert(output);
            }
        }
        if (jumpUrl != '') {
            if (jumpMode == 1) {
                jumpUrl = jumpUrl.replace(/#.*/, ''); // remove #
                if (Fai.top.location.href == jumpUrl) {
                    Fai.top.location.reload();
                } else {
                    Fai.top.location.href = jumpUrl;
                }
            } else if (jumpMode == 2) {
                jumpUrl = jumpUrl.replace(/#.*/, ''); // remove #
                if (parent.location.href == jumpUrl) {
                    parent.location.reload();
                } else {
                    parent.location.href = jumpUrl;
                }
            } else if (jumpMode == 3) {
                return result.success;
            } else if (jumpMode == 4) {
                Fai.fkEval(jumpUrl);
            } else if (jumpMode == 5) {
                if (Fai.top.location.href == jumpUrl) {
                    Fai.top.location.reload();
                } else {
                    Fai.top.location.href = jumpUrl;
                }
            } else {
                jumpUrl = jumpUrl.replace(/#.*/, ''); // remove #
                if (document.location.href == jumpUrl) {
                    document.location.reload();
                } else {
                    document.location.href = jumpUrl;
                }
            }
        }
    } else {
        if (result.msg) {
            output = result.msg;
        }
        if (output == '') {
            output = failureMsg;
        }
        if (output == '') {
            output = '系统错误';
        }
        if (alertMode == 0) {
            alert(output);
        } else if (alertMode == 1 || alertMode == 2) {
            Fai.ing(output, false);
        } else {
            alert(output);
        }
    }

    return result.success;
};

export const checkBit = function (flag, bitFlag) {
    //return (flag & bitFlag) == bitFlag;

    /*
    因为js位操作有关的超过了32位后无效。所有位置0。第32位代表负数。且32位的左移1位就是直接跳回到第1位。  与java long类型移位操作不符。
    20131225修改  支持long 类型62位内的checkBit
    */
    var bit_31 = true; //31位皆置1为：2147483647

    if (flag > 2147483647 || flag < 0 || bitFlag > 2147483647 || bitFlag < 0) {
        bit_31 = false;
    }

    if (bit_31) {
        return (flag & bitFlag) == bitFlag;
    }

    var flagBinary = flag.toString(2);
    var bitFlagBinary = bitFlag.toString(2);

    if (flagBinary.length > 62 || bitFlagBinary.length > 62) {
        alert(
            'Does not support more than 62 bit. flagBinary.length=' +
                flagBinary.length +
                ',bitFlagBinary.length' +
                bitFlagBinary.length +
                '.'
        );
        return false;
    } //flagBinary = flagBinary.split("").reverse().join("");
    //bitFlagBinary = bitFlagBinary.split("").reverse().join("");

    var flagLow;
    var bitFlagHight;
    var bitFlagLow;
    var flagHight;
    var lowStr;
    var hightStr;
    flagHight = flagLow = bitFlagHight = bitFlagLow = 0; //拆分高低位处理

    if (flagBinary.length > 31) {
        hightStr = flagBinary.slice(0, flagBinary.length - 31);
        lowStr = flagBinary.slice(flagBinary.length - 31);
        flagHight = parseInt(hightStr, '2');
        flagLow = parseInt(lowStr, '2');
    } else {
        flagLow = parseInt(flagBinary.slice(0, flagBinary.length), '2');
    }

    if (bitFlagBinary.length > 31) {
        hightStr = bitFlagBinary.slice(0, bitFlagBinary.length - 31);
        lowStr = bitFlagBinary.slice(bitFlagBinary.length - 31);
        bitFlagHight = parseInt(hightStr, '2');
        bitFlagLow = parseInt(lowStr, '2');
    } else {
        bitFlagLow = parseInt(bitFlagBinary.slice(0, bitFlagBinary.length), '2');
    }

    var result = (flagLow & bitFlagLow) == bitFlagLow;

    if (result) {
        result = (flagHight & bitFlagHight) == bitFlagHight;
    }

    return result;
};

export * from './popupWindow/index';

export const format = (...args) => {
    var s = args[0];
    for (var i = 0; i < args.length - 1; i++) {
        var reg = new RegExp('\\{' + i + '\\}', 'gm');
        s = s.replace(reg, args[i + 1]);
    }
    return s;
};

export function parseFileSize(bit) {
    if (typeof bit != 'undefined' && typeof bit == 'number') {
        var newFileSize;
        var tmpSize;
        if (bit < 1024) {
            newFileSize = bit + 'B';
        } else if (bit < 1024 * 1024) {
            tmpSize = bit / 1024;
            //alert(tmpSize);
            newFileSize = tmpSize.toFixed(2) + 'KB';
        } else {
            tmpSize = bit / (1024 * 1024);
            newFileSize = tmpSize.toFixed(2) + 'MB';
        }

        return newFileSize;
    } else {
        return '-';
    }
}
