import { mobiIng } from '@/shared/fai';
import { participantLottery } from '@/api/linkSetting';
import { preprocessUrl } from '@/shared/url';

let LotteryPopup = {};
// eslint-disable-next-line no-unused-vars
const CUSTOM = 1, // 自定义奖品
    PRODUCT = 2, // 产品
    INTEGRAL = 3, // 会员积分
    NOTHING = 4; // 安慰语

LotteryPopup.component = {
    el: '#lotteryPopup',
    template: `
        <transition name="fade">
            <div v-show="!hidden" :class="!hidden ? 'lottery-fade-in' : 'lottery-fade-out'" class="fix-wrap-20201019">
                <div class="webLeft"></div>
                <div class="popup-mask">
                    <transition name="scale">
                        <div v-show="!hidden" :class="!hidden ? 'lottery-scale-in' : 'lottery-scale-out'" class="popup">
                            <ul class="lottery-list">
                                <li
                                    @click="hitLottery(index)"
                                    v-for="(lot, index) in lotteryList"
                                    class="lottery-item"
                                    :key="index"
                                    :class="[lot.index == finishIndex && finished ? 'finish-item' : '', 
                                    index == 4 ? activeClass : '', 
                                    begin && activeIndex == lot.index ? 'item-active' : 'item-default',
                                    ]"
                                >
                                    <div style="text-align:center;" :class="lot.type == ${PRODUCT} ? 'lot-special-img-wrap' : ''">
                                        <img v-if="index != 4" :src="lot.picPath" alt="" :class="lot.type == ${NOTHING} || lot.type == ${INTEGRAL} ? 'lot-icon-img' : lot.type == ${PRODUCT} ? 'lot-special-img' : 'lot-pro-img'">
                                    </div>
                                    <span v-if="lot.type == 2" class="lot-desc">{{lot.pInfo.name}}</span>
                                    <span v-if="lot.type != 2" class="lot-desc">{{lot.name}}</span>
                                    <span v-if="index != 4 && begin && activeIndex != lot.index" class="lot-bg"></span>
                                </li>
                            </ul>
                            <svg @click="hideLotPopup" class="close">
                                <use href="#jzm_icon_close3"></use>
                            </svg>
                        </div>
                    </transition>
                </div>
                <div class="webRight"></div>
            </div>
        </transition>
    `,
    name: 'lotteryPopup',
    methods: {
        // 控制滚动穿透
        switchScroll(hidden) {
            if (hidden) {
                // 恢复原属性
                jm('#g_body').css('overflow-y', 'auto');
            } else {
                jm('#g_body').css('overflow', 'hidden');
            }
        },
        hideLotPopup() {
            this.hidden = true;
            this.switchScroll(true);
            setTimeout(() => {
                LotteryPopup.destroy();
            }, 300);
        },
        hitLottery(index) {
            if (this.count > 1 || index != 4 || this.begin) return;
            this.finished = false;

            const phone = this.phoneNumber,
                lotteryId = this.lotteryId;
            // var postData = {
            //     cmd: 'addWafCk_participantLottery',
            //     lotteryId,
            //     phone,
            // };
            participantLottery(lotteryId, phone).then((data) => {
                if (data.success) {
                    const prize = data.info.prize;
                    this.lotteryAnimation(prize);
                } else if (data.rt == -1) {
                    // 跳转登录页
                    let loginUrl = this.loginUrl;
                    if (!loginUrl) {
                        loginUrl = Fai.top.location.href + `&lotteryId=${lotteryId}`;
                    }
                    localStorage.setItem('returnUrl', loginUrl);
                    location.href = preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp';
                } else if (data.rt == -6) {
                    mobiIng('感谢您的参与！');
                }
            });
        },
        lotteryAnimation(prize) {
            const HITRATE = prize.pby,
                INITIAL = 10,
                STEP = 20,
                ADD_TIMES = 2,
                CENTER_INDEX = 4,
                NUM = 8,
                MAX_SPEED = 300;

            let that = this;
            let lotteryList = that.lotteryList.slice();
            const filterList = lotteryList.filter((v) => v.pby == HITRATE && v.id == prize.id),
                COUNT = filterList.length,
                random = Math.floor(Math.random() * COUNT),
                randomIndex = filterList[random].index;

            let speed = INITIAL;

            that.begin = true;
            that.activeClass = 'center2';
            that.lotteryList[CENTER_INDEX].title = '抽奖中';
            that.lotteryList[CENTER_INDEX].desc = '';
            function start() {
                let loop = setInterval(() => {
                    that.activeIndex = (that.activeIndex % NUM) + 1;
                    that.count++;
                    if (speed < MAX_SPEED) {
                        speed += STEP;
                        clearInterval(loop);
                        loop = null;
                        start();
                    } else {
                        speed += STEP * ADD_TIMES;
                        // eslint-disable-next-line no-unused-vars
                        for (let item of lotteryList) {
                            if (randomIndex == that.activeIndex) {
                                clearInterval(loop);
                                loop = null;
                                setTimeout(() => {
                                    that.finished = true;
                                    that.finishIndex = randomIndex;
                                    setTimeout(() => {
                                        that.count = 1;
                                        that.finished = false;
                                        that.begin = false;
                                        that.activeClass = 'center1';
                                        that.lotteryList[CENTER_INDEX].title = '抽奖';
                                        for (let lot of lotteryList) {
                                            if (lot.index == randomIndex) {
                                                that.hideLotPopup();
                                                // 还原图片路径
                                                if (lot.type >= 3) {
                                                    lot.picPath = lot.originPath;
                                                }
                                                Mobi.ResultPopup.render(lot, prize);
                                                break;
                                            }
                                        }
                                    }, 800);
                                }, 500);
                                return;
                            }
                        }
                    }
                }, speed);
            }
            start();
        },
    },
};
LotteryPopup.render = function (lotteryList, lotteryId, phoneNumber, loginUrl) {
    $("<div id='lotteryPopup'></div>").appendTo('body');

    // 处理抽奖列表
    lotteryList.splice(4, 0, {
        index: 0,
        picPath: '',
        name: '',
    });

    // 实现抽奖动画效果的index，固定值
    lotteryList[0].index = 1;
    lotteryList[1].index = 2;
    lotteryList[2].index = 3;
    lotteryList[3].index = 8;
    lotteryList[4].index = 0;
    lotteryList[5].index = 4;
    lotteryList[6].index = 7;
    lotteryList[7].index = 6;
    lotteryList[8].index = 5;

    for (let i = 0; i < lotteryList.length; i++) {
        if (lotteryList[i].type == 3) {
            lotteryList[i].originPath = lotteryList[i].picPath;
            lotteryList[i].picPath = `${Fai.top._resRoot}/image/v2/lottery/zp2.png`;
        } else if (lotteryList[i].type == 4) {
            lotteryList[i].originPath = lotteryList[i].picPath;
            lotteryList[i].picPath = `${Fai.top._resRoot}/image/v2/lottery/zp0.png`;
        } else if (!lotteryList[i].picPath && lotteryList[i].index != 0) {
            lotteryList[i].picPath = `${Fai.top._resRoot}/image/v2/lottery/zp1.png`;
        } else if (lotteryList[i].picPath && lotteryList[i].picPath.includes('_404')) {
            lotteryList[i].picPath = lotteryList[i].picPath.replace(/_404/, '');
        }
    }

    this.component.data = {
        hidden: false,
        begin: false,
        finished: false,
        finishIndex: 0,
        activeIndex: 1,
        count: 1,
        activeClass: 'center1',
        lotteryList,
        lotteryId,
        phoneNumber,
        loginUrl,
    };
    this.vm = new Vue(this.component);
    this.component.methods.switchScroll(false);
};
LotteryPopup.destroy = function () {
    if (this.vm) {
        $(this.vm.$el).remove();
        this.vm && this.vm.$destroy();
        this.vm = null;
    }
};

export default LotteryPopup;
