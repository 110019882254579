/* eslint-disable no-undef */
import { logJZFdp } from '@/api/logDog';
import { request } from '@/api/request';
import { mobiIng } from '@/shared/fai';
import { browser } from '@jz/utils';

export const initJumpWxApp = function (moduleId, jumpWxAppData) {
    if (VITE_APP_MODE !== 'visitor') {
        return;
    }
    let jumpAppPath = '';
    let jumpAppId = '';
    let { type = 0, qzOriginalId = '', qzWxAppPath = '', id, path = '', qzAppId = '' } = jumpWxAppData;
    if (type === 0) {
        jumpAppId = id;
        jumpAppPath = path;
    } else if (type === 1) {
        jumpAppId = qzOriginalId;
        jumpAppPath = qzWxAppPath;
    }

    if (jumpAppPath !== '' && !jumpAppPath.includes('.html')) {
        if (jumpAppPath.includes('?')) {
            jumpAppPath = jumpAppPath.replace(/(.*?)(\?)/g, '$1.html$2');
        } else {
            jumpAppPath += '.html';
        }
    }

    const $module = jm(`#module${moduleId}`);
    if ($module.length == 0) {
        console.error('Mobi.initJumpWxApp: 找不到模块');
        return;
    }
    const addEmptyWrap = ({ clickNoEvent = false }) => {
        let ajaxLoading = false;
        jm('<div class="wxapp_launch_btn_wrap"></div>')
            .appendTo($module)
            .click(function (e) {
                jumpWxAppFdpLog({ type, isErrorInWx: false });
                e.stopPropagation();
                if (clickNoEvent) {
                    return;
                }
                // eslint-disable-next-line no-undef
                if (browser.isPc()) {
                    mobiIng('请在手机微信端打开链接');
                } else {
                    // 不在移动端微信并且不在pc端中，弹出提示
                    if (type === 1) {
                        // 轻站小程序，请求后端是否能直接跳转微信并打开小程序
                        if (ajaxLoading) {
                            return;
                        }
                        ajaxLoading = true;
                        getWeixinAppScheme({
                            appid: qzAppId,
                            pageUrl: qzWxAppPath,
                        })
                            .then((data) => {
                                const { success = false, openlink = '' } = data.data;
                                if (success && openlink) {
                                    window.location.href = openlink;
                                } else {
                                    mobiIng('请在手机微信端打开链接');
                                }
                                ajaxLoading = false;
                            })
                            .catch(() => {
                                mobiIng('请在手机微信端打开链接');
                                ajaxLoading = false;
                            });
                    } else {
                        // 不是轻站小程序，则直接走弹窗跳微信逻辑
                        mobiIng('请在手机微信端打开链接');
                    }
                }
            });
    };
    if (!Fai.top._isAllowJumpWxApp) {
        addEmptyWrap({ clickNoEvent: true });
        return;
    }
    initWxConfig()
        .then(() => {
            // 电脑pc端微信也暂时不支持跳转小程序，给出提示
            // eslint-disable-next-line no-undef
            if (browser.isWindowsWechat()) {
                addEmptyWrap({ clickNoEvent: false });
                return;
            }
            // 用原生吧，jm有毒
            const { width, height } = window.getComputedStyle($module[0]);
            const wrapId = `launchBtnWrap${moduleId}`;
            const wxAppBtnHtml = `
              <div id="${wrapId}" class="wxapp_launch_btn_wrap">
                  <wx-open-launch-weapp
                      class="wxapp_launch_btn"
                      id="launchBtn${moduleId}"
                      username="${jumpAppId}"
                      path="${jumpAppPath}"
                  >
                      <template>
                          <div style="width: ${width}; height: ${height};">&nbsp;</div>
                      </template>
                  </wx-open-launch-weapp>
              </div>
          `;
            jm(wxAppBtnHtml).appendTo($module);
            jm(`#${wrapId}`).click(function (e) {
                e.stopPropagation();
            });
            var btn = document.getElementById(`launchBtn${moduleId}`);
            btn.addEventListener('launch', () => {
                jumpWxAppFdpLog({ type, isErrorInWx: false });
            });
            btn.addEventListener('error', () => {
                jumpWxAppFdpLog({ type, isErrorInWx: true });
            });
        })
        .catch((err) => {
            let isNotInWechatPc = typeof err === 'object' && err.type === -1;
            if (!isNotInWechatPc) {
                console.log(err);
            }
            addEmptyWrap({ clickNoEvent: !isNotInWechatPc });
        });
};

const jumpWxAppFdpLog = ({ type = 0, isErrorInWx = false }) => {
    // eslint-disable-next-line no-undef
    const isWeixin = browser.isWeChat();
    const isWeixinPc = browser.isWindowsWechat();
    const isPc = browser.isPc();
    const isInMiniProgram = browser.isWechatMiniProgram();
    const isInMobiWechat = isWeixin && !isWeixinPc;
    let environment = '';
    if (isPc) {
        environment = 'pc';
    } else if (isInMiniProgram) {
        environment = '微信小程序';
    } else if (isInMobiWechat) {
        environment = '微信';
    } else {
        environment = '移动端外网';
    }
    logJZFdp('jz_fktzxcx', {
        jz_free_text1: type === 0 ? '外部小程序' : '轻站小程序',
        jz_content_terminal: 'mobi',
        jz_free_bool1: isErrorInWx,
        jz_version: Fai.top._ver || 0,
        jz_domain: window.location.href,
        jz_free_text0: environment,
    });
};

/**
 * 获取跳转小程序的url
 * @param { Object } ajaxData - 数据
 * @returns { Promisee } - 结果
 */
export function getWeixinAppScheme(ajaxData) {
    const data = {
        cmd: 'getWafNotCk_getWeixinAppScheme',
        ...ajaxData,
    };
    return request.post('/ajax/mobi_h.jsp', { data });
}

export const initWxConfig = function () {
    return (
        Mobi.initWxConfig.p ||
        (Mobi.initWxConfig.p = new Promise((resolve, reject) => {
            const ua = navigator.userAgent.toLowerCase();
            const isWeixin = ua.indexOf('micromessenger') != -1 || ua.indexOf('wxwork') != -1;
            if (!isWeixin) {
                reject({ type: -1, msg: '不是微信浏览器' });
                return;
            }
            if (typeof Fai.top.wxConfigData !== 'object') {
                reject({ type: -2, msg: '缺失Fai.top.wxConfigData' });
                return;
            }
            const { appId, openWxDebug } = Fai.top.wxConfigData;
            getWXShareData(window.location.href, ({ timestamp, noncestr, signature }) => {
                wx.config({
                    debug: openWxDebug,
                    appId,
                    timestamp,
                    nonceStr: noncestr,
                    signature,
                    jsApiList: [
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                        'scanQRCode',
                    ],
                    openTagList: ['wx-open-launch-weapp'],
                });
                wx.ready(function () {
                    resolve();
                });
                wx.error(function (...args) {
                    console.error('wx.error: ', ...args);
                    reject({ type: -3, msg: 'wxVerifyError' });
                });
            });
        }))
    );
};

// 微信分享 （分销功能沿用这个，若修改记得同步Dist.getWXShareData）
const getWXShareData = function (url, callBack) {
    request
        .post('/ajax/wx_h.jsp?cmd=getWafNotCk_getWXShareData', {
            data: {
                url,
            },
        })
        .then((result) => {
            let { data = {} } = result;
            if (data.success) {
                let wxShareData = {};
                wxShareData.noncestr = data.noncestr;
                wxShareData.timestamp = data.timestamp;
                wxShareData.signature = data.signature;
                callBack(wxShareData);
            }
        });
};
