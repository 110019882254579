import { getNavV2, getNavV3 } from '@/api/nav';
import { COL_DEF_FLAG } from '@/def/col';
import { checkBit } from '@/shared/fai';

/**
 * =================================================
 *
 * Constants
 *
 * =================================================
 */

const LOAD_NAV = 'LoadNavV3';
const LOAD_NAV_V2 = 'LoadNavV2';
const UPDATE_NAV = 'UpdateNav';
const UPDATE_FULL_PAGE_NAV = 'UpdateFullPageNav';
const UPDATE_INDEPENDENT_NAV = 'UpdateIndependentNav';
const SET_NAV_V2 = 'setNavV2';

export { LOAD_NAV };

/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    navList: [],
    navChildren: [],
    pattern: {},
    selectNavSkinId: null,
    skinPattern: {},
    defaultFullsitePattern: {},
    navPattern: {},
    hasSecMainNav: false,
    navHash: {},
    fullPageNav: {
        changed: false,
        hide: false,
    },
    independentNav: {
        changed: false,
        hide: false,
        set: false,
    },
    navV2: {
        arrowOpen: false,
        columnStyle: 1,
        navList: [],
        navChildren: [],
        navHash: {},
    },
});

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    loadNav({ commit, rootState }) {
        if (rootState.app.openThemeV3) {
            return getNavV3(rootState.app.colId).then((data) => {
                commit(LOAD_NAV, data);
                commit(UPDATE_INDEPENDENT_NAV, {
                    set: checkBit(rootState.app.colInfo.flag, COL_DEF_FLAG.INDEPENDENT_SET_NAV),
                });
            });
        } else {
            return getNavV2(rootState.app.colId).then((data) => {
                commit(LOAD_NAV_V2, data);
            });
        }
    },
    updateList({ commit }, list) {
        commit(UPDATE_NAV, list);
    },
    updateFullpageNav({ commit }, payload) {
        commit(UPDATE_FULL_PAGE_NAV, payload);
    },
    updateIndependentNav({ commit }, payload) {
        commit(UPDATE_INDEPENDENT_NAV, payload);
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    [LOAD_NAV](state, payload) {
        const {
            selectNavSkinId,
            navList,
            navChildren,
            pattern,
            skinPattern,
            defaultFullsitePattern,
            navPattern,
            navHash,
        } = payload;

        state.selectNavSkinId = selectNavSkinId;
        state.navList = navList;
        state.navChildren = navChildren;
        state.pattern = pattern;
        state.skinPattern = skinPattern;
        state.defaultFullsitePattern = defaultFullsitePattern;
        state.navPattern = navPattern;
        state.navHash = navHash;
    },
    updateHasSecMainNav(state, flag) {
        state.hasSecMainNav = flag;
    },
    [UPDATE_NAV](state, list = []) {
        state.navList = list;
    },
    [UPDATE_FULL_PAGE_NAV](state, fullPageNav = {}) {
        state.fullPageNav = Object.assign({}, state.fullPageNav, fullPageNav);
    },
    [UPDATE_INDEPENDENT_NAV](state, independentNav = {}) {
        state.independentNav = Object.assign({}, state.independentNav, independentNav);
    },
    [LOAD_NAV_V2](state, payload) {
        const { arrowOpen = false, columnStyle = 1, navList = [], navChildren = [], navHash = {} } = payload;
        state.navV2.arrowOpen = arrowOpen;
        state.navV2.columnStyle = columnStyle;
        state.navV2.navList = navList;
        state.navV2.navChildren = navChildren;
        state.navV2.navHash = navHash;
    },
    [SET_NAV_V2](state, updater = {}) {
        Object.assign(state.navV2, updater);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
