<template>
    <module-frame :module-id="moduleId">
        <div v-if="showFixTopHeadHeight" id="fixTopHeadHeight" style="height: 2.25rem"></div>
        <div :id="'newsNewDetailPanel' + moduleId" class="newsNewDetailPanel newsDetailV3Panel" :class="headToolCls">
            <news-detail-header />
            <news-detail-content :info="newsInfo" />
        </div>
        <div v-if="isOpen && allowSubmitMessage" class="newsBottom J_newsBottom">
            <div
                v-if="allowSubmitMessage"
                id="postComment"
                class="postComment"
                :class="{ 'not-allowed-button': allowSubmitMessage === false }"
                ><span class="icon-postComm"></span>{{ mobi_productPostComment }}</div
            >
        </div>
        <comment />
    </module-frame>
</template>
<script>
//////  ModuleNewsDetailV3.java 迁移至此。

import { mapGetters, mapState, mapMutations } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import NewsDetailHeader from './component/header.vue';
import NewsDetailContent from './component/content.vue';
import comment from './component/comment.vue';
import { newsDetailMixins } from './mixins';

export default {
    components: {
        ModuleFrame,
        NewsDetailHeader,
        NewsDetailContent,
        comment,
    },
    mixins: [newsDetailMixins],
    inject: ['moduleId', 'openThemeV3'],
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['LS']),
        ...mapGetters(['getModuleById']),
        pageElementList() {
            //页面元素列表
            return this.content.pageElementList;
        },
        shareOn() {
            // 开启分享
            return this.content.ss == 0;
        },
        isOpen() {
            //是否开通评论
            return this.options.isOpen || false;
        },
        hasHeadTool() {
            let { showHomePage, sbb } = this.pageElementList;
            return showHomePage == 0 || sbb == 0 || this.shareOn;
        },
        headToolCls() {
            return !this.hasHeadTool ? 'is_no_head_tool' : '';
        },
        newsInfo() {
            return this.options.newsInfo;
        },
        showFixTopHeadHeight() {
            return this.options.indepentheadShow && this.options.isHeadFixTheme;
        },
        mobi_productPostComment() {
            return this.LS.mobi_productPostComment;
        },
    },
    watch: {
        module: {
            async handler() {
                await Vue.nextTick();
                this.init();
            },
            deep: true,
        },
        currentPageModules() {
            if (VITE_APP_MODE !== 'visitor') {
                this.$nextTick(() => {
                    this.fixPageBottom();
                });
            }
        },
    },
    mounted() {
        this.init();
        this.fixPageBottom();
    },
    methods: {
        ...mapMutations('app', ['setPageBottomFixed']),
        fixPageBottom() {
            let oneModule = Object.values(this.$store.state.currentPageModuleIdMap).length == 1;
            this.setPageBottomFixed(!oneModule && (this.isOpen || this.shareOn));
        },
    },
};
</script>

<style lang="scss"></style>
