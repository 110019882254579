export const TEMPLATE_LAYOUT = {
    NAV_TOP: 0,
    NAV_BOTTOM: 1,
    NAV_LEFT: 2,
    NAV_AUTO: 3,
    NAV_FIX: 4,
    NAV_HEAD: 5,
    NAV_BANNER_BOTTOM: 6,
    NAV_LEFT_FIX: 7,
    NAV_TOP_HIDE: 8,
};
