import Vue from 'vue';

/**
 * 将模块数据放进 currentPageModuleIdMap
 * @param {object} state  - Vuex State
 * @param {object} module - 模块数据
 */
export function setModuleToMap(state, module) {
    state.currentPageModuleIdMap = Object.assign({}, state.currentPageModuleIdMap, { [module.id]: module });
    if (VITE_APP_MODE !== 'visitor') {
        if (typeof window.currentPageModuleIdMap === 'object') {
            Vue.set(window.currentPageModuleIdMap, module.id, module);
        } else {
            window.currentPageModuleIdMap = state.currentPageModuleIdMap;
        }
    }
}

/**
 * 批量将模块数据放进 currentPageModuleIdMap
 * @param {object} state - Vuex State
 * @param {Array} modules - 模块数组
 */
export function batchSetModuleToMap(state, modules = []) {
    modules.forEach((module) => {
        setModuleToMap(state, module);
    });
}

/**
 * 合并新数据到 currentPageModuleIdMap
 * @param {object} state  - Vuex State
 * @param {object} updatedMap - 需要更新的模块数据
 */
export function assignModuleIdMap(state, updatedMap = {}) {
    state.currentPageModuleIdMap = Object.assign({}, state.currentPageModuleIdMap, updatedMap);
    if (VITE_APP_MODE !== 'visitor') {
        for (let id in updatedMap) {
            Vue.set(window.currentPageModuleIdMap, id, updatedMap[id]);
        }
    }
}
