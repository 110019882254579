<template>
    <component :is="nav"></component>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'Nav',
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        nav() {
            return this.openThemeV3 ? () => import('./v3/index.vue') : () => import('./v2/index.vue');
        },
    },
};
</script>
<style>
.navbar.jz_subMenu_1002 {
    font-size: 0;
}
</style>
