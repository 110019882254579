/**
 * 栏目Mapping
 */
const COL = {
    CUS: 1, // 自定义
    SYS_TEMPLATE: 2, // 模版栏目，代表全局栏目数据
    SYS_INDEX: 3, // 首页
    SYS_NEWS_DETAIL: 4, // 文章详情
    SYS_PRODUCT_DETAIL: 5, // 产品详情
    SYS_PRODUCT_RESULT: 6, // 产品筛选结果(产品展示、全部商品)
    SYS_NEWS_RESULT: 7, // 新闻筛选结果(新闻动态)
    SYS_MAP: 8, // 地图
    SYS_MSG_BOARD: 9, // 留言版
    SYS_MEMBER_LOGIN: 10, // 会员登录
    SYS_MEMBER_SIGNUP: 11, // 会员注册
    SYS_MEMBER_PROFILE: 12, // 会员资料
    SYS_MALL_CART: 13, // 购物车
    SYS_MALL_ORDER_SETTLE: 14, // 订单结算
    SYS_MALL_ORDER_LIST: 15, // 订单列表（我的订单）
    SYS_MALL_ORDER_DETAIL: 16, // 订单详情
    SYS_SEARCH_RESULT: 17, // 全站搜索结果
    SYS_PRODUCT_SEARCH_RESULT: 18, // 产品搜索结果
    SYS_MEMBER_INTEGRAL_LIST: 19, // 积分列表（我的积分）
    SYS_PRODUCT_NEW_DETAIL: 20, // 新产品详情
    SYS_NEWS_NEW_DETAIL: 21, // 新新闻详情
    SYS_MEMBER_FIND_PWD: 22, // 忘记密码
    SYS_PRODUCT_NEW_GROUP: 23, // 产品分类
    SYS_PHOTOGROUP_DETAIL: 24, // 图册详情页
    SYS_REFUND_LIST: 25, // 订单退货列表
    SYS_REFUND_DETAIL: 26, // 订单退货详情页
    SYS_PHOTOGROUP_RESULT: 27, // 图册筛选结果
    SYS_NEWS_DETAIL_V3: 28, // 文章详情 3.0 版本
    SYS_DIST: 29, // 分销登录
    SYS_GROUP_BUY_DETAIL: 30, // 拼团详情
    SYS_CUT_PRICE_DETAIL: 31, // 砍价详情
    SYS_COUPON_PROMOTION: 32, // 优惠券推广
    SYS_PROMOTER_WELCOME: 33, // 推广员中心（欢迎页）
    SYS_PROMOTER_INDEX: 34, // 推广员中心（首页）
    SYS_PROMOTER_ORDER: 35, // 推广订单（推广员）
    SYS_PROMOTER_INVITE: 36, // 我的邀请（推广员）
    SYS_PROMOTER_FLOW: 37, // 收支明细（推广员）
    SYS_PROMOTER_WITHDRAW: 38, // 提现（推广员）
    SYS_PROMOTER_WITHDRAW_MSG: 39, // 提现通知（推广员）
    SYS_PROMOTER_WITHDRAW_MSG_DETAIL: 40, // 提现通知详情（推广员）
    SYS_PROMOTER_CUSTOMERS: 41, // 客户列表(推广员)
    SYS_CUSTOM_SEARCH_RESULT: 42, // 查询信息结果页
    SYS_MEMBER_DEPOSIT: 43, // 我的余额首页
    SYS_DEPOSIT_TOPUP: 44, // 我的余额充值页
    SYS_DEPOSIT_TOPUP_LIST: 45, // 我的余额充值列表页
    SYS_DEPOSIT_CONSUME_LIST: 46, // 我的余额消费列表页
    SYS_DEPOSIT_INFO: 47, // 充值信息页
    SYS_DEPOSIT_PWD: 48, // 充值密码设置页
    SYS_PROMOTER_PD: 49, // 推广产品（推广员）
    SYS_MEMBER_SHIP_CARD_LIST: 50, // 会员卡列表
    SYS_MEMBER_SHIP_CARD_DETAIL: 51, // 会员卡详情
    SYS_RICH_PAGE: 52, // 接收并显示富文本信息
    SYS_COMBINED_PURCHASE_DETAIL: 53, // 组合套餐详情
    SYS_VIDEO_SHOPPING_DETAIL: 54, // 短视频购物详情
    SYS_MERCHANTS_APPLY: 55, // 多商户申请页
    SYS_MERCHANTS: 56, // 多商户模块
    SYS_SUBMERCHANT_DETAIL: 57, // 子商户详情页
    SYS_SUBMERCHANT_LOGIN: 58, // 子商户登录(结果)页
    SYS_MEMBER_INFO: 59, // 会员资料
    SYS_PROMOTER_CUS_DETAIL: 60, // 推广员客户详情页
    SYS_COMMUNITY_GROUP: 61, // 社区团购模块
    SYS_COMMUNITY_GROUP_BUYER_ACTIV_DETAIL: 62, // 社区团购买家活动详情页
    SYS_COMMUNITY_GROUP_BUYER_ACTIV_LIST: 63, // 社区团购买家活动列表页
    SYS_COMMUNITY_GROUP_GROUPER_LIST: 64, // 社区团购附近团长页
    SYS_COMMUNITY_GROUP_SIGNUP: 65, // 社区团购团长申请页
    SYS_COMMUNITY_GROUP_INDEX: 66, // 社区团购团长入口
    SYS_PROMOTER_ORDER_DETAIL: 67, // 推广订单详情
    SYS_PROMOTER_ORDER_FLOW: 68, // 推广订单物流
    SYS_SCAN_PAY: 69, // 当面付
    SYS_COOKIE: 74, // Cookie协议
};

const isNewsDetailCol = function (id) {
    return [COL.SYS_NEWS_DETAIL_V3, COL.SYS_NEWS_NEW_DETAIL, COL.SYS_NEWS_DETAIL].includes(id);
};

const isProductDetailCol = function (id) {
    return [COL.SYS_PRODUCT_DETAIL, COL.SYS_PRODUCT_NEW_DETAIL].includes(id);
};

const isPhotoGroupDetailCol = function (id) {
    return [COL.SYS_PHOTOGROUP_DETAIL].includes(id);
};

/**
 * 对应mobi.jsp.inc添加webDetailPage class的条件
 * 不是所有的详情页
 * @param {number} colId
 * @param {number} templateDesignType
 * @returns
 */
const isWebDetailPage = (colId, templateDesignType, openThemeV3) => {
    return (
        ([
            COL.SYS_PRODUCT_NEW_DETAIL,
            COL.SYS_NEWS_NEW_DETAIL,
            COL.SYS_PHOTOGROUP_DETAIL,
            COL.SYS_NEWS_DETAIL_V3,
            COL.SYS_PHOTOGROUP_DETAIL,
            COL.SYS_GROUP_BUY_DETAIL,
            COL.SYS_CUT_PRICE_DETAIL,
        ].includes(colId) ||
            (colId === COL.SYS_NEWS_DETAIL && templateDesignType === 2)) &&
        !openThemeV3
    );
};

export const COL_DEF_FLAG = {
    INDEPEND_SET_HEAD: 0x200000, // 独立设置顶部
    INDEPEND_SET_HEAD_HIDE: 0x400000, // 独立设置顶部隐藏
    INDEPENDENT_SET_NAV: 0x800, // 独立设置导航的开启或者关闭
    INDEPENDENT_HIDE_NAV: 0x1000, // 独立设置导航的显示或者隐藏
};

const isSpecialIndependSetMainNavPageV3 = (type) => {
    switch (type) {
        case COL.SYS_PRODUCT_DETAIL:
        case COL.SYS_PRODUCT_NEW_DETAIL:
        case COL.SYS_NEWS_NEW_DETAIL:
        case COL.SYS_PHOTOGROUP_DETAIL:
        case COL.SYS_NEWS_DETAIL_V3:
        case COL.SYS_MEMBER_LOGIN:
        case COL.SYS_MEMBER_PROFILE:
        case COL.SYS_MEMBER_SIGNUP:
        case COL.SYS_PRODUCT_NEW_GROUP:
            return true;
    }
    return false;
};

const isHiddenBackgroundColId = (type) => {
    switch (type) {
        case COL.SYS_MEMBER_LOGIN:
        case COL.SYS_MEMBER_PROFILE:
        case COL.SYS_MEMBER_SIGNUP:
            return true;
    }
    return false;
};

export {
    COL,
    isNewsDetailCol,
    isProductDetailCol,
    isWebDetailPage,
    isSpecialIndependSetMainNavPageV3,
    isHiddenBackgroundColId,
    isPhotoGroupDetailCol,
};
