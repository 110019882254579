import { getModulesByColId, addModuleAPI, getModulesByCusUrl } from '@/api/module';
import { COL } from '@/def/col';
import { useIdleCallback, useRaf } from '@/shared/util';

async function addModule(style, params, colId = COL.SYS_INDEX) {
    let cloneParams = { ...params };
    let { flag = {} } = params;
    cloneParams['flag'] && delete cloneParams['flag'];
    let { module } = await addModuleAPI({
        params: {
            style,
            flag,
            colId,
        },
        data: cloneParams ? cloneParams : void 0,
    });

    return module;
}

function loadCurrentPageModuleIdMap(modules = []) {
    // transform list to Map<id, module>
    const moduleIdMap = modules
        .map((item) => ({ [item.id]: item }))
        .reduce((acc, current) => (acc = Object.assign({}, acc, current)), {});

    if (VITE_APP_MODE !== 'visitor') {
        window.currentPageModuleIdMap = Object.assign(window.currentPageModuleIdMap || {}, moduleIdMap);
    }

    return moduleIdMap;
}

export default {
    async addModule({ commit, rootState, dispatch }, { addByDrag, style, params, parentId, insertIdx }) {
        const module = await addModule(style, params, rootState.app.colId);
        commit('addModule', { addByDrag, module, parentId, insertIdx });
        dispatch('manage/addPanel/setNeedReSortState');
        return module;
    },
    async loadCurrentPageModules({ commit }, { colId, cusUrl, list, routeData = {}, query }) {
        let modules = [];
        if (!list) {
            if (cusUrl) {
                modules = await (await getModulesByCusUrl(cusUrl, routeData, query)).modules;
            } else {
                modules = (await getModulesByColId(colId, routeData, query)).modules;
            }
        } else {
            if (typeof colId !== 'number') {
                throw new Error('colId must be a number, but current colId is ' + colId);
            }
        }

        const useTaskSlice = modules.length > 100;
        if (useTaskSlice) {
            let cancelRaf = null;
            let cancelIdleCallback = null;
            commit('clearCurrentPageModuleIds');
            const fn = (allModules, start, inc) => {
                if (start >= allModules.length) {
                    cancelRaf();
                    cancelIdleCallback();
                    commit('manage/loadedPageModules', true);
                    return;
                }
                const modules = allModules.slice(start, start + inc);
                commit(
                    'assignCurrentPageModuleIds',
                    modules.map((item) => item.id)
                );
                [, cancelIdleCallback] = useIdleCallback(() => {
                    [, cancelRaf] = useRaf(fn.bind(null, allModules, start + inc, inc));
                });
            };
            const moduleIdMap = loadCurrentPageModuleIdMap(modules);
            commit('assignCurrentPageModuleIdMap', moduleIdMap);
            commit('manage/loadCurrentPageModuleAttr', moduleIdMap);
            [, cancelRaf] = useRaf(fn.bind(null, modules, 0, 50));
        } else {
            const moduleIdMap = loadCurrentPageModuleIdMap(modules);
            commit(
                'loadCurrentPageModuleIds',
                modules.map((item) => item.id)
            );
            commit('assignCurrentPageModuleIdMap', moduleIdMap);
            commit('manage/loadCurrentPageModuleAttr', moduleIdMap);
            commit('manage/loadedPageModules', true);
        }

        return modules;
    },
    setModule({ commit }, { moduleId, data = {} }) {
        commit('setModule', { moduleId, data });
    },
    deleteModule({ commit }, moduleId) {
        commit('deleteModule', Number(moduleId));
    },
    hideModule({ commit, dispatch }, moduleId) {
        dispatch('manage/addPanel/clearNeedReSortState');
        commit('hideModule', Number(moduleId));
    },
    showModule({ commit, dispatch }, moduleId) {
        dispatch('manage/addPanel/clearNeedReSortState');
        commit('showModule', Number(moduleId));
    },
    addChildModule({ commit }, payload) {
        commit('addChildModule', payload);
    },
    changeCurrentPageModuleIds({ commit }, moduleIds) {
        moduleIds = moduleIds.map((id) => parseInt(id));
        commit('changeCurrentPageModuleIds', moduleIds);
    },
    updateDesignerShowLineHeight({ commit }, showLineHeight) {
        commit('designerShowLineHeightChange', showLineHeight);
    },
    setHiddenModules({ commit }, hiddenModules) {
        commit('setHiddenModules', hiddenModules);
    },
    updateModuleAttrPattern({ commit }, { moduleId, pattern }) {
        commit('manage/setModuleAttrPattern', { moduleId, pattern });
    },
    unshiftModule({ commit }, module = {}) {
        commit('unshiftModule', module);
    },
    pushModule({ commit }, module = {}) {
        commit('pushModule', module);
    },
    hideModuleByEntrance({ dispatch, commit }, { moduleId, entrance }) {
        if (entrance === 'moduleTool') {
            dispatch('manage/addPanel/setNeedReSortState');
        }
        commit('hideModule', Number(moduleId));
    },
};
