import { logDog } from '@/shared/log';

import mobiFormComponent from '@/modules/mobiForm/module/mobiForm.vue';
import FormPanel from '@/modules/mobiForm/panel/form_edit_panel';
import formPopupToolbarConfig from '@/system/formPopup/config/toolbar-form-popup.config';
const manageMode = VITE_APP_MODE !== 'visitor';
let FormPopup = {};
const MIN_WIDTH = 0.5; // 最小宽度
const MAX_WIDTH = 0.9; // 最大宽度
function scrollTop(scrollTop) {
    if (scrollTop) {
        document.getElementById('g_web').scrollTop = scrollTop;
    } else {
        return document.getElementById('g_web').scrollTop;
    }
}
FormPopup.component = {
    el: '#formDiv',
    template: `
			<div id="formDiv" class="formDiv" :class="popupStyle" @click="panelClick" ref="formDiv">
				<div v-if="isCoverStyle" @click="stopPropagation" class='formHeader ShipDetailsHeader webHeaderBg g_main_bgColor_v3 g_oldThemeBgColor'>
					<span class='g_close icon-gClose J_popup_close' @click="closeHandle"></span>
					<span class='popupName'>{{popupName}}</span>
				</div>
				<div id='modulePopupWrap' v-drag-resize="resizeOptions" :style="popupWidthStyle" ref="modulePopupWrap">
					<div id='modulePopup' @click="stopPropagation" v-hoverTool="hoverToolConfig" :module="module.id" :form="formId">
						<div class="fixedHead" :class="noAnimationClass" :style="fixedHeadWidthStyle"  ref="fixedHead">
							<span v-if="!isCoverStyle" class='popupName'>{{popupName}}</span>
							<span v-if="!isCoverStyle && showCloseBtn " class="mask_close J_popup_close" @click="closeHandle">
								<svg role="img" class="jzm-v26 J_popup_close">
									<use xlink:href="#jzm-v26"></use>
								</svg>
							</span>
						</div>
						<div id='formComponent'></div>
                        <div v-if="isInquiryPopupForm" style="height: 0.5rem;background-color: #f8f8f8;"> </div>
                        <div v-if="isInquiryPopupForm" class="inquiry_product_list_title">${
                            typeof LS !== 'undefined' && LS.module_product
                        }</div>
                        <div v-if="isInquiryPopupForm" class="inquiry_product_list">
                            <div v-for="(inquiryProduct,index) in inquiryProductList" class="inquiry_product_item">
                                <div class="inquiry_product_img" :style="'background-image: url(' + inquiryProduct.picPath  +')'"></div>
                                <div class="inquiry_product_info">
                                    <span class="inquiry_product_name">{{inquiryProduct.name}}</span>
                                    <span v-show="inquiryProductList.length !== 1" class="inquiry_product_delete" @click="deleteInquiryProduct(index)">${
                                        typeof LS !== 'undefined' && LS.g_del
                                    }</span>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
            </div>
		 `,
    data() {
        const hoverToolConfig = {
            id: `modulePopup`,
            ...formPopupToolbarConfig,
        };
        return {
            formId: FormPopup.formId,
            module: FormPopup.module,
            hoverToolConfig,
        };
    },
    computed: {
        // 是否是全铺样式
        isCoverStyle() {
            return this.module.content.ps === 0;
        },
        // 对应的样式一个包着的class
        popupStyle() {
            let classes = [];
            if (this.isCoverStyle) {
                classes.push('coverStyle');
            } else {
                classes.push('maskStyle');
            }

            if (this.isInquiryPopupForm) {
                classes.push('inquiry_popup_form');
            }
            return classes;
        },
        // 是否显示关闭按钮
        showCloseBtn() {
            return this.module.content.pcb === 0;
        },
        // 访客态不加动画，防止mounted顶部宽度变化时候出现动画
        noAnimationClass() {
            return VITE_APP_MODE !== 'visitor' ? '' : 'noAnimationClass';
        },
        // 表单弹窗的宽度
        popupWidthStyle() {
            if (this.isCoverStyle) return;
            let popupWidthStyle = {};
            let popupWidth = this.module.content.pw;
            if (popupWidth.t === 1) {
                popupWidthStyle.width = popupWidth.w + '%';
            } else {
                popupWidthStyle.width = '90%';
            }
            return popupWidthStyle;
        },
        // 固定顶部的宽度 因为定位的参照盒子不一样，设置同样的宽度会有问题，所以稍微设置小一点
        fixedHeadWidthStyle() {
            let popupWidthStyle = {};
            let popupWidth = this.module.content.pw;
            if (popupWidth.t === 1) {
                popupWidthStyle.width = popupWidth.w - 3 + '%';
            } else {
                popupWidthStyle.width = '88%';
            }
            return popupWidthStyle;
        },
        // 拖拽的option
        resizeOptions() {
            const $body = jm('#g_body');
            let handles = 'w,e';
            // 访客态或者全铺样式不允许拖拽
            let notAllow = !manageMode || (manageMode && this.isCoverStyle);
            let alsoResizeParentSelector = null;
            let alsoResizeChildSelector = null;
            let maxHeight = null;
            let minHeight = null;

            // 初始化宽度是%，要换算成px
            let minWidth = $body[0].offsetWidth * MIN_WIDTH;
            let maxWidth = $body[0].offsetWidth * MAX_WIDTH;

            return {
                notAllow,
                handles,
                alsoResizeParentSelector,
                alsoResizeChildSelector,
                fixPosition: true,
                resize: this.resizeing,
                stop: this.resizeStop,
                maxHeight,
                maxWidth,
                minHeight,
                minWidth,
            };
        },
        isInquiryPopupForm() {
            return FormPopup.fromBtn && window.$store.state.app.openMultiPdInquiry;
        },
        popupName() {
            return this.module.name;
        },
        inquiryProductList() {
            return this.module.extInfo.inquiryProductList || [];
        },
    },
    methods: {
        resizeing(event, ui) {
            let { width } = { ...ui.size };
            let $target = jm(event.target);
            let $fixedHead = jm($target).find('.fixedHead');

            $target.css({
                height: 'auto',
            });

            // 拖拽的时候要减去拖拽的两边1px的虚线
            $fixedHead.css({
                width: width - 2 + 'px',
            });
        },
        resizeStop(event, ui) {
            const $body = jm('#g_body');
            let $target = jm(event.target);
            let { width } = { ...ui.size };
            let wrapWidth = $body[0].offsetWidth;
            let pw = Math.round((width / wrapWidth) * 100);

            // 做保护 防止除完之后的数值不在范围内
            if (pw < MIN_WIDTH * 100) {
                pw = MIN_WIDTH * 100;
            }
            if (pw > MAX_WIDTH * 100) {
                pw = MAX_WIDTH * 100;
            }

            // 如果图片宽度之前是默认，拖拽之后要变成自定义
            this.module.content.pw.t = 1;
            this.module.content.pw.w = pw;

            $target.css({
                height: 'auto',
                width: width + 'px',
            });

            this.resize = 1;
            logDog(201228, 12);
            Fai.top._mobiFormChange = true;
            this.$designer.styleChanged();
        },
        panelClick() {
            // 如果是全铺样式 对应的是打开编辑面板
            if (this.isCoverStyle && manageMode) {
                this.$designer.open({
                    panelType: 'mobiFormPopup',
                    styleId: 84,
                    moduleId: 84,
                });
                //openDesigner('mobiFormPopup', '84', 84, '', '', true);
            } else if (!this.isCoverStyle) {
                // resize 是0代表正常的销毁事件
                if (this.resize != 1) {
                    FormPopup.destroy();
                } else if (this.resize == 1) {
                    this.resize = 0;
                }
            }
        },
        closeHandle() {
            FormPopup.destroy();
        },
        stopPropagation(e) {
            let $target = jm(e.target);
            // J_popup_close为关闭按钮，不需要打开编辑面板
            if (!$target.hasClass('J_popup_close') && !$target.parents('.mask_close').hasClass('J_popup_close')) {
                // resize 是1代表刚刚拖拽完 会触发到
                if (this.resize != 1 && manageMode) {
                    //openDesigner('mobiFormPopup', '84', 84, '', '', true);
                    this.$designer.open({
                        panelType: 'mobiFormPopup',
                        styleId: 84,
                        moduleId: 84,
                    });
                } else if (this.resize == 1) {
                    this.resize = 0;
                }
            }
            e.stopPropagation();
        },
        deleteInquiryProduct(index) {
            this.inquiryProductList.splice(index, 1);
            localStorage.setItem(
                'inquiry_product_list',
                JSON.stringify(this.inquiryProductList.map((inquiryProduct) => inquiryProduct.id))
            );
        },
    },
    mounted() {
        FormPopup.rending = true;
        let modulePopupWrapWidth = this.$refs.modulePopupWrap.offsetWidth;
        jm('.fixedHead').css({
            transition: 'none',
            width: `${modulePopupWrapWidth}px`,
        });
        // 宽度重新赋值之后恢复动画效果
        setTimeout(function () {
            jm('.fixedHead').css({
                transition: 'width 0.5s, height 0.5s',
            });
        }, 0);
    },
    watch: {
        'module.content.ps': {
            // 从全铺切换到遮罩样式时候，重新计算固定顶部的宽度
            handler() {
                this.$nextTick(function () {
                    let modulePopupWrapWidth = this.$refs.modulePopupWrap.offsetWidth;
                    let $fixedHead = jm('.fixedHead');
                    // 宽度重新计算无动画效果
                    $fixedHead.css({
                        transition: 'none',
                        width: `${modulePopupWrapWidth}px`,
                    });
                    // 宽度重新赋值之后恢复动画效果
                    setTimeout(function () {
                        $fixedHead.css({
                            transition: 'width 0.5s, height 0.5s',
                        });
                    }, 0);
                });
            },
            deep: true,
        },
    },
};
FormPopup.render = async function (formId, module, renderOptions, fromBtn) {
    let moduleClone = module;
    FormPopup.scrollTop = scrollTop();
    jm(this.component.template).appendTo('#g_body');
    moduleClone.renderOptions = renderOptions;
    this.formId = formId;
    this.module = moduleClone;
    FormPopup.fromBtn = fromBtn;

    const inquiryProductList = JSON.parse(localStorage.getItem('inquiry_product_list') || '[]');
    Vue.set(moduleClone.extInfo, 'inquiryProductList', []);
    if (window.$store.state.app.openMultiPdInquiry && fromBtn) {
        jzRequest
            .get('/ajax/product_h.jsp', {
                params: {
                    cmd: 'getWafNotCk_getMulitProductInquiryPdList',
                    idList: JSON.stringify(inquiryProductList),
                },
            })
            .then(({ data: { pdList } }) => {
                Vue.set(moduleClone.extInfo, 'inquiryProductList', pdList);
            });
    }
    this.moduleWrap = new Vue(this.component);

    // 实例化一个新模块
    this.vueModule = new Vue({
        el: '#formComponent',
        store: window.$store,
        provide: {
            formId,
            moduleInfo: moduleClone,
            renderOptions,
        },
        render(h) {
            return h(mobiFormComponent, {
                props: {
                    moduleId: module.id,
                },
            });
        },
    });
    FormPopup.module = moduleClone;
    FormPopup.formId = formId;
    if (manageMode) {
        Fai.top.mobiFormPopupIng = true;
        window.FormPopup = FormPopup;
    }
};

FormPopup.destroy = function () {
    FormPopup.rending = false;
    let moduleWrap = this.moduleWrap;
    let vueModule = this.vueModule;

    scrollTop(FormPopup.scrollTop);
    // 销毁父实例
    if (moduleWrap) {
        jm(moduleWrap.$el).remove();
        moduleWrap && moduleWrap.$destroy();
        moduleWrap = null;
    }
    // 销毁子实例
    if (vueModule) {
        jm(vueModule.$el).remove();
        vueModule && vueModule.$destroy();
        vueModule = null;
    }

    if (manageMode) {
        Vue.prototype.$designer.close();
        FormPanel.destroy();
        Fai.top.mobiFormPopupIng = false;
    }
};

export default FormPopup;
