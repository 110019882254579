/**
 * @渲染访客态的广告
 * @author json
 * @date 07/06/2021
 */
import { getWebsiteAdInfo } from '@/api/advertisement';
import purchaseVer from './purchaseVerForFree.vue';

const manageMode = VITE_APP_MODE !== 'visitor';

let adList = {
    openFreeVerAd: false,
    openFreeVerAdMall: false,
};

async function getWebsiteAdData() {
    let result = await getWebsiteAdInfo(),
        { data, success, msg } = result;

    if (success) {
        for (let key in data) {
            adList[key] = data[key];
        }
    } else {
        console.log(msg);
    }
}

async function canInitAd() {
    await getWebsiteAdData();
    return adList.openFreeVerAd || adList.openFreeVerAdMall;
}

export const initGuideAd = async function () {
    if (manageMode) {
        return;
    }
    let canInit = await canInitAd();
    if (!canInit) {
        return;
    }
    $("<div id='jz_guide_ad' class='jz_guide_ad'></div>").appendTo($('#g_web'));
    new Vue({
        el: '#jz_guide_ad',
        components: {
            purchaseVer,
        },
        provide() {
            return {
                websiteInfo: this.adList,
            };
        },
        data() {
            return {
                adList,
            };
        },
        template: `
            <div class="jz_advertisement_guide">
                <purchase-ver></purchase-ver>
            </div>
        `,
    });
};
