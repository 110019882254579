import { getUrlParam } from '@/shared/url';
import { onlineServiceInputWord } from '@/system/onlineService';

//请求监听器
var ajaxHelper = {};
ajaxHelper.domEventList = {};
ajaxHelper.ajaxModuleList = [];
ajaxHelper.ham = false;
ajaxHelper.listen = function (key, fn) {
    if (!this.domEventList[key]) {
        // 如果还没有订阅过此类消息，给该类消息创建一个缓存列表
        this.domEventList[key] = [];
    }
    this.domEventList[key].push(fn); // 订阅的消息添加进消息缓存列表
};
ajaxHelper.trigger = function () {
    var key = Array.prototype.shift.call(arguments), // 取出消息类型
        fns = this.domEventList[key]; // 取出该消息对应的回调函数集合
    if (!fns || fns.length === 0) {
        // 如果没有订阅该消息，则返回
        return false;
    }
    for (var i = 0, fn; (fn = fns[i++]); ) {
        fn.apply(this, arguments); // arguments 是发布消息时附送的参数
    }
};

function loadWholeModuleDom(moduleId, dom, scripts) {
    var module = jm('#module' + moduleId);

    module.children().remove();
    module.append(dom);
    jm.fkEval.call(window, scripts);

    if (Fai.top._templateFrameId == '1005' || Fai.top._templateFrameId == 1019) {
        //炫彩主题，1005这个模板很特别，"更多"标签是在div内容之后的，切换其他主题后，如果没有大保存，修改模块的的数据
        changeMorePosition(1); //出来的数据模板，还是1005主题模板的数据，造成"更多"标签错位，这里做条件处理
    } else {
        changeMorePosition(0);
    }
}
export function ajaxLoadDom(colId, extId, ajaxOptionInfo) {
    // topBar 可以废弃
    // ajaxHelper.listen('topBar', function (topBar) {
    //     var topBarNode = jm('.multilingual');
    //     topBarNode.children().remove();
    //     topBarNode.append(topBar.dom);
    //     jm.fkEval.call(window, topBar.scripts);
    //     LocaterDomService.initLocater();
    //     if (VITE_APP_MODE !== 'visitor') {
    //         LocaterDomService.bindManageEventListeners();
    //     }
    // });
    // footer 可以废弃
    // ajaxHelper.listen('footer', function (footer) {
    //     var webFooter = jm('#webFooter');
    //     webFooter.html(footer.dom);
    //     jm.fkEval(footer.scripts);
    // });

    // FIXME: getIntegralDom?
    ajaxHelper.listen('ajaxLoadDomScript', function (ajaxScript) {
        jm.fkEval.call(window, ajaxScript);
    });
    //  FIXME: loadModuleDom anchor?
    ajaxHelper.listen('renderAjaxLoadModule', function () {
        if (!ajaxHelper.ham || !ajaxHelper.ajaxModuleList.length) {
            return;
        }
        var moduleDomList = ajaxHelper.ajaxModuleList;
        for (var i = 0; i < moduleDomList.length; i++) {
            var moduleDomInfo = moduleDomList[i];
            var moduleId = moduleDomInfo.moduleId;
            var dom = moduleDomInfo.dom;
            var scripts = moduleDomInfo.scripts;

            loadWholeModuleDom(moduleId, dom, scripts);
        }
        // 点击Input时隐藏底部服务
        onlineServiceInputWord();
    });

    // 异步获取分页 可以废弃
    // ajaxHelper.listen('ajaxLoadPaginationDomList', function () {
    //     if (!ajaxHelper.ajaxLoadPaginationDomList.length) {
    //         return;
    //     }
    //     var pageDomList = ajaxHelper.ajaxLoadPaginationDomList;
    //     for (var i = 0; i < pageDomList.length; i++) {
    //         var moduleInfo = pageDomList[i];
    //         var moduleId = moduleInfo.moduleId;
    //         var dom = moduleInfo.dom;
    //         if (!dom) {
    //             continue;
    //         }
    //         var paginationDiv = jm('#paginationDiv' + moduleId);
    //         paginationDiv.children().remove();
    //         paginationDiv.append(dom);
    //     }
    // });

    var href = window.location.href,
        _fromJzmHome = '',
        lcid = '';

    if (href.indexOf('lcid') > 0) {
        var lcidInt = getUrlParam(href, 'lcid');
        if (lcidInt) {
            lcid += '?lcid=' + lcidInt;
        }
    }

    if (href.indexOf('_fromJzmHome') > 0) {
        var fromJzmHome = getUrlParam(href, '_fromJzmHome');
        if (fromJzmHome) {
            _fromJzmHome += '&_fromJzmHome=true';
        }
    }

    jm.ajax({
        type: 'post',
        url: '/ajax/ajaxLoadModuleDom_h.jsp' + lcid,
        data:
            'cmd=getWafNotCk_loadModuleDom&colId=' +
            colId +
            '&extId=' +
            extId +
            '&ajaxOptionInfo=' +
            jm.encodeUrl(jm.decodeUrl(jm.toJSON(ajaxOptionInfo))) +
            _fromJzmHome +
            '&anchor=' +
            jm.encodeUrl(Fai.top.location.hash),
        success(result, textStatus, request) {
            var waf = request.getResponseHeader('wafUpdate');
            if (waf && waf == 'true') {
                window.location.reload();
            }
            try {
                result = jm.parseJSON(result);
            } catch (e) {
                result = { success: false };
            }
            if (result.success) {
                var rtInfo = jm.parseJSON(result.rtInfo);
                if (rtInfo.topBar) {
                    ajaxHelper.trigger('topBar', rtInfo.topBar);
                }
                if (rtInfo.footer) {
                    ajaxHelper.trigger('footer', rtInfo.footer);
                }
                if (result.scripts) {
                    ajaxHelper.trigger('ajaxLoadDomScript', result.scripts);
                }
                if (rtInfo.moduleDomList && rtInfo.moduleDomList.length > 0) {
                    ajaxHelper.ajaxModuleList = rtInfo.moduleDomList;
                    ajaxHelper.trigger('renderAjaxLoadModule');
                }

                // 异步获取分页
                if (rtInfo.ajaxLoadPaginationDomList && rtInfo.ajaxLoadPaginationDomList.length > 0) {
                    ajaxHelper.ajaxLoadPaginationDomList = rtInfo.ajaxLoadPaginationDomList;
                    ajaxHelper.trigger('ajaxLoadPaginationDomList');
                }
            }
        },
    });
}

//处理模块更多这个按钮显示在不同位置   1005
export function changeMorePosition(_moduleStyle) {
    if (Fai.top._openThemeV3) {
        return;
    }
    var allForm = document.querySelectorAll('#webModuleContainer>.form'),
        formsLength = allForm.length;
    if (_moduleStyle === 1) {
        for (var index = 0; index < formsLength; index++) {
            let formInfo = allForm[index],
                formId = formInfo.id || '',
                more = formInfo.querySelector('.formBannerMore' + formId.replace('module', ''));
            const IS_SSRMODULE = Fai.top.isModuleV2Map[`style_${formInfo.getAttribute('_modulestyle')}`];
            if (!more || IS_SSRMODULE) continue;
            formInfo.appendChild(more);
        }
    } else {
        for (let index = 0; index < formsLength; index++) {
            let formInfo = allForm[index],
                formBannerTitleCenter = formInfo.querySelector('.formBannerTitle>.titleCenter'),
                formId = formInfo.id || '',
                more = formInfo.querySelector('.formBannerMore' + formId.replace('module', ''));
            const IS_SSRMODULE = Fai.top.isModuleV2Map[`style_${formInfo.getAttribute('_modulestyle')}`];
            if (IS_SSRMODULE) continue;

            !!formBannerTitleCenter && !!more && formBannerTitleCenter.appendChild(more);
        }
    }
}
