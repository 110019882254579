var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _c("div", { attrs: { id: "signupPage" } }, [
      _c("div", { staticClass: "loginHeader webHeaderBg profileLoginHeader" }, [
        _c("a", {
          staticClass: "g_close icon-gClose",
          on: { click: _vm.closeClick },
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "title pageTitle",
          domProps: { textContent: _vm._s(_vm.LS.col_member_signup) },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "signUpContent" }, [
        _vm.manageMode
          ? _c("div", { staticClass: "notice" }, [
              _vm._v("您目前处于网站管理状态，请先退出再注册"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "profileArea", attrs: { id: "profileArea" } },
          [
            _vm.signUpbyMobile
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "register_line",
                      class: _vm.commonClassList,
                    },
                    [
                      _c("input", {
                        staticClass:
                          "addCtTel mustItem itemEdit userAddItem isCheckUAI g_input mobile_input",
                        attrs: {
                          id: "mobile",
                          type: "text",
                          maxlength: "50",
                          name: _vm.mobileItem.name,
                          placeholder:
                            _vm.mobileItem.propPlaceholder +
                            _vm.LS.mobi_member_Must,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mobileCtIcon otherSettingIcon selectAnimationRemove",
                        },
                        [_c("div", { staticClass: "icon-downward" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "g_input selectCover selectCoverRight" },
                        [_vm._v("+86")]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          staticClass: "itemEdit userAddItem g_select",
                          attrs: { id: _vm.MOBILE_CT, name: _vm.MOBILE_CT },
                          on: {
                            click: _vm.mobileCtClick,
                            blur: _vm.mobileCtBlur,
                            change: _vm.mobileCtChange,
                          },
                        },
                        _vm._l(_vm.mobileCtLst, function (ct) {
                          return _c("option", {
                            key: ct.lskey,
                            domProps: {
                              value: ct.ctName,
                              textContent: _vm._s(ct.ctShowName + ct.ctCode),
                            },
                          })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("span", { class: _vm.mobileItem.itemIcon }),
                      _vm._v(" "),
                      _c("span", { class: _vm.mobileItem.customPropName }, [
                        _vm._v("手机"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "g_globalLine" }, [
                    _c("span", {
                      staticClass: "phone_tip",
                      domProps: {
                        textContent: _vm._s(_vm.LS.memberSignup_captchaV3),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "captchaText g_input",
                      attrs: {
                        id: "messageAuthCode",
                        type: "text",
                        maxlength: "6",
                        placeholder: _vm.messageAuthCodePlaceholder,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "fk-sign-getMobileCode fk-sign-getMobileCodeFix",
                      attrs: {
                        id: "messageAuthCodeClick",
                        title: _vm.messageAuthCodeTitle,
                      },
                      domProps: {
                        textContent: _vm._s(_vm.LS.mobi_getMobileCode),
                      },
                      on: { click: _vm.signMobileCodeClick },
                    }),
                    _vm._v(" "),
                    _c("div", { staticStyle: { clear: "both" } }),
                  ]),
                ]
              : _vm.signUpbyMail
              ? [
                  _c("div", { class: _vm.commonClassList }, [
                    _c("input", {
                      staticClass:
                        "mustItem itemEdit userAddItem isCheckUAI g_input",
                      attrs: {
                        id: "email",
                        type: "text",
                        maxlength: "50",
                        name: _vm.mailItem.name,
                        placeholder:
                          _vm.mailItem.propPlaceholder +
                          _vm.LS.mobi_member_Must,
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-emailIcon g_mainColor" }),
                  ]),
                ]
              : [
                  _c("div", { class: _vm.commonClassList }, [
                    _c("input", {
                      staticClass: "mustItem itemEdit userAddItem g_input",
                      attrs: {
                        id: "memberSignupAcct",
                        type: "text",
                        placeholder: _vm.memberSignupAcctPlaceholder,
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-userIcon g_mainColor" }),
                    _c("span", { staticClass: "mustIcon" }, [_vm._v("*")]),
                  ]),
                ],
            _vm._v(" "),
            _c("div", { class: _vm.commonClassList }, [
              _c("input", {
                staticClass:
                  "mustItem itemEdit icon-pswIcon userAddItem g_input",
                attrs: {
                  id: "memberSignupPwd",
                  type: "password",
                  autocomplete: "off",
                  placeholder: _vm.memberSignUpPwdPlaceholder,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
              _c("span", { staticClass: "mustIcon" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.commonClassList }, [
              _c("input", {
                staticClass:
                  "mustItem itemEdit icon-pswIcon userAddItem g_input",
                attrs: {
                  id: "memberSignupRepwd",
                  type: "password",
                  autocomplete: "off",
                  placeholder: _vm.memberSingupRepwdPlaceholder,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-pswIcon g_mainColor" }),
              _c("span", { staticClass: "mustIcon" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.filterList, function (item) {
              return [
                item.fieldKey == _vm.MOBILE
                  ? [
                      _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "register_line",
                          class: _vm.commonClassList,
                        },
                        [
                          _c("input", {
                            staticClass:
                              "addCtTel itemEdit userAddItem g_input mobile_input",
                            class: {
                              mustItem: item.must == "isCheck",
                              isCheckUAI: item.must == "isCheck",
                            },
                            attrs: {
                              id: item.fieldKey,
                              name: item.name,
                              placeholder: _vm.addMustTip(item),
                              type: "text",
                              maxlength: "50",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mobileCtIcon otherSettingIcon selectAnimationRemove",
                            },
                            [_c("div", { staticClass: "icon-downward" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "g_input selectCover selectCoverRight",
                            },
                            [_vm._v("+86")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              staticClass: "itemEdit userAddItem g_select",
                              attrs: { id: _vm.MOBILE_CT, name: _vm.MOBILE_CT },
                              on: {
                                click: _vm.mobileCtClick,
                                blur: _vm.mobileCtBlur,
                                change: _vm.mobileCtChange,
                              },
                            },
                            _vm._l(_vm.mobileCtLst, function (ct) {
                              return _c("option", {
                                key: ct.lskey,
                                domProps: {
                                  value: ct.ctName,
                                  textContent: _vm._s(
                                    ct.ctShowName + ct.ctCode
                                  ),
                                },
                              })
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("span", { class: item.itemIcon }),
                          _vm._v(" "),
                          _c("span", { class: item.customPropName }, [
                            _vm._v("手机"),
                          ]),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          key: item.id,
                          class: [_vm.commonClassList, _vm.fileClass(item)],
                        },
                        [
                          item.pt == 0
                            ? [
                                _c("input", {
                                  staticClass: "itemEdit userAddItem g_input",
                                  class: {
                                    mustItem: item.must == "isCheck",
                                    isCheckUAI: item.must == "isCheck",
                                  },
                                  attrs: {
                                    id: item.fieldKey,
                                    name: item.name,
                                    placeholder: _vm.addMustTip(item),
                                    type: "text",
                                    maxlength: "50",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { class: item.itemIcon }),
                              ]
                            : item.pt == 1
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mobileCtIcon otherSettingIcon selectAnimationRemove",
                                  },
                                  [_c("div", { staticClass: "icon-downward" })]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "g_input selectCover",
                                }),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    staticClass:
                                      "itemEdit userAddItem g_select",
                                    class: {
                                      isCheckUAI: item.must == "isCheck",
                                    },
                                    attrs: {
                                      id: item.fieldKey,
                                      name: item.name,
                                    },
                                    on: {
                                      click: _vm.itemEditClick,
                                      blur: _vm.itemEditBlur,
                                    },
                                  },
                                  [
                                    _c("option", {
                                      attrs: { value: "" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.addMustTip(item)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._l(item.propOptions, function (prop) {
                                      return _c("option", {
                                        key: prop,
                                        domProps: {
                                          value: prop,
                                          textContent: _vm._s(prop),
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("span", { class: item.itemIcon }),
                              ]
                            : item.pt == 2
                            ? [
                                _c("div", { staticClass: "member_file_tips" }, [
                                  _vm._v(_vm._s(_vm.getFileTips(item))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "member_file_contentwrap",
                                    attrs: {
                                      id: "member_file_contentwrap-" + item.id,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass:
                                        "itemEdit userAddItem member_file_input",
                                      class: {
                                        isCheckUAI: item.must == "isCheck",
                                      },
                                      attrs: {
                                        id: item.fieldKey,
                                        name: item.name,
                                        "data-name": item.id,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "member_file_btn" },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "member_file_btnupload uploadify-button",
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.addFileText
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "member_file_content" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "member_file_meta" },
                                          [
                                            _c("div", {
                                              staticClass: "member_file_name",
                                            }),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "member_file_size",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "member_file_icon" },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "member_file_close",
                                              },
                                              [
                                                _c("use", {
                                                  attrs: {
                                                    "xlink:href": "#jzm-v25",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                ref: "nofllow",
                                                refInFor: true,
                                                staticClass:
                                                  "member_file_preview_link",
                                                attrs: {
                                                  href: "javascript:void(0)",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "member_file_preview",
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#jzm-vicon_preview",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                ref: "nofllow",
                                                refInFor: true,
                                                staticClass:
                                                  "member_file_download_link",
                                                attrs: {
                                                  href: "javascript:void(0)",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "member_file_download",
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#jzm-v16",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          item.customPropName != ""
                            ? _c("span", {
                                class: item.customPropName,
                                domProps: { textContent: _vm._s(item.name) },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
              ]
            }),
            _vm._v(" "),
            _vm.noRemark
              ? _c("div", { class: _vm.commonClassList }, [
                  _c("input", {
                    staticClass: "itemEdit g_input userAddItem",
                    attrs: {
                      id: "memberSignupRemark",
                      type: "text",
                      placeholder: _vm.memberSignupRemarkPlaceholder,
                      maxlength: _vm.memberSignupRemarkMaxLength,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon-msgIcon g_mainColor",
                    domProps: {
                      textContent: _vm._s(_vm.LS.mobi_memberSignup_remark),
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.protocolOpen
              ? _c("div", { staticClass: "ProtocolLineCtrl g_globalLine" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.protocolChecked,
                        expression: "protocolChecked",
                      },
                    ],
                    staticClass: "memberAgreeProCheck",
                    staticStyle: { position: "relative" },
                    attrs: { id: "memberAgreePro", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.protocolChecked)
                        ? _vm._i(_vm.protocolChecked, null) > -1
                        : _vm.protocolChecked,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.protocolChecked,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.protocolChecked = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.protocolChecked = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.protocolChecked = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    class: {
                      "icon-checkIcon": _vm.protocolChecked,
                      g_main_color_v3: _vm.protocolChecked,
                      "icon-uncheckIcon": !_vm.protocolChecked,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.checkLabel.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "memberAgreeProLabel",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.checkLabel.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.checkLabel.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.LS.mobi_member_Protocol_Msg))]
                      ),
                      _vm._v(" "),
                      _c("a", {
                        staticClass: "memberProtocol g_main_color_v3",
                        attrs: { id: "signUpMProtocol", hidefocus: "true" },
                        domProps: {
                          textContent: _vm._s(_vm.signUpMProtocolName),
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "signupSubmitCtrl g_globalLine" }, [
              _c("input", {
                staticClass:
                  "g_button sendIcon signupSubmit submitIcon g_main_bgColor_v3 fpw_btn",
                attrs: {
                  type: "button",
                  disabled: "",
                  value: _vm.LS.memberRegV3,
                },
                on: { click: _vm.memberSignupSubmitData },
              }),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "checkEmail", attrs: { id: "checkEmail" } }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "emailAddr",
            staticStyle: { display: "none" },
            attrs: { id: "emailAddr", target: "_blank" },
          },
          [_vm._v("地址")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "J_cusBack", attrs: { id: "mProPage" } }, [
      _c(
        "div",
        {
          staticClass:
            "loginHeader webHeaderBg profileLoginHeader J_registerHeader",
        },
        [
          _c("a", {
            staticClass: "g_close icon-gClose",
            on: { click: _vm.mProPageClick },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "title pageTitle" }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mProContent" }, [
        _c("div", {
          staticClass: "set-line",
          staticStyle: { "overflow-x": "auto" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }