import { logDog as logDogApi } from '@/api/logDog';
import { addRequestPrefix } from '@/shared/util';
import { request } from '@/api/request';
import { encodeUrl } from '@/shared/util';
export function registerLogDogApi(Vue) {
    Vue.prototype.$logDog = logDog;
}
/**
 * BSS埋点API
 * @param {number} dogId
 * @param {number} srcId
 */
export function logDog(dogId, srcId) {
    return logDogApi(dogId, srcId);
}

/*
    explain: 保存成功后再dog
    Fai.top._dogList: Object
    type: String | require | 一种类型只对应一个行为,同一种类型的最新行为会覆盖前一次行为
*/
export function logDogAfterSave(type, src, dogId) {
    // type, src, dogId
    if (Fai.top._dogList == null) {
        Fai.top._dogList = {};
    }
    Fai.top._dogList[type + '_' + src] = { src, dogId };
}

export const logBssFodder = function (logBssFodder) {
    $.ajax({
        type: 'POST',
        url: '/ajax/log_h.jsp',
        data: 'cmd=wafNotCk_logBssFodder&logBssFodder=' + encodeUrl(JSON.stringify(logBssFodder)),
    });
};

export const logMallFdp = (() => {
    // 小小做个防抖处理 把同步调用的埋点集中到一个请求中发出
    let trackList = [];
    let logTimer = null;
    return function (event, properties, withVer) {
        if (!Fai.top._isMall || !event) return;
        trackList.push({
            event,
            properties: JSON.stringify(properties),
            withVer: withVer || false,
        });
        if (logTimer) return;
        logTimer = setTimeout(function () {
            request.post('/ajax/log_h.jsp?cmd=wafNotCk_logMallFdpTrack', {
                data: {
                    trackList: JSON.stringify(trackList),
                },
            });

            logTimer = null;
            trackList = [];
        }, 0);
    };
})();

/**
 * logErr
 * function: logErr
 *  2018,5, 30
 *  isRefresh  打印错误日志
 **/
export const logErr = function (str) {
    if (!str) return;

    $.ajax({
        type: 'GET',
        url: '/ajax/log_h.jsp?cmd=wafNotCk_err&err=' + encodeUrl(str),
    });
};

export function logMonitor(monitorId, isLog) {
    jm.ajax({
        type: 'POST',
        url: addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/log_h.jsp',
        data: 'cmd=wafNotCk_monitor&id=' + monitorId + (isLog ? '&isLog=' + isLog : ''),
    });
}
