export const getModuleAttr = (moduleId) => {
    let moduleAttr = Fai.top.$store.state.manage.moduleAttr || {};
    let attr = moduleAttr[`module${moduleId}`];
    let $module = $('#module' + moduleId);

    if (typeof attr == 'undefined') {
        attr = {};
    }

    if (typeof attr.pattern == 'undefined') {
        attr.pattern = {};
    }
    if (typeof attr.data == 'undefined') {
        attr.data = {};
    }

    // init data
    var data = attr.data;
    if (typeof data.changed == 'undefined') {
        data.changed = false;
    }

    // init pattern
    var pattern = attr.pattern;
    if (typeof pattern.changed == 'undefined') {
        pattern.changed = false;
    }

    if (typeof pattern.animation == 'undefined') {
        pattern.animation = { t: 0 };
    }

    if (typeof pattern.border == 'undefined') {
        if ($module.hasClass('formStyle31')) {
            //所有魔方导航的边框初始化为隐藏
            pattern.border = { y: 1 };
        } else {
            pattern.border = { y: 0 };
        }
    }
    if (typeof pattern.bannerType == 'undefined') {
        pattern.bannerType = 0;
    }
    if (typeof pattern.bannerAutoHeight == 'undefined') {
        pattern.bannerAutoHeight = true;
    }
    if (typeof pattern.bannerText == 'undefined') {
        pattern.bannerText = { y: 0 };
    }
    if (typeof pattern.bannerMore == 'undefined') {
        pattern.bannerMore = { y: 0 };
    }
    if (typeof pattern.bannerBg == 'undefined') {
        pattern.bannerBg = { y: 0 };
    }
    if (typeof pattern.outside == 'undefined') {
        pattern.outside = { y: 0 };
    }
    if (typeof pattern.inner == 'undefined') {
        pattern.inner = { y: 0 };
    }
    if (typeof pattern.contentText == 'undefined') {
        pattern.contentText = { y: 0 };
    }
    if (typeof pattern.contentLink == 'undefined') {
        pattern.contentLink = { y: 0 };
        if (pattern.cl != null) {
            pattern.contentLink.ftc = pattern.cl.ftc || 0; // 添加按钮是否跟随主题色
        }
    }
    if (typeof pattern.contentBg == 'undefined') {
        if ($module.hasClass('formStyle31')) {
            //所有魔方导航的内容背景初始化为隐藏
            pattern.contentBg = { y: 1 };
        } else {
            pattern.contentBg = { y: 0 };
        }
    }

    // 添加 pattern.contentBg.f pattern.contentBg.p 不为 null，避免初次切换图片背景不生效问题
    pattern.contentBg.f = pattern.contentBg.f || '';
    pattern.contentBg.p = pattern.contentBg.p || '';
    pattern.contentBg.c = pattern.contentBg.c || '#000';

    if (typeof pattern.transparent == 'undefined') {
        pattern.transparent = 100;
    }
    if (typeof pattern.contentRowHeight == 'undefined') {
        pattern.contentRowHeight = { y: 0 };
    }
    if (typeof pattern.inputTxtBg == 'undefined') {
        pattern.inputTxtBg = { y: 0 };
    }
    if (typeof pattern.inputTxtBorder == 'undefined') {
        pattern.inputTxtBorder = { y: 0 };
    }
    if (typeof pattern.inputTxtTip == 'undefined') {
        pattern.inputTxtTip = { y: 0 };
    }

    return attr;
};

export const getModuleAttrPattern = (moduleId) => {
    let attr = getModuleAttr(moduleId) || {};
    return attr.pattern || {};
};

// 获取用于存储的样式数据 -- 参考 moduleManage.js
export const getModuleAttrPatternStg = (moduleId) => {
    var module = $('#module' + moduleId);
    var style = module.attr('_modulestyle');
    var data = {};
    var pattern = getModuleAttrPattern(moduleId);

    var height = 0;
    if (module.length > 0) {
        height = parseInt(module.attr('_height'));
        if (!Fai.top._openThemeV3 && style == 51) {
            if (height > 0) {
                data.h = height;
            }
        } else if (module.attr('_autoHeight') == 0 && height > 0) {
            data.h = height;
        }
    }

    var width = 0;
    if (module.length > 0) {
        width = parseInt(module.attr('_width') || 0);
        if (module.attr('_autoWidth') == 0 && width > 0) {
            data.w = width;
        }
    }

    var transparent = pattern.transparent;
    data.tran = parseInt(transparent);

    // animition
    data.a = {};
    data.a.t = 0;

    var animation = pattern.animation;
    if (animation.t == 0) {
        data.a.t = 0;
    } else {
        data.a.t = parseInt(animation.t);

        typeof animation.r != 'undefined' && (data.a.r = parseInt(animation.r));
        typeof animation.s != 'undefined' && (data.a.s = parseFloat(animation.s));
        typeof animation.d != 'undefined' && (data.a.d = parseFloat(animation.d));
        typeof animation.tg != 'undefined' && (data.a.tg = parseInt(animation.tg));
    }

    // banner
    if (pattern.bannerType == 1) {
        data.e = 1;
    } else {
        data.e = 0;
    }
    if (pattern.bannerAutoHeight == false) {
        data.bh = parseInt(module.attr('_bHeight'));
    }

    // border
    data.d = {};
    data.d.y = 0; //边框应该加个默认选项。
    var border = pattern.border;
    if (border.y == 1) {
        data.d.y = 1;
    } else if (border.y == 2) {
        data.d.y = 2;
        data.d.c = border.c;
        data.d.w = parseInt(border.w) || 1;
        data.d.s = parseInt(border.s) || 0;
    }

    //pattern version
    data.v = 0;

    // pattern 抽奖
    const isLotteryModule = ['13'];

    if (isLotteryModule.indexOf(style) != -1) {
        // pattern 抽奖
        let moduleInfo = window.$store.state.currentPageModuleIdMap[moduleId] || {};
        var lotteryPattern = moduleInfo.pattern,
            lts = lotteryPattern.lts,
            ts = lotteryPattern.ts;
        data.lts = lts;
        data.ts = ts;
    }

    // banner text
    var bannerText = pattern.bannerText;
    if (bannerText.y == 1) {
        data.bt = {};
        data.bt.y = 1;
    } else if (bannerText.y == 2) {
        data.bt = {};
        data.bt.y = 2;
        data.bt.f = bannerText.f;
        data.bt.s = parseInt(bannerText.s);
        data.bt.w = parseInt(bannerText.w);
        data.bt.c = bannerText.c;
        data.bt.fct = parseInt(bannerText.fct);
        data.bt.a = parseInt(bannerText.a) || 0;
        if (bannerText.py == 1) {
            data.bt.py = 1;
            data.bt.r = parseInt(bannerText.r);
            data.bt.u = parseInt(bannerText.u);
            data.bt.p = bannerText.p;
        }
    }

    // banner more
    var bannerMore = pattern.bannerMore;
    if (bannerMore.y == 1) {
        data.bm = {};
        data.bm.y = 1;
        data.bm.f = bannerMore.f;
        data.bm.s = parseInt(bannerMore.s);
        data.bm.w = parseInt(bannerMore.w);
        data.bm.d = parseInt(bannerMore.d);
        data.bm.c = bannerMore.c;
        data.bm.fct = parseInt(bannerMore.fct);
        if (bannerMore.py == 1) {
            data.bm.py = 1;
            data.bm.r = parseInt(bannerMore.r);
            data.bm.u = parseInt(bannerMore.u);
        }
    }

    // banner bg
    var bannerBg = pattern.bannerBg;
    data.bg = {};
    data.bg.y = 0;
    if (bannerBg.f) {
        data.bg.f = bannerBg.f; // 避免多次上传，所以这里会保持提交文件id
    }
    if (bannerBg.y == 1) {
        data.bg.y = 1;
    } else if (bannerBg.y == 2) {
        data.bg.y = 2;
        data.bg.c = bannerBg.c;
        data.bg.r = parseInt(bannerBg.r) || 0;
    }

    //outside
    var outside = pattern.outside;
    if (outside.y == 1) {
        data.o = {};
        data.o.y = 1;
        data.o.l = parseInt(outside.l);
        data.o.r = parseInt(outside.r);
        data.o.t = parseInt(outside.t);
        data.o.b = parseInt(outside.b);
    }

    var inner = pattern.inner;
    if (inner.y == 1) {
        data.i = {};
        data.i.y = 1;
        data.i.l = parseInt(inner.l);
        data.i.r = parseInt(inner.r);
        data.i.t = parseInt(inner.t);
        data.i.b = parseInt(inner.b);
    }

    var contentText = pattern.contentText;
    if (contentText.y == 1) {
        data.ct = {};
        data.ct.y = 1;
        data.ct.f = contentText.f;
        data.ct.c = contentText.c;
        data.ct.fct = parseInt(contentText.fct);
        data.ct.cs = parseInt(contentText.cs);
    }

    var contentLink = pattern.contentLink;
    if (contentLink.y == 1) {
        data.cl = {};
        data.cl.y = 1;
        data.cl.f = contentLink.f;
        data.cl.d = parseInt(contentLink.d) || 0;
        data.cl.c = contentLink.c;
        data.cl.fct = parseInt(contentLink.fct) || 0;
        data.cl.ls = parseInt(contentLink.ls) || 12;
        data.cl.b = parseInt(contentLink.b) || 0;
    }

    if (style == 54 && contentLink.y == 1) {
        // 按钮模块，保存contentLink.ftc
        data.cl.ftc = parseInt(contentLink.ftc) || 0;
    }
    var contentBg = pattern.contentBg;
    data.cb = {};
    data.cb.y = 0;
    if (contentBg.f) {
        data.cb.f = contentBg.f; // 避免多次上传，所以这里会保持提交文件id
    }
    if (contentBg.y == 1) {
        data.cb.y = 1;
    } else if (contentBg.y == 2) {
        data.cb.y = 2;
        data.cb.c = contentBg.c;
        data.cb.r = parseInt(contentBg.r) || 0;
    }

    // 魔方导航模块，保存内容区背景是否选择了主题色
    if ([31].includes(parseInt(style)) && contentBg.y == 2) {
        data.cb.isThemeColor = parseInt(contentBg.isThemeColor);
    }

    //分割线间的高度 -- "行高"
    var contentRowHeight = pattern.contentRowHeight;
    data.crh = {};
    data.crh.y = 0;
    if (contentRowHeight.y == 1) {
        data.crh.y = 1;
        data.crh.h = parseInt(contentRowHeight.h) || 24; // 24 是行高最低阈值
    }

    data.ti = {};
    data.ti.b = pattern.inputTxtBg;
    data.ti.pt = pattern.inputTxtTip;
    data.ti.d = pattern.inputTxtBorder;

    return data;
};

export function toggleItemLine(node) {
    //jm(node).parent().find('.pgBox2Item').toggle();
    var nodeObj = jm(node);
    if (nodeObj.hasClass('opened')) {
        nodeObj.parent().find('.pgBox2Item').height(0);
        nodeObj.removeClass('opened');
    } else {
        jm('.pgBox2Item').height(0);
        jm('.pgBoxItem').removeClass('opened');
        var childLen = jm(node).parent().find('.pgBox2Item .pgName').length;
        var itemPageHeight = 0;
        if (childLen >= 6) {
            itemPageHeight = '15rem';
        } else {
            itemPageHeight = childLen * 2.5 + 'rem';
        }
        nodeObj.addClass('opened').parent().find('.pgBox2Item').height(itemPageHeight);
    }
}
