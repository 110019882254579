// @ts-check
import { MobiModule } from '@/modules/Module';
import MemberFindPwVisitor from './visitor.vue';
import { MODULE_STYLE } from '@/def/module';

class MemberFindPwdModule extends MobiModule {
    constructor() {
        super(MODULE_STYLE.MEMBER_FIND_PWD, { system: true });
    }

    /** @override */
    render() {
        return MemberFindPwVisitor;
    }
}

export const MemberFindPwd = new MemberFindPwdModule();
