<template>
    <preview :components-table="componentsTable" :system-components="SystemComponents" />
</template>

<script>
import { componentsTable, SystemComponents } from '@/modules/visitor-interface';
import Preview from './preview.vue';

export default {
    name: 'VisitorPreview',
    components: {
        Preview,
    },
    data() {
        return {
            componentsTable,
            SystemComponents,
        };
    },
};
</script>

<style></style>
