import { popupWindow } from '@/shared/fai';
import { mobiAlert } from '@/shared/tips';

// 分销使用帮助页面链接统一处理，webportal.cc将废弃
// 20171103分销使用帮助页面链接统一处理
const getOemViewUrl = function (id) {
    return `//www.${window.Hosts.homeDomain}/sys/view.jsp?id=${id}`;
};

//判断是否接入百度商桥
export const isOpenBaiduBridge = function () {
    var baiduBridge = window._hmt;
    var isOpen = false;
    if (typeof baiduBridge != 'undefined') {
        var baiduBridgeId = baiduBridge.id;
        if (typeof baiduBridgeId != 'undefined') {
            isOpen = true;
        }
    }
    return isOpen;
};

export const showBaiduBridge = function () {
    if (isOpenBaiduBridge()) {
        //如果使用不了，有可能是因为客户在客户端设置咨询框的宽度和高度为0
        showBaiduBridgeV2();
    } else {
        if (VITE_APP_MODE !== 'visitor') {
            var tips = [];
            if (Fai.top._oem) {
                tips.push(
                    "<div id='baiduBridgeTips'>开启该功能需先接入百度商桥 <a href='" +
                        getOemViewUrl(589) +
                        "' target='_blank'>接入教程</a></div>"
                );
            } else {
                tips.push(
                    "<div id='baiduBridgeTips'>开启该功能需先接入百度商桥 <a href='//" +
                        Fai.top._jzProUrl +
                        "/blog/604' target='_blank'>接入教程</a></div>"
                );
            }
            var popupId = popupWindow({
                title: '温馨提示',
                divContent: tips,
                width: 374,
                height: 88,
            });
            var closePopupWindow = function () {
                Fai.closePopupWindow(popupId);
            };
            Fai.addPopupWindowBtn(popupId, {
                id: 'save',
                text: '知道了',
                extClass: 'saveButton',
                click: closePopupWindow,
            });
        } else {
            mobiAlert('未接入百度商桥');
        }
    }
};

export const showBaiduBridgeV2 = function () {
    jm('ins#newBridge').show();
    if (jm('ins#newBridge').find('#nb_icon_wrap').length) {
        jm('ins#newBridge').find('#nbWebImLightContainer').hide(); //对话板隐藏
    }
    jm('ins#newBridge').find('#nb_icon_wrap').hide();
    jm('ins#newBridge').find('#nb_nodeboard').hide(); //留言板隐藏
    //jm("ins#nb_invite_wrap").show();
    jm('ins#newBridge')
        .off('click', '#nb_icon_wrap')
        .on('click', '#nb_icon_wrap', function () {
            jm('ins#newBridge').find('#nbWebImLightContainer').show();
        });

    if (jm('ins#newBridge').find('#nbWebImLightContainer').length > 0) {
        jm('ins#newBridge').find('#nb_invite_wrap').remove();
    } else {
        // 兼容手机的。@ken 20180315
        jm('ins#newBridge').find('#nb_invite_wrap').show();
    }

    if (jm('ins#newBridge').find('#nb_toolbar_wrap').length > 0 && !(VITE_APP_MODE !== 'visitor')) {
        jm('ins#newBridge').find('#nb_toolbar_wrap').hide();
    }

    jm('#nb_icon_wrap').click();

    //对话缩小关闭
    jm('ins#newBridge')
        .off('click', '#nbwlToggle')
        .on('click', '#nbwlToggle', function () {
            jm('ins#newBridge').hide();
        });

    //留言缩小关闭
    jm('ins#newBridge')
        .off('click', '#nb_nodeboard_close')
        .on('click', '#nb_nodeboard_close', function () {
            jm('ins#newBridge').hide();
        });

    //留言发送成功关闭
    jm('ins#newBridge')
        .off('click', '#sucess_close')
        .on('click', '#sucess_close', function () {
            jm('ins#newBridge').hide();
        });

    //关闭后要恢复滚动，百度商桥那部分的demo在手机访客态下恢复滚动的的逻辑失效了
    jm('ins#newBridge')
        .off('click', '.qiao-webimlite-head-close')
        .on('click', '.qiao-webimlite-head-close', cleanOverFlow);
    jm('ins#newBridge').off('click', '#nb_invite_cancel').on('click', '#nb_invite_cancel', cleanOverFlow);
    jm('ins#newBridge').off('click', '#nb_invite_tool').on('click', '#nb_invite_tool', cleanOverFlow);
    window.addEventListener(
        'popstate',
        function () {
            cleanOverFlow();
        },
        false
    );
    // cleanOverFlow();

    //清除百度商桥自带的让滚动条失效了的逻辑，百度商桥代码存在问题，ios某版系统下，让滚动条失效去，恢复逻辑有问题
    function cleanOverFlow() {
        setTimeout(function () {
            jm('body').css('overflow', '');
            jm('body').attr('ontouchmove', '');
            jm('html').css('overflow', '');
        }, 0);
    }
};

export const baiduBridgeCodes = function (codes) {
    if (!codes) return '';

    var patt = new RegExp(
        // eslint-disable-next-line @jz/no-use-http-prefix
        '<script[^>]*>\\s*var\\s+_hmt\\s*=\\s*_hmt\\s*\\|\\|\\s*\\[\\]\\s*;\\s*\\(s*function\\(\\)\\s*\\{\\s*var\\s+hm\\s*=\\s*document\\.createElement\\(\\s*"script"\\s*\\);\\s*hm\\.src\\s*=\\s*"https://hm\\.baidu\\.com/hm\\.js\\?[A-Za-z0-9]+";\\s*var\\s+s\\s*=\\s*document\\.getElementsByTagName\\s*\\(\\s*"script"\\s*\\)\\[0\\];\\s*s\\.parentNode\\.insertBefore\\(\\s*hm\\s*,\\s*s\\s*\\);\\s*}\\s*\\)\\(\\);\\s*</script>',
        'g'
    );
    var result = patt.exec(codes);
    if (result != null) {
        return result[0];
    }
    return '';
};
