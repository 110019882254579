import { getMobiMultiLanguageUrl } from './url';
export const addFontFaceToHead = (customFontFaceFamily, substring, resUrl) => {
    if (!customFontFaceFamily || !substring) return;
    const fontInfo = analysisCustomFontFaceFamily(customFontFaceFamily);
    let resSrc = '';
    // resUrl是在初始化的时候调用
    if (resUrl) {
        // 这里特殊处理，资源文件缺失会返回no-pic的图片-_-
        if (resUrl.includes('no-pic')) return;
        resSrc = resUrl;
    } else {
        resSrc = `${getMobiMultiLanguageUrl()}/font.jsp?cmd=getSubStringFont&substring=${encodeURIComponent(
            substring
        ).replace(/'/g, '%27')}&id=${fontInfo.id}&_TOKEN=${$('#_TOKEN').attr('value')}`;
        substring = Fai.unique(substring);
    }
    const fontFaceString = `
            <style type='text/css' id='${customFontFaceFamily}' subString='${substring.replace(/'/g, '%27')}'>
                @font-face {
                    font-family: '${customFontFaceFamily}';
                    src: url('${resSrc}') format('truetype');
                    font-weight: normal;
                    font-style: normal;
                }
            </style>
        `;
    window.$('head').append(fontFaceString);

    // 这里有个很坑爹的BUG，您要preload font face文件，
    // 单单设置font-family还不行，还需要设置文本，
    // 没有文本的空元素设置font-family是不会preload字体文件的，
    // 元素的属性也不能设置为none，所以在这里设置了一个元素来preload字体文件。
    if (window.$('#tmpPreloadFontFace').length === 0) {
        const tempFontDiv = window
            .$('<div id="tmpPreloadFontFace"></div>')
            .css({ height: 0, width: 0, overflow: 'hidden' });
        window.$('body').append(tempFontDiv);
    }
    window.$('#tmpPreloadFontFace').css('font-family', `${customFontFaceFamily}`).text(substring);
};
export const resetHeadFontFace = (customFontFaceFamily, substring) => {
    const $oldFontFace = window.$(`#${customFontFaceFamily}`);
    $oldFontFace.remove();
    addFontFaceToHead(customFontFaceFamily, substring);
};

export const initCustomFontFaceFamily = (name, id) =>
    `fontface__${name}__${id}__${(Math.random() * +new Date()).toFixed(0)}`.replace(/\s/g, '');

export const analysisCustomFontFaceFamily = (fontFamily) => {
    if (!fontFamily) return {};
    const [flag, name, id, random] = fontFamily.split('__');
    return { flag, name, id, random };
};

export const mergeFonts = function ($el) {
    var fontList = [];
    if (!$el) throw 'mergeFonts: 请传入$el';
    var $fontsEl = $el.find('*[style*=font-family]');
    $fontsEl &&
        $fontsEl.each(function (_, e) {
            var fontFamily = window.$(e).css('font-family');

            var _Mobi$analysisCustomF = analysisCustomFontFaceFamily(fontFamily),
                flag = _Mobi$analysisCustomF.flag,
                id = _Mobi$analysisCustomF.id,
                random = _Mobi$analysisCustomF.random;

            var fontText = (window.$(e).text() || '').replace(/\s/g, '');

            if (flag === 'fontface') {
                // 同一个字体合并，并且进行字符串去重
                var fontData = fontList.find(function (e) {
                    return e.fontId == id;
                });

                if (fontData) {
                    fontData.substring += fontText;
                    fontData.substring = Fai.unique(fontData.substring);

                    window.$(e).css('font-family', fontData.fontFamily);
                } else {
                    if (fontText) {
                        fontList.push({
                            id: random,
                            fontId: +id,
                            fontFamily: fontFamily,
                            substring: Fai.unique(fontText),
                        });
                    }
                }
            }
        });
    return fontList;
};

export const addFontFaceToHead2 = function (fontList) {
    fontList.forEach(({ fontFamily, resUrl }) => {
        // 如果已经存在则不添加
        if (jm(`#${fontFamily}`).length === 0) {
            const fontFaceString = `
            <style type='text/css' id='${fontFamily}'>
                @font-face {
                    font-family: '${fontFamily}';
                    src: url('${resUrl}') format('truetype');
                    font-weight: normal;
                    font-style: normal;
                }
            </style>
            `;
            jm('head').append(fontFaceString);
        }
    });
};
