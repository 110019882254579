import { formatModuleDataList } from '../helpers/formatModuleDataList';
/**
 * =================================================
 *
 * State
 *
 * =================================================
 */
const state = () => ({
    isShowTopBar: true,
    isShowSideBar: true,
    isShowPageManage: true,
    isShowModuleManage: true,
    isEditMode: false,
    modules: [],
    isActiveSaveBtn: false,
    isActiveCancelBtn: false,
    isActiveExitBtn: true,
    isPopupZone: false,
    isFloatZone: false,
    delModules: [],
    hideModules: [],
    sortModules: [], // 用于模块排序(右键 - 置于顶层、上移一层、下移一层、置于底层)
});

const getters = {
    containerModules: (state, getters, rootState) => {
        return formatModuleDataList(state.modules).map((item) => {
            if (state.isFloatZone) {
                return {
                    ...item,
                    display: !rootState.editMode.hideModules.includes(item.id),
                };
            } else {
                return {
                    ...item,
                    display: !rootState.editMode.hideModules.includes(item.id),
                };
            }
        });
    },
};

/**
 * =================================================
 *
 * actions
 *
 * =================================================
 */
const actions = {
    enterEditMode({ commit }, { isPopupZone = false, isFloatZone = false } = {}) {
        commit('updateIsShowTopBar', false);
        commit('updateIsShowSideBar', false);
        commit('updateIsShowPageManage', false);
        commit('updateIsShowModuleManage', false);
        commit('updateIsEditMode', true);
        commit('updateIsPopupZone', isPopupZone);
        commit('updateIsFloatZone', isFloatZone);

        commit('resetModule');
        commit('resetDeleteModule');
        commit('resetHideModules');
    },
    exitEditMode({ commit }) {
        commit('updateIsShowTopBar', true);
        commit('updateIsShowSideBar', true);
        commit('updateIsShowPageManage', true);
        commit('updateIsShowModuleManage', true);
        commit('updateIsEditMode', false);
        commit('updateIsPopupZone', false);
        commit('updateIsFloatZone', false);

        commit('resetModule');
        commit('resetDeleteModule');
        commit('resetHideModules');
    },
    loadModules({ dispatch }, moduleDataList) {
        moduleDataList.forEach((module) => {
            dispatch('addModule', module);
        });
    },
    addModule({ commit }, module) {
        commit('addModule', module);
    },
    activeBtn({ commit }) {
        commit('updateIsActiveSaveBtn', true);
        commit('updateIsActiveCancelBtn', true);
        commit('updateIsActiveExitBtn', false);
    },
    deactiveBtn({ commit }) {
        commit('updateIsActiveSaveBtn', false);
        commit('updateIsActiveCancelBtn', false);
        commit('updateIsActiveExitBtn', true);
    },
};

/**
 * =================================================
 *
 * mutations
 *
 * =================================================
 */
const mutations = {
    updateIsShowTopBar(state, payload) {
        state.isShowTopBar = payload;
    },
    updateIsShowSideBar(state, payload) {
        state.isShowSideBar = payload;
    },
    updateIsShowPageManage(state, payload) {
        state.isShowPageManage = payload;
    },
    updateIsShowModuleManage(state, payload) {
        state.isShowModuleManage = payload;
    },
    updateIsEditMode(state, payload) {
        state.isEditMode = payload;
    },
    addModule(state, payload) {
        state.modules.push(payload);
    },
    updateModule(state, payload) {
        let { id } = payload;
        let exitModule = state.modules.find((module) => module.id == id);
        if (exitModule) {
            state.modules = state.modules.map((module) => {
                return module.id == id ? payload : module;
            });
        } else {
            this.commit('addModule', payload);
        }
    },
    hideModule(state, payload) {
        let { id } = payload;
        state.modules = state.modules.map((module) => {
            return module.id == id ? payload : module;
        });
    },
    resetModule(state) {
        state.modules = [];
    },
    updateIsActiveSaveBtn(state, payload) {
        state.isActiveSaveBtn = payload;
    },
    updateIsActiveCancelBtn(state, payload) {
        state.isActiveCancelBtn = payload;
    },
    updateIsActiveExitBtn(state, payload) {
        state.isActiveExitBtn = payload;
    },
    updateIsPopupZone(state, payload) {
        state.isPopupZone = payload;
    },
    updateIsFloatZone(state, payload) {
        state.isFloatZone = payload;
    },
    addDeleteModule(state, payload) {
        state.delModules.push(payload);
    },
    resetDeleteModule(state) {
        state.delModules = [];
    },
    deleteModule(state, payload) {
        let index = state.modules.findIndex((module) => module.id === payload);
        state.modules.splice(index, 1);
    },
    addHideModule(state, payload) {
        if (payload instanceof Array) {
            state.hideModules = [...state.hideModules, ...payload];
        } else {
            state.hideModules.push(payload);
            if (state.isPopupZone) {
                // 隐藏的模块放到最后
                let index = state.modules.findIndex((module) => module.id == payload);
                let hiddenModule = state.modules[index];

                let modules = JSON.parse(JSON.stringify(state.modules));
                modules.splice(index, 1);
                modules.push(hiddenModule);

                state.modules = modules;
            }
        }
    },
    showModule(state, payload) {
        let index = state.hideModules.findIndex((id) => id === payload);
        state.hideModules.splice(index, 1);
    },
    resetHideModules() {
        state.hideModules = [];
    },
    /* 排序操作 - 最下面的元素显示在上方 - start - */
    initSortModules(state, payload) {
        state.sortModules = payload;
    },
    putOnTop(state, moduleId) {
        let sortModuleIdList = state.sortModules.filter((id) => id != moduleId);
        sortModuleIdList.push(moduleId);
        state.sortModules = sortModuleIdList;
    },
    putOnBottom(state, moduleId) {
        let sortModuleIdList = state.sortModules.filter((id) => id != moduleId);
        sortModuleIdList.unshift(moduleId);
        state.sortModules = sortModuleIdList;
    },
    putOnForward(state, moduleId) {
        let index = state.sortModules.findIndex((id) => id == moduleId);
        let length = state.sortModules.length;
        if (index < length - 1) {
            let tempArr = JSON.parse(JSON.stringify(state.sortModules));
            let nextModuleId = tempArr[index + 1];
            tempArr[index + 1] = moduleId;
            tempArr[index] = nextModuleId;
            state.sortModules = tempArr;
        }
    },
    putOnNext(state, moduleId) {
        let index = state.sortModules.findIndex((id) => id == moduleId);
        if (index > 0) {
            let tempArr = JSON.parse(JSON.stringify(state.sortModules));
            let forwardModuleId = tempArr[index - 1];
            tempArr[index - 1] = moduleId;
            tempArr[index] = forwardModuleId;
            state.sortModules = tempArr;
        }
    },
    addSortModule(state, moduleId) {
        if (!state.sortModules.includes(moduleId)) {
            state.sortModules.push(moduleId);
        }
    },
    resetSortModule(state) {
        state.sortModules = [];
    },
    /* 排序操作 - end -*/
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
