import mapView from '@/modules/onlineMap/module/component/mapView.vue';
let MapDetail = {};
MapDetail.render = function (location, options, module) {
    // var mapView = JzmModule.utils.getComponentByName('mapView');

    let $body = jm('body');
    let mapViewWrap = `
		<div class='map_view'>
			<map-view :location='location' :module="module" :options="options"></map-view>
		</div>
		`;
    // 先删除现有节点，因为重新new Vue导致内部组件不响应变化，暂时采用这个办法
    jm('.map_view').remove();
    // 原先 iframe: body, 如今：g_web 节点下
    // $body.append(mapViewWrap);
    $('.g_web').append(mapViewWrap);
    // 隐藏滚动条
    $body.css('overflow-y', 'hidden');

    // 激活视图
    new Vue({
        el: '.map_view',
        components: {
            mapView,
        },
        data() {
            return {};
        },
        computed: {
            location() {
                return location;
            },
            module() {
                return module;
            },
            options() {
                return options;
            },
        },
    });
};

export default MapDetail;
