/*
弹出框（在top显示）
title				弹出框的标题
width				内容区宽度（数字/字符串）
height				内容区高度（数字/字符串）
opacity				背景遮盖层的透明度，默认0.3
displayBg			是否加背景遮盖层，默认true
frameSrcUrl			内容区iframe的src地址
frameScrolling		iframe是否有scrolling(yes/no/auto)，默认auto
bannerDisplay		是否显示标题栏和边框，默认true
closeBtnClass		关闭按钮样式
framePadding		是否去掉内容区的padding
bgClose				是否点击背景关闭，默认false
divId				以div的html来作为内容
divContent			以html来作为内容

closeFunc			关闭popup window时执行的函数，可以通过closePopupWindow(popupID, closeArgs)来传递closeFunc的回调参数，即调用：closeFunc(closeArgs)
msg                 关闭popup window时提示的语句（点击右上角那个×）
helpLink			帮助按钮的link url
waitingPHide		是否隐藏loading面板（前后端分离后，交由加载的文件控制）
*/
import { context, deepExtendClone, browser } from '@jz/utils';
import { logDog } from '@/shared/log';
import { isNull, addUrlParams } from '@/shared/fai';
import { closePopupWindow, bg } from './utils';
import { isDevEnv, REQUEST_BASE_URL, MANAGE_SITE_BASE_URL } from '@/shared/env';
export const popupWindow = function (options) {
    //settings走默认设置
    var settings = {
        title: '',
        width: 500,
        height: 300,
        frameSrcUrl: 'about:_blank',
        frameScrolling: 'auto',
        bannerDisplay: true,
        framePadding: true,
        opacity: '0.5',
        displayBg: true,
        bgClose: false,
        closeBtnClass: '',
        waitingPHide: true,
        popUpWinZIndex: 0,
    };
    settings = deepExtendClone(settings, options);
    if (isDevEnv) {
        if (
            !settings?.frameSrcUrl?.includes('about:_blank') &&
            !settings?.frameSrcUrl?.includes(MANAGE_SITE_BASE_URL)
        ) {
            settings.frameSrcUrl = `${REQUEST_BASE_URL + settings.frameSrcUrl}`;
        }
    }
    var contentWidth = parseInt(settings.width),
        contentHeight = parseInt(settings.height);

    var browserWidth = context.document.documentElement.clientWidth;
    if (!browser.isIE()) {
        browserWidth = context.document.body.clientWidth;
    }
    var browserHeight = context.document.documentElement.clientHeight;

    var leftMar = (browserWidth - contentWidth) / 2;
    if (settings.leftMar != null) {
        leftMar = parseInt(settings.leftMar);
    }
    if (context.__noGmain) {
        leftMar = (document.documentElement.scrollWidth - contentWidth) / 2;
    }
    var topDiff = 80;
    if (!settings.bannerDisplay) {
        topDiff = 0;
    }
    var topMar = (browserHeight - contentHeight - topDiff) / 2;
    if (settings.topMar != null) {
        topMar = parseInt(settings.topMar);
    }

    var bgDisplay = '',
        bannerStyle = '',
        trans = '';
    if (!settings.bannerDisplay) {
        bannerStyle = 'display:none;';
        bgDisplay = 'background:none;';
        if (!settings.closeBtnClass) {
            settings.closeBtnClass = 'formX_old'; // 没有边框时使用另一个样式（如幻灯片）
        }
    }
    if (!settings.framePadding) {
        bgDisplay += 'padding:0;';
        trans = 'allowtransparency="true"';
    }

    var id = parseInt(Math.random() * 10000),
        displayModeContent =
            '<iframe ' +
            trans +
            " id='popupWindowIframe" +
            id +
            "' class='popupWindowIframe' src='' frameborder='0' scrolling='" +
            settings.frameScrolling +
            "' style='width:100%;height:100%;'></iframe>",
        iframeMode = true;
    if (settings.divId != null) {
        iframeMode = false;
        displayModeContent = $(settings.divId).html();
    }
    if (settings.divContent != null) {
        iframeMode = false;
        displayModeContent = settings.divContent;
    }
    //加背景
    if (settings.displayBg) {
        bg(id, settings.opacity);
    }

    var html = '';
    //加弹出窗口
    var scrollTop = context.$('body').scrollTop();
    if (scrollTop == 0) {
        scrollTop = context.$('html').scrollTop();
    }

    var winStyle = 'left:' + leftMar + 'px; top:' + (topMar + scrollTop) + 'px;';

    if (settings.popUpWinZIndex) {
        winStyle += 'z-index: ' + settings.popUpWinZIndex;
    }
    var formMSGStyle = 'position:relative;width:' + contentWidth + 'px;height:' + contentHeight + 'px;',
        fixFlashIframe = '';
    if (browser.isIE()) {
        fixFlashIframe =
            '<iframe id="fixFlashIframe' +
            id +
            '" style="position:absolute;z-index:-1;left:0;top:0;" frameborder="0" width="100%" height="100%" src="javascript:"></iframe>';
    }

    //fixFlashIframe防止在IE下Flash遮挡弹出层
    html = [
        '<div id="popupWindow' + id + '" class="formDialog" style="' + winStyle + '">',
        fixFlashIframe,
        '<div class="formTL" style=\'' +
            bannerStyle +
            '\'><div class="formTR"><div class="formTC">' +
            settings.title +
            '</div></div></div>',
        '<div class="formBL" style=\'' + bgDisplay + "'>",
        '<div class="formBR" style=\'' + bgDisplay + "'>",
        '<div class="formBC" id="formBC' + id + '" style="height:auto;' + bgDisplay + '">',
        '<div class="formMSG" style="' + formMSGStyle + '">',
        displayModeContent,
        '</div>',
        "<table cellpadding='0' cellspacing='0' class='formBtns'>",
        "<tr><td align='center' style='padding:15px 0px;'></td></tr>",
        '</table>',
        '</div>',
        '<div id="waitingP' + id + '" class="waitingP" style="height:auto;"></div>',
        '</div>',
        '</div>',
        '<a href="javascript:;" class="formX ' +
            settings.closeBtnClass +
            "\" hidefocus='true' onclick='return false;'></a>",
        '</div>',
    ];

    var popupWin = context.$(html.join('')).appendTo('body');

    // fix height
    var btnsHeight = 40;
    var offHeight = 20;
    if (!settings.bannerDisplay) {
        btnsHeight = 0;
    }
    if (popupWin.height() + btnsHeight > browserHeight - offHeight) {
        var diffHeight = popupWin.height() + btnsHeight - popupWin.find('.formMSG').height(); // 40预留给button区
        popupWin.find('.formMSG').css('height', browserHeight - offHeight - diffHeight + 'px');
        popupWin.css('top', 10 + scrollTop + 'px');
    }

    //重置加载层宽高度
    if (iframeMode) {
        context
            .$('#waitingP' + id)
            .height(context.$('#formBC' + id).height())
            .width(context.$('#formBC' + id).width());
    } else {
        if (settings.waitingPHide) {
            context.$('#waitingP' + id).hide();
        }
    }

    if (settings.divInit != null) {
        settings.divInit(id);
    }
    context.$('#popupWindow' + id).ready(function () {
        if (iframeMode) {
            var frameSrcUrlArgs = 'popupID=' + id;
            context
                .$('#popupWindowIframe' + id)
                .attr('src', addUrlParams(settings.frameSrcUrl, frameSrcUrlArgs))
                .load(function () {
                    if (settings.waitingPHide) {
                        context.$('#waitingP' + id).hide();
                    }
                    if (!!options && options.fromNewsGuide) {
                        typeof options.callback == 'function' && options.callback();
                    }
                });
        }
        popupWin.draggable({
            start: function () {
                // 拖动不选中
                context.$('body').disableSelection();
                // chrome,ie10以上 top选色板focusout失效。
                context.$('#colorpanel').remove();
                context.$('.faiColorPicker').remove();
            },
            handle: '.formTL',
            stop: function () {
                // 拖动不选中
                context.$('body').enableSelection();
                logDog(200046, 39);
            },
        });

        popupWin.find('.formX').bind('click', function () {
            if (isNull(options.msg)) {
                closePopupWindow(id);
            } else {
                closePopupWindow(id, undefined, options.msg);
            }
            return false;
        });
        popupWin.find('.formTL').disableSelection();

        // 如果开启了点击背景关闭
        if (settings.bgClose) {
            context.$('#popupBg' + id).bind('click', function () {
                if (isNull(options.msg)) {
                    closePopupWindow(id);
                } else {
                    closePopupWindow(id, undefined, options.msg);
                }
                return false;
            });
        }
    });

    if (isNull(context._popupOptions)) {
        context._popupOptions = {};
    }
    if (isNull(context._popupOptions['popup' + id])) {
        context._popupOptions['popup' + id] = {};
    }
    if (!isNull(options.callArgs)) {
        context._popupOptions['popup' + id].callArgs = options.callArgs;
    }
    context._popupOptions['popup' + id].options = options;
    context._popupOptions['popup' + id].change = false;

    //页面监控
    var jsMonitor;
    if (typeof options.frameSrcUrl != undefined) {
        $.ajax({
            url: '/ajax/log_h.jsp?cmd=wafNotCk_monitorManageMode&siteId=' + context.siteId,
            type: 'get',
            success: function (result) {
                var res = $.parseJSON(result);
                if (typeof res.jsData != undefined && res.jsData != '') {
                    jsMonitor = res.jsData;
                    context.$('#popupWindow' + id).append(jsMonitor);
                }
            },
        });
    }

    return id;
};
