import request from '@/api/request';
import { decodeHtml } from '@/shared/util';
const getGuanZhuData = function (wxOpenId, openIdStr) {
    wxOpenId = jm.getCookie(openIdStr) || wxOpenId || '';
    request
        .post('/ajax/wx_h.jsp?cmd=getWafNotCk_getGuanZhuData', {
            data: {
                wxOpenId,
            },
        })
        .then((result) => {
            const { data = {} } = result;
            if (data.success) {
                jm(document).ready(function () {
                    jm('#g_body').append(data.guanzhuDom);
                    jm.fkEval.call(window, data.guanzhuScripts);
                });
            }
        });
};

const initWxShare = function (wxShareData) {
    const wxShareUrl = document.location.href;
    const shareTit = decodeHtml(wxShareData.shareTit || '');
    const shareDes = decodeHtml(wxShareData.shareDes || '');
    const shareIconPath = wxShareData.shareIconPath;
    Mobi.initWxConfig()
        .then(() => {
            Mobi.isWxInit = true;
            // 分享给朋友
            // eslint-disable-next-line no-undef
            wx.onMenuShareAppMessage({
                title: shareTit,
                desc: shareDes,
                link: wxShareUrl,
                imgUrl: shareIconPath,
                success() {
                    if ([21, 28, 4].includes(Fai.top._colInfo.id)) window.bizShared.Clue.logClueShareNewsAction();
                },
            });
            //分享到朋友圈
            // eslint-disable-next-line no-undef
            wx.onMenuShareTimeline({
                title: shareTit,
                desc: shareDes,
                link: wxShareUrl,
                imgUrl: shareIconPath,
                success() {
                    if ([21, 28, 4].includes(Fai.top._colInfo.id)) window.bizShared.Clue.logClueShareNewsAction();
                },
            });
            //分享到QQ
            // eslint-disable-next-line no-undef
            wx.onMenuShareQQ({
                title: shareTit,
                desc: shareDes,
                link: wxShareUrl,
                imgUrl: shareIconPath,
                success() {
                    if ([21, 28, 4].includes(Fai.top._colInfo.id)) window.bizShared.Clue.logClueShareNewsAction();
                },
            });
            //分享到微博
            // eslint-disable-next-line no-undef
            wx.onMenuShareWeibo({
                title: shareTit,
                desc: shareDes,
                link: wxShareUrl,
                imgUrl: shareIconPath,
                success() {
                    if ([21, 28, 4].includes(Fai.top._colInfo.id)) window.bizShared.Clue.logClueShareNewsAction();
                },
            });

            // eslint-disable-next-line no-undef
            wx.updateAppMessageShareData({
                title: shareTit,
                desc: shareDes,
                link: wxShareUrl,
                imgUrl: shareIconPath,
                success() {
                    if ([21, 28, 4].includes(Fai.top._colInfo.id)) window.bizShared.Clue.logClueShareNewsAction();
                },
            });

            // eslint-disable-next-line no-undef
            wx.updateTimelineShareData({
                title: shareTit,
                desc: shareDes,
                link: wxShareUrl,
                imgUrl: shareIconPath,
                success() {
                    if ([21, 28, 4].includes(Fai.top._colInfo.id)) window.bizShared.Clue.logClueShareNewsAction();
                },
            });
        })
        .catch((err) => {
            if (typeof err === 'object') {
                console.log(JSON.stringify(err));
            }
        });
};

export const handleWxShare = function () {
    if (window.wxShareData && Object.keys(window.wxShareData).length) {
        initWxShare(window.wxShareData);
    }

    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1 || ua.indexOf('wxwork') != -1;
    if (isWeixin) {
        let wxOpenId = jm.cookie('_wxOpenId');
        if (jm.cookie('_hideWXPublicAttentionTip') != 1 && window.advancedSetAllowed) {
            getGuanZhuData(wxOpenId, '_wxOpenId2');
        }
    }
};
