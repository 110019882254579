<template>
    <module-frame :module-id="moduleId">
        <div :id="'photoGroupResult' + moduleId" class="fk-photoGroupResult">
            <template v-if="emptyPhotoGroup">
                <div v-text="LS.noIllustrationsUnderThisCategory"></div>
            </template>
            <template v-else>
                <div
                    v-for="photoGroup in filterPhotoGroupList"
                    :id="'photoGroupR' + photoGroup.id"
                    :key="photoGroup.id"
                    class="f-photoGroupItem"
                    :style="photoGroupStyle"
                >
                    <a
                        hidefocus="true"
                        class="f-photoGroupR-a"
                        :href="photoGroupHref(photoGroup)"
                        @click="photoGroupHrefClick(photoGroup)"
                    >
                        <div
                            :src-original="photoGroup.picThumbPath640"
                            class="f-photoGroupR-img photoGroup-img J_img_lazyload"
                            :title="photoGroup.name"
                            :style="photoGroupImgStyle(photoGroup)"
                        >
                            <div class="f-photoGroupR-curtain f-photoGroupR-curtainDefault"></div>
                            <span
                                class="f-photoGroupR-title"
                                :style="cusTitleFontStyle"
                                v-text="photoGroup.name"
                            ></span>
                            <span class="f-photoGroupR-photoNum" v-text="photoGroup.photoNumbers"></span>
                        </div>
                    </a>
                </div>
            </template>
        </div>
    </module-frame>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { PhotoGroupResult } from '../photoGroupResultDef';
import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import ModuleFrame from '@/modules/frame/index.vue';

import { changeHref } from '@/features/router/universal';

export default {
    name: 'PhotoGroupResult',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['LS']),
        moduleInfo() {
            return this.getModuleById(this.moduleId);
        },
        content() {
            return this.moduleInfo.content || {};
        },
        coverHeightType() {
            return this.content[PhotoGroupResult.COVER_HEIGHT_TYPE] || 0;
        },
        coverHeight() {
            return this.content[PhotoGroupResult.COVER_HEIGHT] || 0;
        },
        titleFontType() {
            return this.content[PhotoGroupResult.TITLE_FONT_TYPE] || 0;
        },
        titleFont() {
            return this.content[PhotoGroupResult.TITLE_FONT] || {};
        },
        clickPopup() {
            return this.content[PhotoGroupResult.CLICK_POPUP] || 0;
        },
        titleFont_colorType() {
            return this.titleFont[PhotoGroupResult.TitleFont.COLOR_TYPE] || 0;
        },
        titleFont_size() {
            return this.titleFont[PhotoGroupResult.TitleFont.SIZE] || 12;
        },
        titleFont_color() {
            return this.titleFont[PhotoGroupResult.TitleFont.COLOR] || '';
        },
        extInfo() {
            return this.moduleInfo.extInfo || {};
        },
        filterPhotoGroupList() {
            return this.extInfo.photoGroupList || [];
        },
        loadingPath() {
            return this.extInfo.loadingPath || '';
        },
        emptyPhotoGroup() {
            return this.filterPhotoGroupList.length == 0;
        },
        cusTitleFontStyle() {
            let style = {};
            if (this.titleFontType == 1) {
                if (this.titleFont_colorType == 1) {
                    style['color'] = this.titleFont_color;
                }
                style['font-size'] = this.titleFont_size + 'px';
            }
            return style;
        },
        photoGroupStyle() {
            let style = {};
            if (this.coverHeightType == 1) {
                style['height'] = this.coverHeight + 'px';
            }
            return style;
        },
    },
    watch: {
        content: {
            deep: true,
            handler() {
                Mobi.reloadPreview();
            },
        },
    },
    mounted() {
        if (this.filterPhotoGroupList.length) {
            if (this.clickPopup == 0) {
                this.filterPhotoGroupList.forEach((photoGroup) => {
                    initPictureSlidesV4(
                        `#photoGroupResult${this.moduleId} #photoGroupR${photoGroup.id}`,
                        photoGroup.slidePhotoList,
                        photoGroup.slideOptions
                    );
                });
            }
        }
        bindImgLazyLoad(`module${this.moduleId}`);
    },
    methods: {
        photoGroupImgStyle() {
            let style = {};
            style['background-image'] = `url(${this.loadingPath})`;
            return style;
        },
        photoGroupHrefClick(photoGroup) {
            if (VITE_APP_MODE !== 'visitor' && this.clickPopup == 1) {
                changeHref(photoGroup.href);
            }
        },
        photoGroupHref(photoGroup) {
            return this.clickPopup == 0 ? 'javascript:;' : photoGroup.href;
        },
    },
};
</script>
<style lang="scss">
.fk-photoGroupResult {
    overflow: hidden;
    padding: 0.5rem;
}
.fk-photoGroupResult .f-photoGroupItem {
    float: left;
    width: 49%;
    height: 8rem;
    margin-top: 2%;
}
.fk-photoGroupResult .f-photoGroupItem .f-photoGroupR-a {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    color: #ffffff;
    font-family: 微软雅黑;
    overflow: hidden;
    z-index: 3;
    box-sizing: border-box;
    text-align: center;
}
.fk-photoGroupResult .f-photoGroupR-img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    background-color: #ffffff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.fk-photoGroupResult .f-photoGroupR-curtain {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
}
.fk-photoGroupResult .f-photoGroupR-curtainDefault {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 1) 83%);
    height: 1.5rem;
}

.fk-photoGroupResult .f-photoGroupR-FontDiv {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
}
.fk-photoGroupResult .f-photoGroupR-title {
    text-align: left;
    width: 62%;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-weight: bold;
    position: absolute;
    left: 2%;
    bottom: 2%;
    z-index: 2;
    font-size: 0.7rem;
}
.fk-photoGroupResult .f-photoGroupR-photoNum {
    text-align: right;
    width: 34%;
    position: absolute;
    bottom: 2%;
    right: 2%;
    z-index: 2;
    font-size: 0.6rem;
}

.fk-photoGroupResult .f-photoGroupItem:nth-child(1) {
    margin-top: 0;
}
.fk-photoGroupResult .f-photoGroupItem:nth-child(2) {
    margin-top: 0;
}
.fk-photoGroupResult .f-photoGroupItem:nth-child(2n + 2) {
    margin-left: 2%;
}

.navBaseIcon .navItem .fk-fixNavIconStyle {
    width: auto;
    display: inline-block;
}
</style>
