// 表单 接口

import request from './request';

const manageMode = VITE_APP_MODE !== 'visitor';
export function getAllCityInfoApi() {
    let url = `/api/${manageMode ? 'manage' : 'guest'}/city/getAllCityInfo`;

    return request.get(url, {}).then(({ data }) => {
        return data;
    });
}

export function getProvinceInfoApi(data = {}) {
    let url = `/api/${manageMode ? 'manage' : 'guest'}/city/getProvinceInfo`;

    return request
        .get(url, {
            data,
        })
        .then(({ data }) => {
            return data;
        });
}

export function getCityInfoApi(params = {}) {
    let url = `/api/${manageMode ? 'manage' : 'guest'}/city/getCityInfo`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}

export function cityGetChildrenApi(params = {}) {
    let url = `/api/${manageMode ? 'manage' : 'guest'}/city/cityGetChildren`;

    return request
        .get(url, {
            params,
        })
        .then(({ data }) => {
            return data;
        });
}
