const vmKey = Symbol();
function setProfileVM(vm) {
    window[vmKey] = vm;
    window.ProfileVM = vm;
}
function getProfileVM() {
    return window[vmKey];
}

export { vmKey, setProfileVM, getProfileVM };
