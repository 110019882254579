import { createUrlArgsOnclickStr } from '@/modules/comm';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { preciseDecimal } from '@/shared/util';
import { headBoxFixedV3 } from '../header/module/v3/header';
import { encodeHtml } from '@/shared/util';
export const createBannerSwipeHandle = function (_bannerData) {
    if (!BannerAnimateFactory().init(_bannerData)) {
        initBannerDefaultDom(_bannerData);
        initSwipe('bannerSwipe', _bannerData);
    }
    Vue.prototype.$designer.styleChanged();
    return;
};

export const initBannerDefaultDom = function (bannerData) {
    //if (bannerData.showType == 3) {
    //	return;
    //}
    var itemsData = bannerData.b,
        itemsLength = itemsData.length,
        i,
        j,
        html = [],
        showDesc = bannerData.sd;

    html.push("<div id='bannerSwipe' class='swipe'>");
    html.push("<div id='bannerSwipeContainer' class='bannerSwipeContainer defaultBanner'>");

    for (i = 0; i < itemsLength; i++) {
        var imgId = itemsData[i].i, //图片id
            linkType = itemsData[i].lt, //链接类型，javascript:; : 自定义
            itemWidth = itemsData[i].w, //单个图片宽;
            itemHeight = itemsData[i].h, //单个图片高;
            type = itemsData[i].t, //背景图片类型;
            path = itemsData[i].p, //背景图片链接
            jumpUrl = itemsData[i].aj, //调整链接，inc中配置输出
            des = itemsData[i].des,
            reqArgs = itemsData[i].reqArgs,
            onclickStr = createUrlArgsOnclickStr(reqArgs); //横幅描述
        html.push("<div id='bannerItem" + imgId + "'>");
        html.push(
            '<a href="' +
                (linkType !== 0 ? jm.encodeHtmlAttr(jumpUrl) : 'javascript:;') +
                '" vheight=\'' +
                itemHeight +
                "' vwidth='" +
                itemWidth +
                "' class='bannerImageDiv J_img_lazyload " +
                (type === 0 ? 'systemtBanner' : 'customBanner') +
                "' style='display:block;background-image:url(" +
                Fai.top._loadingPath +
                ")' src-original='" +
                path +
                "' " +
                onclickStr +
                '></a>'
        );
        //html.push(			'<a href="' + (linkType != 0 ? jumpUrl : "javascript:;") + '" vheight="'+ itemHeight +'" vwidth="'+ itemWidth +'" class="bannerImageDiv '+(type == 0 ? 'systemtBanner' : 'customBanner')+'" style="display:block; background-image:url(' + path + ');"></a>');
        if (showDesc) {
            html.push("<div class='bannerDescription'><div class='description'>" + encodeHtml(des) + '</div></div>');
        }
        html.push('</div>');
    }

    html.push('</div>');

    if (showDesc) {
        html.push("<div id='bannerBullet' class='bannerBullet2'>");
    } else {
        html.push("<div id='bannerBullet' class='bannerBullet'>");
    }
    html.push("<ul id='bullets' class='bullets'>");
    if (itemsLength > 1) {
        for (j = 0; j < itemsLength; j++) {
            html.push('<li ' + (j === 0 ? "class='on'" : '') + '></li>');
        }
    }
    html.push('</ul>');
    html.push('</div>');

    html.push('</div>');
    jm('#webBanner').html('').append(html.join(''));
};

export const initSwipe = function (dId, _bannerData, options) {
    //if(_bannerData && _bannerData.h) return;
    var swipe = document.querySelector('#' + dId);
    var webBanner = jm('#webBanner')[0];
    var loading = jm('#webBanner').find('.J_pageLoading');
    if (!swipe) return;

    if (jm._swipehandle) {
        jm._swipehandle.kill();
    }

    var bannerCustomItem = swipe.querySelectorAll('.bannerImageDiv.customBanner'),
        bannerItem = swipe.querySelectorAll('.bannerImageDiv'),
        bannerCustomItemLength = bannerCustomItem.length,
        bannerItemLength = bannerItem.length,
        totailWidth = swipe.offsetWidth,
        bannerHeight = '',
        scaleImg = 0,
        vWidth,
        vHeight,
        scale;

    for (var item = 0; item < bannerCustomItemLength; item++) {
        vWidth = parseInt(bannerCustomItem[item].getAttribute('vwidth'));
        vHeight = parseInt(bannerCustomItem[item].getAttribute('vheight'));
        scale = vHeight / vWidth;
        //webpOri = bannerCustomItem[item].getAttribute('webp-origin');

        /*if (webpOri) {
			jm(bannerCustomItem[item]).css('background-image', 'url(' + img2Webp(webpOri) + ')');
		}*/

        if (scale > scaleImg) {
            scaleImg = scale;
        }
    }
    //系统banner  如果视图宽度大于480就拉升，小于480高度就150px
    if (bannerItemLength === 0) {
        // 修复横幅高度间隙，Math.ceil出现的0~1px偏差，这里精确到后两位，让偏差缩小到0.01~0.1 @ken 20171031
        //var defaultBnrHeight = preciseDecimal(totailWidth * 300/640, 2);
        //修复横幅下面优惠券模块优惠券圆角失效 --- rofe  bugId:1009554
        var defaultBnrHeight = (totailWidth * 300) / 640;
        swipe.querySelector('.defaultBanner').style.height = defaultBnrHeight + 'px';
    } else {
        //修复横幅高度在苹果手机上获取高度不正确bug
        bannerHeight = getBannerHeight() / Fai.top._htmlFontSize;

        swipe.style.height = bannerHeight + 'rem';
        webBanner.style.height = bannerHeight + 'rem';
    }

    // 系统 banner 赋值初始化横幅路径，修复工单106874
    /*jm('#'+ dId + ' .systemtBanner').forEach(function(item){
    	var $item = jm(item);
    	var sysWebpOri = $item.attr('webp-origin');
    	if (!!sysWebpOri) {
    		$item.css('background-image', 'url(' + img2Webp(sysWebpOri) + ')');
    	}
    })*/

    if (loading.length) {
        loading.hide();
    }

    if (Fai.top._openThemeV3) {
        headBoxFixedV3(false);
    }
    //兼容旧版横幅滑动特效
    if (!options) {
        if (!checkBrowserFactory()() || _bannerData.et === 1) {
            jm._swipehandle = jm('#' + dId)?.swipehandle(swipe, {
                auto: 3000,
                continuous: true,
                bulletsClick: true,
                speed: _bannerData.st * 100,
                disableOnInteraction: false,
                callback(pos) {
                    var liLen = bullets.length;
                    while (liLen--) {
                        bullets[liLen].className = ' ';
                    }
                    if (!bullets[pos]) {
                        bullets[pos - bullets.length].className = 'on';
                    } else {
                        bullets[pos].className = 'on';
                    }
                },
            });
        } else {
            swipe.style.visibility = 'visible';
            var swipeWidth = swipe.getBoundingClientRect().width,
                element = swipe.children[0],
                slides = element.children;
            // element.style.width = ((swipeWidth * slides.length) + "px");
            var pos = slides.length;
            while (pos--) {
                slides[pos].style.width = swipeWidth + 'px';
            }
        }
        // 图片懒加载   同步加载同类图片，粗糙方法可能性能不太行，临时处理 ---zhj
        if (bannerCustomItemLength < 3) {
            bindImgLazyLoad('webBanner', { loadExistImg: true });
        } else {
            bindImgLazyLoad('webBanner');
        }

        if (!swipe.querySelector('.bullets')) {
            return;
        }

        var bullets = swipe.querySelector('.bullets').getElementsByTagName('li');
    } else {
        // 图片懒加载
        bindImgLazyLoad('webBanner');
    }

    function getBannerHeight() {
        var height = 0;
        var isOverCustomCount = bannerItemLength - bannerCustomItemLength > 0;

        if (isOverCustomCount) {
            height =
                totailWidth * scaleImg < (totailWidth * 300) / 640
                    ? preciseDecimal((totailWidth * 300) / 640, 2)
                    : preciseDecimal(totailWidth * scaleImg, 2);
        } else {
            height = preciseDecimal(totailWidth * scaleImg, 2);
        }

        return height;
    }
};

//集合banner切换数据，函数等
export const BannerAnimateFactory = () => {
    return (function () {
        let FUNC = {};
        //baseData
        var effectType = 1, //特效类型
            currentIndex = 0, //当前帧
            oldIndex = -1, //上一帧
            animating = false, //是否正在执行动画
            autoPlay = true, //是否自动播放
            timer, //定时器
            defaultData = {
                duration: 3000, //停留时间
            },
            data = {
                //用户配置数据
            },
            showDesc,
            itemMaxWidth = 0, //banner item 最大宽度
            itemMaxHeight = 0, //banner item 最大高度
            _bannerData, //banner数据
            //baseElem
            bannerSwipe = jm('#bannerSwipe'),
            container = bannerSwipe.find('.bannerSwipeContainer'),
            items = container.find('.billboard_item'),
            itemsLen = items.length;

        //初始化1，正常加载
        FUNC.init = function (options) {
            var bannerData = options;
            //没有横幅的页面 return
            if (jm('#webBanner').length < 1) {
                return;
            }
            //1张或没有横幅
            if (bannerData.b.length < 2) {
                return;
            }
            //横幅隐藏
            if (bannerData.showType === 3) {
                return;
            }

            //浏览器不支持 或 特效类型为1,（水平滑动）；
            var browserCompatible = checkBrowserFactory()();
            if (!browserCompatible || bannerData.et === 1) {
                return false;
            }
            clearTimeout(timer);
            currentIndex = 0;
            animating = false;
            initData(bannerData);
            initDom(bannerData);
            initSwipe('bannerSwipe', bannerData);
            initElemData(bannerData);
            initBind(bannerData);

            setOutWrapHeight();

            return true;
        };

        //初始化数据
        function initData(bannerData) {
            if (bannerData !== undefined) {
                _bannerData = bannerData;
                data.effectType = bannerData.et;
                data.switchingTime = bannerData.st * 100;
                data.duration = defaultData.duration;
                effectType = bannerData.et;
                showDesc = bannerData.sd;
            }

            if (data.effectType === 0) {
                effectType = random(0, 13); //随机效果，从已有的效果选择一种进行切换
            }
        }

        //初始化dom节点
        function initElemData() {
            bannerSwipe = jm('#bannerSwipe');
            container = bannerSwipe.find('.bannerSwipeContainer');
            items = container.find('.billboard_item');
            itemsLen = items.length;
            var ul = jm('#bullets'),
                btns = ul.find('li');
            btns.removeClass('on');
            btns.eq(currentIndex).addClass('on');
        }
        //初始化banner HTML节点
        function initDom(bannerData) {
            var switchType = effectType,
                itemsData = bannerData.b,
                itemsLength = itemsData.length,
                itemCut = 0,
                i,
                j,
                q,
                w;
            switch (switchType) {
                case 9:
                case 10:
                    itemCut = 4;
                    break;
                case 11:
                case 12:
                case 13:
                    itemCut = 5;
                    break;
                // case 15:
                // case 16:
                // itemCut = 6;
                // break;
                default:
                    itemCut = 0;
            }
            var html = [];

            html.push("<div id='bannerSwipe' class='swipe'>");
            html.push(
                "<div id='bannerSwipeContainer' class='bannerSwipeContainer defaultBanner billboard billboard" +
                    switchType +
                    "'>"
            );
            var imgId, //图片id
                linkType, //链接类型
                jumpUrl, //跳转链接
                itemWidth, //图片宽度
                itemHeight, //图片高度
                type, //banner item类型
                path; //背景图片链接

            //循环banner结构
            for (i = 0; i < itemsLength; i++) {
                imgId = itemsData[i].i;
                linkType = itemsData[i].lt;
                jumpUrl = '';
                itemWidth = itemsData[i].w;
                itemHeight = itemsData[i].h;
                type = itemsData[i].t;
                path = itemsData[i].p;
                jumpUrl = itemsData[i].aj;
                q = 1;
                w = 1;
                var des = itemsData[i].des,
                    reqArgs = itemsData[i].reqArgs,
                    onclickStr = createUrlArgsOnclickStr(reqArgs); //横幅描述

                if (itemCut === 0) {
                    html.push(
                        "<div id='bannerItem" +
                            imgId +
                            "' class='billboardAnim billboard_item " +
                            (itemCut !== 0 ? 'billboard_item' + i : '') +
                            '  billboardItem_' +
                            switchType +
                            "' _des='" +
                            des +
                            "'>"
                    );
                    html.push(
                        '<a href="' +
                            (linkType !== 0 ? jm.encodeHtmlAttr(jumpUrl) : 'javascript:;') +
                            '" vheight=\'' +
                            itemHeight +
                            "' vwidth='" +
                            itemWidth +
                            "' class='bannerImageDiv J_img_lazyload " +
                            (type === 0 ? 'systemtBanner' : 'customBanner') +
                            "' style='display:block;background-image:url(" +
                            Fai.top._loadingPath +
                            ")' src-original='" +
                            path +
                            "' " +
                            onclickStr +
                            '></a>'
                    );
                } else if (itemCut === 4) {
                    html.push(
                        "<div class='billboard_item billboardItem_" +
                            switchType +
                            ' billboardItem_' +
                            switchType +
                            '_' +
                            (i + 1) +
                            "' _des='" +
                            des +
                            "'>"
                    );

                    for (w = 1; w < itemCut; w++) {
                        html.push("<div class='billboardTile billboardTile_" + w + " billboardAnim'>");
                        html.push("<div class='billboardImg'>");
                        html.push(
                            "<a class='J_img_lazyload bannerImageDiv J_bannerItem systemtBanner billboardImgInner " +
                                (type === 0 ? 'systemtBanner' : 'customBanner') +
                                "' hidefocus='true' title='' href=\"" +
                                (linkType !== 0 ? jm.encodeHtmlAttr(jumpUrl) : 'javascript:;') +
                                '" vheight=\'' +
                                itemHeight +
                                "' vwidth='" +
                                itemWidth +
                                "' target='' style='overflow: hidden; display: block; outline: none; margin: 0px auto; position: relative; z-index: 1;background-image:url(" +
                                Fai.top._loadingPath +
                                "); background-position: 50% 50%; background-repeat: no-repeat;' src-original='" +
                                path +
                                "' " +
                                onclickStr +
                                '>'
                        );
                        html.push('</a>');
                        html.push('</div>');
                        html.push('</div>');
                    }
                } else {
                    html.push(
                        "<div class='billboard_item billboardItem_" +
                            switchType +
                            ' billboardItem_' +
                            switchType +
                            '_' +
                            (i + 1) +
                            "' _des='" +
                            des +
                            "'>"
                    );

                    for (q = 1; q < itemCut; q++) {
                        html.push("<div class='billboardTile billboardTile_" + q + "'>");
                        html.push("<div class='billboardAnim billboardTileImg'>");
                        html.push("<div class='billboardImg'>");
                        html.push(
                            "<a class='J_img_lazyload bannerImageDiv J_bannerItem systemtBanner billboardImgInner " +
                                (type === 0 ? 'systemtBanner' : 'customBanner') +
                                "' hidefocus='true' title='' href=\"" +
                                (linkType !== 0 ? jm.encodeHtmlAttr(jumpUrl) : 'javascript:;') +
                                '" vheight=\'' +
                                itemHeight +
                                "' vwidth='" +
                                itemWidth +
                                "' target='' style='overflow: hidden; display: block; outline: none; margin: 0px auto; position: relative; z-index: 1;background-image:url(" +
                                Fai.top._loadingPath +
                                "); background-position: 50% 50%; background-repeat: no-repeat;' src-original='" +
                                path +
                                "' " +
                                onclickStr +
                                '>'
                        );
                        html.push('</a>');
                        html.push('</div>');
                        html.push('</div>');
                        html.push('</div>');
                    }
                }
                if (showDesc) {
                    html.push(
                        "<div class='bannerDescription'><div class='description'>" + encodeHtml(des) + '</div></div>'
                    );
                }

                html.push('</div>');
            }
            html.push('</div>');

            //循环点结构
            if (showDesc) {
                html.push(
                    "<div id='bannerBullet' class='bannerBullet2' style='" +
                        (itemsLength < 2 ? 'display:none;' : '') +
                        "' >"
                );
            } else {
                html.push(
                    "<div id='bannerBullet' class='bannerBullet' style='" +
                        (itemsLength < 2 ? 'display:none;' : '') +
                        "' >"
                );
            }
            html.push("<ul id='bullets' class='bullets'>");
            for (j = 0; j < itemsLength; j++) {
                html.push('<li ' + (j === currentIndex ? "class='on'" : '') + '></li>');
            }
            html.push('</ul>');
            html.push('</div>');
            html.push('</div>');

            jm('#webBanner').html(html.join(''));
        }

        //再随机样式时，dom节点插入导致特效切换时滚动条跳动
        function setOutWrapHeight() {
            var webBannerH = jm('#webBanner').height() || 0;
            if (data.effectType === 0 && webBannerH !== 0) {
                jm('#webBanner').css('height', webBannerH + 'px');
            } else {
                jm('#webBanner').css('height', '');
            }
        }

        //初始化添加样式
        function initStyle() {
            //初始化dom结构
            itemMaxWidth = container.width() || 0; //动画区域宽度
            itemMaxHeight = jm('#webBanner').height() || 0; //动画区域高度
            let itemMaxHeight1 = jm('#bannerSwipe').height() || 0; //动画区域高度

            //标记第一张图片，CSS中会做特殊处理
            var currentItem = items.eq(currentIndex);
            currentItem.addClass('billboardItem_' + effectType + '_start');
            var item, billboardAnim;
            if (data.effectIndex === 0) {
                //随机效果特殊处理
                container.addClass('billboard' + data.effectIndex); //添加真正的特效
            }

            for (var i = 0; i < items.length; i++) {
                item = items.eq(i);
                setVendorCss(item, 'animationDuration', data.switchingTime + 'ms');
                billboardAnim = item.find('.billboardAnim');
                switch (effectType) {
                    case 3:
                        setVendorCss(item, 'transformOrigin', '50% 50% ' + -itemMaxWidth / 2 + 'px');
                        break;
                    case 4:
                        setVendorCss(item, 'transformOrigin', '50% 50% ' + -itemMaxHeight / 2 + 'px');
                        break;
                    case 9:
                        setVendorCss(billboardAnim, 'transformOrigin', '50% 50% ' + -itemMaxWidth / 2 + 'px');
                        setVendorCss(billboardAnim, 'animationDuration', data.switchingTime + 'ms');
                        break;
                    case 10:
                        setVendorCss(
                            billboardAnim,
                            'transformOrigin',
                            '50% 50% ' + -item.find('.billboardAnim').height() / 2 + 'px'
                        );
                        setVendorCss(billboardAnim, 'animationDuration', data.switchingTime + 'ms');
                        item.css('height', itemMaxHeight1);
                        break;
                    case 11:
                    case 12:
                    case 13:
                        setVendorCss(billboardAnim, 'animationDuration', data.switchingTime + 'ms');
                        if (effectType === 11 || effectType === 12) {
                            var userAgent = navigator.userAgent.toLowerCase();
                            //360浏览器或者搜狗浏览器图片会模糊，改为contain。
                            if (userAgent.indexOf('se 2.x') > -1 || userAgent.indexOf('36') > -1) {
                                setVendorCss(billboardAnim.find('.customBanner'), 'backgroundSize', 'contain');
                            }
                        }
                        break;
                    default:
                }
            }

            items.css('display', 'none');
            currentItem.css('display', 'block');
        }
        //初始化事件绑定
        function initBind() {
            initStyle();
            clickBtn();
            initBannerTouch();
            play();
        }

        //执行动画
        function play() {
            var duration = defaultData.duration;
            if (_bannerData.atOnce && _bannerData.et !== 0) {
                duration = 1000;
            }
            _bannerData.atOnce = false;
            if (!animating) {
                clearTimeout(timer);
                timer = setTimeout(next, duration);
            }
        }
        //停止
        function stop() {
            if (!autoPlay) {
                return;
            }

            // autoPlay = false;
            clearTimeout(timer);
        }
        //点击li 事件绑定
        function clickBtn() {
            var bannerBullet = jm('#bannerBullet'),
                _bs = bannerBullet.find('li');
            _bs.click(function (e) {
                e.stopPropagation();
                var ii = _bs.index(this);
                stop();
                goTo(ii);
            });
        }

        //跳到另一个banner btn
        function goToBtn(newIndex) {
            var ul = jm('#bullets'),
                btns = ul.find('li');
            btns.removeClass('on');
            btns.eq(newIndex).addClass('on');
        }

        //跳到另一个banner
        function goToBanner(newIndex, reverseAnim) {
            var currentItem = items.eq(currentIndex),
                newItem = items.eq(newIndex);
            newItem.css('display', 'block');

            if (showDesc) {
                currentItem.find('.bannerDescription').css('display', 'none');
                newItem.find('.bannerDescription').css('display', 'block');
            }
            currentItem
                .removeClass('billboardItem_' + effectType + '_on billboardItem_' + effectType + '_on_reverse')
                .addClass('billboardItem_' + effectType + '_off');
            newItem
                .removeClass('billboardItem_' + effectType + '_off billboardItem_' + effectType + '_off_reverse')
                .addClass('billboardItem_' + effectType + '_on');
            if (reverseAnim) {
                currentItem.addClass('billboardItem_' + effectType + '_off_reverse');
                newItem.addClass('billboardItem_' + effectType + '_on_reverse');
            }
        }

        function goTo(newIndex, reverseAnim) {
            if (isNaN(newIndex) || newIndex >= itemsLen || newIndex < 0 || newIndex === currentIndex) {
                return;
            }
            if (animating) {
                return;
            }
            if (typeof reverseAnim === 'undefined') {
                reverseAnim = newIndex > currentIndex ? false : true;
            }
            var newItem = items.eq(newIndex);
            goToBtn(newIndex);
            goToBanner(newIndex, reverseAnim);
            oldIndex = currentIndex;
            currentIndex = newIndex;
            animating = true;

            addAnimationEndEvent(newItem[0], eventAnimationEnd);
        }

        //动画结束后处理
        function eventAnimationEnd() {
            if (removeAnimationEndEvent(items.eq(currentIndex)[0], eventAnimationEnd)) {
                animating = false;

                items.eq(oldIndex).css('display', 'none');
                if (showDesc) {
                    items.eq(oldIndex).find('.bannerDescription').css('display', 'none');
                }
                if (autoPlay) {
                    clearTimeout(timer);
                    if (data.effectType === 0) {
                        randomPlay();
                    } else {
                        timer = setTimeout(function () {
                            next();
                        }, defaultData.duration);
                    }
                } else {
                    if (data.effectType === 0) {
                        randomPlay();
                    }
                }
            }
        }

        //添加animationEnd事件，处理动画结束时需要处理的事务
        function addAnimationEndEvent(elem, endEvent) {
            elem.addEventListener('animationend', endEvent, false);
            elem.addEventListener('webkitAnimationEnd', endEvent, false);
            elem.addEventListener('oAnimationEnd', endEvent, false);
            elem.addEventListener('oanimationend', endEvent, false);
            elem.addEventListener('msAnimationEnd', endEvent, false);
            return true;
        }

        //移除animationEnd事件
        function removeAnimationEndEvent(elem, endEvent) {
            elem.removeEventListener('animationend', endEvent, false);
            elem.removeEventListener('webkitAnimationEnd', endEvent, false);
            elem.removeEventListener('oAnimationEnd', endEvent, false);
            elem.removeEventListener('oanimationend', endEvent, false);
            elem.removeEventListener('msAnimationEnd', endEvent, false);
            return true;
        }

        //下一个
        function next() {
            var tmpIndex = currentIndex + 1;
            if (tmpIndex >= items.length) {
                tmpIndex = 0;
            }
            goTo(tmpIndex, false);
        }

        //上一个
        function prev() {
            var tmpIndex = currentIndex - 1;
            if (tmpIndex < 0) {
                tmpIndex = items.length - 1;
            }
            goTo(tmpIndex, true);
        }

        var isScrolling, //是否移动
            startPos, //触摸开始位置
            endPos; //触摸结束位置
        //初始化触屏事件
        function initBannerTouch() {
            var isTouch =
                !!('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch);
            if (isTouch) {
                container[0].addEventListener('touchstart', touchStart, false); //addEventListener第二个参数可以传一个对象，会调用该对象的handleEvent属性
            }
        }

        //触屏开始
        function touchStart(event) {
            var touch = event.targetTouches[0]; //touches数组对象获得屏幕上所有的touch，取第一个touch
            isScrolling = 0; //这个参数判断是垂直滚动还是水平滚动
            startPos = { x: touch.pageX, y: touch.pageY, time: +new Date() }; //取第一个touch的坐标值
            container[0].addEventListener('touchmove', touchMove, false);
            container[0].addEventListener('touchend', touchEnd, false);
        }
        //触屏移动
        function touchMove(event) {
            //当屏幕有多个touch或者页面被缩放过，就不执行move操作
            if (event.targetTouches.length > 1 || (event.scale && event.scale !== 1)) {
                return;
            }
            var touch = event.targetTouches[0];

            endPos = {
                x: touch.pageX - startPos.x,
                y: touch.pageY - startPos.y,
                time: +new Date(),
            };
            isScrolling = Math.abs(endPos.x) < Math.abs(endPos.y) ? 1 : 0;
            if (isScrolling === 0) {
                //event.preventDefault();
            }
        }
        //触屏结束
        function touchEnd() {
            if (isScrolling === 0) {
                //remove icon class
                if (Number(endPos?.x) > 10 && Number(endPos?.y) < 40) {
                    prev();
                } else if (Number(endPos?.x) < -10 && Number(endPos?.y) < 40) {
                    next();
                }
            }
            container[0].removeEventListener('touchmove', this, false);
            container[0].removeEventListener('touchend', this, false);
        }

        //添加有浏览器厂商前缀的Style样式
        function setVendorCss(ele, cssName, cssValue) {
            var cssName2 = cssName.substring(0, 1).toUpperCase() + cssName.substring(1); //将参数css属性首字母变大写
            ele.css('Webkit' + cssName2, cssValue);
            ele.css('Moz' + cssName2, cssValue);
            ele.css('ms' + cssName2, cssValue);
            ele.css('O' + cssName2, cssValue);
            ele.css(cssName, cssValue);
        }

        //随机播放
        function randomPlay() {
            initData(_bannerData);
            initDom(_bannerData);
            initSwipe('bannerSwipe', _bannerData);
            initElemData();
            initBind();
        }
        //获取随机数
        function random(start, end) {
            var num = end - start + 1,
                ran = 0;
            while (ran === 0) {
                ran = Math.floor(Math.random() * num + start);
            }
            return ran;
        }

        return FUNC;
    })();
};

const checkBrowserFactory = function () {
    var animationProperties = ['animation', 'MozAnimation', 'webkitAnimation', 'msAnimation', 'OAnimation'],
        transformProperties = ['transform', 'MozTransform', 'webkitTransform', 'msTransform', 'OTransform'],
        opacityProperties = ['opacity', 'MozOpacity', 'webkitOpacity', 'msOpacity', 'OOpacity'],
        div = document.createElement('div'),
        props,
        result = {};

    return function (flag) {
        if (typeof result[flag] == 'boolean') {
            return result[flag];
        }
        switch (flag) {
            case 'animation':
                props = animationProperties;
                break;
            case 'transform':
                props = transformProperties;
                break;
            case 'opacity':
                props = opacityProperties;
                break;
            default:
                props = animationProperties;
        }
        //判断当前浏览器是否支持animation
        for (var i = 0, len = props.length; i < len; i++) {
            if (div.style[props[i]] !== undefined) {
                result[flag] = true;
                return result[flag];
            }
        }
        result[flag] = false;
        return result[flag];
    };
};

export const switchBannerData = function (data) {
    Fai.top.saveReloadPreview = true;

    if (!BannerAnimateFactory().init(data)) {
        initBannerDefaultDom(data);
        initSwipe('bannerSwipe', data);
    }
};
