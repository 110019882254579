<template>
    <div class="attachListItem--item">
        <a target="_blank" @click="changeTheUrlToAdaptApple">
            <div class="attachListItem">
                <div class="attachFileType">
                    <div :class="info.fileIcon"></div>
                </div>
                <div class="fileContent">
                    <div class="fileContentName">{{ info.name }}</div>
                    <div class="fileContentSize">{{ info.fileSizeOut }}</div>
                </div>
            </div>
        </a>
        <template v-if="showViewPDF">
            <a target="_blank" class="attachListViewIcon" :href="pdfPath" @click="log">
                <svg class="jzm-vicon_preview">
                    <use xlink:href="#jzm-vicon_preview" />
                </svg>
            </a>
        </template>
    </div>
</template>

<script>
import { newsDetailMixins } from '../mixins';
import { changeTheUrlToAdaptApple } from '@/modules/news/util';
import { preprocessUrl, encodeUrl } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
import { ing } from '@/shared/tips';
import { format } from '@/shared/fai';
import { fileDownloadCdnLimitHandler } from '@/shared/fileDownload';
export default {
    name: 'AttachItem',
    mixins: [newsDetailMixins],
    inject: ['moduleId', 'manageMode'],
    props: {
        info: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        let isAndroid = false;
        if (typeof window != 'undefined') {
            let sUserAgent = window.navigator.userAgent.toLowerCase();
            isAndroid = sUserAgent.match(/android/i) == 'android';
        }

        return {
            isAndroid,
        };
    },
    computed: {
        extName() {
            let name = this.info.name;
            return name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        },
        showViewPDF() {
            return this.extName.toLocaleLowerCase().includes('pdf') && !this.isAndroid;
        },
        isNeedLogin() {
            return this.options.isNeedLogin;
        },
        currentNewsAttachAllow() {
            return this.options.currentNewsAttachAllow;
        },
        pdfPath() {
            return this.isNeedLogin || (!this.currentNewsAttachAllow && !this.manageMode) ? null : this.info.path;
        },
        memberName() {
            return this.options.memberName;
        },
    },
    methods: {
        changeTheUrlToAdaptApple() {
            fileDownloadCdnLimitHandler();
            if (this.isNeedLogin && !this.manageMode) {
                changeHref(
                    preprocessUrl({ path: '', oldPath: '' }) +
                        `login.jsp?errno=-3001&returnUrl=${encodeUrl(location.href)}`
                );
            } else if (!this.currentNewsAttachAllow && !this.manageMode) {
                ing(format(LS.site_file_down_group_tip, this.memberName));
                return;
            } else {
                let { downloadUrl, subUrl } = this.info;
                changeTheUrlToAdaptApple(downloadUrl, subUrl);
            }
        },
        log() {
            !(VITE_APP_MODE !== 'visitor') && this.$logDog(201610, 1);
        },
    },
};
</script>

<style></style>
