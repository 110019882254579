import cusHistory from '../cusHistory';
import { mobiIng } from '@/shared/fai';
import { setCimg, setProfileInfo } from '@/api/member';
import { deviceTypeIsMobi } from '@/features/global-mobi-function/methods/linkSetting';

let memberHeadPic = function (sessionMid, headPicJson) {
    this.sessionMid = sessionMid;
    this.headPicJson = headPicJson;
};

//构造ShoppingCart 对象
var PRO = memberHeadPic.prototype;

var areaH, areaW, coverImgTop, coverImgLeft, imgTop, imgLeft, coverImgWidth;
//初始化函数
PRO.init = function () {
    this.initBind();
};

//绑定初始化函数
PRO.initBind = function () {
    var self = this;
    var coverImg = jm('.picArea .coverImg'),
        area = jm('.picArea'),
        headPic = jm('#headPic');

    (coverImg = jm('.picArea .coverImg')), (area = jm('.picArea')), (headPic = jm('#headPic'));

    headPic.on({
        load() {
            imgTop = jm(this)[0].offsetTop;
            imgLeft = jm(this)[0].offsetLeft;

            coverImgWidth = Math.min(jm(this)[0].height, jm(this)[0].width);
            coverImgTop = imgTop + jm(this)[0].height / 2 - coverImgWidth / 2;
            coverImgLeft = imgLeft + jm(this)[0].width / 2 - coverImgWidth / 2;

            headPic.css({ top: imgTop + 'px', left: imgLeft + 'px' });
            moveViewArea();
        },
    });

    // 编辑头像
    jm('#memberHeadPic').click(function (event, historyCash) {
        var timer = setInterval(function () {
            if (jm('#headPic')[0].complete) {
                jm('#profile').hide();
                jm('.middleCenter').css('padding-bottom', '0');
                // 隐藏底部导航 - 遮住保存按钮
                if (jm('#p_footer_nav_wrap').css('display') === 'none') {
                    jm('#p_footer_nav_wrap').attr('data-display', 'none');
                } else {
                    jm('#p_footer_nav_wrap').hide();
                }
                var e = jm('#editMemberHeadPic');
                e.css('height', jm(window).height() + 'px');
                jm('#editMemberHeadPic').show();
                if (!deviceTypeIsMobi()) {
                    jm('#coverBox').remove();
                }
                area.css(
                    'height',
                    jm(window).height() -
                        Math.floor(jm('.loginHeader').height()) -
                        Math.floor(jm('.editHeadPicFooter').height()) +
                        'px'
                );
                areaH = Number(area.height());
                areaW = Number(area.width());
                imgTop = (areaH - headPic.height()) / 2;
                imgLeft = (areaW - headPic.width()) / 2;

                if (jm.toJSON(self.headPicJson) == '{}' || self.headPicJson.thumbId == '') {
                    coverImgTop = imgTop;
                    coverImgLeft = imgLeft;
                    coverImgWidth = Number(headPic.width());
                } else {
                    var scale = self.headPicJson.imgW / headPic.width();
                    coverImgTop = imgTop + self.headPicJson.top / scale;
                    coverImgLeft = imgLeft + self.headPicJson.left / scale;
                    coverImgWidth = self.headPicJson.width / scale;
                }
                headPic.css({ top: imgTop + 'px', left: imgLeft + 'px' });
                changePosition();
                moveViewArea();
                clearInterval(timer);

                cusHistory.add('memberHeadPic', historyCash, 1, '#memberHeadPic');
            }
        }, 50);
    });

    // 主题3.0编辑头像
    jm('.J_memberHeadPicV3').click(function (historyCash) {
        var timer = setInterval(function () {
            if (jm('#headPic')[0].complete) {
                jm('#profile').hide(); // 会员中心面板隐藏
                jm('#modifyPersInfo').hide(); // 个人资料隐藏
                // 隐藏底部导航 - 遮住了保存按钮
                if (jm('#p_footer_nav_wrap').css('display') === 'none') {
                    jm('#p_footer_nav_wrap').attr('data-display', 'none');
                } else {
                    jm('#p_footer_nav_wrap').hide();
                }
                jm('.middleCenter').css('padding-bottom', '0');
                var e = jm('#editMemberHeadPic');
                e.css('height', jm(window).height() + 'px');
                jm('#editMemberHeadPic').show();
                if (!deviceTypeIsMobi()) {
                    jm('#coverBox').remove();
                }
                area.css(
                    'height',
                    jm(window).height() -
                        Math.floor(jm('.loginHeader').height()) -
                        Math.floor(jm('.editHeadPicFooter').height()) +
                        'px'
                );
                areaH = Number(area.height());
                areaW = Number(area.width());
                imgTop = (areaH - headPic.height()) / 2;
                imgLeft = (areaW - headPic.width()) / 2;

                if (jm.toJSON(self.headPicJson) == '{}' || self.headPicJson.thumbId == '') {
                    coverImgTop = imgTop;
                    coverImgLeft = imgLeft;
                    coverImgWidth = Number(headPic.width());
                } else {
                    var scale = self.headPicJson.imgW / headPic.width();
                    coverImgTop = imgTop + self.headPicJson.top / scale;
                    coverImgLeft = imgLeft + self.headPicJson.left / scale;
                    coverImgWidth = self.headPicJson.width / scale;
                }
                headPic.css({ top: imgTop + 'px', left: imgLeft + 'px' });
                changePosition();
                moveViewArea();
                clearInterval(timer);
                cusHistory.add('memberHeadPicV3', historyCash, 2, '.J_memberHeadPicV3');
            }
        }, 50);
    });

    jm('.editHeadPicFooter .saveBtn').click(async function () {
        var mid = jm(this).attr('mid');
        var newImg = jm(this).attr('newimg');
        var oldImgId = jm(this).attr('oldimgid');
        var info = {};
        if (!Fai.top._openThemeV3) {
            //3.0以前的会员中心页面点击头像会直接发送ajax请求直接修改头像
            if (!newImg && !oldImgId) {
                jm('#editMemberHeadPic').hide();
                jm('#profile').show();
                return;
            }
            // self.headPicJson.path = headPic.attr('src');
            self.headPicJson.width = Math.round(coverImgWidth);
            self.headPicJson.top = Math.round(coverImgTop - imgTop);
            self.headPicJson.left = Math.round(coverImgLeft - imgLeft);
            self.headPicJson.imgH = Math.round(headPic[0].height);
            self.headPicJson.imgW = Math.round(headPic[0].width);
            if (newImg != null) {
                try {
                    let result = await setCimg({
                        oldImgId,
                        mid,
                        newImg,
                    });
                    if (result.success) {
                        self.headPicJson.thumbId = result.fileId;
                    }
                } catch (e) {
                    console.log(e);
                    mobiIng(LS.memberPvrofileError);
                }
            } else {
                self.headPicJson.thumbId = oldImgId;
            }
            info['headPic'] = self.headPicJson;
            setProfileInfo({
                id: mid,
                info: jm.toJSON(info),
            })
                .catch(() => {
                    mobiIng(LS.memberProfileError);
                })
                .then((data) => {
                    if (data.success) {
                        self.headPicJson.path = headPic.attr('src');
                        initMemberHeadPic(self.headPicJson);
                        jm('#editMemberHeadPic').hide();
                        jm('#profile').show();
                        mobiIng(LS.saveMemHeadImgSuc, 1);
                        jm('#p_footer_nav_wrap').show();
                    } else {
                        mobiIng(data.msg ? data.msg : LS.memberProfileError);
                    }
                });
        }
    });

    // 主题3.0编辑头像点击保存
    jm('.editHeadPicFooterV3 .saveBtn').click(function () {
        var mid = jm(this).attr('mid');
        var newImg = jm(this).attr('newimg');
        var oldImgId = jm(this).attr('oldimgid');
        var info = {};
        if (!newImg && !oldImgId) {
            jm('#editMemberHeadPic').hide();
            if (!Fai.top._openThemeV3) {
                jm('#profile').show();
            } else if (Fai.top._openThemeV3) {
                jm('#profile').hide();
                jm('#modifyPersInfo').show();
            }
            jm('#p_footer_nav_wrap').show();
            return;
        }
        // self.headPicJson.path = headPic.attr('src');
        self.headPicJson.width = Math.round(coverImgWidth);
        self.headPicJson.top = Math.round(coverImgTop - imgTop);
        self.headPicJson.left = Math.round(coverImgLeft - imgLeft);
        self.headPicJson.imgH = Math.round(headPic[0].height);
        self.headPicJson.imgW = Math.round(headPic[0].width);
        /**
         * 在3.0主题中一个特殊的案例, 即商城的3.0版本, 这个版本没有会员资料的保存按钮,
         * 更改即保存, 在此使用Fai.top._profile判断是否是商城3.0, 这个变量会伴随3.0会员中心
         * vue模块一起生成,特此说明
         *
         */
        if (!Fai.top._openThemeV3 || Fai.top._profile) {
            if (newImg != null) {
                setCimg({
                    oldImgId,
                    mid,
                    newImg,
                })
                    .catch(() => {
                        mobiIng(LS.memberProfileError);
                    })
                    .then((result) => {
                        if (result.success) {
                            self.headPicJson.thumbId = result.fileId;
                        }
                    });
            } else {
                self.headPicJson.thumbId = oldImgId;
            }
            info['headPic'] = self.headPicJson;
            setProfileInfo({
                id: mid,
                info: jm.toJSON(info),
            })
                .catch(() => {
                    mobiIng(LS.memberProfileError);
                })
                .then((data) => {
                    if (data.success) {
                        self.headPicJson.path = headPic.attr('src');
                        if (Fai.top._profile) {
                            jm('#modifyPersInfo').show();
                        } else jm('#profile').show();
                        initMemberHeadPicV3(self.headPicJson);
                        jm('#editMemberHeadPic').hide();
                        jm('#p_footer_nav_wrap').show();
                        mobiIng(LS.saveMemHeadImgSuc, 1);
                    } else {
                        mobiIng(data.msg ? data.msg : LS.memberProfileError);
                    }
                });
            Fai.top.msgHeadPic = self.headPicJson;
        } else {
            jm('#profile').hide();
            jm('#editMemberHeadPic').hide();
            jm('#modifyPersInfo').show();
            jm('#p_footer_nav_wrap').show();
            self.headPicJson.path = headPic.attr('src');
            initMemberMsgHeadPicV3(self.headPicJson);
            Fai.top.msgHeadPic = self.headPicJson; // 在个人资料修改的头像，先保存起来，等用户按了保存按钮，再发送ajax请求改变头像数据
        }
    });

    // 移动可视区域 start
    var startCoverImgTop,
        startCoverImgLeft,
        startCoverImgWidth,
        startFingerDist,
        startPosition,
        endPosition,
        deltaX,
        deltaY;
    jm('.picArea').on({
        touchstart(e) {
            var touchTarget = e.touches.length; //获得触控点数
            startCoverImgTop = coverImg[0].offsetTop;
            startCoverImgLeft = coverImg[0].offsetLeft;
            coverImgWidth = coverImg[0].width;
            if (touchTarget == 1) {
                e.preventDefault();
                var touch = e.touches[0];
                startPosition = {
                    x: touch.pageX,
                    y: touch.pageY,
                };
            } else {
                startFingerDist = getTouchDist(e).dist;
                startCoverImgWidth = coverImgWidth;
            }
        },

        touchmove(e) {
            var touchTarget = e.touches.length; //获得触控点数
            //alert(touchTarget);
            if (touchTarget == 1) {
                e.preventDefault();
                var touch = e.touches[0];
                endPosition = {
                    x: touch.pageX,
                    y: touch.pageY,
                };
                deltaX = endPosition.x - startPosition.x;
                deltaY = endPosition.y - startPosition.y;
                coverImgTop = startCoverImgTop + deltaY;
                coverImgLeft = startCoverImgLeft + deltaX;

                moveViewArea();
            } else {
                var nowFingerDist = getTouchDist(e).dist, //获得当前长度
                    ratio = nowFingerDist / startFingerDist; //计算缩放比

                coverImgWidth = Math.round(startCoverImgWidth * ratio);
                coverImgTop = startCoverImgTop + (startCoverImgWidth - coverImgWidth) / 2;
                coverImgLeft = startCoverImgLeft + (startCoverImgWidth - coverImgWidth) / 2;

                moveViewArea();
            }
        },
    });
    var isMove = false;
    jm('body').on('mouseup', function () {
        isMove = false;
    });
    jm('.picArea').on(
        {
            touchstart(e) {
                startCoverImgTop = coverImg[0].offsetTop;
                startCoverImgLeft = coverImg[0].offsetLeft;
                startCoverImgWidth = coverImg[0].width;
                let touch = e.touches[0];
                startPosition = {
                    x: touch.pageX,
                    y: touch.pageY,
                };
                isMove = true;
                var targetId = e.target.id;
                //alert(targetId);
                if (targetId == 'coverBox' || targetId == 'coverImg') {
                    //左上角固定
                    moveDragPoint(0);
                } else if (targetId == 'dragBotCenter' || targetId == 'dragRightCenter' || targetId == 'dragRightBot') {
                    //左上角固定
                    moveDragPoint(1);
                } else if (targetId == 'dragLeftCenter' || targetId == 'dragLeftBot') {
                    //右上角固定
                    moveDragPoint(2);
                } else if (targetId == 'dragTopCenter' || targetId == 'dragRightTop') {
                    //左下角固定
                    moveDragPoint(3);
                } else if (targetId == 'dragLeftTop') {
                    //右下角固定
                    moveDragPoint(4);
                }
            },
            touchend() {
                isMove = false;
            },
        },
        '#coverBox,#coverImg'
    );

    function moveDragPoint(flag) {
        document.ontouchmove = function (e) {
            if (!isMove) return;
            let touch = e.touches[0];
            endPosition = {
                x: touch.pageX,
                y: touch.pageY,
            };
            deltaX = endPosition.x - startPosition.x;
            deltaY = endPosition.y - startPosition.y;

            switch (flag) {
                case 0:
                    //移动
                    coverImgWidth = startCoverImgWidth;
                    coverImgTop = startCoverImgTop + deltaY;
                    coverImgLeft = startCoverImgLeft + deltaX;
                    break;
                case 1:
                    //左上角固定缩放
                    coverImgWidth = startCoverImgWidth + Math.max(deltaX, deltaY);
                    coverImgTop = startCoverImgTop;
                    coverImgLeft = startCoverImgLeft;
                    break;
                case 2:
                    //右上角固定缩放
                    coverImgWidth = startCoverImgWidth - deltaX;
                    coverImgTop = startCoverImgTop;
                    coverImgLeft = startCoverImgLeft + deltaX;
                    break;
                case 3:
                    //左下角固定缩放
                    coverImgWidth = startCoverImgWidth + deltaX;
                    coverImgTop = startCoverImgTop - deltaX;
                    coverImgLeft = startCoverImgLeft;
                    break;
                case 4:
                    //右下角固定缩放
                    coverImgWidth = startCoverImgWidth - deltaX;
                    coverImgTop = startCoverImgTop + deltaX;
                    coverImgLeft = startCoverImgLeft + deltaX;
                    break;
            }
            moveViewArea();
        };
    }
};

//调整可视区域的位置
function changePosition() {
    jm('.coverImg').css({
        width: coverImgWidth + 'px',
        height: coverImgWidth + 'px',
        top: imgTop + 'px',
        left: imgLeft + 'px',
    });
    jm('.picArea .coverRing').css({
        width: coverImgWidth - 2 + 'px',
        height: coverImgWidth - 2 + 'px',
        top: imgTop + 'px',
        left: imgLeft + 'px',
    });
    jm('.picArea .coverBox').css({
        width: coverImgWidth + 'px',
        height: coverImgWidth + 'px',
        top: imgTop - 1 + 'px',
        left: imgLeft - 1 + 'px',
    });
    jm('.picArea .cover1').css({
        width: '100%',
        height: coverImgTop + 'px',
        top: 0,
        left: 0,
    });
    jm('.picArea .cover2').css({
        width: imgLeft + 'px',
        height: coverImgWidth + 'px',
        top: imgTop + 'px',
        left: 0,
    });
    jm('.picArea .cover4').css({
        width: areaW - coverImgWidth - imgLeft + 'px',
        height: coverImgWidth + 'px',
        top: imgTop + 'px',
        left: coverImgWidth + imgLeft + 'px',
    });
    jm('.picArea .cover5').css({
        width: '100%',
        height: areaH - coverImgWidth - imgTop + 'px',
        top: coverImgWidth + imgTop + 'px',
        left: 0,
    });
}

function moveViewArea() {
    var coverImg = jm('.picArea .coverImg'),
        headPic = jm('#headPic');
    if (coverImgWidth < 100) {
        coverImgWidth = 100;
    } else if (coverImgWidth > Math.min(headPic[0].height, headPic[0].width)) {
        coverImgWidth = Math.min(headPic[0].height, headPic[0].width);
    }
    if (coverImgTop < imgTop) {
        coverImgTop = imgTop;
    } else if (coverImgTop > imgTop + headPic[0].height - coverImgWidth) {
        coverImgTop = imgTop + headPic[0].height - coverImgWidth;
    }

    if (coverImgLeft < imgLeft) {
        coverImgLeft = imgLeft;
    } else if (coverImgLeft > imgLeft + headPic[0].width - coverImgWidth) {
        coverImgLeft = imgLeft + headPic[0].width - coverImgWidth;
    }
    if (
        coverImgTop >= imgTop &&
        coverImgTop <= imgTop + headPic[0].height - coverImgWidth &&
        coverImgLeft >= imgLeft &&
        coverImgLeft <= imgLeft + headPic[0].width - coverImgWidth
    ) {
        coverImg.css({
            width: coverImgWidth + 'px',
            height: coverImgWidth + 'px',
            top: coverImgTop + 'px',
            left: coverImgLeft + 'px',
        });
        jm('.picArea .coverRing').css({
            width: coverImgWidth - 2 + 'px',
            height: coverImgWidth - 2 + 'px',
            top: coverImgTop + 'px',
            left: coverImgLeft + 'px',
        });
        jm('.picArea .coverBox').css({
            width: coverImgWidth + 'px',
            height: coverImgWidth + 'px',
            top: coverImgTop - 1 + 'px',
            left: coverImgLeft - 1 + 'px',
        });

        jm('.picArea .cover1').css({
            width: '100%',
            height: coverImgTop + 'px',
            top: 0,
            left: 0,
        });
        jm('.picArea .cover2').css({
            width: coverImgLeft + 'px',
            height: coverImgWidth + 'px',
            top: coverImgTop + 'px',
            left: 0,
        });
        jm('.picArea .cover4').css({
            width: areaW - coverImgWidth - coverImgLeft + 'px',
            height: coverImgWidth + 'px',
            top: coverImgTop + 'px',
            left: coverImgWidth + coverImgLeft + 'px',
        });
        jm('.picArea .cover5').css({
            width: '100%',
            height: areaH - coverImgWidth - coverImgTop + 'px',
            top: coverImgWidth + coverImgTop + 'px',
            left: 0,
        });
    }
}
function getTouchDist(e) {
    var x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0,
        x3 = 0,
        y3 = 0,
        result = {};

    x1 = e.touches[0].pageX;
    x2 = e.touches[1].pageX;
    y1 = e.touches[0].pageY - document.body.scrollTop;
    y2 = e.touches[1].pageY - document.body.scrollTop;

    if (!x1 || !x2) return;

    if (x1 <= x2) {
        x3 = (x2 - x1) / 2 + x1;
    } else {
        x3 = (x1 - x2) / 2 + x2;
    }
    if (y1 <= y2) {
        y3 = (y2 - y1) / 2 + y1;
    } else {
        y3 = (y1 - y2) / 2 + y2;
    }

    result = {
        dist: Math.round(Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))),
        x: Math.round(x3),
        y: Math.round(y3),
    };
    return result;
}

// 主题3.0会员中心页面头像
export const initMemberHeadPicV3 = function (options) {
    var img = jm('#memberHeadPicV3,.memberMsgHeadPicV3'),
        icon = jm('.icon-pnameIcon');
    if (typeof options.path != 'undefined') {
        img.attr('src', options.path);
    }
    if (options.width != 0 && options.imgW > 0 && options.imgH > 0) {
        var scale = options.width / icon.width(),
            marginTop = -(options.top / scale) + 'px',
            marginLeft = -options.left / scale + 'px';
        img.css({
            width: options.imgW / scale + 'px',
            height: options.imgH / scale + 'px',
            marginTop,
            marginLeft,
        });
        if (options.imgW / scale == 0 || options.imgH / scale == 0) {
            img.removeAttr('style');
        }
    }
};

// 主题3.0个人资料页面会员头像
const initMemberMsgHeadPicV3 = function (options) {
    var img = jm('.memberMsgHeadPicV3'),
        icon = jm('.icon-pnameIcon');
    if (typeof options.path != 'undefined') {
        img.attr('src', options.path);
    }
    if (options.width != 0 && options.imgW > 0 && options.imgH > 0) {
        var scale = options.width / icon.width(),
            marginTop = -(options.top / scale) + 'px',
            marginLeft = -options.left / scale + 'px';
        img.css({
            width: options.imgW / scale + 'px',
            height: options.imgH / scale + 'px',
            marginTop,
            marginLeft,
        });
        if (options.imgW / scale == 0 || options.imgH / scale == 0) {
            img.removeAttr('style');
        }
    }
};

// 会员头像
const initMemberHeadPic = function (options) {
    var img = jm('#memberHeadPic'),
        icon = jm('.icon-pnameIcon');
    if (typeof options.path != 'undefined') {
        img.attr('src', options.path);
    }
    if (options.width != 0) {
        var scale = options.width / icon.width(),
            marginTop = -(options.top / scale) + 'px',
            marginLeft = -options.left / scale + 'px';
        img.css({
            width: options.imgW / scale + 'px',
            height: options.imgH / scale + 'px',
            marginTop,
            marginLeft,
        });
        //如果是默认头像会width=240,走进这里，然后width和height都被置为0,导致点击无效
        if (options.imgW / scale == 0 || options.imgH / scale == 0) {
            img.removeAttr('style');
        }
    }
};

export default memberHeadPic;
