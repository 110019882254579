import { PopupZone } from '@/modules/popupZone/module';

let popupZone = PopupZone;

let popupZoneRender = popupZone.render();

export function registerPopupZone(Vue) {
    Vue.prototype.$createPopupZone = (moduleId, domId, store) => {
        return new Vue({
            el: domId,
            store: store || window.$store,
            render(h) {
                return h(popupZoneRender, {
                    props: {
                        moduleId: moduleId,
                    },
                });
            },
        });
    };
}
