import { MobiModule } from '@/modules/Module';
import { MODULE_STYLE } from '@/def/module';
import ProductResultView from './module/index.vue';

class _ProductResult extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PRODUCT_RESULT, options);
    }

    render() {
        return ProductResultView;
    }
}

export const ProductResult = new _ProductResult();
