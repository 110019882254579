<template>
    <div
        :id="newsLineId"
        :topClassname="topClassName"
        :topSwitch="topSwitch"
        :newsId="id"
        :newsName="title"
        class="newsLine line icon-gline J_picNum"
        :class="newsLineClassNames"
        picnum="2"
    >
        <a
            class="lineBodyLink"
            hidefocus="true"
            :href="href"
            :target="target"
            style="width: auto"
            :onclick="onclickStr"
        >
            <div :id="lineBodyId" class="lineBody lineBodyPic">
                <div v-if="topFlagClass" class="topFlagTableCell">
                    <div :class="topFlagClass"></div>
                </div>
                <template v-if="topicPicPosition === 1">
                    <div v-if="!pictureId" class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg icon-noFigureS" :style="picCellStyle"></div>
                    </div>
                    <div v-else class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg" :style="picCellStyle"></div>
                    </div>
                </template>
                <div class="fontTableCell">
                    <div class="fontHiddenTable">
                        <div class="twoLineHidden">
                            {{ title }}
                        </div>
                    </div>
                </div>
                <template v-if="topicPicPosition === 2">
                    <div v-if="!pictureId" class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg icon-noFigureS" :style="picCellStyle"></div>
                    </div>
                    <div v-else class="picTableCell" :style="picTableCellStyle">
                        <div :id="newsImgId" :alt="alt" class="newsLeftImg" :style="picCellStyle"></div>
                    </div>
                </template>
            </div>
        </a>
        <div
            v-if="showNewsElementsRequire"
            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
            @click="gotoHref"
        >
            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
            </div>
            <div v-if="showAuthorRequire" class="ne_newsAuthor faisco-icons-author" :style="newsAuthorStyle">
                <span class="newsAuthorName">{{ newsAuthor }}</span>
            </div>
            <div
                v-if="(showCommentRequire || showAuthorRequire) && (showSortRequire || showDateRequire)"
                class="ne_separatorLine g_ne_separatorLine"
            >
            </div>
            <div v-if="showSortRequire" class="ne_newsSort">{{ groupName }}</div>
            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
        </div>
        <template v-else>
            <div v-if="showCommentRequire" class="ne_newsComment faisco-icons-comment">
                <span class="newsCommentCount">{{ totalNewsCommentCount }}</span>
            </div>
            <div v-if="showAuthorRequire" class="ne_newsAuthor faisco-icons-author" :style="newsAuthorStyle">
                <span class="newsAuthorName">{{ newsAuthor }}</span>
            </div>
            <div v-if="(showCommentRequire || showAuthorRequire) && (showSortRequire || showDateRequire)">
                <div class="ne_separatorLine g_ne_separatorLine"></div>
            </div>
            <div v-if="showSortRequire">
                <div class="ne_newsSort">{{ groupName }}</div>
            </div>
            <div v-if="showDateRequire" class="ne_newsTime">{{ formattedNewsDate }}</div>
        </template>
    </div>
</template>

<script>
import newsMoreMixin from '../mixins/news-more-mixin';

export default {
    name: 'NewsMoreItemStyle2',
    mixins: [newsMoreMixin],
    data() {
        return {};
    },
    computed: {
        newsLineClassNames() {
            return `${this.lineClass}One ${this.topFlagLineClass} ${this.firstClass} ${
                this.showLine ? 'wWLineOne' : ''
            }`;
        },
        newsImgId() {
            return `newsImg${this.id}`;
        },
    },
};
</script>
