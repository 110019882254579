/**
 * @function 模块编辑工具集合
 * @description
 *		初始化 右键菜单
 **/
let FUNC = {};
// 模块工具集合初始化接口
FUNC.init = function (options) {
    if (Object.prototype.toString.call(options).toLowerCase() != '[object object]') {
        throw new Error('Mobi.moduleEditTool.init 参数错误');
    }

    var module = document.getElementById('module' + options.id),
        $module = $(module);

    if ($module.length < 1) {
        return;
    }

    $module
        .off('mousemove.moduleEdit')
        .on('mousemove.moduleEdit', function () {
            initLockEdit_mouseover(options);
        })
        .on('mouseleave.moduleEdit', function () {
            initLockEdit_mouseleave(options);
        });
};

// 解锁当前模块按钮
FUNC.clickForOpenEdit = function (id) {
    openCurrentModuleEdit(id);
    $(document.getElementById('module' + id))
        .children('.J_lockEditTip')
        .addClass('fk-openEditTip')
        .removeClass('fk-lockEditTip')
        .removeAttr('onclick');
};

// 绑定模块mouseover事件-锁定按钮的表现
function initLockEdit_mouseover(options) {
    // 检测锁定按钮，已解锁，则不需要执行以下的代码
    if (checkLockEditBtn(options.id)) {
        return;
    }

    showLockEditBtn(options.id);
}

// 绑定模块mouseleave事件-锁定按钮的表现
function initLockEdit_mouseleave(options) {
    setTimeout(function () {
        hideLockEditBtn(options.id);
    }, 100);
}

// 显示锁定按钮
function showLockEditBtn(id) {
    var module = document.getElementById('module' + id),
        $module = $(module);

    if ($module.length < 1) {
        return;
    }

    $module.children('.J_lockEditTip').show();
}

// 隐藏锁定按钮
function hideLockEditBtn(id) {
    var module = document.getElementById('module' + id),
        $module = $(module);

    if ($module.length < 1) {
        return;
    }

    $module.children('.J_lockEditTip').hide();
}

// 检测锁定按钮
function checkLockEditBtn(id) {
    var module = document.getElementById('module' + id),
        $module = $(module);

    if ($module.length < 1) {
        return;
    }

    if ($module.data('lockedit') != 'lock') {
        $module.children('.J_lockEditTip').remove();
        return true;
    } else {
        return false;
    }
}

// 解锁当前排版
function openCurrentModuleEdit(id) {
    var module = document.getElementById('module' + id),
        $module = $(module);

    $module.removeData('lockedit');
    // Mobi.removeMoveFrame('module' + id);
    $module.removeClass('formLockEdit');
    $module.resizable('enable');
}

export default FUNC;
