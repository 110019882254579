import { MobiModule } from '@/modules/Module';
import BannerModuleView from './module/index.vue';

class _Banner extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }
    render() {
        return BannerModuleView;
    }
}

export const Banner = new _Banner();
