import { changeHref, goBack } from '@/features/router/universal';

export const returnBack = function () {
    let isManageMode = VITE_APP_MODE !== 'visitor';
    if (
        document.referrer.indexOf('login.jsp') > 0 ||
        (isManageMode && window.$store.state.route.query.appId == 'login')
    ) {
        // history.go(-2);
        if (isManageMode) {
            window.$router.back();
        } else {
            changeHref('/');
        }
        return;
    }

    if (!isManageMode) {
        if (document.referrer === '') {
            location.href = '/';
            return;
        }
    }
    goBack();
};

export const isNull = (val) => {
    return typeof val == 'undefined' || val == null || !val;
};

export const isEmail = function (email) {
    var pattern = /^[a-zA-Z0-9][a-zA-Z0-9_=&\-.+]*[a-zA-Z0-9]*@[a-zA-Z0-9][a-zA-Z0-9_\-.]+[a-zA-Z0-9]$/;
    return pattern.test(email);
};
