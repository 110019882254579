export function formatModuleDataList(moduleDataList) {
    const moduleIdTable = moduleDataList.reduce((acc, module) => Object.assign(acc, { [module.id]: module }), {});

    return moduleDataList.map((module) => {
        if (!Array.isArray(module.inList)) return module;
        return {
            ...module,
            inList: module.inList.map((id) => moduleIdTable[id]),
        };
    });
}
