import { getModuleAttr } from '@/features/global-mobi-function/methods/util';

export const isModuleV2 = (styleId) => {
    return window.isModuleV2Map[`style${styleId}`] || true;
};

export const getModuleAttrPattern = (moduleId) => {
    let attr = getModuleAttr(moduleId) || {};
    return attr.pattern || {};
};
