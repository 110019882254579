/**
 * @fileoverview
 * 访客态统计代码
 */

import { visited } from '@/api/statistics';
import { getBrowserType, getScreenType, Screen, getMobiOSType } from '@/shared/browser';
import { fkEval } from '@/shared/fai';

export function statisticalMobiVisitors() {
    const options = {
        colId: window.statisticOptions.colId,
        pdId: window.statisticOptions.pdId,
        ndId: window.statisticOptions.ndId,
        sc: window.statisticOptions.shortcut,
        statId: window.statisticOptions.statId,
    };

    var defaults = {
        colId: -1,
        pdId: -1,
        ndId: -1,
    };
    var opt = jm.extend({}, defaults, options);

    if (opt.colId == null || opt.colId == '' || opt.colId < 0) {
        //栏目ID
        opt.colId = -1;
    }
    if (opt.pdId == null || opt.pdId == '' || opt.pdId < 0) {
        //产品ID
        opt.pdId = -1;
    }
    if (opt.ndId == null || opt.ndId == '' || opt.ndId < 0) {
        //新闻ID
        opt.ndId = -1;
    }

    var visitEquipment = '',
        u = navigator.userAgent;
    if (!u.match(/AppleWebKit.*Mobile.*/) && !u.match(/ipad/i)) {
        visitEquipment = 'pc';
    } else if (u.match(/ipad/i)) {
        visitEquipment = 'ipad';
    } else if (u.match(/android/i)) {
        visitEquipment = 'android';
    } else if (u.match(/iphone/i)) {
        visitEquipment = 'iphone';
    } else {
        visitEquipment = 'other';
    }

    var data = [];

    var browserType,
        screenType,
        windowDesktop = false;
    //管理态下的统计使用site  访客的使用Mobi的
    if (VITE_APP_MODE !== 'visitor') {
        browserType = Fai.top.Fai.getBrowserType();
        screenType = Fai.top.Fai.getScreenType(Fai.top.Fai.Screen().width, Fai.top.Fai.Screen().height);
    } else {
        browserType = getBrowserType();
        screenType = getScreenType(Screen().width, Screen().height);
        //访客态下的windowDesktop下不统计
        windowDesktop = jm.os.WINDOW_DESKTOP;
    }

    data.push('&colId=' + opt.colId);
    data.push('&pdId=' + opt.pdId);
    data.push('&ndId=' + opt.ndId);
    data.push('&windowDesktop=' + windowDesktop);
    data.push('&mobiOSType=' + getMobiOSType());
    data.push('&browserType=' + browserType);
    data.push('&screenType=' + screenType);
    data.push('&rf=' + jm.encodeUrl(opt.rf || document.referrer));
    data.push('&sc=' + opt.sc);
    data.push('&visitUrl=' + jm.encodeUrl(document.location.href));
    data.push('&visitEquipment=' + visitEquipment);
    data.push('&statId=' + opt.statId);

    visited(data.join('')).then((data) => {
        if (data && data.scripts) {
            fkEval(data.scripts);
        }
    });
}

export function mobiStatVisitTime() {
    window.onbeforeunload = function (event) {
        var unloadTime = event.timeStamp;
        var acceptURL = '/ajax/mobistatistics_h.jsp?cmd=wafNotCk_visitTime';
        var data = [];
        data.push('unloadTime=' + unloadTime);
        data.push('&statId=' + window.statisticOptions.statId);
        jm.ajax({
            url: acceptURL,
            type: 'post',
            cache: false,
            data: data.join(''),
            success() {},
        });
    };
}
