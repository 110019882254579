import { installMobileAuthPopup } from '@jz/biz-shared';
import { getAuthCode, checkVisitorsRealName, validateAuthCode, getCaptchaAccessKey } from '@/api/mobileAuth';
import { mobiIng } from '@/shared/fai';
import { logJZFdp } from '@/api/logDog';

const DEFAULT_CAPTCHA_TYPE = 1; // 1: 滑块验证码 2: 旋转验证码

/**
 *
 * 手机实名认证弹窗服务
 *
 * @example
 *
 * const mobileAuthPopupService = new MobileAuthPopupService();
 * const needAuth = await mobileAuthPopupService.needAuth(messageType);
 * if (needAuth) {
 *      mobileAuthPopupService.createSubmitHandler(() => {
 *          // ...... submit success callback
 *      }, () => {
 *          // ...... submit fail callback
 *      });
 *      mobileAuthPopupService.getInstance().show();
 * }
 *
 */
export class MobileAuthPopupService {
    constructor(themeColor = '') {
        this.themeColor = themeColor;
        this._activeMessageType = -1;
    }

    /**
     *
     * @param {number} messageType - MOBILE_AUTH_MESSAGE_TYPES
     */
    _log(props = {}) {
        const messageValues = {
            [MOBILE_AUTH_MESSAGE_TYPES.MSG_BOARD]: '留言板',
            [MOBILE_AUTH_MESSAGE_TYPES.PRODUCT_COMMENT]: '产品评论',
            [MOBILE_AUTH_MESSAGE_TYPES.NEWS_COMMENT]: '文章评论',
        };
        logJZFdp('jz_c_comment_realname', {
            jz_content_terminal: 'mobi',
            jz_free_text1: '触发成功',
            jz_free_text0: messageValues[this._activeMessageType] || '无',
            jz_version: window.jzVersion,
            ...props,
        });
    }

    async _genCaptchaAccessKey(captchaType = DEFAULT_CAPTCHA_TYPE) {
        try {
            const data = await getCaptchaAccessKey(captchaType);
            return data.acKceEssY;
        } catch (error) {
            console.error(error);
            mobiIng('服务繁忙');
            return '';
        }
    }

    async _getAuthCode(phone, validator = { checkSign: void 0, accessKey: void 0 }) {
        let result = {
            needCaptcha: false,
            success: false,
        };
        try {
            let params = {
                phoneNumber: phone,
            };

            if (validator.accessKey) params.accessKey = validator.accessKey;
            if (validator.checkSign) params.checkSign = validator.checkSign;

            const data = await getAuthCode(params);
            if (data.success === false) {
                if (data.captcha === true) {
                    result.needCaptcha = true;
                } else {
                    mobiIng(data.msg);
                }
                return result;
            } else {
                if (window._webDebug) mobiIng(`[debug] 验证码: ${data.code}`, 1, false, 3000);
            }
            result.success = true;
            return result;
        } catch (error) {
            console.error(error);
            mobiIng('服务繁忙');
            return result;
        }
    }

    /**
     *
     * @param {number} messageType - MOBILE_AUTH_MESSAGE_TYPES
     *
     */
    async needAuth(messageType) {
        this._activeMessageType = messageType;
        try {
            const data = await checkVisitorsRealName(messageType);
            const showAfterReview = data.data?.showAfterReview ?? false;
            const needAuth = data.data?.needMobilePhoneRealname ?? false;

            if (data.success === false) {
                // 没有手机实名认证
                if (needAuth === true) {
                    this._log({
                        jz_free_text2: '无',
                    });
                }
                return {
                    needAuth,
                    showAfterReview,
                };
            } else {
                // 有实名认证过则无需再认证
                return {
                    needAuth: false,
                    showAfterReview,
                };
            }
        } catch (error) {
            console.error(error);
            return {
                needAuth: false,
                showAfterReview: false,
            };
        }
    }

    /**
     * @returns { { show: Function, hide: Function } }
     */
    getInstance() {
        return Vue.prototype.$mobileAuthPopup;
    }

    createSubmitHandler(onSuccess, onFail) {
        let submiting = false;

        this.getInstance().onSubmit = async ({ phone, authCode }) => {
            if (submiting === true) return;
            submiting = true;
            try {
                const validated = await validateAuthCode(phone, authCode);
                if (validated.success === true) {
                    this.getInstance().hide();
                    this._log({
                        jz_free_text2: '手机实名认证',
                    });
                    this._activeMessageType = -1;
                    typeof onSuccess === 'function' && onSuccess({ phone, authCode });
                } else {
                    if (typeof onFail === 'function') {
                        onFail(validated);
                    }
                }
            } catch (error) {
                console.error(error);
                if (typeof onFail === 'function') {
                    onFail({
                        success: false,
                        msg: '服务繁忙',
                    });
                }
            } finally {
                submiting = false;
            }
        };
    }

    show() {
        this.getInstance().show({
            themeColor: this.themeColor,
            getAuthCode: this._getAuthCode,
            localeCode: window._curLanCode,
            captcha: {
                type: DEFAULT_CAPTCHA_TYPE,
                genAccessKey: this._genCaptchaAccessKey,
            },
        });
    }

    hide() {
        this.getInstance().hide();
    }
}

export const MOBILE_AUTH_MESSAGE_TYPES = {
    MSG_BOARD: 1,
    PRODUCT_COMMENT: 2,
    NEWS_COMMENT: 3,
};

export function registerMobileAuthPopupInVue(Vue) {
    installMobileAuthPopup(Vue);
}

export const MOBILE_AUTH_TIPS = `
<span style="color:red;">根据 <a style='color:red;cursor:pointer;' href='//mp.weixin.qq.com/s/0ZlQLQILaXwpklpef88eJg' target='_blank'>《互联网跟帖评论服务管理规定》</a>，免费域名不支持评论、留言功能与内容显示，请访问绑定域名体验。</span>
`;
