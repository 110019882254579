<template>
    <div id="webHeader" class="webHeader webHeaderBg" :style="headerBgStyle">
        <span v-if="mallHeaderBg.img" :hideFileUrl="mallHeaderBg.img" style="display: hidden"></span>
        <template v-if="isHomePage">
            <div id="mallThemeHeader" :class="mallThemeHeaderClass">
                <div id="headerBgArea" class="headerBgArea" :style="mallHeaderBgStyle">
                    <div class="jz_top_wrapper J_top_wrapper">
                        <a
                            id="pageLogoImg"
                            v-bind="{
                                href: logoJumpUrl,
                                rel: logoNofollow,
                            }"
                            class="J_logoImg f_logo"
                            :style="logoImgStyle"
                        ></a>
                        <div class="f_input_box">
                            <i class="f_icon_conter faisco-icons-S000182"></i>
                            <input :placeholder="searchGoText" @click="popupSearch" />
                        </div>
                        <a v-if="titleTopIcon === 0" :href="productGroupBtnHref">
                            <i class="faisco-icons-S000183"></i>
                        </a>
                        <a v-else-if="titleTopIcon === 1" :href="profileUrl">
                            <i class="faisco-icons-S000040"></i>
                        </a>
                    </div>
                </div>
                <div class="headerHandlerArea">
                    <div class="logoArea">
                        <a
                            v-bind="{
                                href: logoJumpUrl,
                                rel: logoNofollow,
                            }"
                        >
                            <span
                                id="pageLogoMall"
                                class="pageLogo"
                                :style="pageLogoStyle"
                                :class="pageLogoClass"
                                :sid="logoStyleId"
                                :pw="pw"
                                :ph="ph"
                            >
                                <div id="pageLogoImgMall" class="J_logoImg logoImg" :style="logoImgStyle"></div>
                            </span>
                        </a>
                    </div>
                    <div class="titleMallArea">
                        <div class="titleArea">
                            <span class="pageTitle" :style="titleFontStyle">
                                <h1 :style="pageTitleH1Style">
                                    <a
                                        id="pageTitleMall"
                                        :rel="titleNofollow"
                                        :href="jumpTitleUrl"
                                        :style="jumpTitleFontStyle"
                                    >
                                        {{ title }}
                                    </a>
                                </h1>
                            </span>
                        </div>
                        <div style="display: flex" class="mallUserBtnArea">
                            <a :style="allProductStyle" :href="prUrl" hidefocus="true" :class="allProductsClass">
                                <div class="headerMenuNum">
                                    <span class="icon-allProductIcon"></span>
                                    <span class="allProductNumText">{{ allProductNum }}</span>
                                </div>
                                <span class="headerMenuText">{{ mobiAllGoodsText }}</span>
                            </a>
                            <a :style="userCenterStyle" hidefocus="true" :class="userCenterClass" :href="profileUrl">
                                <div class="headerMenuIcon icon-headerMenuIcon"></div>
                                <span class="headerMenuText">
                                    {{ colMemberCenterText }}
                                </span>
                            </a>
                            <a v-if="mallOpen" :style="userCenterStyle" class="headerItemMenu myOrder" :href="mlistUrl">
                                <div class="headerMenuIcon icon-headerMenuIcon"></div>
                                <span class="headerMenuText">
                                    {{ memberCenterOrderText }}
                                </span>
                            </a>
                        </div>
                        <div class="headerMenuBg"></div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div id="mallThemeTopBarBg" :class="mallThemeTopBarBgClass" :style="mallHeaderBgStyle"> </div>
            <div id="mallThemeTopBar" :class="mallThemeTopBarClass">
                <div class="jz_top_wrapper J_top_wrapper">
                    <a class="J_to_back f_back faisco-icons-S000108"></a>
                    <div class="f_input_box">
                        <i class="f_icon_conter faisco-icons-S000182"></i>
                        <input :placeholder="searchGoText" @click="popupSearch" />
                    </div>
                    <a :href="indexUrl" class="f_top_right">
                        <i class="f_home faisco-icons-S0000196"></i>
                    </a>
                </div>
                <div class="logoTitleSide">
                    <div class="logoArea">
                        <span
                            id="pageLogoMall"
                            class="pageLogo"
                            :class="pageLogoClass"
                            :sid="logoStyleId"
                            :pw="pw"
                            :ph="ph"
                        >
                            <a
                                alt=""
                                v-bind="{
                                    href: logoJumpUrl,
                                    rel: logoNofollow,
                                }"
                                hidefocus="true"
                            >
                                <div id="pageLogoImgMall" class="J_logoImg logoImg" :style="logoImgStyle"></div>
                            </a>
                        </span>
                    </div>
                    <div class="titleArea">
                        <h1 :style="titleAreaStyle">
                            <a
                                v-bind="{
                                    href: jumpTitleUrl,
                                    rel: titleNofollow,
                                    style: jumpTitleFontStyle,
                                }"
                                hidefocus="true"
                            >
                                <span id="pageTitleMall" class="pageTitle">
                                    {{ title }}
                                </span>
                            </a>
                        </h1>
                    </div>
                </div>

                <div class="toolMenuSide">
                    <div class="userEnterAera">
                        <a :href="profileUrl" hidefocus="true" class="userEnterLink">
                            <span class="userEnterIcon icon-userEnterIcon"></span>
                            <span class="userEnterText">
                                {{ colMemberCenterText }}
                            </span>
                        </a>
                    </div>
                    <div class="seacherAera" @click="popupSearch">
                        <span class="seacherBtnIcon icon-seacherBtnIcon"></span>
                        <span class="seacherAeraText">
                            {{ searchGoText }}
                        </span>
                    </div>
                </div>
            </div>
        </template>

        <div
            v-if="designMode || (!isMallTheme && !designMode)"
            id="header"
            class="header-forLogo header"
            :class="headerClass"
            :style="headerStyle"
        >
            <template v-if="matchLogoAlign">
                <span id="pageTitle" class="pageTitle" :style="titleFontStyle">
                    <h1 :style="pageTitleH1Style">
                        <a :href="jumpTitleUrl" :rel="titleNofollow" :style="jumpTitleFontStyle">
                            {{ title }}
                        </a>
                    </h1>
                </span>
                <span
                    v-show="!isHiddenLogo"
                    id="pageLogo"
                    class="pageLogo"
                    :class="pageLogoClass"
                    :sid="logoStyleId"
                    :pw="pw"
                    :ph="ph"
                >
                    <a v-bind="{ href: logoJumpUrl, rel: logoNofollow }">
                        <div id="pageLogoImg" class="J_logoImg logoImg" :style="logoImgStyle"></div
                    ></a>
                </span>
            </template>
            <template v-else-if="leftAlign">
                <span
                    v-show="!isHiddenLogo"
                    id="pageLogo"
                    class="pageLogo"
                    :class="pageLogoClass"
                    :style="pageLogoStyle"
                    :sid="logoStyleId"
                    :pw="pw"
                    :ph="ph"
                >
                    <a v-bind="{ href: logoJumpUrl, rel: logoNofollow }">
                        <div id="pageLogoImg" class="J_logoImg logoImg" :style="logoImgStyle"></div>
                    </a>
                </span>
                <span id="pageTitle" class="pageTitle" :class="titleClass" :style="titleFontStyle">
                    <div :style="pageTitleH1Style">
                        <a :href="jumpTitleUrl" :rel="titleNofollow" :style="jumpTitleFontStyle">
                            {{ title }}
                        </a>
                    </div>
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { createBackgroundImageStyle } from './bg-style';
import { COL } from '@/def/col';
import {
    changeSiteTitleBg,
    logoSizeCompressByMargin,
    titlePositionRefreshByLogo,
    setShowSiteTitleBgFlag,
} from '@/shared/title';
import { popupSearch } from '../v3/search';
import {
    createLogoImgBorderStyle,
    createLogoImgShadowStyle,
    createLogoImgRadiusStyle,
    repeatValueMapping,
    bindTopIconBack,
} from './utils';

import { TEMPLATE_LAYOUT } from '@/def/template';
import { autoFixGWebTop } from '@/shared/autoFixGwebTop';

// created from web/mobi/inc/header.jsp.inc
export default {
    name: 'WebHeaderV2',
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['colId', 'lcid', 'templateFrameId', 'LS', 'designMode', 'templateLayoutId', 'resRoot']),
        ...mapState('header', ['headerV2']),
        ...mapGetters('app', ['isDependentCol']),
        pageTitleH1Style() {
            return {
                fontWeight: 'normal',
                fontSize: 'inherit',
                display: 'inline-block',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            };
        },
        titleAreaStyle() {
            return {
                fontWeight: 'normal',
                fontSize: 'inherit',
                display: 'inline-block',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            };
        },
        showNav() {
            return [
                TEMPLATE_LAYOUT.NAV_HEAD,
                TEMPLATE_LAYOUT.NAV_TOP,
                TEMPLATE_LAYOUT.NAV_TOP_HIDE,
                TEMPLATE_LAYOUT.NAV_LEFT_FIX,
                TEMPLATE_LAYOUT.NAV_AUTO,
            ].includes(this.templateLayoutId);
        },
        siteTitleInfo() {
            return this.headerV2?.title ?? {};
        },
        logoJumpUrl() {
            return this.headerV2.logoJumpUrl;
        },
        logo() {
            return this.headerV2.logo;
        },
        jumpTitleUrl() {
            return this.headerV2.jumpTitleUrl;
        },
        title() {
            return this.siteTitleInfo?.name ?? '';
        },
        isMallTheme() {
            return this.headerV2.isMallTheme;
        },
        mallOpen() {
            return this.headerV2.mallOpen;
        },
        picParam() {
            return this.headerV2?.picParam ?? {};
        },
        isHeadHide() {
            return this.headerV2.isHeadHide;
        },
        pageUrls() {
            return this.headerV2.urls;
        },
        canUseNoFollow() {
            return this.headerV2.canUseNoFollow;
        },
        setShowSiteTitleBgFlag() {
            return this.headerV2.setShowSiteTitleBgFlag;
        },
        prUrl() {
            return this.pageUrls?.prUrl ?? '';
        },
        productGroupBtnHref() {
            return this.pageUrls?.productGroupBtnHref ?? '';
        },
        indexUrl() {
            return this.pageUrls?.indexUrl ?? '';
        },
        profileUrl() {
            return this.pageUrls?.profileUrl ?? '';
        },
        mlistUrl() {
            return this.pageUrls?.profileUrl ?? '';
        },
        titleTopIcon() {
            return this.siteTitleInfo?.mti ?? 0;
        },
        allProductNum() {
            return this.headerV2.allProductNum;
        },
        headerBgStyle() {
            if (this.siteTitleInfo.bgType === '1') {
                return {
                    backgroundColor: '#fff',
                    opacity: 1,
                    filter: 100,
                };
            } else if (this.siteTitleInfo.bgType === '2') {
                let imageStyle = {};
                if (this.headerV2.linkCoverPreViewPath !== undefined) {
                    imageStyle['background-image'] = `url(${this.headerV2.linkCoverPreViewPath})`;
                }
                const bgFontColor = this.siteTitleInfo?.bgFont?.color ?? '';
                return Object.assign(
                    {
                        background: bgFontColor,
                    },
                    imageStyle,
                    createBackgroundImageStyle(this.siteTitleInfo.bgType, bgFontColor)
                );
            }
            return {};
        },
        headerClass() {
            return this.isHeadHide ? 'mobiTitleClose' : '';
        },
        isHomePage() {
            return this.colId === COL.SYS_INDEX;
        },
        foreignClass() {
            // 非中文版本
            // cn and tcn
            return [2052, 1028].includes(this.lcid) ? '' : 'foreignVersion';
        },
        mallThemeHeaderStyle() {
            return this.siteTitleInfo.mtht === 0 ? 2 : this.siteTitleInfo.mths;
        },
        mallThemeHeaderClass() {
            return `mallThemeHeader ${this.templateFrameId} ${this.foreignClass} mallThemeHeaderStyle${this.mallThemeHeaderStyle}`;
        },
        logoStyleId() {
            return this.logo.style;
        },
        logoNofollow() {
            return this.logo.jnf && this.canUseNoFollow;
        },
        pageLogoClass() {
            let className = [];
            if (this.logo.marginType === 1) {
                className.push('hasMarin');
            }
            if (this.logo) {
                if (this.logo.a === 0) {
                    className.push('left');
                } else if (this.logo.a === 3) {
                    className.push('right');
                }
            }
            return className;
        },
        pw() {
            return this.picParam.p160w;
        },
        ph() {
            return this.picParam.p160h;
        },
        logoImgBorder() {
            return createLogoImgBorderStyle(this.logoStyleId, this.isMallTheme);
        },
        logoImgRadius() {
            return createLogoImgRadiusStyle(this.logoStyleId, this.isMallTheme);
        },
        logoImgShadow() {
            return createLogoImgShadowStyle(this.logoStyleId);
        },
        logoImgStyle() {
            let logoImgDefault = {};
            if (this.logoStyleId === 0) {
                logoImgDefault = {
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                };
            }
            const url = this.logoStyleId === 0 ? this.picParam.preview160 : this.picParam.preview;

            const result = {
                ...this.logoImgBorder,
                ...this.logoImgRadius,
                ...this.logoImgShadow,
                ...logoImgDefault,
            };
            if (url) {
                result['background-image'] = `url(${url})`;
            }
            return result;
        },
        titleNofollow() {
            return this.siteTitleInfo.jnf && this.canUseNoFollow;
        },
        jumpTitleFontStyle() {
            if (this.siteTitleInfo.fontType === 1) {
                const font = this.siteTitleInfo.font;
                const fontColorType = font?.colorType ?? 0;
                let color = fontColorType === 1 ? font.color : 'inherit';
                return {
                    color,
                    fontSize: `${font?.size ?? 12}px`,
                };
            }
            return { color: 'inherit' };
        },
        allProductStyle() {
            let style = {};
            if (this.siteTitleInfo.mhl === 0) {
                style.display = 'block';
                style.flex = 1;
            } else {
                style.display = 'none';
            }
            return style;
        },
        noMallTopMemuClass() {
            return this.mallOpen ? '' : 'noMallMenu';
        },
        allProductsClass() {
            return `headerItemMenu ${this.noMallTopMemuClass} allProducts`;
        },
        mobiAllGoodsText() {
            return this.LS.mobi_allGoods;
        },
        userCenterStyle() {
            let style = {
                flex: 1,
            };
            if (this.siteTitleInfo.mhl !== 0 && this.mallOpen) {
                style.width = '50%';
            }
            return style;
        },
        userCenterClass() {
            return `headerItemMenu ${this.noMallTopMemuClass} userCenter`;
        },
        mallThemeTopBarBgClass() {
            return `mallThemeTopBarBg ${this.foreignClass}`;
        },
        proGroupCls() {
            return this.colId === COL.SYS_PRODUCT_NEW_GROUP ? 'jz_mall_pro_group' : '';
        },
        mallThemeTopBarClass() {
            return `mallThemeTopBar ${this.foreignClass} ${this.proGroupCls}`;
        },
        searchGoText() {
            return this.LS.searchGo;
        },
        colMemberCenterText() {
            return this.LS.col_member_center;
        },
        headerStyle() {
            let paddingStyle = this.isHiddenLogo ? { padding: '0px' } : {};

            const titleAlign = this.siteTitleInfo.align;
            const alignMapping = [void 0, 'left', 'center', 'right'];
            const titleAlignStyle = { textAlign: alignMapping[titleAlign] };

            return { ...paddingStyle, ...titleAlignStyle };
        },
        logoAlign() {
            return this.logo?.a ?? 0;
        },
        matchLogoAlign() {
            return this.logoAlign === 2 || this.logoAlign === 3;
        },
        leftAlign() {
            return this.logoAlign === 1 || this.logoAlign === 0;
        },
        isHiddenLogo() {
            return this.logo.h === true;
        },
        titleClass() {
            return this.title === '' && !this.isHiddenLogo && this.logoAlign === 1 ? 'pageTitleHidden' : '';
        },
        mallHeaderBgType() {
            return this.siteTitleInfo?.mbt ?? 0;
        },
        mallHeaderBgInfo() {
            return this.siteTitleInfo?.mbc ?? {};
        },
        mallHeaderBg() {
            return {
                color: this.mallHeaderBgInfo?.color ?? '#000',
                aphla: this.mallHeaderBgInfo?.alpha ?? 100,
                img: this.siteTitleInfo?.mallHeaderBgImgPath ?? '',
                repeat: this.siteTitleInfo?.mbit ?? 1,
            };
        },
        mallHeaderBgStyle() {
            const style = {};
            if (this.mallHeaderBgType !== 1) {
                return style;
            }

            if (this.mallHeaderBg.color != '') {
                style.background = this.mallHeaderBg.color;
            }
            if (this.isHomePage) {
                if (this.mallHeaderBg.img != '' && this.mallHeaderBgImgRepeat !== 1 && this.templateFrameId !== 2004) {
                    style.backgroundImage = `url(${this.mallHeaderBg.img})`;
                } else {
                    if (this.mallHeaderBg.color != '' && this.mallHeaderBg.color.indexOf('linear-gradient') < 0) {
                        style.backgroundImage = 'none';
                    }
                }
                style.backgroundSize = repeatValueMapping[this.mallHeaderBg.repeat];
            }
            if (this.mallHeaderBgColorAlpha !== 100) {
                style.opacity = this.mallHeaderBgColorAlpha * 0.01;
            }
            return style;
        },
        mallHeaderBgColorAlpha() {
            return this.mallHeaderBgInfo.alpha;
        },
        titleFontStyle() {
            if (this.siteTitleInfo.fontType === 1) {
                const font = this.siteTitleInfo.font;
                const fontColorType = font?.colorType ?? 0;
                let color;
                if (fontColorType === 1) {
                    color = font.color;
                }
                return {
                    color,
                    fontSize: `${font?.size ?? 12}px`,
                };
            }
            return {};
        },
        memberCenterOrderText() {
            return this.LS.member_center_order;
        },
        pageLogoStyle() {
            let logoMarginType = this.logo?.marginType ?? 0;
            if (logoMarginType === 1) {
                const { top, left, bottom, right } = this.logo?.margin ?? {};
                return {
                    marginTop: top + 'px',
                    marginLeft: left + 'px',
                    marginBottom: bottom + 'px',
                    marginRight: right + 'px',
                };
            } else {
                return {};
            }
        },
    },
    watch: {
        'headerV2.isHeadHide'(isHide) {
            if (isHide === true) {
                autoFixGWebTop(window._templateLayoutId);
            }
        },
    },
    async mounted() {
        if (VITE_APP_MODE !== 'visitor') {
            // 访客态由顶层注入数据
            await this.loadHeaderInfo();
            await this.$nextTick();
        }

        if (this.designMode || (!this.isMallTheme && !this.designMode)) {
            setShowSiteTitleBgFlag(this.setShowSiteTitleBgFlag);
        }

        if (this.siteTitleInfo.bgType === '1') {
            changeSiteTitleBg(this.siteTitleInfo.bgType);
        } else if (this.siteTitleInfo.bgType === '2') {
            changeSiteTitleBg(
                this.siteTitleInfo.bgType,
                this.siteTitleInfo.bgImgStyle,
                this.siteTitleInfo.bgFont.color,
                this.siteTitleInfo.bgFont.aphla,
                this.headerV2.linkCoverPreViewPath
            );
        } else {
            changeSiteTitleBg();
        }
        logoSizeCompressByMargin();
        titlePositionRefreshByLogo();
        bindTopIconBack();
        await this.$nextTick();
        autoFixGWebTop(window._templateLayoutId);
    },
    methods: {
        ...mapActions('header', ['loadHeaderInfo']),
        popupSearch() {
            popupSearch({
                lanCode: '',
                nSL: [],
                hotTopic: [],
                searchProduct: 1,
                searchTip: this.searchGoText,
                moduleType: 0,
                moduleColor: '#aaa',
                historySearch: 1,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.mobiTitleClose {
    display: none;
}
</style>
