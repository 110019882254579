<template>
    <div id="userCommentPanel" class="userCommentPanel" style="display: none">
        <div class="userCommentHead">
            <span class="userCommentGoBack icon-gClose g_iconMiddle"></span>{{ LS.mobi_Post_Comments
            }}<a class="userCommentGoHome icon-gHome g_iconMiddle" :href="options.linkLang + '/'"></a>
        </div>
        <div class="userCommentBody">
            <div v-if="options.warningTips" class="comment_warning js_newsDetailCommitDisabled">
                {{ options.warningTips }}
            </div>
            <template v-if="showTab">
                <ul class="tabUl">
                    <li id="tabUserLi1" class="tabLi tabActive" :class="userCommentBodyClass" data="1">{{
                        LS.mobi_Log_Comments
                    }}</li
                    ><li id="tabUserLi2" class="tabLi" data="2">{{ LS.mobi_Incognito_Comments }}</li>
                </ul>
            </template>
            <div class="tabBody">
                <div id="tabUserPanel1" class="tabPanel tabPanelAtive">
                    <div v-if="manageMode" class="notice">您目前处于网站管理状态，请先退出再评论</div>
                    <div class="userCommentInfo">
                        <div class="g_globalLine">
                            <input
                                id="user"
                                :disabled="isLogin"
                                :value="isLogin ? memberName : null"
                                :minlength="isLogin ? creatorMinlen : null"
                                :placeholder="mobi_member_PleaseInput + LS.mobi_member_Account"
                                type="text"
                                maxlength="50"
                                class="itemEdit g_input"
                            />
                            <span class="icon-mnameIcon"></span>
                        </div>
                        <div v-if="!isLogin" class="g_globalLine">
                            <input
                                id="password"
                                type="password"
                                maxlength="20"
                                class="itemEdit g_input"
                                :placeholder="mobi_member_PleaseInput + LS.mobi_member_Password"
                            />
                            <span class="icon-mpswIcon"></span>
                        </div>
                        <div id="userCommentRemarkPanel1">
                            <div class="g_globalLine">
                                <textarea
                                    id="userCommentRemark"
                                    class="itemEdit msgIcon g_textArea"
                                    :minlength="commentMinlen"
                                    :maxlength="commentMaxlen"
                                    :placeholder="mobi_member_PleaseInput + LS.msgBoardContent"
                                ></textarea>
                                <span class="icon-mmsgIcon"></span>
                            </div>
                        </div>
                        <div
                            id="userCommentLoginAndComform"
                            class="userCommentComform g_button"
                            :class="userCommentComformClass"
                            >{{ LS.mobi_Submit }}</div
                        >
                    </div>
                </div>
                <template v-if="showTab">
                    <div id="tabUserPanel2" class="tabPanel">
                        <div v-if="manageMode" class="notice">您目前处于网站管理状态，请先退出再评论</div>
                        <div id="userCommentInfo2" class="userCommentInfo">
                            <div class="g_globalLine">
                                <input
                                    id="anonymityCommentUser"
                                    type="text"
                                    maxlength="50"
                                    class="itemEdit g_input"
                                    :placeholder="mobi_member_PleaseInput + LS.productCommentCreator"
                                />
                                <span class="icon-mnameIcon"></span>
                            </div>
                            <div id="userCommentRemarkPanel2"></div>
                            <div id="anonymityCommentComform" class="userCommentComform g_button g_main_bgColor_v3">{{
                                LS.mobi_Submit
                            }}</div>
                        </div>
                    </div>
                </template>
                <input id="postCommentId" type="hidden" :value="newsId" />
            </div>
        </div>
    </div>
</template>

<script>
import { newsDetailMixins } from '../mixins';
import { COL } from '@/def/col';
import { mapState } from 'vuex';
export default {
    name: 'Comment',
    mixins: [newsDetailMixins],
    inject: ['manageMode', 'moduleId', 'openThemeV3'],
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['colId', 'LS']),
        showTab() {
            return this.options.isNewsCommentNoMemberLimit;
        },
        mobi_member_PleaseInput() {
            return this.LS.mobi_member_PleaseInput || '';
        },
        memberName() {
            return this.options.memberName;
        },
        isLogin() {
            return this.options.isLogin;
        },
        creatorMinlen() {
            return this.options.creatorMinlen;
        },
        commentMinlen() {
            return this.options.commentMinlen;
        },
        commentMaxlen() {
            return this.options.commentMaxlen;
        },
        isNewDetailThemeV3() {
            return this.colId == COL.SYS_NEWS_DETAIL_V3;
        },
        userCommentBodyClass() {
            let classList = [];
            if (this.isNewDetailThemeV3) {
                classList.push('g_main_color_v3 g_main_bdColor_v3');
            }
            return classList;
        },
        userCommentComformClass() {
            let classList = [];
            if (this.isNewDetailThemeV3) {
                classList.push('g_main_bgColor_v3');
            }
            return classList;
        },
    },
};
</script>

<style>
#userCommentPanel .comment_warning {
    background-color: #fff5dc;
    text-align: center;
    padding: 10px 0;
    color: #ff8e1e;
    font-size: 13px;
    line-height: 22px;
}
</style>
