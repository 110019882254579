import { context, deepExtendClone } from '@jz/utils';
import { isNull, addUrlParams } from '@/shared/fai';
import { closePopupWindow, bg } from './utils';
import { logDog } from '@/shared/log';
const popupWinloaded = function (_context) {
    var _this = _context,
        popupWin = _this.popupWin;

    popupWin.ready(function () {
        var popupWindowId = _this.popupWindowId;

        if (_this.iframeMode && !_this.settings.noframeUrl) {
            var frameSrcUrlArgs = 'popupID=' + popupWindowId;

            context
                .$('#popupWindowIframe' + popupWindowId)
                .attr('src', addUrlParams(_this.settings.frameSrcUrl, frameSrcUrlArgs))
                .load(function () {
                    var pWCenter = _this.popupWin.find('.pWCenter');
                    var pWBottom = _this.popupWin.find('.pWBottom');
                    var pWBottomHeight = pWBottom.height();

                    //处理当浏览器展示高度小于弹窗的时候滚动条超出的问题
                    pWCenter.css('border-bottom', '2px solid transparent');

                    //文件素材弹窗不需要在ready的时候赋值宽高
                    if (_this.popUpWinClass != 'fileUploadV2') {
                        $(this).find('html').css('overflow-y', 'auto');
                        pWCenter.height(
                            _this.contentHeight -
                                _this.pWHeadHeight -
                                pWBottomHeight -
                                parseInt(pWCenter.css('border-bottom-width').replace('px', ''))
                        );
                        pWCenter.width(_this.contentWidth);
                    }
                    if (_this.settings.waitingPHide) {
                        _this.pwLoading.hide();
                    }

                    if (
                        context._isTemplateVersion2 &&
                        context._useBannerVersionTwo &&
                        typeof Site != 'undefined' &&
                        typeof context._bannerV2Data != 'undefined' &&
                        context._bannerV2Data.bl &&
                        context._bannerV2Data.bl.length > 1
                    ) {
                        Site.bannerV2.stopBannerInterval();
                    }

                    if (_this.settings.fromNewsGuide) {
                        typeof _this.settings.newsGuideCallBack == 'function' && _this.settings.newsGuideCallBack();
                    }
                });
        }

        function draggable() {
            popupWin.draggable({
                start: function () {
                    // 拖动不选中
                    context.$('body').disableSelection();
                    // chrome,ie10以上 top选色板focusout失效。
                    context.$('#colorpanel').remove();
                    context.$('.faiColorPicker').remove();
                },
                handle: '.pWHead',
                stop: function () {
                    // 拖动不选中
                    context.$('body').enableSelection();
                    logDog(200046, 39);
                },
            });
        }

        draggable();

        popupWin
            .find('.pWHead')
            .off('mouseenter.popenter')
            .on('mouseenter.popenter', function () {
                draggable();
            });

        popupWin
            .find('.pWHead')
            .off('mouseleave.popleave')
            .on('mouseleave.popleave', function () {
                popupWin.draggable('destroy');
            });

        popupWin.find('.J_pWHead_close').bind('click', function () {
            var popupWindowId = _this.popupWindowId;
            if (isNull(_this.settings.msg)) {
                closePopupWindow(popupWindowId);
            } else {
                closePopupWindow(popupWindowId, undefined, _this.settings.msg);
            }

            return false;
        });

        popupWin.find('.J_pWHead_close').disableSelection();

        var id = popupWindowId;
        // 如果开启了点击背景关闭
        if (_this.settings.bgClose) {
            context.$('#popupBg' + id).bind('click', function () {
                if (isNull(_this.settings.msg)) {
                    closePopupWindow(id);
                } else {
                    closePopupWindow(id, undefined, _this.settings.msg);
                }
                return false;
            });
        }

        if (
            _this.settings.callback &&
            Object.prototype.toString.call(_this.settings.callback) === '[object Function]'
        ) {
            _this.settings.callback();
        }
    });
};
export class PopupWindow {
    constructor(options) {
        var settings = {
            title: '',
            width: 500,
            height: 300,
            top: 0,
            left: 0,
            frameSrcUrl: 'about:_blank',
            frameScrolling: 'auto',
            bannerDisplay: true,
            framePadding: true,
            opacity: '0.5',
            displayBg: true,
            bgClose: false,
            closeBtnHide: false,
            waitingPHide: true,
            divId: null,
            divContent: null,
            msg: '',
            popUpWinClass: '',
            popUpWinZIndex: 0,
            replaceContent: false,
            noframeUrl: false,
            noHeadLine: false, //头部
            noHeadBorder: false, //头部样式的底部边框
            popUpTip: '',
            callback: null, //初始化弹窗回调
            closeFunc: null, //关闭按钮回调
        };
        this.settings = deepExtendClone(settings, options);

        this.contentWidth = parseInt(this.settings.width);
        this.contentHeight = parseInt(this.settings.height);
        this.popUpWinClass = this.settings.popUpWinClass;
        this.popupWindowId = this.settings.replaceContent
            ? this.settings.popupWindowId
            : parseInt(Math.random() * 10000);
        this.iframeMode = true;
        this.popupWin;
        this.pWHead;
        this.pWHeadHeight;
        this.pwLoading;
        this.init();
        popupWinloaded(this);

        //noframeUrl的场景：弹出提示语(新弹窗样式)by Alson
        if (this.settings.noframeUrl) {
            var popupWindowId = this.popupWindowId;
            var savebtnId = 'popup' + this.popupWindowId + 'save';
            var closebtnId = 'popup' + this.popupWindowId + 'cloze';
            var btnPanel = context.$('#popupWindow' + this.popupWindowId).find('.pWBtns');
            var btnConfirmText = this.settings.btnConfirm ? this.settings.btnConfirm : '确定';
            var btnClsText = this.settings.btnCls ? this.settings.btnCls : '取消';
            context
                .$(
                    "<input id='" +
                        savebtnId +
                        "' type='button' value='" +
                        btnConfirmText +
                        "' class='jz_button jz_button__global_oper jz_button__active pWBtn' extClass='' />"
                )
                .appendTo(btnPanel);
            context
                .$(
                    "<input id='" +
                        closebtnId +
                        "' type='button' value='" +
                        btnClsText +
                        "' class='jz_button jz_button__global_oper pWBtn' extClass='' />"
                )
                .appendTo(btnPanel);

            context.$('#' + savebtnId).bind('click', function () {
                closePopupWindow(popupWindowId, { select: true });
            });
            context.$('#' + closebtnId).bind('click', function () {
                closePopupWindow(popupWindowId, { select: false });
            });
        }
    }
    init() {
        var displayModeContent,
            noHeadLine = '',
            noHeadBorder = '',
            positionStyle = '',
            headStyle = '',
            pWCenterStyle = '',
            popupWindowId,
            pwHtml,
            clientHeight,
            clientWidth,
            hasScroll = false;

        popupWindowId = this.popupWindowId;

        displayModeContent =
            "<iframe id='popupWindowIframe" +
            popupWindowId +
            "' class='popupWindowIframe' src='' frameborder='0' scrolling='" +
            this.settings.frameScrolling +
            "' style='width:100%;height:100%;'></iframe>";

        if (this.settings.divId != null) {
            this.iframeMode = false;
            displayModeContent = $(this.settings.divId).html();
        } else if (this.settings.divContent != null) {
            this.iframeMode = false;
            displayModeContent = this.settings.divContent;
        } else if (this.settings.noframeUrl) {
            //noframeUrl的场景：提示语弹窗(链接控件的删除客服操作) by Alson
            this.iframeMode = false;
            displayModeContent =
                "<p id='J_popUptip' style='margin-top: 45px; text-align: center;padding-left: 35px;padding-right: 35px;font-size: 15px;'>" +
                this.settings.popUpTip +
                '</p>';
        }

        //隐藏标题的场景：提示语弹窗
        if (this.settings.noHeadLine) {
            noHeadLine = 'display:none;';
            pWCenterStyle = 'height: 90px;';
        }
        if (this.settings.noHeadBorder) {
            noHeadBorder = 'border-bottom-color:#ffffff;';
        }

        headStyle = noHeadLine + noHeadBorder;
        headStyle = headStyle ? " style='" + headStyle + "'" : '';

        //加背景
        if (this.settings.displayBg && !this.settings.replaceContent) {
            bg(popupWindowId, this.settings.opacity, this.settings.popUpWinZIndex);
        }

        var extStyle = '';
        if (this.settings.popUpWinZIndex) {
            extStyle = 'z-index: ' + this.settings.popUpWinZIndex;
        }

        if (this.settings.replaceContent) {
            extStyle = 'animation: initial;';
        }

        clientHeight = context.document.body.clientHeight || context.document.documentElement.clientHeight;
        clientWidth = context.document.body.clientWidth || context.document.documentElement.clientWidth;

        if (context.Root || context.__noGmain) {
            clientHeight = context.document.documentElement.clientHeight;
            clientWidth = context.document.documentElement.clientWidth;
        }

        if (this.contentHeight > clientHeight) {
            this.contentHeight = clientHeight;
            hasScroll = true;
        }

        if (context.Root) {
            //加弹出窗口
            var scrollTop = context.$('body').scrollTop();
            if (scrollTop == 0) {
                scrollTop = context.$('html').scrollTop();
            }
        }
        if (this.settings.top !== 0 || this.settings.left !== 0) {
            positionStyle = 'left: ' + this.settings.left + 'px;top: ' + this.settings.top + 'px;';
        } else {
            positionStyle = 'left: ' + (clientWidth - this.contentWidth) / 2 + 'px;'; //top: ' + (clientHeight - this.contentHeight) / 2 + 'px 下面判断必进，
            if (context.Root) {
                positionStyle += 'top: ' + ((clientHeight - this.contentHeight) / 2 + scrollTop) + 'px;';
            } else {
                if (context.__noGmain) {
                    positionStyle =
                        'left: ' + (context.document.documentElement.scrollWidth - this.contentWidth) / 2 + 'px;';
                    positionStyle +=
                        'top: ' +
                        ((clientHeight - this.contentHeight) / 2 + context.$(context.window).scrollTop()) +
                        'px;';
                } else {
                    positionStyle += 'top: ' + (clientHeight - this.contentHeight) / 2 + 'px;';
                }
            }
        }

        pwHtml =
            '<div id="popupWindow' +
            popupWindowId +
            '" hasScroll=' +
            hasScroll +
            "  class='fk-popupWindowVT " +
            this.settings.popUpWinClass +
            "' style='" +
            positionStyle +
            ' height:' +
            this.contentHeight +
            'px; ' +
            extStyle +
            "'>" +
            "<div class='pWHead'" +
            headStyle +
            '>' +
            "<div class='pWHead_title'>" +
            this.settings.title +
            '</div>' +
            "<div style='" +
            (this.settings.closeBtnHide ? 'display: none;' : '') +
            "' class='pWHead_close'>" +
            "<div class='J_pWHead_close pWHead_close_img'></div>" +
            '</div>' +
            '</div>' +
            "<div class='pWCenter' style='width:" +
            this.contentWidth +
            'px;' +
            pWCenterStyle +
            "'>" +
            displayModeContent +
            "<div class='tabs_extendedLine' style='display:none;'></div>" +
            '</div>' +
            "<div class='pWBottom'>" +
            "<div class='pWBtns'></div>" +
            '</div>' +
            '<div id="pwLoading' +
            popupWindowId +
            "\" class='pwLoading' style='height:auto;'></div>" +
            '</div>';

        if (this.settings.replaceContent) {
            context.$('#popupWindow' + popupWindowId).css('animation', 'initial');
            context.$('#popupWindow' + popupWindowId).replaceWith(pwHtml);
        } else {
            context.$('body').append(pwHtml);
        }

        this.popupWin = context.$('#popupWindow' + popupWindowId);
        this.pWHead = this.popupWin.find('.pWHead:visible');
        this.pWHeadHeight = this.pWHead.height();
        this.pwLoading = this.popupWin.find('#pwLoading' + popupWindowId);

        if (this.iframeMode) {
            this.pwLoading.height(this.contentHeight - this.pWHeadHeight).width(this.contentWidth);
        } else {
            if (this.settings.waitingPHide) {
                this.pwLoading.hide();
            }
        }

        if (isNull(context._popupOptions)) {
            context._popupOptions = {};
        }

        if (isNull(context._popupOptions['popup' + popupWindowId])) {
            context._popupOptions['popup' + popupWindowId] = {};
        }

        if (!isNull(this.settings.callArgs)) {
            context._popupOptions['popup' + popupWindowId].callArgs = this.settings.callArgs;
        }

        context._popupOptions['popup' + popupWindowId].options = this.settings;
        context._popupOptions['popup' + popupWindowId].change = false;
    }
    getPopupWindowId() {
        return this.popupWindowId;
    }
}
