export const checkThemeVer = function (
    tmpId = Fai.top._templateFrameId,
    tmpType = Fai.top._other.templateInfo.templateDesignType
) {
    var themeVer = '';

    if (tmpId < 1015 || (tmpType == 2 && tmpId < 2002)) {
        //旧版主题(id为1015前的炫彩、普通主题、2002之前的商城主题)
        themeVer = 'oldTheme';
    } else if ((tmpType == 1 && tmpId >= 1015) || (tmpType == 2 && tmpId >= 2002 && tmpId < 3000)) {
        //新版主题(id为1015后的炫彩、2002之后商城主题)
        themeVer = 'newTheme';
    }

    return themeVer;
};
