import { MobiModule } from '@/modules/Module';
import HeaderView from './module/index.vue';

class _Header extends MobiModule {
    constructor() {
        super(null /** 系统模块没style */, {
            system: true,
        });
    }

    render() {
        return HeaderView;
    }
}

export const Header = new _Header();
