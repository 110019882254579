import request, { postByFormUrlEncoded } from './request';

export function getNavV3(colId) {
    return request
        .get('/api/manage/nav/navV3', {
            params: { _colId: colId, _navSkin: window._reqNavSkinId || -1 },
        })
        .then(({ data = {} }) => {
            return data.data;
        });
}

export function getNavV2(colId) {
    return request
        .get('/api/manage/nav/navV2', {
            params: { colId, _navSkin: window._reqNavSkinId || -1 },
        })
        .then(({ data = {} }) => {
            return data.data;
        });
}

export function getNewNavId() {
    return request.get('/ajax/nav_h.jsp?cmd=getWafNotCk_getNewNavId&v=' + Math.random() * 1000, {
        params: {},
    });
}

export function getMainNav() {
    return request.get('/ajax/nav_h.jsp?cmd=getWafNotCk_getMainNavList&v=' + Math.random() * 1000, { params: {} });
}

export const navSkinStyle = (option) => {
    let { aid, id, _fullSitePattern, _navSkinPattern } = option;
    return postByFormUrlEncoded({
        url: '/mobistyle.jsp',
        data: {
            cmd: 'navSkinStyle',
            aid,
            id,
            _fullSitePattern: JSON.stringify(_fullSitePattern),
            _navSkinPattern: JSON.stringify(_navSkinPattern),
        },
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('/api/nav/navSkinStyle', `${err}`);
        });
};

export const footerNavSkinStyle = (option) => {
    let { aid, id, _fullSitePattern, _footerNavSkinPattern } = option;
    return request
        .post('/api/manage/style/footerNavSkinStyle', {
            data: {
                aid,
                id,
                _fullSitePattern: JSON.stringify(_fullSitePattern),
                _footerNavSkinPattern: JSON.stringify(_footerNavSkinPattern),
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('/api/nav/navSkinStyle', `${err}`);
        });
};

export const getServiceNavList = () => {
    return request.get('/ajax/nav_h.jsp?cmd=getWafNotCk_getServiceNavList').then((res) => res.data);
};

export const getNavList = () => {
    return request.get('/ajax/nav_h.jsp?cmd=getWafNotCk_getNavList').then((res) => res.data);
};
