export const MsgBoardPropDef = {
    INFO: {
        ID: 'id',
        NAME: 'name',
        FIELD_KEY: 'fieldKey',
        TYPE: 'type',
    },
    FLAG: {
        HIDDEN: 0x1, // 是否隐藏
        REQUIRED: 0x2, // 是否必填
    },
    LIMIT: {
        CUS_COUNT_MAX: 5,
        NAME_MINLEN: 1,
        NAME_MAXLEN: 20,
    },
    TYPE: {
        CUS: 1,
        SYS_NAME: 2,
        SYS_EMAIL: 3,
        SYS_PHONE: 4,
    },
};

export const MsgBoardDef = {
    FLAG: {
        PUBLIC: 0x1, //  是否公开留言
        UN_READED: 0x2, //	1为未阅读，0为已阅读
    },
    LIMIT: {
        REQ_NAME_MINLEN: 1,
        REQ_NAME_MAXLEN: 50,
        REQ_PHONE_MAXLEN: 50,
        REQ_EMAIL_MAXLEN: 50,
        REQ_PROP_MAXLEN: 50,

        REQ_CONTENT_MINLEN: 1,
        REQ_CONTENT_MAXLEN: 10000,

        RSP_CONTENT_MAXLEN: 10000,

        REQ_COUNT_MAX: 1000000,
    },
};

export const MODULE_ID = 7; // 系统留言板模块ID
