import { getFloagBtnContent, getSimpleTextContent, getPackContent } from '@/manage/utils/module';

import { getModuleAttrPatternStg, getModuleAttr } from '@/features/global-mobi-function/methods/util';

// 通过id获取模块数据
export const getModuleDataById = (moduleId) => {
    return window.$store.state.currentPageModuleIdMap[moduleId] || {};
};

const getModuleAttrData = (moduleId) => {
    return window.$store.state.manage.moduleAttr[moduleId] || {};
};

export const getSrcChangedModuleInfo = (
    moduleId,
    srcModule,
    srcModuleAttr = {},
    srcModuleAttrV3 = {},
    srcModuleInfo
) => {
    var srcContentChange = srcModuleAttr.contentChange,
        srcPattern = srcModuleAttr.pattern || {},
        srcPatternChanged = srcPattern.changed,
        srcPatternV3Changed = srcModuleAttrV3.patternChanged,
        srcModuleChangeInfo = {};
    srcModuleAttrV3 = srcModuleAttrV3 || {};

    if (srcPatternChanged) {
        srcModuleChangeInfo.pattern = getModuleAttrPatternStg(moduleId);
    }

    if (srcPatternV3Changed) {
        srcModuleChangeInfo.patternV3 = srcModuleAttrV3.patternV3;
    }

    if (srcModule.hasClass('formStyle52')) {
        //简易文本
        srcModuleChangeInfo.content = getSimpleTextContent(srcModule);
    } else if (srcModule.hasClass('formStyle53')) {
        //浮动图片
        // srcModuleChangeInfo.content = srcContent;
        let moduleInfo = window.$store.state.currentPageModuleIdMap[moduleId] || {};
        srcModuleChangeInfo.content = moduleInfo.content;
    } else if (srcModule.hasClass('formStyle54')) {
        //浮动按钮
        srcModuleChangeInfo.content = getFloagBtnContent(srcModule);
    } else if (srcModule.hasClass('formStyle51')) {
        //自由容器模块
        srcModuleChangeInfo.content = getPackContent(srcModule);

        var inPackModuleList = srcModuleChangeInfo.content.moduleList || [],
            packChangedModule = {};

        $(inPackModuleList).each(function (i, o) {
            let packModuleId = JSON.parse(o).id;
            let srcModuleInfo = getModuleDataById(packModuleId);
            let packModuleAttrV3 = srcModuleInfo.allAttrV3 || {};
            let packModuleAttr = getModuleAttrData(`module${packModuleId}`);

            packChangedModule[packModuleId] = getSrcChangedModuleInfo(
                packModuleId,
                $('#module' + packModuleId),
                packModuleAttr,
                packModuleAttrV3,
                srcModuleInfo
            );
        });
        Object.assign(srcModuleInfo.content, srcModuleChangeInfo.content);

        srcModuleChangeInfo.packChangedModule = packChangedModule;
    } else if (srcModule.hasClass('formStyle50')) {
        //标签模块
        let tabModule = getModuleDataById(moduleId),
            tmpLabelList = tabModule.content.labelList,
            tabModuleList = [],
            tabLabelList = [],
            tabChangedModule = {};
        $(tmpLabelList).each(function (i, v) {
            var id = v.id;
            if (id > 0) {
                let tabModuleAttr = getModuleAttrData(`module${id}`);
                let tabModuleInfo = getModuleDataById(id);
                let tabModuleAttrV3 = tabModuleInfo.allAttrV3;

                tabChangedModule[id] = getSrcChangedModuleInfo(
                    id,
                    $('#module' + id),
                    tabModuleAttr,
                    tabModuleAttrV3,
                    tabModuleInfo
                );
                tabModuleList.push(id);
            }
            tabLabelList.push({
                id: v.id,
                labelName: v.labelName,
                do: v.do,
            });
        });
        var tabContent = {
            labelList: tabLabelList,
            moduleList: tabModuleList,
        };
        srcModuleChangeInfo.content = tabContent;
        srcModuleChangeInfo.tabChangedModule = tabChangedModule;
    } else if (srcModule.hasClass('formStyle58')) {
        // 折叠模块
        let foldModule = window.$store.getters.getModuleById(moduleId),
            tmpLabelList = foldModule.content.labelList,
            foldModuleList = [],
            foldLabelList = [],
            foldChangedModule = {};
        $(tmpLabelList).each(function (i, v) {
            var id = v.id;
            if (id > 0) {
                let srcModuleInfo = getModuleDataById(id);
                let foldModuleAttrV3 = srcModuleInfo.allAttrV3;
                let foldModuleAttr = getModuleAttrData(`module${id}`);

                foldChangedModule[id] = getSrcChangedModuleInfo(
                    id,
                    $('#module' + id),
                    foldModuleAttr,
                    foldModuleAttrV3,
                    srcModuleInfo
                );
                foldModuleList.push(id);
            }
            foldLabelList.push({
                id: v.id,
                labelName: v.labelName,
                do: v.do,
            });
        });
        var foldContent = {
            labelList: foldLabelList,
            moduleList: foldModuleList,
        };
        srcModuleChangeInfo.content = foldContent;
        srcModuleChangeInfo.foldChangedModule = foldChangedModule;
    }

    srcModuleChangeInfo.srcContentChange = srcContentChange; //记录content是否有改变
    srcModuleChangeInfo.srcPatternChanged = srcPatternChanged; //记录pattern是否有改变
    srcModuleChangeInfo.srcPatternV3Changed = srcPatternV3Changed;

    if (srcModuleInfo.contentChange) {
        srcModuleChangeInfo.srcModuleInfo = srcModuleInfo;
    }

    return srcModuleChangeInfo;
};

export const getModuleAttrPattern = function (moduleId) {
    var attr = getModuleAttr(moduleId);
    return attr.pattern;
};

export const getModuleAttrV3 = function (moduleId) {
    const module = getModuleDataById(moduleId) || {};
    return module.allAttrV3 || {};
};
