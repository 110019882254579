export const PhotoGroupResult = {
    CONDITION_SETTING: 'cs', // boolean标题使用当前条件
    COVER_HEIGHT_TYPE: 'coverHeightType', //封面高度类型 0:默认,1:自定义
    COVER_HEIGHT: 'coverHeight', //封面高度属性
    TITLE_FONT_TYPE: 'titleFontType', //标题文字类型 0:默认,1:自定义
    TITLE_FONT: 'titleFont', //标题文字属性
    CLICK_POPUP: 'clickPopup', //点击图弹出 0:幻灯片 1：图册详情页

    Default: {
        CONDITION_SETTING: true, // 标题使用当前条件
        COVER_HEIGHT_TYPE: 0,
        TITLE_FONT_TYPE: 0,
        COVER_HEIGHT: 30,
        CLICK_POPUP: 0,
    },

    TitleFont: {
        SIZE: 'size', //字体大小
        COLOR_TYPE: 'colorType', //字体颜色类型 0:默认 1:自定义
        COLOR: 'color', //字体颜色

        Default: {
            SIZE: 12,
            COLOR_TYPE: 0,
            COLOR: '#000000',
        },
    },
};
