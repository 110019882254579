import request from './request';

export function getFooterNavInfo(params = {}) {
    return request
        .get('/api/manage/nav/getFooterNavInfo', {
            params: {
                ...params,
            },
        })
        .then(({ data = {} }) => {
            return data.data;
        });
}

export function getFooterNavInfoV2() {
    return request
        .get('/api/manage/nav/getFooterNavV2Info', {
            params: {},
        })
        .then(({ data = {} }) => {
            return data.data;
        });
}

export function getNavDataByAjax(param) {
    return request.get('/ajax/nav_h.jsp', {
        params: param.split('&').reduce((prev, val) => {
            const [key, value] = val.split('=');
            prev[key] = value;
            return prev;
        }, {}),
    });
}

export function getMallCartProductNum() {
    return request
        .get('/ajax/nav_h.jsp', {
            params: {
                cmd: 'getWafNotCk_getMallCartProductNum',
            },
        })
        .then(({ data = {} }) => {
            return data.data;
        });
}
