import { getStyle } from '@/shared/util';
/**
 * created from Mobi.logoV3StyleAdjust
 * @param {Number} styleId
 * @param {Object} logo
 * @param {Object} title
 * @returns
 */
export function logoV3StyleAdjust(styleId, logo, title) {
    var headerDom = jm('#header_v3');
    var logoDom = jm('#headIcon_v3');
    var logoOrgWidth = logo.li.ow || 160;
    var logoOrgHeight = logo.li.oh || 160;
    //清楚logo 和 title 节点的样式
    logoDom.attr('style', ' ');
    headerDom.removeClass('logoPos0 logoPos1 logoPos2 logoPos3');
    headerDom.removeClass('titleAlign1 titleAlign2 titleAlign3');
    var logoPos = '';
    var titleAlign = '';
    if (styleId != 3 && styleId != 4) {
        return;
    }
    titleAlign = 'titleAlign' + title.align;
    if (title.align == 0) {
        titleAlign = 'titleAlign2';
    }
    if (logo.st == 1) {
        if (logo.li.ow != 0 && logo.li.oh != 0 && logo.li.ow != logo.li.oh) {
            logoDom.css('width', (logoDom.height() * logoOrgWidth) / logoOrgHeight + 'px');
        }

        if (logo.m.type == 1) {
            //主要是动态计算高度
            logoDom
                .css('margin-top', logo.m.mt + 'rem')
                .css('margin-right', logo.m.mr + 'rem')
                .css('margin-bottom', logo.m.mb + 'rem')
                .css('margin-left', logo.m.ml + 'rem');

            var logoSize_tmp =
                headerDom.height() -
                parseInt(getStyle(logoDom[0], 'margin-top').replace('px', '')) -
                parseInt(getStyle(logoDom[0], 'margin-bottom').replace('px', ''));
            logoDom.css('height', logoSize_tmp - 1 + 'px');

            //动态计算宽度
            logoDom.css('width', (logoDom.height() * logoOrgWidth) / logoOrgHeight + 'px');
        }

        logoPos = 'logoPos' + logo.lp;
    } else {
        logoDom.css('display', 'none');
    }
    headerDom.addClass(logoPos);
    headerDom.addClass(titleAlign);
    headerDom.css('opacity', 1);
}
