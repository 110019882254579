//by qjie 2020.5.12 主要处理mobi支付相关
import { browser } from '@jz/utils';
import { ing } from '@/shared/tips';
import { changeHref } from '@/features/router/universal';
// 支付业务类型
export const BussinessType = {
    BSSTYPE_FORM: 5, // 表单支付
    BSSTYPE_KNOWPRODUCT: 6, // 知识付费产品支付
};

const PayType = {
    WXPAY: 10,
    ALIAPY: 12,
    FAIPAY: 50,
};

export const jzPayUtils = {
    /*
     * 1. bssId int 支付业务id
     * 2. bssType int 支付业务类型
     * 3. returnUrl String 支付取消或者支付异常之后的跳转
     * 4. sucUrl String 支付成功之后的跳转
     * 5. id number 支付业务id , 例如知识付费是产品ID，表单付费是表单ID
     */
    openPayPage(bssType, bssId, sucUrl, failUrl, id) {
        if (failUrl == '') {
            failUrl = sucUrl;
        }
        let mallPaymentUrl = `/mallPayment.jsp?id=${id}&bssId=${bssId}&bssType=${bssType}&returnUrl=${failUrl}&sucUrl=${sucUrl}`;
        if (Fai.top._curLanCode !== 'cn' || (!Fai.top.defaultCn && Fai.top._curLanCode === 'cn')) {
            mallPaymentUrl = `/${Fai.top._curLanCode}${mallPaymentUrl}`;
        }
        changeHref(mallPaymentUrl);
    },

    /*
			pathname: url 最后的路径名
			urlParams: url 的参数
		*/
    setPayCookie(bssType, bssId, pathname, urlParams) {
        if (pathname == null || pathname == '') {
            const pathArr = location.pathname.split('/').filter((item) => item != window._curLanCode);
            pathname = pathArr.join('/');
        }

        if (urlParams == null || urlParams == '') {
            urlParams = location.search;
        }

        if (urlParams[0] != '?') {
            urlParams = '?' + urlParams;
        }
        // 存储cookie，用于支付宝跳转回原来的页面
        const cookieKey = `payReturnUrl_${bssType}_${bssId}`;
        const cookieVal = `/${pathname}${urlParams}`;
        jm.cookie(cookieKey, cookieVal, { path: '/', expires: 1 });
    },

    normalOpenPay(options) {
        const { bssId, bssType, sucUrl, failUrl, id } = options;
        if (this.isWeChatPay() && browser.isPc()) {
            ing('请在手机微信上访问和提交支付');
            return;
        }

        if (this.isFaiWeChatPay() && !this.isWechat()) {
            ing('非微信环境不支持支付，请在微信浏览器下进行操作');
            return;
        }

        this.setPayCookie(bssType, bssId);
        this.openPayPage(bssType, bssId, sucUrl, failUrl, id);
    },

    // 微信支付 pc 端暂不支持，多种支付方式由内部判断微信支付，单一支付方式且为微信支付时进行提示
    isWeChatPay() {
        const firstPayType = Fai.top._jzPayTypeList[0];
        const length = Fai.top._jzPayTypeList.length;
        // 具体查看 MallPayDef.PayMode, 10 代表微信支付
        return length == 1 && firstPayType == PayType.WXPAY ? true : false;
    },

    // 聚合支付之微信支付
    isFaiWeChatPay() {
        const firstPayType = Fai.top._jzPayTypeList[0];
        const length = Fai.top._jzPayTypeList.length;
        return length == 1 && firstPayType == PayType.FAIPAY ? true : false;
    },

    isWechat() {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.match(/MicroMessenger/i) == 'micromessenger';
    },
};
