import { MobiModule } from '@/modules/Module';
import ProductGroupDetailView from './product-group-detail.vue';

class ProductGroupDetailModule extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return ProductGroupDetailView;
    }
}

export const ProductGroupDetail = new ProductGroupDetailModule();
