import { getPreviewObject, getPreviewObjectClass } from '@/shared/getPreviewObject';
import { createElementByHtml } from '@/shared/util';

export function initTipsMarquee(marquee_li) {
    var mobiTipsMarquee = getPreviewObject('mobiTipsMarquee'),
        mobiTipsMarqueeCopy = getPreviewObject('J_mobiTipsMarqueeCopy'),
        mobiTipsMarqueeleft;

    jm(mobiTipsMarquee).addClass('j-marquee');
    jm(mobiTipsMarquee).css({ width: marquee_li + 'px', left: '0', top: '0' });
    mobiTipsMarqueeleft = mobiTipsMarquee.offsetWidth; // 获取原本mobiTipsMarquee的outerWidth
    jm(mobiTipsMarqueeCopy).css({
        width: marquee_li + 'px',
        left: mobiTipsMarqueeleft + 'px',
        top: '0',
    });
    setParentMarquee(mobiTipsMarquee, mobiTipsMarqueeCopy);
}

//克隆marquee列表 无缝滚动的实现
export function copyTipsDom(tipsDom) {
    var len = tipsDom.length - 1,
        marqueeParent = getPreviewObjectClass('J_marqueeContainer')[0],
        tipMarqueeCopy = createElementByHtml(
            "<ul class='marquee j-marquee J_marquee' id='J_mobiTipsMarqueeCopy'></ul>"
        ),
        tipsDomClone;

    marqueeParent.appendChild(tipMarqueeCopy);
    for (var i = 0; i <= len; i++) {
        tipsDomClone = jm(tipsDom[i]).clone();
        jm(tipMarqueeCopy).append(tipsDomClone);
    }
}

// 设置marqueeParent的宽度以及位置，增加动画效果
function setParentMarquee(mobiTipsMarquee, mobiTipsMarqueeCopy) {
    var mobiTipsMarqueeWidth = mobiTipsMarquee.offsetWidth,
        mobiTipsMarqueeCopyWidth = mobiTipsMarqueeCopy.offsetWidth,
        width = mobiTipsMarqueeWidth + mobiTipsMarqueeCopyWidth,
        marqueeParent = getPreviewObjectClass('J_marqueeContainer')[0],
        SPEED = 70; // 这个速度是参照滚动公告的最慢速度

    jm(marqueeParent).css({ width: width + 'px', left: '0', top: '0' });
    marqueeParent.setAttribute('style', marqueeParent.getAttribute('style') + marqueeAnimation(width, SPEED));
}

// 动画效果的实现
function marqueeAnimation(width, speed) {
    var animationName = 'mobi_notice_right_to_left_50';

    return (
        '-webkit-animation: ' +
        animationName +
        ' ' +
        width / speed +
        's linear 1s infinite;' + // Safari 4+
        '-moz-animation:    ' +
        animationName +
        ' ' +
        width / speed +
        's linear 1s infinite;' + // Fx 5+
        '-o-animation:      ' +
        animationName +
        ' ' +
        width / speed +
        's linear 1s infinite;' + // Opera 12+
        'animation:         ' +
        animationName +
        ' ' +
        width / speed +
        's linear 1s infinite;'
    ); // IE 10+
}
