import { getPreviewObject } from '@/shared/getPreviewObject';

// 隐藏模块特效
export function hideModuleAnimate(id) {
    var $module = $(getPreviewObject('module' + id)), // Mobi对应模块
        $mobiModulePanel = $('.add-panel').eq(0), // 面板容器
        $mobiChoiceModuleContainer = $mobiModulePanel.find('.module-list').eq(0), // 已使用模块容器
        $endAnimateItem = $mobiChoiceModuleContainer.find('#colModuleId' + id), // 特效结束时的对象（默认为面板当前模块）
        $webHeaderBox = $(getPreviewObject('webHeaderBox')),
        moduleOffsetTop = $module.offset().top,
        modulePosition = moduleOffsetTop, // 模块相对高度
        showDelay = 0, // 动画执行延迟时间
        animateShowTime = 850, // 动画执行时间
        animateDiv = $('<div>'), // 特效容器
        animateStartObj,
        animateEndObj,
        animateTime; // 动画共占用时间

    if ($module.length < 1 || $mobiChoiceModuleContainer.length < 1) {
        return;
    }

    // Mobi对应模块Y轴位置（减去Position为Fix的头部高度）
    if ($webHeaderBox.css('position') == 'fixed') {
        modulePosition = moduleOffsetTop - $webHeaderBox.height();
    }

    $('.preview-area_manage').eq(0).scrollTop(modulePosition); // 手机预览框滚到相应模块

    // 动画开始时模块对象
    animateStartObj = getAnimateObj($module);
    animateDiv.css({
        border: '2px solid #ff4400',
        position: 'absolute',
        zIndex: '100',
        top: animateStartObj.top,
        left: animateStartObj.left,
        height: animateStartObj.height + 'px',
        width: animateStartObj.width + 'px',
    });

    $('body').append(animateDiv);

    // 找到对应模块,且面板有滚动条，内容滚动到相应的模块
    if ($endAnimateItem.length > 0) {
        showDelay += 600;
    }

    // 开始动画
    setTimeout(function () {
        // 获取不到指定的模块图标，按默认的栏目菜单位置收起
        if ($endAnimateItem.length < 1 || $endAnimateItem.width() <= 0) {
            $endAnimateItem = $mobiChoiceModuleContainer;
        }

        $endAnimateItem[0].scrollIntoView(false);

        animateEndObj = getAnimateObj($endAnimateItem);
        animateDiv.animate(
            {
                top: animateEndObj.top + 'px',
                left: animateEndObj.left + 'px',
                width: animateEndObj.width + 'px',
                height: animateEndObj.height + 'px',
            },
            animateShowTime,
            function () {
                animateDiv.remove();
            }
        );
    }, showDelay);

    animateTime = showDelay + animateShowTime;

    // 特效模块相对顶级窗口的绝对位置和大小
    function getAnimateObj($module) {
        var obj = {};

        if (!$module || $module.length < 1) {
            return;
        }

        obj.top = $module.offset().top;
        obj.left = $module.offset().left;
        obj.width = $module.outerWidth();
        obj.height = $module.outerHeight();
        return obj;
    }

    return animateTime;
}

export function getModuleStyleById(moduleId) {
    var module = jm('#module' + moduleId);
    if (module.length < 1) return;
    var classes = module.attr('class'),
        classesAry = classes.split(' '),
        styleId;

    for (var i = 0; i < classesAry.length; i++) {
        if (classesAry[i].indexOf('formStyle') > -1) {
            styleId = parseInt(classesAry[i].replace('formStyle', ''));
        }
    }

    return styleId;
}

/**
 * create from moduleManage.js
 * 删除Mobi.xxx依赖
 * @param {Number} moduleId 模块Id
 * @param {Number} delay delay
 */
export function addModuleAnimate(moduleId, delay = 0) {
    // 标题栏隐藏后再执行动画,0s延迟实际会按浏览器默认的延迟秒数执行,此时标题栏已隐藏
    setTimeout(function () {
        // 生成遮罩效果
        addOverlay(moduleId);

        let $preview = $('#preview');

        // 遮罩层绑定事件,新增模块页面滚动条滚动后再绑定
        setTimeout(function () {
            var $findModuleDiv = $preview.find('#findModuleDiv');
            var $gWeb = $preview.find('#g_web');
            $gWeb.one('scroll.clearOverLay', function () {
                removeOverlay();
            });

            $findModuleDiv.one('mouseenter click', function () {
                removeOverlay();
            });
        }, 300);
    }, delay);
}

/**
 * create from moduleManage.js
 * @param {Number} moduleId 模块ID
 */
function addOverlay(moduleId) {
    let $module = $('#module' + moduleId);
    let baseTop = $('#preview').offset().top;
    let baseLeft = $('#preview').offset().left;

    if ($module.length <= 0) {
        return;
    }

    let maxHeight = parseInt($('#preview').outerHeight()) - ($module.offset().top - baseTop);
    let divStr =
        "<div id='findModuleDiv' style='position:absolute; top:" +
        ($module.offset().top - baseTop - 8) +
        'px; left:' +
        ($module.offset().left - baseLeft - 3) +
        "px; border:2px solid #f40;border-top-width:7px; z-index:100;'>" +
        "<div style='border:1px solid #fff;'>" +
        "<div style='background:#000; opacity:0.4; filter:alpha(opacity=40);width:" +
        $module.outerWidth() +
        'px; height:' +
        Math.min($module.outerHeight(), maxHeight) +
        "px; '></div>" +
        "<div style='position:absolute;left:50%;top:50%;margin-left:-25px;margin-top:-25px;width:51px;height:51px;background:transparent url(" +
        Fai.top._resRoot +
        "/image/tip.gif?v=201404211853) no-repeat 0 0;opacity:0.8; filter:alpha(opacity=80);'></div>" +
        '</div>' +
        '</div>';

    removeOverlay();
    $('#preview').append(divStr);
}

/**
 * create from moduleManage.js
 * @param {Number} moduleId 模块ID
 */
export function removeOverlay() {
    let $preview = $('#preview');
    $preview.find('#findModuleDiv').remove();
    $preview.find('#g_web').off('.clearOverLay');
}

//将停靠模块移动屏幕中部显示
export const showThisModule = function (id) {
    var module = $('#module' + id),
        nestInfo = checkNestModule(module);
    if (typeof module == 'undefined' || module.length < 1) {
        return;
    }

    //如果在标签模块，切换到对应的tab再滚动
    if (nestInfo.inTab) {
        (module = $('#module' + nestInfo.parentId)), $('#formTabButton' + id).trigger('click');
    }

    if (!nestInfo.inPack) {
        const distance = module.offset().top - $('#g_web').offset().top - getHeaderHeight();
        module.length > 0 && $('#g_web').scrollTop($('#g_web').scrollTop() + distance);
    }

    if (nestInfo.inPopupZone) return; // 弹窗不用隐藏滚动

    addModuleAnimate(id);
};

// 判断是否为嵌套类型的模块
export function checkNestModule(module) {
    var result = {
            nest: false, //是否为嵌套模块
            isTab: false, // 模块组
            isPack: false, // 自由容器
            isFold: false, // 折叠标签
            isPopupZone: false, // 弹窗容器
            isFloatZone: false, // 悬浮容器
            parentId: 0, // 父模块id
            inTab: false, // 父模块是模块组
            inPack: false, // 父模块是自由容器
            inFold: false, // 父模块是折叠标签组
            inPopupZone: false, // 父模块是弹窗容器
            inFloatZone: false, // 父模块是悬浮容器
            inList: [], // 子模块idList
        },
        tabModuleId,
        packModuleId,
        foldModuleId,
        inTab = parseInt(module.attr('_intab')) || 0,
        inPack = parseInt(module.attr('_inpack')) || 0,
        inFold = parseInt(module.attr('_infold')) || 0,
        inPopupZone = parseInt(module.attr('_inpopupzone')) || 0,
        inFloatZone = parseInt(module.attr('_infloatzone')) || 0;

    if (module.hasClass('formStyle50')) {
        result.nest = true;
        result.isTab = true;

        module.find('.J_formTabButton').each(function (index, el) {
            tabModuleId = parseInt(jm(el).attr('tabmoduleid'));
            if (!isNaN(tabModuleId)) {
                result.inList.push(tabModuleId);
            }
        });
    } else if (module.hasClass('formStyle51')) {
        result.nest = true;
        result.isPack = true;

        module.find('.J_formPackContent .form').each(function (index, el) {
            packModuleId = parseInt(jm(el).attr('id').replace('module', ''));
            if (!isNaN(packModuleId)) {
                result.inList.push(packModuleId);
            }
        });
    } else if (module.hasClass('formStyle58')) {
        result.nest = true;
        result.isFold = true;

        module.find('.J_formFoldItem .form').each(function (index, el) {
            foldModuleId = parseInt(jm(el).attr('id').replace('module', ''));
            if (!isNaN(foldModuleId)) {
                result.inList.push(foldModuleId);
            }
        });
    } else if (module.hasClass('formStyle62')) {
        result.nest = true;
        result.isPopupZone = true;
    } else if (module.hasClass('formStyle103')) {
        result.nest = true;
        result.isFloatZone = true;
    }

    if (inTab > 0) {
        result.parentId = inTab;
        result.inTab = true;
    } else if (inPack > 0) {
        result.parentId = inPack;
        result.inPack = true;
    } else if (inFold > 0) {
        result.parentId = inFold;
        result.inFold = true;
    } else if (inPopupZone > 0) {
        result.parentId = inPopupZone;
        result.inPopupZone = true;
    } else if (inFloatZone > 0) {
        result.parentId = inFloatZone;
        result.inFloatZone = true;
    }

    return result;
}

//专门给模块添加、删除、隐藏用的,用来定位滚动条的。(Firfox下$("body").scrollTop不起作用)
function getHeaderHeight() {
    if (Fai.top._openThemeV3) {
        let webHeaderBoxV3 = $('#webHeader_v3'),
            styleId = $(webHeaderBoxV3).attr('styleId'),
            headerHeight = parseInt($(webHeaderBoxV3).height());
        if (styleId < 5) {
            return headerHeight;
        }
    } else {
        let webHeaderBox = getPreviewObject('webHeaderBox');
        if ($(webHeaderBox).css('position') == 'fixed') {
            return parseInt($(webHeaderBox).height());
        }
    }
    return 0;
}
