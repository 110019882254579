import { MobiModule } from '@/modules/Module';
import NavView from './module/index.vue';

class _Nav extends MobiModule {
    constructor() {
        super(null /** 系统模块没有style */, { system: true });
    }

    render() {
        return NavView;
    }
}

export const Nav = new _Nav();
