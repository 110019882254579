<template>
    <component :is="footer"></component>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
    name: 'WebFooter',
    data() {
        return {};
    },
    computed: {
        ...mapState('app', ['openThemeV3', 'mobiFooterClose', 'independentSetFooter', 'independentSetFooterHide']),
        ...mapGetters('footer', ['footerStyleGetters']),
        footer() {
            return this.openThemeV3
                ? () => import('@/system/footer/module/v3/Footer.vue')
                : () => import('@/system/footer/module/v2/Footer.vue');
        },
    },
};
</script>

<style lang="scss"></style>
